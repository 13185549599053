class UtilAlerta {

	static ID = 0;
	static LOGGER = UtilLog.getLogger(UtilAlerta.name);

	static exibirAlerta(cfgs: CfgsAlerta): Promise<void> {

		return new Promise(async (resolve) => {

			const isAlertaComBloqueio = cfgs.blockUICallback; 

			if (!isAlertaComBloqueio && !cfgs.botoes) {
				cfgs.botoes = [{ label: UtilMsg.getMsg("MSG_VH_003") }];
			}
	
			if ($(window).width() == 0) {
				UtilAlerta.LOGGER.error("Abortando pois não há width na window para exibir o alerta " + JSON.stringify(cfgs));
				return;
			}
	
			cfgs.id = cfgs.id || "modal_alerta_" + UtilAlerta.ID++;
			let $modal = $("div.alerta.modal");
			let css = "margin-top: 10px";
	
			if ($modal.length == 0) {
				$modal = $("body")
					.append(`<div id='${cfgs.id}' class='alerta modal fade ${cfgs.classe || ''}' tabindex='-1' role='dialog' aria-labelledby='myModalLabel' aria-hidden='true'></div>`)
					.find("#" + cfgs.id);
				css = "";
			}
	
			const styleCloseBtn = isAlertaComBloqueio ? ` style="display: none" ` : "";
			const $modalDialog = $(`
				<div class="modal-dialog msg-alerta" style="${css}">
					<div class="modal-content">
						<div class="modal-header" ${cfgs.titulo ? "" : 'style="border-bottom: none; padding-bottom: 0px;"'}>
							<button type="button" class="close" data-dismiss="modal" aria-hidden="true" ${styleCloseBtn}>×</button>
							<h3 id="myModalLabel" aria-label="${UtilHtml.removeTagsHtml(cfgs.titulo) || ''}" tabindex="0">
								${cfgs.titulo || "&nbsp;"}
							</h3>
						</div>
						<div class="modal-body">
							<p aria-label="${UtilHtml.removeTagsHtml(cfgs.msg)}" tabindex="0">
								${cfgs.msg}
							</p>
						</div>
						${cfgs.botoes?.length ? '<div class="modal-footer"><div class="btn-group"></div></div>' : ''}
					</div>
				</div>
			`);
	
			$modal.append($modalDialog);
	
			let indiceBotaoClicado = null;
	
			cfgs.botoes?.forEach((b, i) => {
				
				const id = b.id || "modal_btn_" + UtilAlerta.ID++;
				const classe = b.classe || "";
				const css = b.css || "";

				$modalDialog.find(".modal-footer .btn-group")
					.append(`
						<button id="${id}" acao-popup data-indice="${i}" class="btn btn-default ${classe}" style="${css}" aria-label="${UtilHtml.removeTagsHtml(b.label)}" data-dismiss="modal" aria-hidden="true" tabindex="0">
							${b.label}
						</button>
					`)
					.find("[acao-popup][data-indice='" + i + "']")
					.on("click", ({ target }) => {
						indiceBotaoClicado = $(target).data("indice");
					});
			});
	
			$modal.modal({
				backdrop: isAlertaComBloqueio ? "static" : true,
				show: true
			});

			$modal.on("hidden.bs.modal", async () => {

				try {
					$modal.data('bs.modal').$backdrop?.remove();
				} catch (ignored) {}

				try {
					$modal.remove();
				} catch (ignored) {}

				try {
					$("body").removeClass("modal-open");
				} catch (ignored) {}

				resolve();

				if (indiceBotaoClicado != null && cfgs.botoes && cfgs.botoes[indiceBotaoClicado] && cfgs.botoes[indiceBotaoClicado].onClick) {
					await cfgs.botoes[indiceBotaoClicado].onClick(this);
				}

				if ($('div[modal]').length) {
					$("body").addClass("modal-open");
				}

				if (cfgs.onHidden) {
					try {
						await cfgs.onHidden();
					} catch (e) {
						UtilAlerta.LOGGER.error("Erro onHidden", e);
					}
				}
			});
	
			setTimeout(() => {
				AmaisVH.dispararAjusteAcessibilidade();
				amaisVH.adicionarEventoRegistrarClickBotao();
			}, 300);
	
			$(document).trigger("fp-alerta-exibido", $modal);
			
			if (cfgs.blockUICallback) {

				try {
					await cfgs.blockUICallback($modal.find(".modal-body p"));

				} catch (e) {
					UtilAlerta.LOGGER.error("Erro no blockUICallback", e);
					
				} finally {
					$modal.modal("hide");
				}
			}
		});
	}
}

type CfgsAlerta = {
	id?: string;
	msg: string;
	titulo?: string;
	classe?: string;
	botoes?: any[];
	blockUICallback?: Function;
	onHidden?: Function;
}
class UtilWindow {

	static LOGGER: any = null;

	static isTouchDevice() {
		const DocumentTouch = window["DocumentTouch"];
		return true === ("ontouchstart" in window || (DocumentTouch && document instanceof DocumentTouch));
	}

	static isFullScreen() {
		return !(document.fullscreenElement !== undefined && document.fullscreenElement === null);
	}

	static async cancelarFullScreen() {
		if (!UtilWindow.isFullScreen()) return;

		if (document.exitFullscreen) {
			await document.exitFullscreen();
		}

		UtilWindow.updateRolagemFullScreen();
	}

	static async ativarFullScreen() {
		
		if (UtilWindow.isFullScreen()) return;
		
		if (document.body.requestFullscreen) {
			await document.body.requestFullscreen();
			UtilWindow.inserirContainerParaRolagem();
		}
	}

	static getIdContainerFullScreen(): string {
		return "fp_container_rolagem_full_screen";
	}

	static inserirContainerParaRolagem() {
		let container = document.getElementById(UtilWindow.getIdContainerFullScreen());

		if (container) {
			UtilWindow.updateRolagemFullScreen();
			return;
		}

		// // precisa esperar a tela redimensionar
		// setTimeout(() => {
			const body = document.getElementsByTagName("body")[0];
			const divBody = document.getElementById("divBody");
			const containerRodape = document.getElementById("containerRodape");

			container = document.createElement("div");

			container.id = UtilWindow.getIdContainerFullScreen();
			container.style.overflow = "auto";
			container.style.display = "block";
			container.style.height = window.innerHeight + "px";
			container.style.width = "100%";
			container.style.backgroundColor = "white";

			const secao = document.createElement("div");

			secao.style.display = "table";
			secao.style.height = "100%";
			secao.style.width = "100%";
			secao.style.backgroundColor = "white";

			container.appendChild(secao);

			body.appendChild(container);

			secao.appendChild(divBody);
			secao.appendChild(containerRodape);

			document.querySelector("html").style.overflow = "hidden";

		// }, 1000);
	}

	static updateRolagemFullScreen() {
		const container = document.getElementById(UtilWindow.getIdContainerFullScreen());

		if (!container) return;

		// setTimeout(() => {
			container.style.height = window.innerHeight + "px";
		// }, 500);
	}

	static tornarArrastavel(e: HTMLElement) {

		if ($(e).data("is-arrastavel")) return;

		let xAnteriorMouse = 0;
		let yAnteriorMouse = 0;
		
		const encerrar = () => {
			document.onmouseup = null;
			document.onmousemove = null;
		}

		const handleMovimentoMouse = (event: MouseEvent) => {
			
			event.preventDefault();
			
			const diffX = xAnteriorMouse - event.clientX;
			const novoLeft = Math.max(0, e.offsetLeft - diffX);
			const diffY = yAnteriorMouse - event.clientY;
			const novoTop = Math.max(0, e.offsetTop - diffY);

			xAnteriorMouse = event.clientX;
			yAnteriorMouse = event.clientY;

			if (novoTop + e.offsetHeight > window.innerHeight) return;
			if (novoLeft + e.offsetWidth > window.innerWidth) return;
			
			e.style.top = novoTop + "px";
			e.style.left = novoLeft + "px";
		}

		const handleResize = () => {
			e.style.removeProperty('top');
			e.style.removeProperty('left');
		};

		const iniciar = (event: MouseEvent) => {
			event.preventDefault();
			e.style.height = e.offsetHeight + "px";
			xAnteriorMouse = event.clientX;
			yAnteriorMouse = event.clientY;
			document.onmouseup = encerrar;
			document.onmousemove = handleMovimentoMouse;
		}

		e.onmousedown = iniciar;
		$(e).data("is-arrastavel", true);

		window.addEventListener("resize", handleResize);
	}

	static centralizar(e: HTMLElement) {
		const w = $(e).outerWidth() || e.offsetWidth || parseInt(e.style.width);
		e.style.left = ((window.innerWidth - w) / 2) + "px";
	}

	static isZoomed() {
		return window.devicePixelRatio !== 1;
	}

	static isInViewport(el: HTMLElement) {

		if (UtilWindow.isFullScreen()) {

			const divScroll = document.getElementById(UtilWindow.getIdContainerFullScreen());
			const pageTop = divScroll.scrollTop;
			const pageBottom = pageTop + divScroll.clientHeight;
			const elTop = $(el).offset().top;
			const elBottom = elTop + $(el).height();

			return elBottom > 0 && elTop <= pageBottom;

		} else {

			const pageTop = $(window).scrollTop();
			const pageBottom = pageTop + $(window).height();
			const elTop = $(el).offset().top;
			const elBottom = elTop + $(el).height();

			return ((elTop <= pageBottom) && (elBottom >= pageTop));
		}
	}

	static getScrollPercentage() {

		let scrollPercent;

		if (UtilWindow.isFullScreen()) {
			const divScroll = document.getElementById(UtilWindow.getIdContainerFullScreen());

			scrollPercent = divScroll.scrollTop / (divScroll.scrollHeight - divScroll.clientHeight);

		} else {
			const scrollTop = $(window).scrollTop();
			const docHeight = $(document).height();
			const winHeight = $(window).height();

			scrollPercent = (scrollTop) / (docHeight - winHeight);
		}

		return Math.round(scrollPercent * 100);
	}

	static scrollTo(seletorJQuery: string, isDestacar = false) {

		if (seletorJQuery === null || seletorJQuery === undefined || !seletorJQuery) return;
		
		const $div = $(seletorJQuery);

		if (!$div.length) return;

		setTimeout(() => {
			let container;
			let top;
			
			if (UtilWindow.isFullScreen()) {
				container = document.getElementById(UtilWindow.getIdContainerFullScreen());
				top = container.scrollTop + $div.offset().top;
				
			} else {
				container = window;
				top = $div.offset().top;
			}

			try {
				container.scroll({top: top, left: 0, behavior: 'smooth' });
			} catch (_) {
				try {
					container.scrollTo(0, top);
				} catch (_) {}
			}

			if (isDestacar) {
				try {
					$div.removeClass("borderBlink").addClass("borderBlink");
				} catch (_) {}
			}
			
		}, 100);
	}

	static getWidth(): number {
		return $(window).width();
	}

	static getLogger(): any {
		if (!UtilWindow.LOGGER) {
			UtilWindow.LOGGER = UtilLog.getLogger(UtilWindow.name);
		}
		return UtilWindow.LOGGER;
	}
}
class UtilMsg {

	static mensagens = {};
	static locale: string = "pt_BR";

	static setLocale(locale: string) {
		UtilMsg.locale = locale;
	}

	static setMensagens(locale: string, msgs: Map<string, string>) {
		UtilMsg.mensagens[locale] = msgs;
	}
	
	static getMsg(idMsg: string, ...params): string {

		if (!UtilMsg.mensagens) return idMsg;

		let msgsLocale = UtilMsg.mensagens[UtilMsg.locale];

		if (msgsLocale == null) return idMsg;

		let msg = msgsLocale[idMsg];

		if (msg == null) return idMsg;

		msg = UtilMsg.substituirLabels(msg);

		return UtilMsg.substituirParametros(msg, ...params);
	}
	
	static substituirLabels(msg: string): string {
		return msg.replace(/LABEL_[A-Z_]+/g, (idLabel, i) => {
			const label = UtilCfg.getCfg(idLabel);
			if (label == null) return idLabel;
			if (i == 0) return label;
			return label.toLowerCase();
		});
	}

	static substituirParametros(msg, ...params): string {
		return msg.replace(/\{(\d+)\}/g, (txt, i) => {
			return params[i];
		});
	}
}


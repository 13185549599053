class UtilPasswordValidator {

    static passwordRegexp(passw, regexp) {
        return regexp.test(passw);
    }

    static isSenhaForte(inputId: string) {
		const input = <HTMLInputElement> document.getElementById(inputId);

		if (!input) return true;

		const strengthBarContainer = input.closest(".form-group")?.querySelector('.password-strength-bar-block');
		const pw = input.value;
		
		if (!strengthBarContainer) return true; // fp-custom: para permitir desabilitar
		
        const basicRegexp = /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?!.*([a-zA-Z\d!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,.\/?])\1\1)[A-Za-z\d!@#$%^&*()\\[\]{}\-_+=~`|:;"'<>,.\/?]{8,}$/gmi;
        return UtilPasswordValidator.passwordRegexp(pw, basicRegexp);
    }

    static passwordError(passw) {
        const errorSymbols = /\s/g;
        return UtilPasswordValidator.passwordRegexp(passw, errorSymbols);
    }

    static validation(passw) {

        let strength = 'none';

        const moderate = /^(?=.*[A-Z])(?=.*[a-z])(?=.{5,})(?!.*([a-zA-Z0-9])\1\1)|(?=.*\d)(?=.*[a-z])(?=.{5,})(?!.*([a-zA-Z0-9])\2\2)|(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.{5,})(?!.*([a-zA-Z0-9])\3\3)$/gmi;
        const strong = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?!.*([a-zA-Z\d])\1\1).{7,}|(?=.*[!@#$%^&*()[\]{}\-_+=~`|:;"'<>,./?])(?=.*[a-z])(?=.*\d)(?!.*([a-zA-Z\d])\2\2).{7,}$/gmi;
        const extraStrong = /^(?=.*[A-Z])(?=.*[a-z])(?=.*\d)(?=.*[!@#$%^&*()[\]{}\-_+=~`|:;"'<>,./?])(?!.*([a-zA-Z\d!@#$%^&*()[\]{}\-_+=~`|:;"'<>,./?])\1\1).{9,}$/gmi;

        if (UtilPasswordValidator.passwordRegexp(passw, extraStrong)) {
            strength = 'extra';

        } else if (UtilPasswordValidator.passwordRegexp(passw, strong)) {
            strength = 'strong';

        } else if (UtilPasswordValidator.passwordRegexp(passw, moderate)) {
            strength = 'moderate';

        } else if (passw.length > 0) {
            strength = 'weak';
        }

        return strength;
    };

    static getPasswordValue(passw, regexp) {
        return regexp.test(passw);
    };

    static setStrengthBarValue(bar, strength) {
        let strengthValue;

        switch (strength) {
            case 'weak':
                strengthValue = 25;
                bar.setAttribute('aria-valuenow', strengthValue);
                break;

            case 'moderate':
                strengthValue = 50;
                bar.setAttribute('aria-valuenow', strengthValue);
                break;

            case 'strong':
                strengthValue = 75;
                bar.setAttribute('aria-valuenow', strengthValue);
                break;

            case 'extra':
                strengthValue = 100;
                bar.setAttribute('aria-valuenow', strengthValue);
                break;

            default:
                strengthValue = 0;
                bar.setAttribute('aria-valuenow', String(0));}

        return strengthValue;
    };

    static setStrengthBarStyles(bar, strengthValue) {

        bar.style.width = `${strengthValue}%`;

        bar.classList.remove('bg-success', 'bg-info', 'bg-warning');

        switch (strengthValue) {
            case 25:
                bar.classList.add('bg-danger');
                bar.textContent = UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_036');
                break;

            case 50:
                bar.classList.remove('bg-danger');
                bar.classList.add('bg-warning');
                bar.textContent = UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_037');
                break;

            case 75:
                bar.classList.remove('bg-danger');
                bar.classList.add('bg-info');
                bar.textContent = UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_038');
                break;

            case 100:
                bar.classList.remove('bg-danger');
                bar.classList.add('bg-success');
                bar.textContent = UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_039');
                break;

            default:
                bar.classList.add('bg-danger');
                bar.textContent = '';
                bar.style.width = `0`;}
    };

    static setStrengthBar(bar, strength) {
        const strengthValue = UtilPasswordValidator.setStrengthBarValue(bar, strength);
        UtilPasswordValidator.setStrengthBarStyles(bar, strengthValue);
    };

    static showStrengthBar(bar) {
        bar.style.display = '';
    }

    static hideStrengthBar(bar) {
        bar.style.display = 'none';
    }

    static setForcaSenha(input, passw, strengthBar, strengthBarContainer) {
        const error = UtilPasswordValidator.passwordError(passw);
        if (error || !passw || passw === '') {
            UtilPasswordValidator.hideStrengthBar(strengthBarContainer);
        } else {
            UtilPasswordValidator.showStrengthBar(strengthBarContainer);
            const strength = UtilPasswordValidator.validation(passw);
            UtilPasswordValidator.setStrengthBar(strengthBar, strength);
        }
    }

	static getHtmlBarraForcaSenha(): any {
		return `
			<div class="password-strength-bar-block progress" style="display: none;">
			  <div class="password-strength-bar progress-bar bg-danger" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100"></div>
			</div>
		`;
	}
}
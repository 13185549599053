class AplicacaoExternaVH extends AmaisVH {

	private tokenDeIdentificacaoAplicacao: any;
	private dadosParaEnvioResultadoLTITO: null;

	constructor() {
		super(AplicacaoExternaVH.name);
		this.addOperacaoParaHash("tia", this.exibirTelaInicial);
		this.addOperacaoParaHash("ae", this.verificarHabilitacaoUsuarioNaAplicacao);
	}

	async exibirTelaInicial(tokenDeIdentificacaoAplicacao, codTurmaAplicacao) {

		this.tokenDeIdentificacaoAplicacao = tokenDeIdentificacaoAplicacao;

		UtilHash.registrarHistorico(this.exibirTelaInicial, tokenDeIdentificacaoAplicacao);

		let exibicaoPaginaAplicacaoExternaTO = null;

		try {
			exibicaoPaginaAplicacaoExternaTO = await this.call("AplicacaoExternaFCD/recuperarAplicacao", tokenDeIdentificacaoAplicacao);
		} catch (ignored) {}

		if (exibicaoPaginaAplicacaoExternaTO == null) {
			await loginVH.exibirTelaDeLogin();
			return;
		}

		if (exibicaoPaginaAplicacaoExternaTO.isAcessoUsuarioAmbienteAdm && exibicaoPaginaAplicacaoExternaTO.codProva) {
			const filtrosDetalhamento = acompanhamentoCDsVH.filtrosDetalhamentoCorrecoesProva(exibicaoPaginaAplicacaoExternaTO.codProva, exibicaoPaginaAplicacaoExternaTO.nomeProva);
			const resultadoDetalhamentoCDTO = await this.call("AcompanhamentoCDsFCD/listarCDs", filtrosDetalhamento.cfgsRelIndiceRespostasTO, filtrosDetalhamento.grupoAcompanhamentoCDTO.cod);
			const possuiCorrecaoPendente = resultadoDetalhamentoCDTO?.possuiCorrecoesPendentes === true;
			const naoTemAcessoAoCadastroDaProva = resultadoDetalhamentoCDTO?.isPodeVisualizarCadastroProva !== true;

			if (possuiCorrecaoPendente || naoTemAcessoAoCadastroDaProva) {
				const detalhesAplicacao = {
					codProva: exibicaoPaginaAplicacaoExternaTO.codProva, codAgendamento: exibicaoPaginaAplicacaoExternaTO.codAgendamento
				}
				acompanhamentoCDsVH.exibirDetalhamentoCorrecoesProva(exibicaoPaginaAplicacaoExternaTO.codProva, exibicaoPaginaAplicacaoExternaTO.nomeProva, detalhesAplicacao);

			} else {
				await cadastroProvaVH.exibirAbasProva(5, exibicaoPaginaAplicacaoExternaTO.codProva, null, null, false, exibicaoPaginaAplicacaoExternaTO.codAgendamento);
			}

			return;
		}

		if (exibicaoPaginaAplicacaoExternaTO.jaEstaLogado) {
			await this.verificarHabilitacaoUsuarioNaAplicacao(tokenDeIdentificacaoAplicacao);
			return;
		}

		this.limpar();

		let isLiberadaParaVisitantes = exibicaoPaginaAplicacaoExternaTO.isLiberadaParaVisitantes;

		this.setTitulo(exibicaoPaginaAplicacaoExternaTO.tituloPagina);
		document.title = exibicaoPaginaAplicacaoExternaTO.tituloPagina;

		const classe = isLiberadaParaVisitantes ? "col-md-4" : "col-md-6";
		const nomeLink = exibicaoPaginaAplicacaoExternaTO.nomeLink || "";

		this.append(`
			<div pagina-externa-aplicacao-apresentacao class="col-xs-12 ${classe}" nome-link-externo="${nomeLink}">
		`)

		if (this.isEmpty(exibicaoPaginaAplicacaoExternaTO.instrucoes)) {
			this.append("<p>" + this.getMsg("MSG_VH_AE_01") + " <b>" + exibicaoPaginaAplicacaoExternaTO.tituloPagina + "</b>");
			this.append(" " + this.getMsg("MSG_VH_AE_02") + " <b>" + exibicaoPaginaAplicacaoExternaTO.nomeEmpresa + "</b>.</p>");

			if (isLiberadaParaVisitantes) {
				this.append("<p>" + this.getMsg("MSG_VH_AE_03") + "</p>");

			} else {
				this.append("<p>" + this.getMsg("MSG_VH_AE_04") + "</p>");
			}

			this.append("<p>" + this.getMsg("MSG_VH_AE_05") + "</p>");
		} else {
			this.append(exibicaoPaginaAplicacaoExternaTO.instrucoes)
		}

		this.append("</div>");

		this.append("<div pagina-externa-aplicacao-login class='col-xs-12 " + (isLiberadaParaVisitantes ? "col-md-8" : "col-md-6") + "'>");
		this.append("<div class='row'>");

		if (isLiberadaParaVisitantes) {
			this.append(`<div container-login="true" class='col-xs-12 col-md-6'>`);
		}

		const cfgsLoginTO = new CfgsLoginTO();

		cfgsLoginTO.habilitarMascaraCPFNoLogin = exibicaoPaginaAplicacaoExternaTO.habilitarMascaraCPFNoLogin;
		cfgsLoginTO.nomeLabelLogin = exibicaoPaginaAplicacaoExternaTO.nomeLabelLogin;
		cfgsLoginTO.habilitarMascaraCPFNaSenha = false;
		cfgsLoginTO.onBeforeLogin = () => {
			if (exibicaoPaginaAplicacaoExternaTO.habilitarMascaraCPFNoLogin) {
				let userNameLogin = $("#userNameLogin input[name='username']");
				let cpf = userNameLogin().val();
				let cpfSoNumeros = cpf.replace("-", "").replace(".", "").replace(".", "");
				userNameLogin.val(cpfSoNumeros);
			}
		};
		cfgsLoginTO.onUsuarioAutenticado = () => {
			const url = new URL(document.URL);
			this.logger.info("Login aplicação externa OK. Abrindo index");
			url.pathname = "/";
			url.hash = "#/ae/" + tokenDeIdentificacaoAplicacao;
			document.location = url.href;
		}

		loginVH.montarHtmlTelaDeLogin(cfgsLoginTO);

		if (isLiberadaParaVisitantes) {
			this.append("</div>");
		}

		if (isLiberadaParaVisitantes) {
			this.append("<div class='col-xs-12 col-md-6'>");

			await this.montarTelaNovoCadastro(exibicaoPaginaAplicacaoExternaTO, async (dadosUsuario) => {
				await this.habilitarNovoCadastro(exibicaoPaginaAplicacaoExternaTO, dadosUsuario);
			});
			// 	listaOpcaoListaTOAgendamentos

			this.append("</div>");
		}

		this.append("</div>");

		this.addEspacamentoHorizontal("1px");

		this.fecharFormulario();

		this.append("</div>")

		this.exibir();

		let inscricaoPerguntasAdd = $("[data-fp-inscricao-perguntas-adicionais]");
		inscricaoPerguntasAdd.data("locale", AmaisVH.localeDoUsuarioLogado);

		try {
			await questVH.exibirQuestionario(
				inscricaoPerguntasAdd,
				exibicaoPaginaAplicacaoExternaTO.questionarioPreProva
			);
		} catch (e) {
			this.logger.info("Questionário não carregou.", e);
			this.handleErroCarregarQuestionario();
			return;
		}
	}

	async montarTelaNovoCadastro(exibicaoPaginaAplicacaoExternaTO: any, onCriarCadastro: Function) {

		const $corpo = $("#corpo");

		$corpo.attr("ignorar-email-boas-vindas", exibicaoPaginaAplicacaoExternaTO.isIgnorarEmailBoasVindas);
		$corpo.attr("cod-agendamento", exibicaoPaginaAplicacaoExternaTO.codAgendamento);

		const salvarCadastro = async () => {

			const idsCamposObrigatorios = $(`
				#login_avaliacao_externa input[id^="novo_"],
				#login_avaliacao_externa select[id^="novo_"],
				#cep_complemento
			`).map((i, elemento) => elemento.id).get();

			if (exibicaoPaginaAplicacaoExternaTO.idQuestionario && !$("[data-fp-inscricao-perguntas-adicionais]").data('fp-ja-exibiu')) {
				this.handleErroCarregarQuestionario();
				return;
			}

			if (!this.validarCamposObrigatorios(idsCamposObrigatorios)) return;
			
			if (!this.conferirSenhas()) return;

			if (UtilNumero.isCPFValido(this.getValor("novo_cpf")) === false) {
				await this.exibirAlerta({
					msg: this.getMsg("FP_FRONT_CadastroUsuarioVH_073"),
					botoes: [{
						label: 'Ok',
						classe: 'btn-primary',
						css: "background-color: #92278F; border-color: #6b1e69",
					}]
				});

				this.focar("novo_cpf");
				return;
			}

			this.toggleBotaoInscricao(true);

			let dadosUsuario = idsCamposObrigatorios.filter(
				id => id.startsWith("novo_")
			).reduce((dados, id) => {
				let nomeCampo = id.substring("novo_".length);
				dados[nomeCampo] = this.getValor(id);
				return dados;
			}, {});

			const complemento = this.getValor("cep_complemento");

			if (this.hasValue(complemento)) {
				const endereco = dadosUsuario["endereco"];
				if (this.hasValue(endereco)) {
					dadosUsuario["endereco"] = endereco + " - " + complemento;
				} else {
					dadosUsuario["endereco"] = complemento;
				}
			}

			if (!dadosUsuario.codAgendamento) {
				dadosUsuario.codAgendamento = exibicaoPaginaAplicacaoExternaTO.codAgendamento;
			}

			await onCriarCadastro(dadosUsuario);
		}

		this.setMetodoSubmit(salvarCadastro);
		this.append("<div class='panel panel-default login' style='max-width: 100%; margin: 0'>")
		this.append("<div id='divLogin' class='panel-body' fp-container-nova-inscricao>")

		this.append("<h4 class='col-sm-12' style='margin-bottom: 30px'>" + this.getMsg("MSG_VH_L_71") + "</h4>");

		this.addFormulario({
			id: "login_avaliacao_externa",
			acao: "login",
			metodo: "post",
			autocomplete: false
		});

		const addCpf = (sufixo: string = "") => {
			this.addCampoTexto({
				id: "novo_cpf",
				dica: this.getMsg("MSG_VH_L_76") + sufixo,
				classe: "col-md-12 col-xs-12",
				tipo: "CPF",
				obrigatorio: true,
			});
		}
		const addEmail = (sufixo: string = "") => {
			this.addCampoTexto({
				id: "novo_email",
				dica: this.getMsg("MSG_VH_L_74") + sufixo,
				obrigatorio: true,
				classe: "col-md-12 col-xs-12",
				attrs: "autocomplete='new-email'"
			});
		}
		const addMatricula = (sufixo: string = "") => {
			this.addCampoTexto({
				id: "novo_matricula",
				dica: this.getMsg("MSG_VH_L_77") + sufixo,
				obrigatorio: true,
				classe: "col-md-12 col-xs-12"
			});
		}

		const sufixoLogin = " (Login)";
		let idCampoLogin: string;

		if (exibicaoPaginaAplicacaoExternaTO.campoLogin === "cpf") {
			addCpf(sufixoLogin);
			idCampoLogin = "novo_cpf";

		} else if (exibicaoPaginaAplicacaoExternaTO.campoLogin === "email") {
			addEmail(sufixoLogin);
			idCampoLogin = "novo_email";

		} else if (exibicaoPaginaAplicacaoExternaTO.campoLogin === "matricula") {
			addMatricula(sufixoLogin);
			idCampoLogin = "novo_matricula";

		} else {
			idCampoLogin = "novo_login";
			this.addCampoTexto({
				id: "novo_login",
				dica: this.getMsg("MSG_VH_L_78"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12",
			});
		}

		this.appendJs(() => {
			$(`#${idCampoLogin}`).on("change", async ({ target }) => {
				await this.verificarLoginDisponivel(
					exibicaoPaginaAplicacaoExternaTO.tokenDeIdentificacaoAplicacao,
					this.getValor(target)
				);
			})
		})
		
		this.addCampoTexto({
			id: "novo_senha",
			dica: this.getMsg("MSG_VH_L_80"),
			classe: "col-md-12 col-xs-12",
			obrigatorio: true,
			tipo: "SENHA",
			attrs: "autocomplete='new-password'",
			validacaoSenha: true,
			onChange: async () => await this.conferirSenhas()
		});

		this.addCampoTexto({
			id: "novo_senha_confirmacao",
			dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_004"),
			classe: "col-md-12 col-xs-12",
			obrigatorio: true,
			tipo: "SENHA",
			attrs: "autocomplete='new-password'",
			onChange: async () => await this.conferirSenhas()
		});

		exibicaoPaginaAplicacaoExternaTO.camposHabilitados = exibicaoPaginaAplicacaoExternaTO.camposHabilitados.filter(
			(campo) => campo !== exibicaoPaginaAplicacaoExternaTO.campoLogin
		);

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("nome")) {
			this.addCampoTexto({
				id: "novo_nome",
				dica: this.getMsg("MSG_VH_L_73"),
				classe: "col-md-12 col-xs-12",
				obrigatorio: true
			})
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("email")) {
			addEmail();
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("cpf")) {
			addCpf();
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("rg")) {
			this.addCampoTexto({
				id: "novo_rg",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_005"),
				classe: "col-md-12 col-xs-12",
				obrigatorio: true,
			})
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("nomeMae")) {
			this.addCampoTexto({
				id: "novo_nomeMae",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_006"),
				classe: "col-md-12 col-xs-12",
				obrigatorio: true
			})
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("apelido")) {
			this.addCampoTexto({
				id: "novo_apelido",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_007"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12"
			})
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("dataNascimento")) {
			this.addCampoTexto({
				id: "novo_dataNascimento",
				dica: "Data de nascimento",
				tipo: "DATA",
				obrigatorio: true,
				classe: "col-md-12 col-xs-12"
			})
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("telefone")) {
			this.addCampoTexto({
				id: "novo_telefone",
				dica: this.getMsg("MSG_VH_L_75"),
				classe: "col-md-12 col-xs-12",
				obrigatorio: true,
				tipo: "TELEFONE"
			})
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("matricula")) {
			addMatricula();
		}

		const possuiCEP = exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("cep");

		if (possuiCEP) {
			this.addCampoTexto({
				id: "novo_cep",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_010"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12",
				tipo: "CEP",
				prefixo: "&nbsp;",
				onChange: async () => {

					this.setValor("novo_endereco", "");
					this.setValor("novo_cidade", "");
					this.setValor("novo_uf", "");

					const cep = this.getValor("novo_cep");

					if (this.isEmpty(cep)) return;

					const $prefixo = $("#novo_cep").closest(".form-group").find(".input-group-addon");

					$prefixo.html(`
						<i class="fa fa-spinner fa-pulse"></i>
					`);

					try {
						const response = await fetch(`https://viacep.com.br/ws/${cep}/json/`);

						if (!response.ok) {
							throw new Error("Http status " + response.status);
						}

						const json = await response.json();

						if (json.erro) {
							throw new Error("Endpoint retornou " + JSON.stringify(json));
						}

						this.setValor("novo_uf", json.uf);
						this.setValor("novo_cidade", json.localidade);

						let endereco = [json.bairro, json.logradouro].filter(info => this.hasValue(info)).join(" - ");

						if (this.hasValue(endereco)) {
							this.setValor("novo_endereco", endereco);
							this.focar("cep_complemento");
						} else {
							this.focar("novo_endereco");
						}

						$prefixo.html(`
							<i class="fa fa-check-circle text-success"></i>
						`);

					} catch (e) {
						this.logger.warn("Erro ao consultar o CEP " + cep, e);

						$prefixo.html(`
							<i class="fa fa-exclamation-circle text-danger" title="CEP inválido"></i>
						`);
					}
				}
			});
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("uf")) {
			await this.addSelect({
				collection: exibicaoPaginaAplicacaoExternaTO.collectionUFs,
				id: "novo_uf",
				dica: this.getMsg("MSG_VH_L_79"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12",
			});
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("cidade")) {
			this.addCampoTexto({
				id: "novo_cidade",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_009"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12",
			});
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("endereco")) {
			this.addCampoTexto({
				id: "novo_endereco",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_008"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12",
			});
		}

		if (possuiCEP) {
			this.addCampoTexto({
				id: "cep_complemento",
				obrigatorio: true,
				classe: "col-md-12 col-xs-12",
				prefixo: "Complemento"
			});
		}
		
		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("tipoAcessibilidade")) {
			exibicaoPaginaAplicacaoExternaTO.listaTiposAcessibilidade.push({id: "NENHUMA", text: "Nenhuma"})
			await this.addSelect({
				collection: exibicaoPaginaAplicacaoExternaTO.listaTiposAcessibilidade,
				id: "novo_tipoAcessibilidade",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_003"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12"
			})
		}

		if (exibicaoPaginaAplicacaoExternaTO.camposHabilitados.includes("informacoesComplementares")) {
			this.addCampoTexto({
				id: "novo_informacoesComplementares",
				dica: "Informações complementares",
				obrigatorio: true,
				classe: "col-md-12 col-xs-12"
			})
		}

		if (this.hasValue(exibicaoPaginaAplicacaoExternaTO.listaOpcaoListaTOAgendamentos)) {
			await this.addSelect({
				collection: exibicaoPaginaAplicacaoExternaTO.listaOpcaoListaTOAgendamentos,
				id: "novo_codAgendamento",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_001"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12"
			})
		}

		if (this.hasValue(exibicaoPaginaAplicacaoExternaTO.listaOpcaoListaTOTurmas)) {
			await this.addSelect({
				collection: exibicaoPaginaAplicacaoExternaTO.listaOpcaoListaTOTurmas,
				id: "novo_codTurma",
				dica: this.getMsg("FP_FRONT_AplicacaoExternaVH_012"),
				obrigatorio: true,
				classe: "col-md-12 col-xs-12"
			})
		}

		this.append("<div data-fp-inscricao-perguntas-adicionais='true' class='col-md-12'></div>");

		this.append("<input type='hidden' id='username_novoCadastro' name='username'>");
		this.append("<input type='hidden' id='password_novoCadastro' name='password'>");

		this.append("<div class='form-group col-md-12' style='margin-top: 20px;'>");
		this.addBotao({ 
			id: "bnt-increver-link-externo",
			label: this.getMsg("MSG_VH_L_81"), 
			onClick: salvarCadastro, 
			classe: "btn-success" 
		});
		this.append("</div>");

		this.append("</div></div>");
	}

	async habilitarNovoCadastro(exibicaoPaginaAplicacaoExternaTO, dadosUsuario) {
		
		const habilitacaoAplicacaoExternaTO: any = {
			dadosNovoUsuario: dadosUsuario,
			codigoSistemaOrigem: dadosUsuario.codigoSistemaOrigem,
			tokenDeIdentificacaoAplicacao: this.tokenDeIdentificacaoAplicacao,
			isIgnorarEmailBoasVindas: exibicaoPaginaAplicacaoExternaTO.isIgnorarEmailBoasVindas,
		}

		if (exibicaoPaginaAplicacaoExternaTO.habilitarMascaraCPFNoLogin && habilitacaoAplicacaoExternaTO.dadosNovoUsuario.login) {
			habilitacaoAplicacaoExternaTO.dadosNovoUsuario.login = habilitacaoAplicacaoExternaTO.dadosNovoUsuario.login.replace('.', '').replace('.', '').replace('-', '')
		}

		const $containerQuestionario = $("[data-fp-inscricao-perguntas-adicionais]");

		habilitacaoAplicacaoExternaTO.respostasQuestionario = await questVH.getRespostas(
			$containerQuestionario, 
			null, 
			habilitacaoAplicacaoExternaTO.dadosNovoUsuario.nome, 
			habilitacaoAplicacaoExternaTO.dadosNovoUsuario.email, 
			habilitacaoAplicacaoExternaTO.dadosNovoUsuario.telefone
		);

		const resultadoInscricaoTO = await this.call({
			endpoint: "AplicacaoExternaFCD/habilitarNovoCadastroExterno",
			params: [habilitacaoAplicacaoExternaTO],
			onRequestError: async () => {
				this.toggleBotaoInscricao();
				return BackendRequestError.ERRO_NAO_TRATADO;
			}
		});

		if (resultadoInscricaoTO.erroAgendamento) {
			await this.exibirAlerta({
				msg: `${this.getMsg("FP_FRONT_AplicacaoExternaVH_011")}<br/><br/>${resultadoInscricaoTO.erroAgendamento}`,
				botoes: [{label: 'Ok', classe: 'btn-primary'}]
			});
		}

		try {
			const cfgsLoginTO = new CfgsLoginTO();
			
			cfgsLoginTO.login = resultadoInscricaoTO.login;
			cfgsLoginTO.senha = dadosUsuario.senha;
			cfgsLoginTO.onUsuarioAutenticado = () => {

				this.logger.info("Login novo usuário na aplicação externa OK. Abrindo index");

				const url = new URL(document.URL);
				url.pathname = "/";
				url.hash = "#/ae/" + habilitacaoAplicacaoExternaTO.tokenDeIdentificacaoAplicacao;
				
				document.location = url.href;
			}

			await loginVH.efetuarLoginJwt(cfgsLoginTO);
		} catch (e) {
			this.logger.error(e);
		}
		// }

		// if (possuiIntegracaoQuest) {
		// 	$(document).trigger("fp-questionario-on-enviar", {
		// 		$containerQuestionario, 
		// 		nomeUsuario: habilitacaoAplicacaoExternaTO.dadosNovoUsuario.nome,
		// 		emailUsuario: habilitacaoAplicacaoExternaTO.dadosNovoUsuario.email,
		// 		telefoneUsuario: habilitacaoAplicacaoExternaTO.dadosNovoUsuario.telefone,
		// 		onRespostasSalvas: onSalvarUsuarioFP
		// 	});
			
		// } else {
		// 	onSalvarUsuarioFP(null);
		// }
	}

	inicializarLTI(dadosParaEnvioResultadoLTITO) {
		this.dadosParaEnvioResultadoLTITO = dadosParaEnvioResultadoLTITO;
	}

	async verificarHabilitacaoUsuarioNaAplicacao(tip, codTurmaInscricao?) {

		const verificacaoHabilitacaoUsuarioTO = {
			tokenDeIdentificacaoAplicacao: tip,
			dadosParaEnvioResultadoLTITO: this.dadosParaEnvioResultadoLTITO,
			codTurmaInscricao: codTurmaInscricao
		}

		this.dadosParaEnvioResultadoLTITO = null; // só usa uma vez para a de entrada LTI

		UtilHash.registrarHistorico(this.verificarHabilitacaoUsuarioNaAplicacao, tip, codTurmaInscricao);

		const inicioAplicacaoExternaTO = await this.call("AplicacaoExternaFCD/verificarHabilitacaoUsuario", verificacaoHabilitacaoUsuarioTO);

		const mostrarMsgAplicacaoIndisponivel = () => {
			// console.error("Erro ao acessar a aplicação online com token " + tip);
			this.limpar();
			this.addTextoAlerta({ texto: this.getMsg("FP_FRONT_AplicacaoExternaVH_002") });
			this.exibir();
		}

		if (!inicioAplicacaoExternaTO) {
			mostrarMsgAplicacaoIndisponivel();
			return;
		}

		localStorage.setItem("isAcessoLinkExterno", "true");

		if (inicioAplicacaoExternaTO.encerrarAposConcluirProva) {
			await this.hide("#link_inicio");
			localStorage.setItem("encerrarAposConcluirProva", "true");
		}

		if (inicioAplicacaoExternaTO.msgErro) {
			this.limpar();

			this.setTitulo(inicioAplicacaoExternaTO.nomeProva);
			this.addTextoAlerta({
				texto: inicioAplicacaoExternaTO.msgErro,
				classes: 'col-md-12'
			});
			this.addEspacamentoHorizontal("1px");

			if (!this.isCfgHabilitada("EMPRESA_COM_LOGIN_INTEGRADO_NO_PORTAL_DO_CLIENTE")) {
				this.addBotao({ 
					label: this.getMsg("MSG_VH_AE_07"),
					onClick: () => {
						document.location = "/";
					}
				})
			}

			this.exibir();

		} else if (inicioAplicacaoExternaTO.isReaberturaVigente === true && inicioAplicacaoExternaTO.codProvaFeita) {
			
			inicioPFVH.verificarPrecondicoesParaInicioDePF(inicioAplicacaoExternaTO.codProvaFeita);

		} else if (inicioAplicacaoExternaTO.dataFimProvaFeita || this.hasValue(inicioAplicacaoExternaTO.pontuacaoObtida)) {

			if (inicioAplicacaoExternaTO.encerrarAposConcluirProva) {

				inicioPFVH.verificarSePodeIniciarProvaAgendada(null, inicioAplicacaoExternaTO.codAgendamentoUsuario, null);

			} else {

				try {
					await aplicacaoProvaVH.exibirDetalhesProvaRealizada(inicioAplicacaoExternaTO.codProvaFeita);
				} catch (e) {
					this.logger.error(e);
					console.log(e);
				}
			}

		} else if (inicioAplicacaoExternaTO.dataInicioProvaFeita) {

			inicioPFVH.verificarPrecondicoesParaInicioDePF(inicioAplicacaoExternaTO.codProvaFeita);

		} else if (inicioAplicacaoExternaTO.codProvaFeita) {

			try {
				await inicioPFVH.recuperarComposicaoDaProvaFeita(inicioAplicacaoExternaTO.codProvaFeita);
			} catch (e) {
				this.logger.error(e);
				console.log(e);
			}

		} else if (inicioAplicacaoExternaTO.codAgendamentoUsuario) {

			inicioPFVH.verificarSePodeIniciarProvaAgendada(null, inicioAplicacaoExternaTO.codAgendamentoUsuario, null);

		} else if (inicioAplicacaoExternaTO.isInscricaoPorTurmas) {

			UtilHash.carregarTelaInicialDoPerfil();

		} else {

			mostrarMsgAplicacaoIndisponivel();
			return;
		}
	}

	conferirSenhas() {
		const senha = this.getValor("novo_senha");
		const confirmacao = this.getValor("novo_senha_confirmacao");

		if (this.isEmpty(senha) || this.isEmpty(confirmacao)) return false;

		if (senha !== confirmacao) {
			this.exibirAlerta({ msg: "As senhas não conferem." });
			return false;
		}

		return true;
	}

	async verificarLoginDisponivel(tokenDeIdentificacaoAplicacao, login) {
		
		if (this.isEmpty(login)) return;

		const isLoginDisponivel = await this.call("AplicacaoExternaFCD/verificarLoginDisponivel", tokenDeIdentificacaoAplicacao, login);

		if (isLoginDisponivel === false) {
			await this.exibirAlerta({ msg: "Já existe conta registrada com este login. Faça login para continuar." });
			
			const $username = $("#username");
			let $elemento = $username;
			while ($elemento.length) {
				$elemento.show();
				$elemento = $elemento.parent();
			}
			this.setValor($username, login);
			$("#password").focus();
		}
	}

	handleErroCarregarQuestionario() {
		this.exibirAlerta({
			titulo: `<i class='fa fa-exclamation-triangle'></i> ${this.getMsg("FP_FRONT_AplicacaoExternaVH_013")}`,
			msg: this.getMsg("FP_FRONT_AplicacaoExternaVH_014"),
			botoes: [{
				label: `<i class='fa fa-refresh'></i> ${this.getMsg("FP_FRONT_AplicacaoExternaVH_015")}`,
			}],
			onHidden: () => {
				document.location.reload();
			}
		});
	}

	toggleBotaoInscricao(isDesabilitar?: boolean) {
		const $btn = $('#bnt-increver-link-externo');
		if (isDesabilitar) {
			this.disable('bnt-increver-link-externo');
			$btn.text('Salvando...');
			return;
		}
		this.enable('bnt-increver-link-externo');
		$btn.text(this.getMsg("MSG_VH_L_81"));
	}
}

const aplicacaoExternaVH = new AplicacaoExternaVH();

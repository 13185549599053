class UtilGrafico {
	
	// paletas de cores desenvolvidas pelo Carlos Designer Imaginie
	// https://www.figma.com/file/WLjRsBcVTN9QyZwVlMazIH/Cores-F%C3%A1brica?node-id=0%3A1

	// Sugestão 2
	static COR_AMARELO = "#F0AD4E";
	static COR_AZUL_CLARO = "#5BC0DE";
	static COR_VERDE_CLARO = "#95B961";
	static COR_AZUL_ESCURO = "#0275D8";
	static COR_VERMELHO = "#D9534F";
	static COR_PRETA = "#292B2C";
	static COR_CINZA = "#e5e8ec";

	static COR_VERDE_ESCURO = "#567030";
	static COR_LARANJA = "#fc8452";
	static COR_ROXO = "#A34C80";
	static COR_ROSA = "#E793C7";

	static TEMA_VERDES = [
		"#4ea397",
		"#22c3aa",
		"#7bd9a5",
		"#d0648a",
		"#f58db2",
		"#f2b3c9"
	];
	
	static async criarEChart(seletor, cfgs) {

		if (cfgs.tipo == "LINHAS_MULTIPLAS" && !cfgs.series || cfgs.tipo != "LINHAS_MULTIPLAS" && (!cfgs.tos || cfgs.tos.length < 1)) return;

		const { echarts } = await UtilBoot.carregarECharts();

		let option = null;
		let yAxisFormatter = cfgs.yAxisFormatter || "{value}";
		let xAxisLabel = cfgs.xAxisLabel || {};
		
		switch (cfgs.tipo) {
			case "PIZZA":
				option = {
					tooltip: {
						trigger: 'item',
						formatter: (params) => {
							return `${params.seriesName}<br/> ${params.marker} <b>${params.value} (${params.percent}%)</b>`
						}
					},
					title: {
						text: cfgs.titulo,
						left: 'center',
						textStyle: {
							color: "#666"
						}
					},
					legend: {
						left: 'center'
					},
					series: [
						{
							name: cfgs.nome || UtilMsg.getMsg("FP_FRONT_UtilGrafico_001"),
							type: 'pie',
							label: {
								show: cfgs.showLabel,
								formatter: (params) => {
									return params.value > 0 ? `${params.percent}%` : '';
								},
								[cfgs.labelPosition && 'position']: cfgs.labelPosition
							},
							emphasis: {
								itemStyle: {
									shadowBlur: 10,
									shadowOffsetX: 0,
									shadowColor: 'rgba(0, 0, 0, 0.5)'
								}
							},
							labelLine: {
								show: true
							},
							data: Object.keys(cfgs.tos[0]).filter(atributo => atributo != cfgs.labelBarra).map(atributo => {
								return {
									name: cfgs.labels[atributo],
									value: cfgs.tos[0][atributo],
									itemStyle: {
										color: cfgs.cores[atributo],
									}
								}
							})
						}
					]
				}
				break;
			case "BARRAS_EMPILHADAS":
				option = {
					tooltip: {
						trigger: 'axis',
						axisPointer: {            // Use axis to trigger tooltip
							type: 'shadow'        // 'shadow' as default; can also be 'line' or 'shadow'
						}
					},
					legend: {
						data: ['Direct', 'Mail Ad', 'Affiliate Ad', 'Video Ad', 'Search Engine']
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: cfgs.tos.map(to => to[cfgs.labelBarra])
					},
					yAxis: {
						type: 'value'
					},
					series: Object.keys(cfgs.tos[0]).filter(atributo => atributo != cfgs.labelBarra).map(atributo => {
						return {
							name: cfgs.labels[atributo],
							type: 'bar',
							stack: 'total',
							label: {
								show: true
							},
							emphasis: {
								focus: 'series'
							},
							data: cfgs.tos.map(to => to[atributo]),
							itemStyle: {
								color: cfgs.cores[atributo],
							}
						}
					})
				}
				break;
			case "LINHAS": 
				option = {
					responsive: true,
					legend: {
						data: [cfgs.titulo]
					},
					grid: {
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: Object.keys(cfgs.tos[0]),
						axisLabel: xAxisLabel
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: yAxisFormatter
						}
					},
					series: [
						{
							name: cfgs.titulo,
							data: Object.keys(cfgs.tos[0]).map(k => cfgs.tos[0][k]),
							type: 'line',
							smooth: true
						}
					]
				};
				break;
			case "BARRAS_HORIZONTAIS_EMPILHADAS":
				const tooltip: any = {
					trigger: 'axis',
					axisPointer: {
						type: 'shadow'
					},
				}

				if (cfgs.exibirPorcentagem) {
					cfgs.tos = this.retornarArrayComPorcentagem(cfgs.tos, Object.keys(cfgs.labels));
					cfgs.cores = this.converterObjetoParaPorcentagens(cfgs.cores);
					cfgs.labels = this.converterObjetoParaPorcentagens(cfgs.labels);

					tooltip.formatter = function(params) {
						let tooltipContent = '';
						for (let i = 0; i < params.length; i++) {
							const param = params[i];
							const data = param.data;
							tooltipContent += `${param.marker}${param.seriesName}: <b>${data.data} (${UtilNumero.floatToString(data.value)}%)</b><br>`;
						}
						return tooltipContent;
					}
				}
				const categorias = cfgs.tos.map(to => to[cfgs.labelBarra]);

				const hasScroll = cfgs.tos && cfgs.tos.length > 5;
				option = {
					tooltip: tooltip,
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					legend: {},
					yAxis: {
						type: 'category',
						data: categorias
					},
					xAxis: {
						type: 'value',
						axisLabel: {
							formatter: cfgs.exibirPorcentagem ? '{value} %' : '{value}',
						},
					},
					series: Object.keys(cfgs.tos[0]).filter(atributo => Object.keys(cfgs.labels).includes(atributo) && atributo != cfgs.labelBarra && !cfgs.exibirPorcentagem || (cfgs.exibirPorcentagem  && atributo.includes('Percent'))).map(atributo => {
						const data = !cfgs.exibirPorcentagem ? cfgs.tos.map(to => to[atributo])
							: cfgs.tos.map(to => {
								return {
									value: to[atributo].toFixed(2),
									data: to[atributo.replace("Percent", "")]
								}
							});

						const label = cfgs.exibirPorcentagem ? {
								show: true,
								formatter: function(params) {
									return `${params.data.data} (${UtilNumero.floatToString(params.value)}%)`;
								}
							} : {show: true}


							return {
								name: cfgs.labels[atributo],
								type: 'bar',
								barWidth: hasScroll ? 40 : null,
								stack: 'total',
								label: label,
								emphasis: {
									focus: 'series'
								},
								data: data,
								itemStyle: {
									color: cfgs.cores[atributo],
								}
							}
					})
				}
				break;
			case "BARRAS_HORIZONTAIS":
				if (cfgs.isValorPercentual) {
					cfgs.tos.forEach(it => it[cfgs.labelSeries] = it[cfgs.labelSeries] ? it[cfgs.labelSeries].toFixed(2) : it[cfgs.labelSeries])
				}
				option = {
					title: {
						text: cfgs.title,
						left: 'center',
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: (params) => {
							return `${params[0].axisValue} <br> ${params[0].marker} <b>${params[0].value} ${cfgs.isValorPercentual ? ' %' : ''}</b>`
						}
					},
					grid: {
						left: '3%',
						right: '4%',
						bottom: '3%',
						containLabel: true
					},
					yAxis: {
						type: 'category',
						data: cfgs.tos.map(to => to[cfgs.labelBarra])
					},
					xAxis: {
						type: 'value',
						axisLabel: {
							formatter: cfgs.isValorPercentual ? '{value} %' : '{value}',
						},
					},
					series: [
						{
							data: cfgs.tos.map(to => to[cfgs.labelSeries]),
							type: 'bar',
							showBackground: true,
							backgroundStyle: {
								color: 'rgba(180, 180, 180, 0.2)'
							},
							label: {
								show: true,
								position: 'inside',
								formatter: cfgs.isValorPercentual ? '{c} %' : '{c}',
							},
						}
					]
				};
				break;
			case "BARRAS":
				if (cfgs.isValorPercentual) {
					cfgs.tos.forEach(it => it[cfgs.labelSeries] = it[cfgs.labelSeries] ? it[cfgs.labelSeries].toFixed(2) : it[cfgs.labelSeries])
				}
				option = {
					title: {
						text: cfgs.title,
						left: 'center',
					},
					tooltip: {
						trigger: 'axis',
						axisPointer: {
							type: 'shadow'
						},
						formatter: (params) => {
							return `${params[0].axisValue} <br> ${params[0].marker} <b>${params[0].value} ${cfgs.isValorPercentual ? ' %' : ''}</b>`
						}
					},
					xAxis: {
						type: 'category',
						data: cfgs.tos.map(to => to[cfgs.labelBarra])
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: cfgs.isValorPercentual ? '{value} %' : '{value}',
						},
					},
					series: [
						{
							data: cfgs.tos.map(to => to[cfgs.labelSeries]),
							type: 'bar',
							label: {
								show: true,
								position: 'inside',
								formatter: cfgs.isValorPercentual ? '{c} %' : '{c}',
							},
						}
					]
				};
				break;
			case "LINHAS_MULTIPLAS":
				option = {
					responsive: true,
					legend: {},
					tooltip: {
						trigger: 'axis'
					},
					grid: {
						left: '0%',
						right: '0%',
						bottom: '0%',
						containLabel: true
					},
					xAxis: {
						type: 'category',
						data: cfgs.categorias,
						axisLabel: xAxisLabel,
						boundaryGap: true,
					},
					yAxis: {
						type: 'value',
						axisLabel: {
							formatter: yAxisFormatter
						}
					},
					series:  cfgs.series.map(serie => {
						return {
							name: serie.nome,
							data: serie.dados,
							type: 'line',
							smooth: true
						}
					})
				};
				break;
			default: 
				console.warn("tipo " + cfgs.tipo + " de gráfico não previsto");
				return;
		}

		const myChart = echarts.init(document.querySelector(seletor));
		myChart.setOption(option);

		function resizeChart() {
			myChart.resize();
		}

		window.addEventListener('resize', resizeChart);
	}

	static async criarDonut(cfgs: GraficoDonutCfgs) {

		const data = cfgs.data.concat([{
			value: cfgs.data.reduce((soma, d) => soma + d.value, 0), // make an record to fill the bottom 50%
			itemStyle: {
				color: 'none', // stop the chart from rendering this piece
				decal: {
					symbol: 'none'
				}
			},
			label: {
				show: false
			}
		}])

		const { echarts } = await UtilBoot.carregarECharts();
		const div = document.getElementById(cfgs.id);
		
		// div.parentElement.style.padding = "0px";
		// div.parentElement.style.margin = "-10px -10px -10px -10px";
		div.style.width = div.offsetWidth + "px";
		div.style.height = div.offsetHeight + "px";

		const donut = echarts.init(div);
		
		const option = ({
			tooltip: {
				trigger: "item"
			},
			grid: {
				left: 0,
				top: "40%",
				right: 0,
				bottom: 0
			},
			legend: null,
			series: [
				{
					name: cfgs.nomeSerie,
					type: 'pie',
					top: '-40%',
					left: '-30%',
					right: '-30%',
					bottom: '-20%',
					radius: ['50%', '70%'],
					center: ['50%', '75%'],
					startAngle: 180, // adjust the start angle
					label: {
						show: true,
						formatter(param) {
							// correct the percentage
							return param.name + ' (' + param.value + ')';
						}
					},
					data
				}
			]
		});

		if (cfgs.tema) {
			option["color"] = cfgs.tema;
		}

		donut.setOption(option);

		if (cfgs.onSeriesClick) {
			donut.off("click").on("click", (e) => {
				if (e.data) cfgs.onSeriesClick(e.data);
			})
		}
	}

	static async criarStackedHorizontalBars(cfgs: GraficoStackedHorizontalBarsCfgs) {

		const yAxisNames = Object.keys(cfgs.data);

		const arrayNomesSeries = [];
		const map = new Map();
		Object.keys(cfgs.data).forEach(key => {
			const array = <DataPointTO[]> cfgs.data[key];
			array.forEach((dpTO: DataPointTO) => {
				arrayNomesSeries.includes(dpTO.name) ? null : arrayNomesSeries.push(dpTO.name);
				map.set(key + dpTO.name, dpTO);
			});
		});
		arrayNomesSeries.sort();

		const { echarts } = await UtilBoot.carregarECharts();
		const div = document.getElementById(cfgs.id);
		
		div.style.width = div.offsetWidth + "px";
		div.style.height = (200 + (yAxisNames.length * 30)) + "px";

		const bars = echarts.init(div);
		
		bars.setOption({
			tooltip: {
				trigger: 'axis',
				axisPointer: { // Use axis to trigger tooltip
					type: 'shadow' // 'shadow' as default; can also be 'line' or 'shadow'
				}
			},
			legend: {
				top: 20
			},
			grid: {
				top: 70,
				left: '3%',
				right: '4%',
				bottom: 30,
				containLabel: true
			},
			xAxis: {
				type: 'value'
			},
			yAxis: {
				type: 'category',
				data: yAxisNames
			},
			series: arrayNomesSeries.map(nomeSerie => {
				return {
					name: nomeSerie,
					type: 'bar',
					stack: 'total',
					label: {
						show: true
					},
					emphasis: {
						focus: 'series'
					},
					data: yAxisNames.map(yAxisName => map.get(yAxisName + nomeSerie))
				};
			})
		});
		
		if (cfgs.onSeriesClick) {
			bars.off("click").on("click", (e) => {
				if (e.data) cfgs.onSeriesClick(e.data);
			})
		}
	}

	private static retornarArrayComPorcentagem(array, labels) {
		const totalAtributos = Object.keys(array[0]).filter(key => key !== 'nome' && labels.includes(key)); // Ignorar o atributo 'nome'

		return array.map(item => {
			const total = totalAtributos.reduce((acc, atributo) => acc + item[atributo], 0);
			const novoItem = {...item};

			totalAtributos.forEach(atributo => {
				novoItem[`${atributo}Percent`] = (item[atributo] / total) * 100;
			});

			return novoItem;
		});
	}

	private static converterObjetoParaPorcentagens(obj) {
		const novoObj = {};

		Object.keys(obj).forEach(chave => {
			novoObj[`${chave}Percent`] = obj[chave];
		});

		return novoObj;
	}
}

type GraficoDonutCfgs = {
	id: string;
	nomeSerie: string;
	data: any[];
	tema?: string[];
	onSeriesClick?: (data: any) => {};
}

type GraficoStackedHorizontalBarsCfgs = {
	id: string;
	nomeSerie: string;
	data: object;
	onSeriesClick?: (data: any) => {};
}

type DataPointTO = {
	name: string;
	value: number;
}

class UtilTelefone {

	static esconderTelefoneCompleto(numero: string): string {

		if (numero == null) return null;

		const telefoneLimpo = numero.replace(/\D/g, '');

		const primeirosDigitos = telefoneLimpo.slice(0, 2);
		const ultimosDigitos = telefoneLimpo.slice(-2);

		const asteriscos = '*'.repeat(telefoneLimpo.length - 4);

		return `${primeirosDigitos}${asteriscos}${ultimosDigitos}`;
	}
}


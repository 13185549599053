const $ = UtilBoot.getJquery();

let $btn;

class UtilImg {
	
	static LOGGER = UtilLog.getLogger(UtilImg.name);

	static transformarSvgs() {
		$btn = $('#btn-importar-questoes');
		if ($btn && $btn.length) {
			$btn.data('aguardando', true)
			$btn.removeClass("btn-primary").addClass("btn-warning").text(amaisVH.getMsg("FP_FRONT_ImportacaoProvaVH_066"));
		}

		try {
			const MathJax = window["MathJax"];
			MathJax.typesetPromise().then(() => {
				UtilImg.substituirSvgsPorImagens();
			});
		} catch (e) {
			UtilBoot.carregarMathJax(() => {
				UtilImg.substituirSvgsPorImagens();
			});
		}
	}
			
	static substituirSvgsPorImagens() {
		
		$("#divBody .MathJax svg:visible").filter((i, svg) => {
			return $(svg).data("gerando") == null;
		}).each((i, svg) => {
			const $svg = $(svg);
			$svg.data("gerando", true);

			UtilImg.gerarImagemDeSvg(svg, $svg.parent()).then(() => {
				UtilImg.substituirSvgsPorImagens();
			}).catch((erro) => {
				console.error("erro em substituirSvgsPorImagens", erro);
			});
		});

		if ($("#divBody .MathJax svg:visible") && !$("#divBody .MathJax svg:visible").length) {
			if ($btn && $btn.length) {
				$btn.data('aguardando', false);
				$btn.removeClass("btn-warning").addClass("btn-primary").text(amaisVH.getMsg("FP_FRONT_ImportacaoProvaVH_056"));
				$btn = null;
			}
		}
	}

	static gerarImagemDeSvg(svgOriginal, elementoSubstituicao): Promise<void> {
		return new Promise(function(resolve, reject) {
			let $svg = $(svgOriginal).clone();

			let idDiv = "fp_img_svg_" + amaisVH.gerarId();
			let $div = $("body").append("<div id='" + idDiv + "' style='display: none'></div>").find("#" + idDiv);
			$div.append($svg);

			let img = new Image();
			img.width = $svg.width() * 1.2; // exibição
			img.height = $svg.height() * 1.2;

			UtilImg.converterSvg({
				pathSvg: null,
				svgXml: $div.html(),
				width: $svg.width() * 5, // resolução
				height: $svg.height() * 5

			}).then((path: string) => {
				if (path) {
					img.onload = () => {
						$div.remove();
						$(elementoSubstituicao).replaceWith(img);
						$("body").append(elementoSubstituicao);
						$(elementoSubstituicao).hide();

						resolve();
					}

					img.src = path;
				}
			}).catch((erro) => {
				console.error("Erro gerarImagemDeSvg", erro);
				reject(erro);
			});
		});
	}
		
	static async converterSvg(cfgs: any): Promise<string> {

		const requisicaoMSTO = new RequisicaoMSTO();

		requisicaoMSTO.servico = "ConversaoSvgServicoImg";
		requisicaoMSTO.requisicao = {
			svgXml: cfgs.svgXml,
			pathOrigem: cfgs.pathSvg,
			formatoSaida: "png",
			widthSaida: cfgs.width,
			heightSaida: cfgs.height
		};

		const { path } = await UtilMS.enviar("/img", requisicaoMSTO);
		return path;
	}
	
	static getExtensao(mimeType) {
		switch (mimeType) {
			case "image/gif":
				return "gif";
			case "image/jpeg":
				return "jpg";
			case "image/png":
				return "png";
			case "image/svg+xml":
				return "svg";
			case "image/tiff":
				return "tif";
			case "image/webp":
				return "webp";
			default: throw "mimeType desconhecido";
		}
	}
	
	static async base64ToBlob(src): Promise<string> {
		return new Promise<string>(async (resolve, reject) => {
			const image = new Image();
			image.onload = () => {
				try {
				    const canvas = document.createElement('canvas');
			        const ctx = canvas.getContext('2d');
			        canvas.height = image.height;
			        canvas.width = image.width;
			        ctx.drawImage(image, 0, 0);
			        canvas.toBlob((blob: any) => {
						blob.name = Date.now() + "." + UtilImg.getExtensao(blob.type);
						resolve(blob);
					});
				} catch (e) {
					reject(e);
				}
			}
			image.src = src;
		});
	}
	
	static download(url: string): Promise<any> {
		return new Promise<string>(async (resolve, reject) => {
			try {
				const xhr = new XMLHttpRequest();
				xhr.open("GET", url);
				xhr.onerror = reject;
				xhr.ontimeout = reject;
				xhr.responseType = "blob";
				xhr.onload = () => {
					let blob = xhr.response; //xhr.response is now a blob object
					blob.name = UtilArquivo.getNomeArquivo(url);
					resolve(blob);
				}
				xhr.send();
			} catch (e) {
				reject(e);
			}
		});
	}

	static uploadCanvasesLC(seletor: string): Promise<string> {

		return new Promise<string>(async (resolve, reject) => {

			try {
				const canvases: HTMLCanvasElement[] = Array.from(document.querySelector(seletor).querySelectorAll("canvas"));
				const canvasImg = canvases[0];
				const canvasDesenhos = canvases[1];
				
				const joinedCanvas = document.createElement("canvas");
				
				joinedCanvas.width = Math.max(
					canvasImg.offsetLeft + canvasImg.offsetWidth, 
					canvasDesenhos.offsetLeft + canvasDesenhos.offsetWidth
				);
				joinedCanvas.height = Math.max(
					canvasImg.offsetTop + canvasImg.offsetHeight, 
					canvasDesenhos.offsetTop + canvasDesenhos.offsetHeight
				);

				joinedCanvas.style.display = "none";

				const ctx = joinedCanvas.getContext('2d');
				
				ctx.beginPath();
				ctx.rect(0, 0, joinedCanvas.width, joinedCanvas.height);
				ctx.fillStyle = "#ddd";
				ctx.fill();
	
				ctx.scale(canvasImg.offsetWidth / canvasImg.width, canvasImg.offsetHeight / canvasImg.height)
				ctx.drawImage(canvasImg, canvasImg.offsetLeft, canvasImg.offsetTop);

				ctx.drawImage(canvasDesenhos, canvasDesenhos.offsetLeft, canvasDesenhos.offsetTop);
				
				document.body.appendChild(joinedCanvas);

				joinedCanvas.toBlob(async (blob: any) => {
					try {
						document.body.removeChild(joinedCanvas);

						blob.name = Date.now() + "." + UtilImg.getExtensao(blob.type);

						const uploadTO = await UtilArmazenamento.upload(blob, true);

						resolve(uploadTO.url);
					} catch (e) {
						UtilImg.LOGGER.error("Erro no upload do print do canvas", e);
						reject(e);
					}
				});

			} catch (e) {
				UtilImg.LOGGER.error(e);
				reject(e);
			}
		});
	}

	static async rotacionar(url: string, rotacao): Promise<string> {
		const requisicaoMSTO = new RequisicaoMSTO();

		requisicaoMSTO.servico = "RotacaoImgServicoImg";
		requisicaoMSTO.requisicao = {
			url: url,
			rotacao: rotacao,
			privado: true
		};

		const { path } = await UtilMS.enviar("/img", requisicaoMSTO);

		return path;
	}

	static tentarRedimensionar(f1: File, maxSize: number) {

		if (!f1?.type || !maxSize) return f1;
		if (!f1.type.startsWith("image/")) return f1;
		if (f1.type === "image/heif" || f1.type === "image/heic") return f1;

		return new Promise((resolve) => {

			try {

				let fr = new FileReader();

				fr.onerror = (e) => {
					UtilImg.LOGGER.warn(`fr.onerror: Não conseguiu redimensionar arquivo ${f1.name}`, e);
					resolve(f1);
				}

				fr.onload = () => {
					
					const img = document.createElement("img");

					img.onerror = (e) => {
						UtilImg.LOGGER.warn(`img.onerror: Não conseguiu redimensionar arquivo ${f1.name}`, e);
						resolve(f1);
					}

					img.onload = () => {
						try {
							const canvas = document.createElement("canvas");
							let fracao = maxSize / img.height;

							if (img.width > img.height) {
								fracao = maxSize / img.width;
							}

							if (fracao >= 1) {
								resolve(f1); // não precisa redimensionar
								return;
							}
							
							canvas.width = img.width * fracao;
							canvas.height = img.height * fracao;
					
							canvas.getContext("2d").drawImage(img, 0, 0, canvas.width, canvas.height);
					
							canvas.toBlob((f2: any) => {
								f2.name = f1.name + ".jpg";
								UtilImg.LOGGER.info(`Imagem redimensionada para ${canvas.width}x${canvas.height}`);
								resolve(f2);
							}, f1.type, 0.7);
						} catch (e) {
							UtilImg.LOGGER.warn(`img.onload: Não conseguiu redimensionar arquivo ${f1.name}`, e);
							resolve(f1);
						}
					}

					if (typeof fr.result === "string") {
						img.src = fr.result;
					}
				}

				fr.readAsDataURL(f1);
			} catch (e) {
				UtilImg.LOGGER.warn(`catch: Não conseguiu redimensionar arquivo ${f1.name}`, e);
				resolve(f1);
			}
		});
	}

	static async converterParaJpeg(uploadTORequest: UploadTO): Promise<UploadTO> {
		let requisicaoMSTO = {
			servico: "ConversaoImgServicoImg",
			jwt: UtilAuth.getJwtToken(),
			requisicao: uploadTORequest
		};
		const { uploadTO } = await UtilMS.enviar("/img", requisicaoMSTO);
		return uploadTO;
	}
	
	static async urlToBase64(urlImagem): Promise<string> {
		
		const response = await fetch(urlImagem);
		const blob = await response.blob();
		return await UtilImg.blobToBase64(blob);
	}
	
	static async blobToBase64(blob): Promise<string> {
		return new Promise((resolve, reject) => {

			try {
				const reader = new FileReader();
				
				reader.onload = () => {
					resolve(reader.result.toString()); 
				};

				reader.onerror = (e) => {
					reject(e);
				};
			  
				reader.readAsDataURL(blob);

			} catch(e) {
				reject(e);
			}
		});
	}

	static capturarFrame(video: HTMLVideoElement): Promise<any> {

		if (!video) return null;

		return new Promise((resolve, reject) => {
			try {
				const canvas = document.createElement("canvas");
		
				canvas.height = video.videoHeight;
				canvas.width = video.videoWidth;

				const context = canvas.getContext('2d');

				context.drawImage(video, 0, 0);

				canvas.toBlob((blob: any) => {
					try {
						if (blob) {
							blob.name = Date.now() + "." + UtilImg.getExtensao(blob.type);
							resolve(blob);
						} else {
							reject(new Error("Erro no capturarFrameVideo. Blob null."));
						}
					} catch (e) {
						reject(new Error("Erro no try 2 do capturarFrameVideo: " + e));
					}
				}, 'image/jpeg');

			} catch (e) {
				reject(new Error("Erro no capturarFrameVideo: " + e));
			}
		});
	}
}

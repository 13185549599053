class UtilJson {
	
	static toString(o: any) : string {
		if (!o) return null;
		return JSON.stringify(o, (prop: string, valor: any) => {
			if (valor instanceof EnumFP) {
				return valor.id;
			 } else if (valor instanceof Map) {
				return Object.fromEntries(valor);
			 } else {
				return valor;
			 }
		});
	}

	static resolverEnuns(data: any) {

		if (!data || data instanceof EnumFP) return;

		Object.keys(data).forEach((key: string) => {
			
			if (!data[key] || data[key] instanceof EnumFP) return;

			if (typeof data[key] === "object") {

				if (data[key]["_classeEnumFP"] && data[key]["id"]) {
					data[key] = EnumFP.from(data[key]["_classeEnumFP"],data[key]["id"]);
				} else {
					UtilJson.resolverEnuns(data[key]);
				}
				
			} else if (EnumFP.isEnum(key)) {
				data[key] = EnumFP.fromProp(key, data[key]) || data[key];
			}
		});
	}

	static clonar(data: any) {
		const data2 = JSON.parse(JSON.stringify(data));
		UtilJson.resolverEnuns(data2);
		return data2;
	}

}

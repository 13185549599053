var Cookies = UtilBoot.getJsCookie();

class UtilAuth {

	static funcionalidadesHabilitadas: Array<TipoFuncionalidade>;

	static isAutenticado() {
		return UtilAuth.getJwtToken() != null;
	}

	static getJwtToken() {
		let token = localStorage.getItem("fp_token_jwt");

		if (!token || token === "") {
			token = Cookies.get("fp_token_jwt");
		}

		if (!token || token === "") {
			token = null;
		}

		return token;
	}

	static setJwtToken(jwtToken: string) {
		localStorage.setItem("fp_token_jwt", jwtToken);
		Cookies.set("fp_token_jwt", jwtToken);
	}

	static limparJwtToken() {
		localStorage.removeItem("fp_token_jwt");
		Cookies.remove("fp_token_jwt");
		Cookies.remove("JSESSIONID");
		Cookies.remove("SESSIONID");
	}
	
	static setFuncionalidadesHabilitadas(funcionalidadesHabilitadas: TipoFuncionalidade[]) {
		UtilAuth.funcionalidadesHabilitadas = funcionalidadesHabilitadas;
	}
	
	static possuiAcesso(tipoFuncionalidade: TipoFuncionalidade) {
		return UtilAuth.funcionalidadesHabilitadas?.includes(tipoFuncionalidade);
	}

	static inicializarVerificacaoLogoutOutraAba() {

		const tokenInicial = UtilAuth.getJwtToken();

		if (!tokenInicial) return; // não está autenticado
		
		window.addEventListener('storage', async () => {
			if (tokenInicial !== UtilAuth.getJwtToken()) {
				const modalAlerta = $('#alerta-sessao-encerrada');
				if (!modalAlerta.length) {
					await amaisVH.exibirAlerta({
						titulo: `<i class="fas fa-exclamation-triangle"></i> ${amaisVH.getMsg("FP_FRONT_AmaisVH_051")}`,
						id: 'alerta-sessao-encerrada',
						msg: amaisVH.getMsg('FP_FRONT_AmaisVH_052'),
					});
					amaisVH.limpar();
					window.location.href = "/";
				}
			}
		});
	}
}

enum TipoFuncionalidade {

	AJUDA_CADASTRO = "AJUDA_CADASTRO",

	ALOCACAO_LISTAGEM = "ALOCACAO_LISTAGEM",
	ALOCACAO_EXCLUSAO = "ALOCACAO_EXCLUSAO",
	ALOCACAO_DOC_EXCLUSAO_PAGINA = "ALOCACAO_DOC_EXCLUSAO_PAGINA",

	AMBIENTE_AVALIADO_MONTAR_PROVA = "AMBIENTE_AVALIADO_MONTAR_PROVA",
	AMBIENTE_AVALIADO_LISTAR_PROVAS_PUBLICAS = "AMBIENTE_AVALIADO_LISTAR_PROVAS_PUBLICAS",
	AMBIENTE_AVALIADO_HISTORICO_PROVAS = "AMBIENTE_AVALIADO_HISTORICO_PROVAS",
	AMBIENTE_AVALIADO_QUESTAO_COMENTARIOS = "AMBIENTE_AVALIADO_QUESTAO_COMENTARIOS",

	APLICACAO_IMPRESSA_CADASTRO = "APLICACAO_IMPRESSA_CADASTRO",
	APLICACAO_ONLINE_CADASTRO = "APLICACAO_ONLINE_CADASTRO",
	APLICACAO_CORRECOES = "APLICACAO_CORRECOES",
	APLICACAO_PROVA_LISTAGEM = "APLICACAO_PROVA_LISTAGEM",

	AUDITORIA_PROVA = "AUDITORIA_PROVA", 
	AUDITORIA_QUESTAO = "AUDITORIA_QUESTAO",

	CADASTRO_AVALIACAO = "CADASTRO_AVALIACAO",
	
	CADASTRO_USUARIO_VISUALIZACAO = "CADASTRO_USUARIO_VISUALIZACAO",
	CADASTRO_USUARIO_APLICACAO_ALTERACAO = "CADASTRO_USUARIO_APLICACAO_ALTERACAO",
	CADASTRO_USUARIO_ALTERACAO = "CADASTRO_USUARIO_ALTERACAO",
	CADASTRO_USUARIO_IMPORTACAO = "CADASTRO_USUARIO_IMPORTACAO",
	CADASTRO_USUARIO_ACESSOS = "CADASTRO_USUARIO_ACESSOS",
	CADASTRO_USUARIO_ARQUIVAMENTO = "CADASTRO_USUARIO_ARQUIVAMENTO",
	CADASTRO_USUARIO_AUDITORIA = "CADASTRO_USUARIO_AUDITORIA",
	CADASTRO_USUARIO_TURMA_VISUALIZACAO = "CADASTRO_USUARIO_TURMA_VISUALIZACAO",
	CADASTRO_USUARIO_TURMA_ALTERACAO = "CADASTRO_USUARIO_TURMA_ALTERACAO",
	CADASTRO_USUARIO_APLICACAO_VISUALIZACAO = "CADASTRO_USUARIO_APLICACAO_VISUALIZACAO",
	CADASTRO_USUARIO_PROVA_CORRECAO = "CADASTRO_USUARIO_PROVA_CORRECAO",
	CADASTRO_USUARIO_APLICACAO_ANULACAO = "CADASTRO_USUARIO_APLICACAO_ANULACAO",
	CADASTRO_USUARIO_EMAIL_VISUALIZACAO = "CADASTRO_USUARIO_EMAIL_VISUALIZACAO",
	CADASTRO_USUARIO_RESPONS_CORRECAO_VISUALIZACAO = "CADASTRO_USUARIO_RESPONS_CORRECAO_VISUALIZACAO",
	CADASTRO_USUARIO_RESPONS_CORRECAO_ALTERACAO = "CADASTRO_USUARIO_RESPONS_CORRECAO_ALTERACAO",
	
	CHECKLIST_CRIACAO = "CHECKLIST_CRIACAO", 
	
	CONCURSO_CADASTRO = "CONCURSO_CADASTRO",

	CORRECAO_DISCURSIVA_AUDITORIA = "CORRECAO_DISCURSIVA_AUDITORIA",
	CORRECAO_DISCURSIVA_EDICAO = "CORRECAO_DISCURSIVA_EDICAO",
	CORRECAO_DISCURSIVA_EXPORTACAO = "CORRECAO_DISCURSIVA_EXPORTACAO",
	CORRECAO_DISCURSIVA_CFG_CORRETORES = "CORRECAO_DISCURSIVA_CFG_CORRETORES",
	CORRECAO_DISCURSIVA_VISUALIZACAO = "CORRECAO_DISCURSIVA_VISUALIZACAO",

	CORRECAO_FR_AJUSTE = "CORRECAO_FR_AJUSTE",
	CORRECAO_FR_ENVIO = "CORRECAO_FR_ENVIO",
	CORRECAO_FR_REL = "CORRECAO_FR_REL",

	DEMANDA_CRIACAO = "DEMANDA_CRIACAO",
	DEMANDA_VISUALIZACAO = "DEMANDA_VISUALIZACAO",
	DEMANDA_ELABORACAO_QUESTOES = "DEMANDA_ELABORACAO_QUESTOES",
	DEMANDA_AUTO_CRIACAO = "DEMANDA_AUTO_CRIACAO",

	DISCURSIVAS_VISUALIZACAO = "DISCURSIVAS_VISUALIZACAO",
	
	NOVA_DISCURSIVAS_CORRECAO = "NOVA_DISCURSIVAS_CORRECAO",
	DISCIPLINA_CADASTRO = "DISCIPLINA_CADASTRO",
	DISCIPLINA_ASSOCIACAO_EQUIVALENCIAS = "DISCIPLINA_ASSOCIACAO_EQUIVALENCIAS",

	EMPRESA_CADASTRO = "EMPRESA_CADASTRO",

	FISCALIZACAO_APLICACAO = "FISCALIZACAO_APLICACAO",

	MODULO_CADASTRO = "MODULO_CADASTRO",

	PERFIS_CADASTRO = "PERFIS_CADASTRO",

	PROVA_CADASTRO = "PROVA_CADASTRO",
	PROVA_CADASTRO_VAZIA = "PROVA_CADASTRO_VAZIA",
	PROVA_IMPORTACAO = "PROVA_IMPORTACAO",
	PROVA_LISTAGEM = "PROVA_LISTAGEM",
	PROVA_LISTAGEM_PASTAS = "PROVA_LISTAGEM_PASTAS",
	PROVA_DASHBOARD = "PROVA_DASHBOARD",
	PROVA_ASSOCIACAO_QUESTOES = "PROVA_ASSOCIACAO_QUESTOES",

	QUESTAO_ANOTACAO = "QUESTAO_ANOTACAO",
	QUESTAO_CADASTRO = "QUESTAO_CADASTRO",
	QUESTAO_BUSCA = "QUESTAO_BUSCA",
	QUESTAO_ANULACAO = "QUESTAO_ANULACAO",
	QUESTAO_SUBSTITUICAO = "QUESTAO_SUBSTITUICAO",
	QUESTAO_COMENTARIOS = "QUESTAO_COMENTARIOS",
	QUESTAO_ESTATISTICA = "QUESTAO_ESTATISTICA",
	
	RECURSOS_VISUALIZACAO = "RECURSOS_VISUALIZACAO",
	RECURSOS_EDICAO = "RECURSOS_EDICAO",
	
	RELATORIO_APLICACOES = "RELATORIO_APLICACOES",
	RELATORIO_PROVAS_REALIZADAS_PROVA = "RELATORIO_PROVAS_REALIZADAS_PROVA",
	RELATORIO_PROVAS_REALIZADAS_TURMA = "RELATORIO_PROVAS_REALIZADAS_TURMA",
	RELATORIO_PROVAS_REALIZADAS_ESTUDO = "RELATORIO_PROVAS_REALIZADAS_ESTUDO",
	RELATORIO_ESTATISTICAS = "RELATORIO_ESTATISTICAS",
	RELATORIO_RANKING = "RELATORIO_RANKING",
	RELATORIO_CLASSIFICACOES = "RELATORIO_CLASSIFICACOES",
	RELATORIO_PERSONALIZADOS = "RELATORIO_PERSONALIZADOS",

	REVISAO_PROVA_CONTEUDO = "REVISAO_PROVA_CONTEUDO",

	SALA_CADASTRO = "SALA_CADASTRO",

	TELA_CORRECAO_ANULACAO = "TELA_CORRECAO_ANULACAO",
	TELA_CORRECAO_REABERTURA_PROVA = "TELA_CORRECAO_REABERTURA_PROVA",
	TELA_CORRECAO_REMOCAO_ANULACAO = "TELA_CORRECAO_REMOCAO_ANULACAO",

	TURMA_CADASTRO = "TURMA_CADASTRO",
	TURMA_AUDITORIA = "TURMA_AUDITORIA",
}
class UtilCfg {
	
	static cfgs: Map<string, string>;
	
	static getCfg(idCfg: string) {
		
		if (!UtilCfg.cfgs) return idCfg;

		return UtilCfg.cfgs[idCfg];
	}
}


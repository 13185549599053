class UtilEmailPrevia {

	static criar(cfgs: UtilEmailPreviaCfgs) {

		return `
			<div class='row' id="componenteEmailPrevia">
				<div class='col-md-12'>
					<label class='col-md-12'>
						${cfgs.titulo} 
						<small class='pull-right'>${cfgs.info || ''}</small>
					</label>
					<div email-previa class='col-md-12'></div>
				</div>
			</div>
		`;
	}

	static atualizarTextoPrevia(templateEmail: string, nomeComponenteTextEmail: string, variaveis?: UtilEmailPreviaVariaveisType[]) {

		UtilEmailPreviaVariaveis[UtilEmailPreviaVariaveisEnum.nome_sistema] = amaisVH.getNomeEmpresaUsuarioLogado();

		if(!variaveis || variaveis.length === 0) {
			variaveis = [];
		}

		for(let item in UtilEmailPreviaVariaveis) {
			variaveis.push({
				variavel: item as any as UtilEmailPreviaVariaveisEnum,
				valor: UtilEmailPreviaVariaveisEnum[item]
			});
		}

		let conteudo = amaisVH.getValor(nomeComponenteTextEmail);

		for(let item of variaveis) {
			let regex = new RegExp(`\\\${${item.valor}}`, 'g');
			let variavel: string;

			if(item.mensagem) {
				variavel = item.mensagem;
			} else {
				variavel = UtilEmailPreviaVariaveis[item.variavel];
			}

			if(!variavel) {
				variavel = `<strong class="text-danger">ATENÇÃO: varíavel não localizada</strong>`
			}

			conteudo = conteudo.replace(regex, variavel);
		}

		$("[email-previa]").css("margin-bottom", "18px").html(
			templateEmail
				.replace(/\$\{conteudo}/g, conteudo)
				.replace(/\$\{nomeSistema}/g, amaisVH.getCfg("LABEL_ALUNO"))
				.replace(/\$\{ano_atual}/g, UtilData.getAno())
		);
	}
}

type UtilEmailPreviaCfgs = {
	titulo: string;
	info?: string;
}

enum UtilEmailPreviaVariaveisEnum {
	usuario_codigo,
	usuario_nome,
	usuario_email_login,
	usuario_primeiro_nome,
	usuario_senha,
	usuario_login,
	usuario_link_definir_nova_senha,
	usuario_aproveitamento,
	usuario_ranking,
	nome_sistema
}

type UtilEmailPreviaVariaveisType = {
	variavel: UtilEmailPreviaVariaveisEnum,
	valor: string,
	mensagem?: string
}

const UtilEmailPreviaVariaveis: {[key in UtilEmailPreviaVariaveisEnum]?: string} = {
	[UtilEmailPreviaVariaveisEnum.usuario_codigo]: '1239',
	[UtilEmailPreviaVariaveisEnum.usuario_nome]: 'José da Silva',
	[UtilEmailPreviaVariaveisEnum.usuario_primeiro_nome]: 'José',
	[UtilEmailPreviaVariaveisEnum.usuario_login]: 'jose.login',
	[UtilEmailPreviaVariaveisEnum.usuario_senha]: 'Abj7WzSs',
	[UtilEmailPreviaVariaveisEnum.usuario_email_login]: 'jose.login@email.com',
	[UtilEmailPreviaVariaveisEnum.usuario_link_definir_nova_senha]: `<a href="#">${window.location.protocol}//${window.location.host}/#/trs/dka3Ka-EXEMPLO-afkK10</a>`,
	[UtilEmailPreviaVariaveisEnum.usuario_aproveitamento]: '90',
	[UtilEmailPreviaVariaveisEnum.usuario_ranking]: '3° em 10',
	[UtilEmailPreviaVariaveisEnum.nome_sistema]: ''
}
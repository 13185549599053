class UtilArmazenamento {
	
	static baseURLAcessoExterno: string;
	static baseURLAcessoDireto: string;
	static baseURLAcessoDiretoRoot: string;
	static baseURLAcessoExternoRoot: string;
	
	static setBaseURLAcessoExterno(baseURLAcessoExterno: string) {
		UtilArmazenamento.baseURLAcessoExterno = baseURLAcessoExterno;
	}
	
	static setBaseURLAcessoDireto(baseURLAcessoDireto: string) {
		UtilArmazenamento.baseURLAcessoDireto = baseURLAcessoDireto;
	}

	static setBaseURLAcessoDiretoRoot(baseURLAcessoDiretoRoot: string) {
		UtilArmazenamento.baseURLAcessoDiretoRoot = baseURLAcessoDiretoRoot;
	}

	static setBaseURLAcessoExternoRoot(baseURLAcessoExternoRoot: string) {
		UtilArmazenamento.baseURLAcessoExternoRoot = baseURLAcessoExternoRoot;
	}
	
	static isOnArmazenamento(url: string) {
		return url.includes(UtilArmazenamento.baseURLAcessoExterno);
	}

	static isOnArmazenamentoExternoOuDiretoMS(url: string) {
		if(url.includes(UtilArmazenamento.baseURLAcessoExterno) || url.includes(UtilArmazenamento.baseURLAcessoDireto)){
			return true;
		}
		else{
			url = url.startsWith("//") ? "https:" + url: url;
			let domainUrl;
			try{
				domainUrl = (new URL(url));
			} catch (e){
				domainUrl = (new URL(url.replace("https", "http")));
			}
			let domainExterno = (new URL(UtilArmazenamento.baseURLAcessoExterno));
			let domainDireto = (new URL(UtilArmazenamento.baseURLAcessoDireto));

			let domainUrlOrigin = domainUrl.origin;
			let domainExternoOrigin = domainExterno.origin;
			let domainDiretoOrigin = domainDireto.origin;

			return domainUrlOrigin === domainExternoOrigin || domainUrlOrigin === domainDiretoOrigin;
		}
	}
	
	static async upload(file, privado): Promise<UploadTO> {
		const uploadURLTO = await UtilArmazenamento.gerarUrlParaUpload(file, privado);
		await UtilArmazenamento.enviar(file, uploadURLTO.urlUploadViaPUT);
		await UtilArmazenamento.finalizar(uploadURLTO.uploadTO);
		return uploadURLTO.uploadTO;
	}

	static async tonarPublico(filePath) {
		const uploadTO: UploadTO = {
			privado: false,
			pathArmazenamento: filePath,
			base64: null,
			metadata: {},
			nomeArquivo: null,
			url: null
		}
		await UtilArmazenamento.finalizar(uploadTO);
	}
	
	static async gerarUrlParaUpload(file, privado): Promise<UploadURLTO> {
		// precisa passar esse "call" para um Util para não chamar VH dos Utils
		const uploadURLTO = <UploadURLTO> await amaisVH.call({
			endpoint: "UploadFCD/gerarUrlUpload", 
			params: [{ nomeArquivo: file.name, privado: privado, mimeType: file.type }],
			msgCarregando: UtilMsg.getMsg("FP_FRONT_AmaisVH_036")
		});

		return uploadURLTO;
	}
	
	static async enviar(file, urlUploadViaPUT: string): Promise<void> {
		return new Promise(function(resolve, reject) {

			let xhr = new XMLHttpRequest();
			xhr.open("PUT", urlUploadViaPUT);
			
			xhr.onerror = () => {}
			xhr.ontimeout = () => {}
			xhr.onload = () => {
				if (xhr.status == 200) {
					resolve();
				} else {
					reject(xhr);
				}
			};
			
			xhr.setRequestHeader('Content-Type', file.type);
			xhr.send(file);
		});
	}
	
	static async finalizar(uploadTO: UploadTO) {
		// precisa passar esse "call" para um Util para não chamar VH dos Utils
		await amaisVH.call({
			endpoint: "UploadFCD/finalizar", 
			params: [uploadTO],
			msgCarregando: UtilMsg.getMsg("FP_FRONT_AmaisVH_009")
		});
	}

	static converterPathAbsolutoParaRelativo(path: string) {
		if (path.includes(UtilArmazenamento.baseURLAcessoExterno)) {
			return path.substring(UtilArmazenamento.baseURLAcessoExterno.length);
		} else {
			return path;
		}
	}

	static isSignedUrl(path: string) {
		return path && path.includes("http") && (path.includes("X-Amz-Signature") || path.includes("&Signature"));
	}
	
	static getPathArmazenamento(path: string, isRoot: Boolean = false) {
		if (UtilArmazenamento.isSignedUrl(path)) {
			return path;
		} else if (isRoot) {
			if (path.includes(UtilArmazenamento.baseURLAcessoExternoRoot) || path.includes(UtilArmazenamento.baseURLAcessoDiretoRoot)) {
				return path;
			} else {
				return UtilArmazenamento.baseURLAcessoExternoRoot + "/" + path;
			}
		} else {
			if (path.includes(UtilArmazenamento.baseURLAcessoExterno) || path.includes(UtilArmazenamento.baseURLAcessoDireto)) {
				return path;
			} else {
				return UtilArmazenamento.baseURLAcessoExterno + path;
			}
		}
	}

	static getPathAcessoDireto(path: string, isRoot: Boolean = false) {
		if (UtilArmazenamento.isSignedUrl(path)) {
			return path;
		} else if (isRoot) {
			if (path.includes(UtilArmazenamento.baseURLAcessoExternoRoot) || path.includes(UtilArmazenamento.baseURLAcessoDiretoRoot)) {
				return path;
			} else {
				return UtilArmazenamento.baseURLAcessoExternoRoot + "/" + path;
			}
		} else {
			if (path.includes(UtilArmazenamento.baseURLAcessoExterno) || path.includes(UtilArmazenamento.baseURLAcessoDireto)) {
				return path;
			} else {
				return UtilArmazenamento.baseURLAcessoDireto + path;
			}
		}
	}

	static carregarMetadata(url: string, nomeAtributo: string) {
		return new Promise(function(resolve, reject) {

			let xhr = new XMLHttpRequest();
			xhr.open("HEAD", url);
			
			xhr.onerror = () => {
				console.error(xhr);
				reject("Erro");
			}
			xhr.ontimeout = () => {
				console.error(xhr);
				reject("Timeout");
			}
			xhr.onload = () => {
				if (xhr.status == 200) {
					resolve(xhr.getResponseHeader("x-amz-meta-" + nomeAtributo));
				} else {
					console.error(xhr);
					reject("Erro " + xhr.status);
				}
			};
			
			xhr.send();
		});
	}
	
	static aguardarGeracaoJson(url: string): Promise<any> {
	    return new Promise<string>((resolve) => {
			const load = async () => {
				try {
					const data = await UtilArmazenamento.carregar(url);

					resolve(JSON.parse(data));
					
				} catch (e) {
					setTimeout(() => {
						load();
					}, 2000);
				}
			}

			load();
		});
	}

	static carregar(url: string): Promise<string> {
		return new Promise<string>((resolve, reject) => {

			let xhr = new XMLHttpRequest();
			
			xhr.open("GET", url);
			
			xhr.onerror = () => {
				reject("Erro ao carregar arquivo " + url);
			}
			xhr.ontimeout = () => {
				reject("Timeout");
			}
			xhr.onload = (data) => {
				if (xhr.status == 200) {
					resolve(xhr.responseText);
				} else {
					reject("Erro " + xhr.status);
				}
			};
			
			xhr.send();
		});
	}
	
	static async aguardarArquivoDisponivel(elementoDoLink, path, htmlDoLink, isRoot = false, callBack?: Function) {

		if ($(elementoDoLink).closest("body").length == 0) return;

		const pathExterno = UtilArmazenamento.getPathArmazenamento(path, isRoot);
		const pathDireto = UtilArmazenamento.getPathAcessoDireto(path, isRoot);

		let response = null;

		try {
			response = await fetch(pathExterno, {
				method: "GET",
				headers: {
					"cache-control": "no-store"
				}
			});
		} catch (ignored) {}

		if (response && response.status == 200) {
			$(elementoDoLink).html(htmlDoLink).attr("href", pathExterno);
		} else {
			setTimeout(async () => {
				try {
					response = null;
					response = await fetch(pathDireto, {
						method: "GET",
						headers: {
							"cache-control": "no-store"
						}
					});
				} catch (ignored) {}
	
				if (response && response.status == 200) {
					$(elementoDoLink).html(htmlDoLink).attr("href", pathExterno);
					if (callBack) callBack();
				} else {
					setTimeout(() => {
						UtilArmazenamento.aguardarArquivoDisponivel(elementoDoLink, path, htmlDoLink, isRoot);
					}, 10000);
				}
			}, 10000);
		}
	}
	
	static async getURLArmazenamentoAssinadaGET(path: string) {

		if (UtilArmazenamento.isSignedUrl(path)) {
			return path;
		} 
		
		// precisa passar esse "call" para um Util para não chamar VH dos Utils
		const resultadoURLGetAssinadaTO = await amaisVH.call("UploadFCD/gerarUrlDownload", {
			path: path
		});

		return resultadoURLGetAssinadaTO.url;
	}
}

type UploadURLTO = {
	urlUploadViaPUT: string;
	uploadTO: UploadTO;
}
type UploadTO = {
	pathArmazenamento: string;
	url: string;
	nomeArquivo: string;
	privado: boolean;
	metadata: any;
	base64: string;
}
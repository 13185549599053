class QuestVH extends AmaisVH {
	
	listaRespostaQuestionarioPendenteTO: RespostaQuestionarioPendenteTO[] = [];

	constructor() {
		super(QuestVH.name);
	}

	addAOsPendentes(tos: RespostaQuestionarioPendenteTO[]) {
		if (this.isEmpty(tos)) return;
		for (const to of tos) {
			if (this.isEmpty(to)) continue;
			this.listaRespostaQuestionarioPendenteTO.push(to);
		}
		setTimeout(() => this.addAlertasAOsPendentes(), 1000);
	}

	exibirQuestionario($containerQuestionario, aoQestionarioTO: AOQuestTO) {

		if (!aoQestionarioTO) return;

		if ($containerQuestionario.length == 0 || $containerQuestionario.data("fp-ja-exibiu")) return;

		$containerQuestionario.html(`
			<span msg-aguarde-carregando-questionario>
				<i class="fa fa-spinner fa-spin fa-fw"></i> ${this.getMsg("FP_FRONT_QuestionarioVH_002")}
			</span>
			<div data-quest-msg-erro="true" class="alert alert-danger" role="alert" style="display: none">
				${this.getMsg("FP_FRONT_QuestionarioVH_003")}
			</div>
		`);

		$containerQuestionario.data("aoQestionarioTO", aoQestionarioTO);

		const $f = $containerQuestionario.find("[msg-aguarde-carregando-questionario]");
		const $divQuestionario = $(`
			<div class="row" style="font-size: 120%">
				<form>
				</form>
			</div>
		`);

		const secoesJaInseridas = [];

		for (const pergunta of aoQestionarioTO.perguntas) {

			const tipo = pergunta.tipo.toLowerCase();
			const isObrigatoria = pergunta.isObrigatoria === true;

			if (tipo === "nome" || tipo === "telefone" || tipo === "e-mail") continue;

			if (pergunta.numSecao && !secoesJaInseridas.includes(pergunta.numSecao)) {
				const secao: AOQuestSecaoTO = aoQestionarioTO.secoes.find(s => s.numSecao === pergunta.numSecao);
				$divQuestionario.append(`
					<div class="form-group col-md-12" style="font-size: 130%; color: #161649; font-weight: bold">
						<h2>${secao.numSecao}) ${secao.texto}</h2>
					</div>
				`);
				secoesJaInseridas.push(pergunta.numSecao);
			}

			let style = "padding-top: 5px; padding-bottom: 22px; margin-bottom: 5px;";
			let condicao = "";

			if (pergunta.condicao) {
				style += " display: none;"
				condicao = `
					condicao-id-pergunta="${pergunta.condicao.idPergunta}" 
					condicao-ids-opcoes="${JSON.stringify(pergunta.condicao.idsOpcoes)}"
				`;
			}

			const $divPergunta = $(`
				<div class="form-group col-md-12" style="${style}" ${condicao}>
					<label style="font-weight: normal">
						${pergunta.texto + (isObrigatoria ? "*" : "")}
						<br>
					</label>
				</div>
			`);

			const propsQuestPergunta = `
				data-quest-pergunta-id="${pergunta.id}"
				data-quest-pergunta-num-secao="${pergunta.numSecao || ''}"
				data-quest-pergunta-is-obrigatoria="${isObrigatoria}"
				data-quest-resposta-id="${pergunta.idResposta || ''}"
			`;

			if (pergunta.opcoes) {

				if (pergunta.isMultiplaResposta) {
					const $divOpcoes = $(`
						<div class="checkbox" ${propsQuestPergunta}></div>
					`);

					for (const opcao of pergunta.opcoes) {
						const checked = opcao.isEscolhida ? "checked='cheched'" : "";
						const attrsQuest = `
							data-quest-opcao-id="${opcao.id}" 
							data-quest-opcao-num="${opcao.numOpcao}"
						`;
						$divOpcoes.append(`
							<label>
								<input type="checkbox" name="pergunta${pergunta.id}" value="${opcao.id}" ${attrsQuest} ${checked}/>
								${opcao.texto}
							</label>
						`);
					}

					$divPergunta.append($divOpcoes);

				} else if (pergunta.opcoes.length <= 2) {
					const $divOpcoes = $(`
						<div class="radio" ${propsQuestPergunta}></div>
					`);

					for (const opcao of pergunta.opcoes) {
						const checked = opcao.isEscolhida ? "checked='cheched'" : "";
						const attrsQuest = `
							data-quest-opcao-id="${opcao.id}" 
							data-quest-opcao-num="${opcao.numOpcao}"
						`;
						$divOpcoes.append(`
							<label>
								<input type="radio" name="pergunta${pergunta.id}" value="${opcao.id}" ${attrsQuest} ${checked} />
								${opcao.texto}
							</label>
						`);
					}

					$divPergunta.append($divOpcoes);

				} else {
					const $divOpcoes = $(`
						<select class="select2 form-control" ${propsQuestPergunta}>
							<option selected disabled>
								${this.getMsg("FP_FRONT_QuestionarioVH_005")}
							</option>
						</select>
					`);

					let possuiOpcaoAberta = false;
					let isCampoAbertoExibido = false;

					for (const opcao of pergunta.opcoes) {
						let value: any = opcao.id;
						const attrsQuest = `
							data-quest-opcao-id="${opcao.id}" 
							data-quest-opcao-num="${opcao.numOpcao}"
						`;
						const selected = opcao.isEscolhida ? "selected=selected" : "";

						if (opcao.isAberta) {
							value = "OUTRAS";
							possuiOpcaoAberta = true;
							isCampoAbertoExibido = opcao.isEscolhida;
						}

						$divOpcoes.append(`
							<option value="${value}" ${attrsQuest} ${selected}>
								${opcao.texto}
							</option>
						`);
					}

					$divPergunta.append($divOpcoes);

					if (possuiOpcaoAberta) {
						const $divOpcaoAberta = $(`
							<input type="text" class="form-control" data-quest-opcao-campo-aberto placeholder="${this.getMsg("FP_FRONT_QuestionarioVH_006")}" style="margin-top: 10px;">
						`);

						if (this.hasValue(pergunta.textoDaResposta)) {
							$divOpcaoAberta.val(pergunta.textoDaResposta);
						}

						if (isCampoAbertoExibido) {
							$divOpcaoAberta.show();
						} else {
							$divOpcaoAberta.hide();
						}

						$divPergunta.append($divOpcaoAberta);
					}
				}
			} else if (tipo != "texto explicativo") {
				const $input = $(`
					<input type="text" class="form-control" ${propsQuestPergunta}>
				`);
				if (this.hasValue(pergunta.textoDaResposta)) {
					$input.val(pergunta.textoDaResposta);
				}
				$divPergunta.append($input);
			}

			$divQuestionario.append($divPergunta);
		}

		$f.replaceWith($divQuestionario);

		$("option[value='OUTRAS']").parent().on("change", function () {
			const $select = $(this);
			if ($select.val() == "OUTRAS") {
				$select.parent().find("input[type='text']").slideDown(function () {
					$(this).focus();
				});
			} else {
				$select.parent().find("input[type='text']").slideUp();
			}
		});

		$containerQuestionario.data("fp-ja-exibiu", true);

		$divQuestionario.find(`
			[data-quest-pergunta-id], 
			[data-quest-opcao-campo-aberto]
		`).on("change", async (event: Event) => {

			if (!event?.target) return;
			this.atualizarCondicoes();
			await this.salvarResposta(aoQestionarioTO, event.target);
		});

		this.exibir();

		this.atualizarCondicoes();

		this.dispararQuestionarioCarregado($containerQuestionario);

		this.logger.info("Evento fp-questionario-carregado diparado.");
	}

	dispararQuestionarioCarregado($containerQuestionario) {

		// faz mais de uma vez para esperar personalizações carregarem
		// as personalizacoes precisam controle se já rodaram ou não

		this.logger.info("Chamou handleQuestionarioCarregado");
		
		let contador = 0;

		const enviar = async () => {
			
			if (contador >= 20) return;

			contador++;

			let tratou = false;

			try {
				tratou = await $(document).triggerHandler("fp-questionario-carregado", [$containerQuestionario]);
				
			} catch (ignored) {}

			if (tratou) return;

			setTimeout(enviar, 300);
		}
		
		enviar();
	}

	async getRespostas($containerQuestionario, codUsuario, nomeUsuario, emailUsuario, telefoneUsuario?): Promise<SalvamentoQuestionarioIntegracaoTO> {

		const aoQestionarioTO: AOQuestTO = $containerQuestionario.data("aoQestionarioTO");

		if (!aoQestionarioTO) return;

		$("[data-quest-msg-erro]").slideUp();

		const abortarComMsgErro = async ($pergunta) => {
			await $("[data-quest-msg-erro]").slideDown(() => {
				UtilWindow.scrollTo($pergunta.closest(".form-group"), true);
				$pergunta.closest(".form-group").find("input, select").focus();
			});
			aplicacaoExternaVH.toggleBotaoInscricao(false);
			throw new Error("Pendência no questionário");
		}

		const mapRespostaSalvamentoQuestionarioIntegracaoTO = this.getMapaRespostaSalvamentoQuestionarioIntegracaoTO();
		const salvamentoQuestionarioIntegracaoTO: SalvamentoQuestionarioIntegracaoTO = {
			idAplicacao: aoQestionarioTO.idAplicacao,
			idRespostaQuestionario: aoQestionarioTO.idRespostaQuestionario,
			idPessoaIntegracao: codUsuario,
			nome: nomeUsuario,
			telefone: telefoneUsuario,
			email: emailUsuario,
			respostas: Array.from(mapRespostaSalvamentoQuestionarioIntegracaoTO.values())
		}

		for (const respostaSalvamentoQuestionarioIntegracaoTO of salvamentoQuestionarioIntegracaoTO.respostas) {

			if (!respostaSalvamentoQuestionarioIntegracaoTO.isObrigatoria) continue;

			if (respostaSalvamentoQuestionarioIntegracaoTO.isAberta) {
				if (this.isEmpty(respostaSalvamentoQuestionarioIntegracaoTO.respostaAberta)) {
					const $pergunta = $(`[data-quest-pergunta-id="${respostaSalvamentoQuestionarioIntegracaoTO.idDaPergunta}"]`);
					await abortarComMsgErro($pergunta);
				}
			} else if (this.isEmpty(respostaSalvamentoQuestionarioIntegracaoTO.idsOpcoesSelecionadas)) {
				const $pergunta = $(`[data-quest-pergunta-id="${respostaSalvamentoQuestionarioIntegracaoTO.idDaPergunta}"]`);
				await abortarComMsgErro($pergunta);
			}
		}

		for (const pergunta of aoQestionarioTO.perguntas) {
			const tipo = pergunta.tipo.toLowerCase();

			if (tipo === "nome" && nomeUsuario) {

				salvamentoQuestionarioIntegracaoTO.respostas.push({
					idDaPergunta: Number(pergunta.id),
					idResposta: pergunta.idResposta,
					respostaAberta: nomeUsuario
				});

			} else if (tipo === "telefone" && telefoneUsuario) {

				salvamentoQuestionarioIntegracaoTO.respostas.push({
					idDaPergunta: Number(pergunta.id),
					idResposta: pergunta.idResposta,
					respostaAberta: telefoneUsuario
				});

			} else if (tipo === "e-mail" && emailUsuario) {

				salvamentoQuestionarioIntegracaoTO.respostas.push({
					idDaPergunta: Number(pergunta.id),
					idResposta: pergunta.idResposta,
					respostaAberta: emailUsuario
				});
			}
		}

		return salvamentoQuestionarioIntegracaoTO;
	}

	aplicarQuestionario(idAplicacaoQuest: number, idPessoaAvaliada?: number): Promise<void> {

		if (!idAplicacaoQuest) return;

		return new Promise(async (resolve) => {

			const aoQuestTO: AOQuestTO = await this.call("QuestFCD/carregarQuestionario", idAplicacaoQuest, idPessoaAvaliada);

			const htmlBotao = this.addBotao({
				label: this.getMsg("FP_FRONT_QuestionarioVH_007"),
				classe: "btn-primary",
				css: "width: 100%",
				onClick: async () => {
					const salvamentoQuestionarioIntegracaoTO: SalvamentoQuestionarioIntegracaoTO = await this.getRespostas(
						$("#questionario"),
						this.getCodUsuarioLogado(),
						this.getNomeUsuarioLogado(),
						this.getEmailUsuarioLogado()
					);
					await this.call("QuestFCD/salvarRespostas", salvamentoQuestionarioIntegracaoTO);
					resolve();
				},
				retornarHtml: true
			});

			this.limpar(false, true);

			this.setTitulo(aoQuestTO.tituloQuestionario);

			if (aoQuestTO.nomePessoaAvaliada) {
				this.setSubtitulo(aoQuestTO.nomePessoaAvaliada);
			}

			this.append(`
				<div id='divQuestionario' style='background-color: #f5f5f5;	margin-top: -29px; padding: 30px;'>
					<div id='bodyQuestionario' style='max-width: 600px; margin: auto; background-color: white; padding: 30px; border: 1px solid #ddd;'>
						<div id='questionario'></div>
						${htmlBotao}
					</div>
				</div>
			`);

			this.exibir({ isDeveFazerScrollParaTitulo: true });

			this.exibirQuestionario($("#questionario"), aoQuestTO);
		});
	}

	exibirBotaoEAplicarPosProva($spanBotao, idAplicacaoQuestPosProva: number) {

		if (!idAplicacaoQuestPosProva) return;

		return new Promise(async (resolve) => {
			$spanBotao.replaceWith(this.addBotao({
				label: `<i class='fa fa-comment-o'></i> ${this.getMsg("FP_FRONT_QuestionarioVH_008")}`,
				retornarHtml: true,
				onClick: async () => {
					await this.aplicarQuestionario(idAplicacaoQuestPosProva);
					resolve(true);
				}
			}));
		});
	}

	private getMapaRespostaSalvamentoQuestionarioIntegracaoTO(): Map<number, RespostaSalvamentoQuestionarioIntegracaoTO> {
		const respostas = new Map();

		for (const pergunta of Array.from(document.querySelectorAll("[data-quest-pergunta-id]"))) {

			const $pergunta = $(pergunta);

			if (!this.isShown($pergunta.closest(".form-group"))) {
				continue;
			}

			const rTO: RespostaSalvamentoQuestionarioIntegracaoTO = {
				idDaPergunta: Number($pergunta.data("quest-pergunta-id")),
				idResposta: Number($pergunta.data("quest-resposta-id")),
				isObrigatoria: $pergunta.data("quest-pergunta-is-obrigatoria"),
			}

			respostas.set(rTO.idDaPergunta, rTO);

			if ($pergunta.is("select")) {
				const idOpcaoSelecionada = $pergunta.find("option:selected").data("quest-opcao-id");

				if (idOpcaoSelecionada) {
					rTO.idsOpcoesSelecionadas = [Number(idOpcaoSelecionada)];

					if ($pergunta.val() == "OUTRAS") {
						rTO.respostaAberta = $pergunta.parent().find("input[type='text']").val();
					}
				}

			} else if ($pergunta.is("div.radio")) {
				const idOpcaoSelecionada = $pergunta.find("input:checked").data("quest-opcao-id");

				if (idOpcaoSelecionada) {
					rTO.idsOpcoesSelecionadas = [Number(idOpcaoSelecionada)];
				}

			} else if ($pergunta.is("div.checkbox")) {

				$pergunta.find("input:checked").each(function () {
					rTO.idsOpcoesSelecionadas = rTO.idsOpcoesSelecionadas || [];
					const idOpcaoSelecionada = $(this).data("quest-opcao-id");
					rTO.idsOpcoesSelecionadas.push(Number(idOpcaoSelecionada));
				});

			} else if ($pergunta.is("input[type='text']")) {
				rTO.isAberta = true;
				rTO.respostaAberta = $pergunta.val();
			}
		}

		return respostas;
	}

	atualizarCondicoes() {
		const m = this.getMapaRespostaSalvamentoQuestionarioIntegracaoTO();

		for (const idPergunta of Array.from(m.keys())) {

			const condicionadasAEstaPergunta = document.querySelectorAll(`[condicao-id-pergunta="${idPergunta}"]`);

			if (condicionadasAEstaPergunta == null || condicionadasAEstaPergunta.length == 0) continue;

			const respostaSalvamentoQuestionarioIntegracaoTO = m.get(idPergunta);

			if (this.isEmpty(respostaSalvamentoQuestionarioIntegracaoTO.idsOpcoesSelecionadas)) {
				$(condicionadasAEstaPergunta).slideUp().find("input, select").val(""); // hide
				continue;
			}

			for (const perguntaCondicionada of Array.from(condicionadasAEstaPergunta)) {
				const idsOpcoesCondicao = JSON.parse(perguntaCondicionada.getAttribute("condicao-ids-opcoes"));

				if (idsOpcoesCondicao.some(id => respostaSalvamentoQuestionarioIntegracaoTO.idsOpcoesSelecionadas.includes(id))) {
					$(condicionadasAEstaPergunta).slideDown(); // show
					continue;
				}

				$(condicionadasAEstaPergunta).slideUp().find("input, select").val(""); // hide
			}
		}
	}
	
	async exibirQuestionarios(codProva: number) {
		this.limpar(true);

		this.abrirAbaAccordion({
			titulo: this.getMsg("FP_FRONT_RelatorioProvasVH_053"),
			aberta: true
		});

		const filtrosQuestionario = await this.call("QuestFCD/recuperarFiltrosRel", codProva);

		this.addFormulario();

		const soPossuiUm = filtrosQuestionario.listaQuestionarioDisponivelTO?.length === 1;

		if (soPossuiUm) {
			filtrosQuestionario.listaQuestionarioDisponivelTO[0].isSelecionado = true;
		}

		await this.addSelect({
			collection: filtrosQuestionario.listaQuestionarioDisponivelTO,
			propId: "idQuestionario",
			propLabel: "nomeQuestionario",
			id: "idQuestionario",
			label: this.getMsg("FP_FRONT_RelatorioProvasVH_108"),
			classe: "col-md-8",
			obrigatorio: true
		})

		this.addEspacamentoHorizontal("1px");

		this.addBotao({
			label: this.getMsg("FP_FRONT_RelatorioProvasVH_088"),
			css: "float: right",
			classe: "btn-primary",
			onClick: async () => await this.gerarQuestionario(codProva)
		})

		this.fecharFormulario();
		this.fecharAbaAccordion()
		this.fecharGrupoAccordion();

		this.append("<div class='row'><div id='divTabelaQuestionarios' style='min-width: 680px; max-width: calc(100vw - 45px);'></div></div>")

		this.exibir();

		if (soPossuiUm) {
			await this.gerarQuestionario(codProva);
		}
	}

	async gerarQuestionario(codProva: number) {

		if (!this.validarCamposObrigatorios("idQuestionario")) {
			return
		}

		$("#divTabelaQuestionarios").html("");

		const filtrosRespostasQuestionarioTO: FiltrosRespostasQuestionarioTO = {
			codProva,
			idQuestionario: this.getValor('idQuestionario'),
			paginacaoTO: null
		}

		let exibicaoRespostasQuestTO = <ExibicaoRespostasQuestTO> await this.call("QuestFCD/listarRespostas", filtrosRespostasQuestionarioTO);
		const colunas: ColunaAddTabela[] = [];

		colunas.push({ titulo: "Nome", prop: "nome" });
		colunas.push({ titulo: "Respondeu em", prop: "dataEnvio", formato: "DD/MM/YYYY HH:mm" });

		exibicaoRespostasQuestTO.listaPerguntaQuestTO.forEach((perguntaQuestTO: PerguntaQuestTO) => {
			colunas.push({ 
				titulo: perguntaQuestTO.textoPergunta, 
				prop: `resposta${perguntaQuestTO.idPergunta}`
			});
		})

		this.setIdTarget('divTabelaQuestionarios');

		await this.addTabela({
			collection: exibicaoRespostasQuestTO.listaQuestionariosRespondidos,
			id: "idListagem",
			colunas: colunas,
			paginacao: {
				paginacaoTO: exibicaoRespostasQuestTO.paginacaoTO,
				onCarregarPagina: async (paginacaoTO) => {
					filtrosRespostasQuestionarioTO.paginacaoTO = paginacaoTO;
					exibicaoRespostasQuestTO = <ExibicaoRespostasQuestTO> await this.call("QuestFCD/listarRespostas", filtrosRespostasQuestionarioTO);
					return exibicaoRespostasQuestTO.listaQuestionariosRespondidos;
				}
			},
			ordenacao: false,
			msgListaVazia: this.getMsg("FP_FRONT_RelatorioProvasVH_109")
		});
		this.setIdTarget(null)

		this.exibir();
	}

	async addAlertasAOsPendentes() {
		if (this.isEmpty(this.listaRespostaQuestionarioPendenteTO)) return;

		let $divAlertas = $("#container [alertas-quest]");
			
		if ($divAlertas.length == 0) {
			$divAlertas = $(`
				<div class="alert alert-warning col-md-12 text-center" role="alert" alertas-quest style="width: 100%"></div>
			`);
			$("#container").prepend($("<div class='row' style='margin-top: 10px'></div>").append($divAlertas));
			$divAlertas.hide();
		}

		const h = [];
		h.push(`
			Há um questionário para você responder.
		`);
		h.push(this.addLink({
			label: "Clique aqui",
			retornarHtml: true,
			css: "padding: 0px; vertical-align: unset",
			onClick: async () => {
				if (this.listaRespostaQuestionarioPendenteTO.length === 1) {
					const to = this.listaRespostaQuestionarioPendenteTO[0];
					// se tem só um já abre o questionário
					await this.aplicarQuestionario(to.idAplicacao, to.idPessoaAvaliada);
					document.location.reload();
				} else {
					await this.exibirPopupQuestionariosPendentes();
				}
			}
		}));
		h.push(`
				para acessar.
			</div>
		`);
		$divAlertas.html(h.join(""));

		$divAlertas.fadeIn("slow");
		this.exibir();
	}

	async salvarResposta(aoQestionarioTO: AOQuestTO, input: any) {

		/**
		 * Não tem o questionario da pessoa gerado ainda,
		 * então não salva resposta por resposta.
		 */
		if (!aoQestionarioTO.idRespostaQuestionario) return;

		const $input = $(input);
		let $pergunta;

		if ($input.is("[data-quest-opcao-campo-aberto]")) {
			$pergunta = $input.prev();
		} else {
			$pergunta = $input.closest("[data-quest-pergunta-id]");
		}
		
		const idPergunta = Number($pergunta.data("quest-pergunta-id"));

		if (!idPergunta) return;

		const m = this.getMapaRespostaSalvamentoQuestionarioIntegracaoTO();
		const resposta: RespostaSalvamentoQuestionarioIntegracaoTO = m.get(idPergunta);

		if (!resposta) return;

		const listaSalvamentoRespostaQuestTO: SalvamentoRespostaQuestTO = {
			idAplicacao: aoQestionarioTO.idAplicacao,
			respostas: [resposta]
		};
		
		for (const divPerguntaCondicionada of $(`[condicao-id-pergunta="${idPergunta}"]`)) {
			const $perguntaCondicionada = $(divPerguntaCondicionada).find("[data-quest-pergunta-id");
			const idPerguntaCondicionada = $perguntaCondicionada.data("quest-pergunta-id");
			const respostaParaAPerguntaCondicionada: RespostaSalvamentoQuestionarioIntegracaoTO = m.get(idPerguntaCondicionada);

			if (!respostaParaAPerguntaCondicionada) continue;

			listaSalvamentoRespostaQuestTO.respostas.push(respostaParaAPerguntaCondicionada);
		}

		await this.call({
			endpoint: "QuestFCD/salvarResposta", 
			params: [listaSalvamentoRespostaQuestTO],
			msgCarregando: "Salvando..."
		});
	}

	getQuestionarioSelecionado(tos: QuestionarioDisponivelTO[], idAplicacao: number): number {

		if (this.isEmpty(tos) || this.isEmpty(idAplicacao)) return null;

		return tos.filter(to => to.idsAplicacao?.includes(Number(idAplicacao))).map(to => to.idQuestionario).pop();
	}

	async exibirPopupQuestionariosPendentes() {

		this.addPopup({
			id: "popup_questionarios_pendentes",
			width: "500px",
			titulo: "Selecione uma opção",
			botoes: []
		});

		this.listaRespostaQuestionarioPendenteTO.forEach(to => {
			let label = to.nomeQuestionario;

			if (to.nomePessoaAvaliada) {
				label += " - " + to.nomePessoaAvaliada;
			}

			this.addLink({
				label,
				onClick: async () => {
					this.fecharPopup("popup_questionarios_pendentes");
					await this.aplicarQuestionario(to.idAplicacao, to.idPessoaAvaliada);
					document.location.reload();
				}
			});

			this.addEspacamentoHorizontal();

		})

		this.exibirPopups();
	}
}

const questVH = new QuestVH();

type SalvamentoQuestionarioIntegracaoTO = {
	idAplicacao: number;
	idRespostaQuestionario: number;
	idPessoaIntegracao: string;
	nome: string;
	telefone: string;
	email: string;
	respostas: RespostaSalvamentoQuestionarioIntegracaoTO[];
}
type RespostaSalvamentoQuestionarioIntegracaoTO = {
	idDaPergunta: number;
	idResposta?: number;
	respostaAberta?: string;
	idsOpcoesSelecionadas?: number[];
	// utilizadas pelo VH, somente
	isObrigatoria?: boolean;
	isAberta?: boolean;
}
type FiltrosRespostasQuestionarioTO = {
	codProva: number;
	idQuestionario: number;
	paginacaoTO: PaginacaoTO;
}
type ExibicaoRespostasQuestTO = {
	listaPerguntaQuestTO: PerguntaQuestTO[];
	listaQuestionariosRespondidos: any[];
	paginacaoTO: PaginacaoTO;
}
type PerguntaQuestTO = {
	idPergunta: number;
	textoPergunta: string;
}


type AOQuestTO = {
	tituloQuestionario: string;
	idAplicacao: number;
	idRespostaQuestionario: number;
	nomePessoaAvaliada: string;
	secoes: AOQuestSecaoTO[];
	perguntas: AOQuestPerguntaTO[];
}
type AOQuestSecaoTO = {
	numSecao: number;
	texto: string;
}
type AOQuestPerguntaTO = {
	id: number;
	idResposta: number;
	texto: string;
	textoDaResposta: string;
	tipo: string;
	isObrigatoria: boolean;
	isMultiplaResposta: boolean;
	opcoes: AOQuestOpcaoTO[];
	condicao: AOQuestCondicaoTO;
	numSecao: number;
}
type AOQuestOpcaoTO = {
	id: number;
	texto: string;
	isAberta: boolean;
	numOpcao: number;
	isEscolhida: boolean;
}
type AOQuestCondicaoTO = {
	idPergunta: number;
	idsOpcoes: number[];
}
type SalvamentoRespostaQuestTO = {
	idAplicacao: number;
	respostas: RespostaSalvamentoQuestionarioIntegracaoTO[];
}
type QuestionarioDisponivelTO = {
	idQuestionario: number;
	nomeQuestionario: string;
	idsIntegracaoAplicacao: string[];
	idsAplicacao: number[];
}
type RespostaQuestionarioPendenteTO = {
	idAplicacao: number;
	idRespostaQuestionario: number;
	nomeQuestionario: string;
	idPessoaAvaliada: number;
	nomePessoaAvaliada: string;
}
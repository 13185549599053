class UtilEmail {

	static esconderEnderecoCompleto(email: string): string {

		if (email == null) return null;

		email = email.trim();
		
		if (email.includes(",")) {
			email = email.substring(0, email.indexOf(',')).trim();
		}
		
		const arrobaServidor = email.substring(email.indexOf('@'));
		let conta = Array.from(email.substring(0, email.indexOf('@')));
		
		const numCaracteresIniciaisExibidos = Math.min(2, Math.round((conta.length - 1.0) / 2.0));
		
		for (let i = numCaracteresIniciaisExibidos; i < conta.length - 1; i++) {
			conta[i] = '*';
		}
		
		return conta.join("") + arrobaServidor;
	}
}


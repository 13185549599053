class UtilTempo {
	/**
	 * Retornar um texto no padrão "D dias, H horas, M minutos e S segundos"
	 * @param tempoEmMs Tempo em milisegundos
	 */
	static getTempoFormatado(tempoEmMs: number){
		const duration = moment.duration(tempoEmMs);
		const dias = Math.floor(duration.asDays());
		const horas = duration.hours();
		const minutos = duration.minutes();
		const segundos = duration.seconds();
		const diasLabel = dias === 1 ? UtilMsg.getMsg("MSG_VH_AP_88") : `${ UtilMsg.getMsg("MSG_VH_AP_88")}s`;
		const horasLabel = horas === 1 ?  UtilMsg.getMsg("MSG_VH_AP_87") : `${ UtilMsg.getMsg("MSG_VH_AP_87")}s`;
		const minutosLabel = minutos === 1 ?  UtilMsg.getMsg("MSG_VH_AP_86") : `${ UtilMsg.getMsg("MSG_VH_AP_86")}s`;
		const segundosLabel = segundos === 1 ?  UtilMsg.getMsg("MSG_VH_AP_85") : `${ UtilMsg.getMsg("MSG_VH_AP_85")}s`;
		if (dias > 0) {
			return `${dias} ${diasLabel}, ${horas} ${horasLabel}, ${minutos} ${minutosLabel} e ${segundos} ${segundosLabel}`;
		}
		if (horas > 0) {
			return `${horas} ${horasLabel}, ${minutos} ${minutosLabel} e ${segundos} ${segundosLabel}`;
		}
		if (minutos > 0) {
			return `${minutos} ${minutosLabel} e ${segundos} ${segundosLabel}`;
		}
		return `${segundos} ${segundosLabel}`;
	}
}


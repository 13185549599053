class FilaAcessoVH extends AmaisVH {

	intervalProximaAtualizacao: any;

	static HTTP_STATUS_AGUARDANDO_FILA = 503;
	static SEGUNDOS_ENTRE_TENTATIVAS = 5;
	
	constructor() {
		super(FilaAcessoVH.name);
		this.addOperacaoParaHash("fa", this.fetchRespeitandoFila);
	}

	isDeveAguardarFila(response: Response) {
		return response?.status === FilaAcessoVH.HTTP_STATUS_AGUARDANDO_FILA;
	}

	async fetchRespeitandoFila(url: string, fetchOptions: any, divMsgCarregandoOriginal?: any): Promise<Response> {

		let userId = localStorage.getItem("fp-fila");

		if (this.hasValue(userId)) {
			fetchOptions.headers = fetchOptions.headers || {};
			fetchOptions.headers["fp-fila"] = userId;
		}

		let response = await fetch(url, fetchOptions);

		if (!this.isDeveAguardarFila(response)) return response;

		let filaAcessoTO = <FilaAcessoTO> (await response.json()).filaAcessoTO;

		localStorage.setItem("fp-fila", filaAcessoTO.userId);
		fetchOptions.headers["fp-fila"] = filaAcessoTO.userId;

		let resolvePromise = null;
		let rejectPromise = null;

		const promise = new Promise<Response>((resolve, reject) => {
			resolvePromise = resolve;
			rejectPromise = reject;
		});

		clearInterval(this.intervalProximaAtualizacao);

		const filaAcessoRetriesTO: FilaAcessoRetriesTO = {
			filaAcessoTO: filaAcessoTO,
			numRetries: 0,
			timestampUltimoRetry: Date.now(),
			segundoParaProximoRetry: 3, // 1a espera é menor
			divMsgCarregandoOriginal: divMsgCarregandoOriginal,
			divMsgAguarde: null,
			isRetryBemSucedido: false
		}
		
		this.atualizarTela(filaAcessoRetriesTO);

		this.intervalProximaAtualizacao = setInterval(async () => {

			filaAcessoRetriesTO.segundoParaProximoRetry--;

			if (filaAcessoRetriesTO.segundoParaProximoRetry <= 0) {

				try {
					try {
						filaAcessoRetriesTO.divMsgAguarde.hide();
					} catch (ignored) {}
					try {
						filaAcessoRetriesTO.divMsgCarregandoOriginal.show();
					} catch (ignored) {}

					filaAcessoRetriesTO.numRetries++;

					response = await fetch(url, fetchOptions);

					if (!this.isDeveAguardarFila(response)) {
						filaAcessoRetriesTO.isRetryBemSucedido = true;
						this.atualizarTela(filaAcessoRetriesTO);
						localStorage.removeItem("fp-fila");
						clearInterval(this.intervalProximaAtualizacao);
						resolvePromise(response);
						return;
					}

					filaAcessoRetriesTO.filaAcessoTO = <FilaAcessoTO> (await response.json()).filaAcessoTO;
					
				} catch (e) {
					this.logger.error(e);
				}

				filaAcessoRetriesTO.segundoParaProximoRetry = FilaAcessoVH.SEGUNDOS_ENTRE_TENTATIVAS;
				filaAcessoRetriesTO.timestampUltimoRetry = Date.now();
			}

			this.atualizarTela(filaAcessoRetriesTO);

		}, 1000);

		return promise;
	}

	atualizarTela(filaAcessoRetriesTO: FilaAcessoRetriesTO) {

		let $modalFilaAcesso = $("#modal-fila-acesso");

		if (filaAcessoRetriesTO.isRetryBemSucedido) {
			try {
				filaAcessoRetriesTO.divMsgAguarde.remove();
			} catch (ignored) {}
			try {
				$modalFilaAcesso.remove();
			} catch (ignored) {}
			return;
		}

		if (filaAcessoRetriesTO.numRetries <= 0) {
			// mantém a msg carregando
			return;
		}

		try {
			filaAcessoRetriesTO.divMsgCarregandoOriginal.hide();
		} catch (ignored) {}

		const msgAguarde = "Aguarde. Tentando novamente em " + filaAcessoRetriesTO.segundoParaProximoRetry + " segundos...";

		if (filaAcessoRetriesTO.divMsgAguarde) {
			filaAcessoRetriesTO.divMsgAguarde.find(".progress-bar").text(msgAguarde);
			filaAcessoRetriesTO.divMsgAguarde.show();
			
		} else {
			filaAcessoRetriesTO.divMsgAguarde = AmaisVH.criarDivMsgAjax(
				msgAguarde,
				true,
				"msg-aguardando-fila"
			);
		}

		if (filaAcessoRetriesTO.numRetries <= 1) {
			// ainda não mostra a fila
			return;
		}

		if ($modalFilaAcesso.length === 0) {
			$("#corpo").append(`
				<div class="modal show" id="modal-fila-acesso" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" style="padding: 0 !important">
					<div class="modal-dialog" role="document" style="width: 100%; height: 100%; margin: 0; padding: 0;">
						<div class="modal-content" style="height: auto; min-height: 100%; border: 0 none; border-radius: 0; box-shadow: none;">
							<div class="modal-header">
								<h2 class="main-title">
									Você está numa sala de espera virtual.
								</h2>
							</div>
							<div class="modal-body text-center">
								<br>
								<h4>
									Por favor, aguarde. Sua posição na fila:
								</h4>
								<h1>
									${filaAcessoRetriesTO.filaAcessoTO.posicao}
								</h1>
								<br>
								<p>
									Última atualização: 
									<strong class="ultimo-retry">
										${UtilData.toDDMMYYYYHHMMSS(filaAcessoRetriesTO.timestampUltimoRetry)}
									</strong>
								</p>
								<p>
									Próxima atualização em: 
									<strong class="segundos-proximo-retry">
										${filaAcessoRetriesTO.segundoParaProximoRetry}
									</strong> 
									segundos
								</p>
								<br>
								<i class="fa fa-circle" aria-hidden="true"></i>
								Não atualize esta página. Sua solicitação será atendida assim que possível.
							</div>
						</div>
					</div>
				</div>
			`);
			$modalFilaAcesso = $("#modal-fila-acesso");
		}

		$modalFilaAcesso.find('.modal-body h1').text(filaAcessoRetriesTO.filaAcessoTO.posicao);
		$modalFilaAcesso.find('.ultimo-retry').html(UtilData.toDDMMYYYYHHMMSS(filaAcessoRetriesTO.timestampUltimoRetry));
		$modalFilaAcesso.find('.segundos-proximo-retry').html(filaAcessoRetriesTO.segundoParaProximoRetry);
	}
}

const filaAcessoVH = new FilaAcessoVH();

type FilaAcessoTO = {
	userId: string;
	posicao: number;
}
type FilaAcessoRetriesTO = {
	filaAcessoTO: FilaAcessoTO;
	numRetries: number;
	timestampUltimoRetry: number;
	segundoParaProximoRetry: number;
	divMsgCarregandoOriginal: any;
	divMsgAguarde: any;
	isRetryBemSucedido: boolean;
}

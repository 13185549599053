class EnumFP {
		
	readonly _classeEnumFP: string;
	readonly id: string;
	readonly idMsg: string | Function;
	static PROP_NAME_TO_CLASSE = new Map<string, string>();
	static INSTANCIAS_POR_CLASSE_E_NOME = new Map<string, EnumFP>();
	static ARRAY_INSTANCIAS_POR_CLASSE = new Map<string, EnumFP[]>();

	constructor(classe: string, id: string, idMsg: string | Function) {
		this._classeEnumFP = classe;
		this.id = id;
		this.idMsg = idMsg;
		EnumFP.INSTANCIAS_POR_CLASSE_E_NOME.set(classe + "_" + id, this);
		EnumFP.PROP_NAME_TO_CLASSE.set(classe.substring(0, 1).toLowerCase() + classe.substring(1), classe);
		if (!EnumFP.ARRAY_INSTANCIAS_POR_CLASSE.has(classe)) {
			EnumFP.ARRAY_INSTANCIAS_POR_CLASSE.set(classe, []);
		}
		EnumFP.ARRAY_INSTANCIAS_POR_CLASSE.get(classe).push(this);
	}
	
	static from(classe: string, id: string): EnumFP {
		return EnumFP.INSTANCIAS_POR_CLASSE_E_NOME.get(classe + "_" + id);
	}

	static values(classe: string): EnumFP[] {
		return EnumFP.ARRAY_INSTANCIAS_POR_CLASSE.get(classe);
	}

	static fromProp(nomeProp: string, id: any): EnumFP {
		return EnumFP.from(EnumFP.PROP_NAME_TO_CLASSE.get(nomeProp), id);
	} 

	static isEnum(nomeProp: string): boolean {
		return EnumFP.PROP_NAME_TO_CLASSE.has(nomeProp);
	}

	getNome() {
		if (typeof this.idMsg === "function") {
			return this.idMsg();
		} else {
			return UtilMsg.getMsg(this.idMsg);
		}
	}

	toString() {
		return this.id;
	}

	toNomeProp(): string {
		return UtilString.snakeToCamelCase(this.id);
	}
}

class UtilHtml {
	
	static reRemocaoQuebraLinha = /\n/g;
	static reIsHtml = /<[a-z][\s\S]*>/i;
	static reBr = /<br\s*\/?>/ig;
	static logger = UtilLog.getLogger(UtilHtml.name);

	static converterTextoTextareaParaHtml(texto) {

		if (!texto) return null;

		// não vai fazer mais esse teste pois tanto uma nova resposta quanto uma carregada da
		// base não estão chegando aqui como HTML. 
		// para respostas que os avaliados precisavam escrever HTML, estava dando problema
		// if (UtilHtml.reIsHtml.test(texto)) {
		// 	return texto;
		// }

		return "<p>" + texto.replace(/</g, "&lt;").replace(/>/g, "&gt;").replace(UtilHtml.reRemocaoQuebraLinha, "<br>") + "</p>";
	}

	static converterTextoHtmlParaTextarea(html) {
		if (!html) return null;

		if (!UtilHtml.reIsHtml.test(html)) {
			return html;
		}

		const texto = html.replace(UtilHtml.reRemocaoQuebraLinha, "");
		const linhas = [];

		$("<div>" + texto + "</div>").find("p").each(function() {
			const linhasBr = $(this).html().split(UtilHtml.reBr);
			for (const linha of linhasBr) {
				linhas.push($("<div>" + linha + "</div>").text());
			}
		});

		return linhas.join("\n");
	}

	static decodificarHtml(s) {
		let txt = document.createElement("textarea");
		txt.innerHTML = s;
		return txt.value;
	}
	
	/**
	 * Tenta enviar o máximo possível de imgs no html para o armazenamento.
	 * Não lança exceção se algum falhar.
	 */
	static async enviarImagensParaArmazenamento(html): Promise<string> {
		
		const $html = $(`<div>${html}</div>`);
		
		for (let img of $html.find("img")) {
			
			const $img = $(img);
			const src = $img.attr("src");
			
			try {
				if (src.startsWith("data:image")) {
					
					const blob: any = await UtilImg.base64ToBlob(src);
					
					blob.name = Date.now() + ".jpg";

					const uploadTO = await UtilArmazenamento.upload(blob, false);

					$img.attr("src", uploadTO.url);
					
				} else if (UtilArmazenamento.isSignedUrl(src)) {

					let url = new URL(UtilArquivo.removerUrlParams(src));
					let urlPath = url.pathname;

					if (urlPath.startsWith("/")) {
						urlPath = urlPath.replace("/", "");
					}

					await UtilArmazenamento.tonarPublico(urlPath);

					$img.attr("src", url.href);

				} else if (!UtilArmazenamento.isOnArmazenamentoExternoOuDiretoMS(src)) {

					const blob = await UtilImg.download(src);
					
					const uploadTO = await UtilArmazenamento.upload(blob, false);

					$img.attr("src", uploadTO.url);
				}
			} catch (e) {
				UtilHtml.logger.warn("Continuando após erro ao enviar img para armazenamento", e);
			}
		}

		return $html.html();
	}
	
	static gerarHTMLNumeracaoLinhasTexto(conteudo: string): string {

		const div = document.createElement("div");

		div.innerHTML = conteudo;

		const numLinhas = div.querySelectorAll("br").length;

		if (numLinhas == 0) return "";

		const html = [`
			1&nbsp;
			<br>
			<br>
			<br>
			<br>
			5&nbsp;&nbsp;
		`];

		for (let i = 10; i <= (numLinhas + 1); i = i + 5) {
			html.push(`
				<br>
				<br>
				<br>
				<br>
				<br>
				${i}&nbsp;&nbsp;
			`);
		}

		return html.join("");
	}
	
	static removeTagsHtml(value: string) {
		if (!value) return "";
		return $("<span>" + value + "</span>").text().trim().replaceAll(/[\n\t'"]+/ig, ' ').trim();
	}

	static possuiImagens(html: string): boolean {
		if (!html) return false;
		return $("<div>" + html + "</div>").find("img").length > 0;
	}
}
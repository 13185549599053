const moment = UtilBoot.getMoment();

moment.locale("pt-br");

$(document).ready(() => {
	$(document).ajaxComplete((event, xhr, settings) => {
		UtilData.setUltimoResponseHeaderDate(xhr.getResponseHeader("Date") || xhr.getResponseHeader("date"));
	});
});

class UtilData {
		
	static ISO_REGEXP = /^\d\d\d\d-\d\d-\d\dT\d\d:\d\d:\d\d[\.\d]*Z$/
	static ISO_REGEXP_SEM_TIME = /^\d\d\d\d-\d\d-\d\d$/
	static MILLIS_UM_ANO = 1000*60*60*24*365;
	static MILLIS_UM_MES = 1000*60*60*24*30;
	static MILLIS_UM_DIA = 1000*60*60*24;
	static MILLIS_UMA_HORA = 1000*60*60;
	static MILLIS_UM_MINUTO = 1000*60;
	static MILLIS_UM_SEGUNDO = 1000;

	static SEGUNDOS_UM_ANO = 60*60*24*365;
	static SEGUNDOS_UM_MES = 60*60*24*30;
	static SEGUNDOS_UM_DIA = 60*60*24;
	static SEGUNDOS_UMA_HORA = 60*60;
	static SEGUNDOS_UM_MINUTO = 60;
	static ultimoResponseHeaderDate: { 
		str: any; 
		milisNavegadorMomentoAtualizacao: number; 
	};

	static setUltimoResponseHeaderDate(ultimoResponseHeaderDate) {
		if (ultimoResponseHeaderDate != null && ultimoResponseHeaderDate.trim().length > 0) {
			UtilData.ultimoResponseHeaderDate = {
				str: ultimoResponseHeaderDate,
				milisNavegadorMomentoAtualizacao: Date.now()
			}
		}
	}

	static getDataServidorUltimoResponseMillis(): number {
		if (!UtilData.ultimoResponseHeaderDate) return null;
		return moment(UtilData.ultimoResponseHeaderDate.str).valueOf();
	}

	static getDataServidorUltimoResponse() {
		if (!UtilData.ultimoResponseHeaderDate) return null;
		return moment(UtilData.ultimoResponseHeaderDate.str).tz('America/Sao_Paulo').toDate();
	}

	static getDiferencaMillisUltimaRequisicao(d) {
		return moment(d).toDate().getTime() - moment(UtilData.ultimoResponseHeaderDate.str).toDate().getTime();
	}

	static getDiferencaMillis(d1:any, d2: any) {
		return moment(d1).toDate().getTime() - moment(d2).toDate().getTime();
	}

	static toDDMMYYYY(data) {
		
		if (data == null) return null;
		
		if (data.slice && data.reverse) { // LocalDate vem como array [Y,M,D]
			return data.slice().reverse().map(n => n < 10 ? "0" + n : n).join("/");
		}
		
		return moment(data).tz('America/Sao_Paulo').format("L");
	}

	static toDDMMYYYYHHMMSS(data: string | number | Date, formato: string = null): string {
		if (data == null) return null;
		return moment(data).tz('America/Sao_Paulo').format(formato || "L LTS");
	}
	
	static toHHMM(data) {
		if (data == null) return null;
		return moment(data).tz('America/Sao_Paulo').format("LT");
	}

	static toHHMMSS(data: string | number | Date): string {
		if (data == null) return null;
		return moment(data).tz('America/Sao_Paulo').format("LTS");
	}

	static toDDMMYYYYHHMM(data) {
		if (data == null) return null;
		return moment(data).tz('America/Sao_Paulo').format("L LT");
	}

	static usuarioDataToDate(data) {
		
		if (data == null || (data.trim && data.trim().length == 0)) return null;
		
		let m = null;
		
		if (typeof data == "number") {
			m = moment(data);
		} else {
			m = moment.tz(data, ['DD/MM/YYYY', 'DD/MM/YYYY HH:mm', 'DD/MM/YYYY HH:mm:ss', 'DD/MM/YYYY HH:mm:ss.SSS'], 'America/Sao_Paulo');
		}
		
		return m.toDate();
	}

	static segundosToHHMM(tempoEmSegundos: number) {
		let minutos: number | string = Math.floor(tempoEmSegundos / 60);
        let segundos: number | string = (tempoEmSegundos % 60);
    	if (minutos < 10) minutos = "0" + minutos;
		if (segundos < 10) segundos = "0" + segundos;
        return minutos + ":" + segundos;
	}

	static getDuracao({millis, segundos}: {millis?: number, segundos?: number}): string {
		const d = millis != null ? moment.duration(millis) : moment.duration(segundos, 'seconds');
		const m = moment.utc(d.asMilliseconds());
		return m.format('H[h] m[m] s[s]');
	}

	static getDuracaoEmDias({millis = null, segundos = null}) {
		const d = millis != null ? moment.duration(millis) : moment.duration(segundos, 'seconds');
		return d.asDays();
	}

	static usuarioDataToMillis(str, formato) {
		if (str == null || str.trim().length == 0) return null;
		return moment.tz(str, formato, 'America/Sao_Paulo').valueOf();
	}
	
	static tempoEmSegundosParaUserFriendly(tempoEmSegundos) {
		let minutos = Math.floor(tempoEmSegundos / 60);
		const horas = Math.floor(minutos / 60);
		minutos = minutos % 60;
		const segundos = tempoEmSegundos % 60;
		const msg = [];

		if (horas > 0) {
			msg.push(horas + UtilMsg.getMsg("FP_FRONT_UtilData_001")  + (horas == 1 ? "" : "s"));
		}
		if (minutos > 0 || (horas > 0 && segundos > 0)) {
			msg.push(minutos + UtilMsg.getMsg("FP_FRONT_UtilData_002")  + (minutos == 1 ? "" : "s"));
		}
		if (segundos > 0) {
			msg.push(segundos + UtilMsg.getMsg("FP_FRONT_UtilData_003")  + (segundos == 1 ? "" : "s"));
		}
		
		if (msg.length == 1) {
			return msg[0];
		} else if (msg.length == 2) {
			return msg[0] + " e " + msg[1];
		} else if (msg.length == 3) {
			return msg[0] + ", " + msg[1] + " e " + msg[2];
		} else {
			throw new Error("tempo inválido: " + tempoEmSegundos);
		}
	}
	
	static getTempoUserFriendly(data) {
		
		if (data == null) {
			return "";
		}
		
		let dataMillis = moment(data).valueOf();
		let agoraMillis = UtilData.httpDateHeaderToMillis(UtilData.ultimoResponseHeaderDate.str) || Date.now();
		let idadeMillis;

		if (dataMillis < agoraMillis) {
			idadeMillis = agoraMillis - dataMillis;
		} else {
			idadeMillis = dataMillis - agoraMillis;
		}

		if (idadeMillis < 0) {
			return null;
		}

		if (idadeMillis == 0) {
			return 0 + UtilMsg.getMsg("FP_FRONT_UtilData_005");
		}

		let idade;
		let metrica;
		let metricaPlural;
				
		if (idadeMillis < UtilData.MILLIS_UM_MINUTO) {
			idade = Math.floor(idadeMillis / UtilData.MILLIS_UM_SEGUNDO)
			metrica = UtilMsg.getMsg("FP_FRONT_UtilData_004")
			metricaPlural = UtilMsg.getMsg("FP_FRONT_UtilData_005")

		} else if (idadeMillis < UtilData.MILLIS_UMA_HORA) {
			idade = Math.floor(idadeMillis / UtilData.MILLIS_UM_MINUTO);
			metrica = UtilMsg.getMsg("FP_FRONT_UtilData_006");
			metricaPlural = UtilMsg.getMsg("FP_FRONT_UtilData_007");

		} else if (idadeMillis < UtilData.MILLIS_UM_DIA) {
			idade = Math.floor(idadeMillis / UtilData.MILLIS_UMA_HORA);
			metrica = UtilMsg.getMsg("FP_FRONT_UtilData_008");
			metricaPlural = UtilMsg.getMsg("FP_FRONT_UtilData_009");

		} else if (idadeMillis < UtilData.MILLIS_UM_MES) {
			idade = Math.floor(idadeMillis / UtilData.MILLIS_UM_DIA);
			metrica = UtilMsg.getMsg("FP_FRONT_UtilData_010");
			metricaPlural = UtilMsg.getMsg("FP_FRONT_UtilData_011");

		} else if (idadeMillis < UtilData.MILLIS_UM_ANO) {
			idade = Math.floor(idadeMillis / UtilData.MILLIS_UM_MES);
			metrica = UtilMsg.getMsg("FP_FRONT_UtilData_012");
			metricaPlural = UtilMsg.getMsg("FP_FRONT_UtilData_013");

		} else {
			idade = Math.floor(idadeMillis / UtilData.MILLIS_UM_ANO);
			metrica = UtilMsg.getMsg("FP_FRONT_UtilData_014");
			metricaPlural = UtilMsg.getMsg("FP_FRONT_UtilData_015");
		}

		if (idade > 1) {
			return idade + metricaPlural;
		} else {
			return idade + metrica;
		}
	}
	
	static getDate(ano?: number, mes?: number, dia?: number) {
		if (ano) {
			return moment([ano, mes, dia]).tz('America/Sao_Paulo').toDate();
		} else {
			return moment().tz('America/Sao_Paulo').toDate();
		}
	}
	
	static httpDateHeaderToMillis(dateHeader) {
		const d = moment(dateHeader);
		if (d.isValid()) {
			return d.valueOf();
		}
		return null;
	}
	
	static getAno(data?: any) {
		if (data == null) return moment().tz('America/Sao_Paulo').year();
		return moment(data).tz('America/Sao_Paulo').year();
	}
	
	static getMes(data?: any) {
		if (data == null) return moment().tz('America/Sao_Paulo').month() + 1;
		return moment(data).tz('America/Sao_Paulo').month() + 1;
	}
	
	static add(data, {dias = null, meses = null}): number {
		if (data == null) return null;
		if (typeof data == "number") {
			if (dias != null) {
				return moment(data).add(dias, "days").valueOf();
			} else {
				return moment(data).add(meses, "months").valueOf();
			}
		}
		throw new Error("Não implementado:" + data);
	}
	
	static getPeriodo(inicio, fim) {
		if (!inicio && !fim) return "";
		
		if (fim) {
			return UtilMsg.getMsg("FP_FRONT_UtilData_016", UtilData.toDDMMYYYYHHMM(inicio), UtilData.toDDMMYYYYHHMM(fim));
		} 
		
		return UtilMsg.getMsg("FP_FRONT_UtilData_017", UtilData.toDDMMYYYYHHMM(inicio));
	}

	static isISOString(str) {
		if (!str) return false;
		return str.match(UtilData.ISO_REGEXP) != null;
	}

	static isISOStringSemTime(str) {
		if (!str) return false;
		return str.match(UtilData.ISO_REGEXP_SEM_TIME) != null;
	}

	static toMillis(o: number | string | Date): number {
		if (!o) return null;
		if (typeof o == "number") return o;
		if (typeof o == "string") return (new Date(o)).getTime();
		if (o instanceof Date) return o.getTime();
		throw new Error("Formato inválido: " + o + " " + typeof o);
	}

	static isFutura(data: number | string | Date) {
		const milis = UtilData.toMillis(data);
		if (milis == null) return false;
		return milis > Date.now();
	}

	static isPassada(data: number | string | Date) {
		const milis = UtilData.toMillis(data);
		if (milis == null) return false;
		return milis < Date.now();
	}
	
	static isHoje(data: number | string | Date): boolean {
		if (!data) return false;
		const d = moment(data);
		const agora = moment();
		return d.year() === agora.year() 
			&& d.month() === agora.month()
			&& d.date() === agora.date();
	}

	static setSegundos(data: number | string | Date, segundos: number) {
		if (!data) return null;
		if (segundos === null || segundos === undefined || segundos < 0 || segundos > 59) return data;
		return moment(data).seconds(segundos).toDate();
	}
}
class UtilSelect {

	static ULTIMO_ID = 0;
	static CFGS_SELECT_2 = {};
	static FP_DATA_ADAPTER: any;
	static FP_SELECT_ALL_ADAPTER: any;

	static async add(nomeVH: string, cfgs: CfgsAddSelect) {
		
		cfgs.id = cfgs.id || "fp_select_" + UtilSelect.ULTIMO_ID++;

		UtilSelect.CFGS_SELECT_2[cfgs.id] = cfgs;

		cfgs.propId = cfgs.propId || "id";
		cfgs.propFilhas = cfgs.propFilhas || "filhos";
		cfgs.propLabel = cfgs.propLabel || "nome";

		cfgs.select2 = cfgs.select2 || {};

		cfgs.select2.multiple = cfgs.multiplo;
		cfgs.select2.width = "100%";
		cfgs.select2.language = "pt-BR";

		if (cfgs.loadOpcoesBack) {
			cfgs.select2.dataAdapter = UtilSelect.FP_DATA_ADAPTER;
			cfgs.select2.minimumInputLength = cfgs.loadOpcoesBack.numMinimoCaracteres ?? 5;
		}

		if (cfgs.select2.multiple === true) {
			cfgs.select2.dropdownAdapter = UtilSelect.FP_SELECT_ALL_ADAPTER;
			cfgs.select2.closeOnSelect = true;
		}

		cfgs.select2.matcher = (params, data) => {
			if ($.trim(params.term) === '') {
				return data;
			}

			const busca = UtilString.substituirAcentuacao(params.term).toLowerCase();

			if (data.text && $.trim(data.text) !== '' && UtilString.substituirAcentuacao(data.text).toLowerCase().search(busca) != -1) {
				return data;
			}

			if (data.nome && $.trim(data.nome) !== '' && UtilString.substituirAcentuacao(data.nome).toLowerCase().search(busca) != -1) {
				return data;
			}

			if (data.descricao && $.trim(data.descricao) !== '' && UtilString.substituirAcentuacao(data.descricao).toLowerCase().search(busca) != -1) {
				return data;
			}

			return null;
		}

		cfgs.select2.templateResult = (o) => {

			if (typeof o === "boolean") return;

			let label;

			if ((cfgs.propLabelCod && o[cfgs.propLabelCod]) && (cfgs.propLabel && o[cfgs.propLabel])) {
				
				let smallText = `#${o[cfgs.propLabelCod]}`;

				if (o.descricao) {
					smallText += ` - ${o.descricao}`;
				} else if (cfgs.propLabelDescricao && o[cfgs.propLabelDescricao]) {
					smallText += ` - ${o.cfgs.propLabelDescricao}`;
				}

				label = `
					<strong>${o[cfgs.propLabel]}</strong>
					<br>
					<small>${smallText}</small>					
				`;

			} else if ("nome" in o && "descricao" in o && o.descricao) {
				label = `
					<strong>${o.nome}</strong>
					<br>
					<small>${o.descricao}</small>
				`;

			} else if ((cfgs.propLabel && o[cfgs.propLabel]) && (cfgs.propLabelDescricao && o[cfgs.propLabelDescricao])) {
				label = `
					<strong>${o[cfgs.propLabel]}</strong>
					<br>
					<small>${o[cfgs.propLabelDescricao]}</small>
				`;

			} else if (cfgs.propLabel && o[cfgs.propLabel]) {
				label = o[cfgs.propLabel];

			} else if (o.text) {
				label = o.text;

			} else if (o.substring) {
				label = o;

			} else if (o.nome) {
				label = o.nome;

			} else if (o.descricao) {
				label = o.descricao;

			} else {
				label = o + "";
			}

			return $(`
				<span style="padding-left: ${o._nivel * 20}px; display: inline-block;">
					${label}
				</span>
			`);
		}

		cfgs.select2.templateSelection = (o) => {
			if (o.text) {
				return o.text;
			} else if (o.substring) {
				return o;
			} else if (o.nome) {
				return o.nome;
			} else if (o.descricao) {
				return o.descricao;
			} else if (cfgs.propLabel && o[cfgs.propLabel]) {
				return o[cfgs.propLabel];
			} else {
				return o + "";
			}
		}

		cfgs.select2.id = (o) => {
			if ("id" in o) return o["id"];
			if (cfgs.propId in o) return o[cfgs.propId];
			return o;
		}

		if (cfgs.onNovo) {
			cfgs.select2.tags = true;
			cfgs.select2.createTag = function (params) {
				const term = $.trim(params.term);

				if (term === "") {
					return null;
				}

				return { id: (AmaisVH.idNegativo--), text: term, isNova: true }
			}
		}

		if (cfgs.loadOpcoesBack && UtilString.hasValue(cfgs.valor) && UtilString.isEmpty(cfgs.collection)) {
			const ids = Array.isArray(cfgs.valor) ? cfgs.valor : [cfgs.valor];
			const payload = UtilJson.clonar(cfgs.loadOpcoesBack.payload ?? {});
			payload.ids = ids;
			const collection = await amaisVH.call(cfgs.loadOpcoesBack.endpoint, payload);
			cfgs.select2.data = UtilSelect.addSelectTransformarCollectionParaData(cfgs, collection);
		}

		if (cfgs.valor?.push && cfgs.valor.length > 0 && cfgs.valor[0] != null && (typeof cfgs.valor[0] === "object")) {
			cfgs.select2.data = UtilSelect.addSelectTransformarCollectionParaData(cfgs, cfgs.valor);
			for (const d of cfgs.select2.data) {
				d.selected = true;
			}

		} else if (cfgs.collection) {
			cfgs.select2.data = UtilSelect.addSelectTransformarCollectionParaData(cfgs, cfgs.collection);
		}

		const h = [];

		const idComponente = cfgs.idComponente || "componente_" + cfgs.id;

		h.push("<div class='form-group ");
		if (cfgs.classe) h.push(cfgs.classe);
		h.push("' id='" + idComponente + "' style='")

		if (cfgs.visivel === false) h.push(" display: none; ");
		if (cfgs.css != null) h.push(cfgs.css);
		h.push("'>")

		if (cfgs.label != null) {
			const label = cfgs.label;

			h.push("<label class='control-label ")

			if (cfgs.ajuda != null) h.push(" pull-left")
			h.push("' ")

			h.push(" for='" + cfgs.id + "'>" + label);
			h.push(cfgs.obrigatorio ? " * " : "");
			h.push("</label>");

			if (cfgs.ajuda != null) h.push("<small class='pull-right'>" + cfgs.ajuda + "</small>");
		}

		cfgs.select2.placeholder = cfgs.dicaComoValorInicial ?? cfgs.dica ?? cfgs.textoOpcaoVazia;

		if (!cfgs.select2.placeholder) {
			if (cfgs.select2.multiple) {
				cfgs.select2.placeholder = UtilMsg.getMsg("MSG_VH_A_03");
			} else {
				cfgs.select2.placeholder = UtilMsg.getMsg("MSG_VH_A_02");
			}
		}

		if (cfgs.obrigatorio) {
			cfgs.select2.placeholder += " * ";
			cfgs.select2.allowClear = false;
		} else {
			cfgs.select2.allowClear = true;
		}
		
		const disabled = cfgs.habilitado === false ? ` disabled="disabled" ` : "";
		const obrigatorio = cfgs.obrigatorio ? ` obrigatorio="true" ` : "";
		const classe = "select2 form-control " + (cfgs.classeCSS ?? "");

		h.push(`
				<select 
					id="${cfgs.id}" 
					class="${classe}" 
					name="${cfgs.id}"  
					vh="${nomeVH}"
					title="${cfgs.select2.placeholder}" 
					placeholder="${cfgs.select2.placeholder}"
					${disabled}
					${obrigatorio}
					${cfgs.html ?? ""}>

					${cfgs.select2.multiple ? "" : "<option></option>"}

				</select>
			</div>
		`);

		return h.join("");
	}
	
	private static addSelectTransformarCollectionParaData(cfgs: CfgsAddSelect, collection: any[]) {
		const data = [];

		if (collection?.length) {
			if (typeof collection[0] == "object") {
				this.addSelectTransformarCollectionParaDataIncluir(cfgs, data, 0, collection);
			} else {
				$.each(collection, (indice, item) => {
					const to = { 
						id: item, 
						text: item, 
						selected: null 
					};
					to.selected = this.addSelectIsSelecionado(cfgs, to);
					data.push(to);
				});
			}
		}

		return data;
	}

	private static addSelectTransformarCollectionParaDataIncluir(cfgs: CfgsAddSelect, data, nivel, collection?: any[]) {
		
		$.each(collection, (i, to) => {
			to._nivel = nivel;

			if (to.id == null && cfgs.propId != null && to[cfgs.propId] != null) {
				to.id = to[cfgs.propId];
			}

			to.selected = this.addSelectIsSelecionado(cfgs, to);

			data.push(to);

			if (to.filhos || to[cfgs.propFilhas]) {
				this.addSelectTransformarCollectionParaDataIncluir(cfgs, data, nivel + 1, to.filhos || to[cfgs.propFilhas]);
			}
		});
	}

	private static addSelectIsSelecionado(cfgs: CfgsAddSelect, to) {

		if (cfgs.valor != null && to.id != null) {
			if (cfgs.valor.push) {
				if (cfgs.valor.includes(to.id) || cfgs.valor.includes(to.id + "") || (!isNaN(to.id) && cfgs.valor.includes(to.id * 1))) {
					return true;
				}
			} else if (cfgs.valor == to.id) {
				return true;
			}
		} else {
			return to.isSelecionado;
		}
		return false;
	}
	
	static update(cfgs: CfgsUpdateSelect) {
		
		cfgs.collection = cfgs.collection ?? [];

		const select = document.getElementById(cfgs.id);

		if (!select) return;

		const $select = $(select);
		const cfgsSelect = $select.data("cfgs");

		$select.empty().val("");

		cfgsSelect.collection = cfgs.collection;
		cfgsSelect.select2.data = UtilSelect.addSelectTransformarCollectionParaData(cfgs, cfgs.collection);

		try {
			$select.select2("destroy");
		} catch (ignored) {}
		
		$select.select2(cfgsSelect.select2);
	}

	static ativar() {
		$("select.select2").each((i, select) => {
			const $select = $(select);
			if ($select.data("select2") == null) {
				const cfgs = UtilSelect.CFGS_SELECT_2[select.id];

				if (cfgs == null) return;

				$select.data("cfgs", cfgs);

				const select2 = $select.select2(cfgs.select2);

				if (cfgs.onNovo) {
					$(select2).on("select2:selecting", async (event) => {
						const data = event?.params?.args?.data;
						if (data?.isNova) {
							const retornoOnNovo = await cfgs.onNovo(data.text);
							if (retornoOnNovo === false) {
								event.preventDefault();
							} else if (retornoOnNovo !== true) {
								data.id = retornoOnNovo;
							}
						}
					});
				}

				delete UtilSelect.CFGS_SELECT_2[select.id];
			}
		});

		setTimeout(() => {
			// bug select2 4.0.12: placeholder não aparece
			$("select.select2").each((i, select) => {
				if ($(select).data("select2") != null) {
					const cfgs = $(select).data("cfgs");
					// const val = $(select).val();
					// const isSemValor = !val || (Array.isArray(val) && val.length === 0);
					
					if (cfgs.select2.multiple) {// && isSemValor) {
						$(select).parent().find(".select2-search__field").css("width", "100%");
					}
				}
			});
		}, 200);
	}

	static desativar() {
		try {
			$("#container select").select2('destroy').remove();
		} catch (ignored) { }
	}

	static getTOSelectSelecionado(id: string): any {
		return $("#" + id).select2('data');
	}

	static getTextoSelect(id: string): string | string[] {
		const select = document.getElementById(id);

		if (select == null) return null;

		const cfgs = $(select).data("cfgs");
		const item = $(select).select2("data");

		if (item == null) return null;

		const array = [];
		let texto = null;

		if (item.push) {
			$.each(item, (i, o) => {
				texto = UtilSelect.getTexto(select, o);
				array.push(texto);
			});
		} else {
			texto = UtilSelect.getTexto(select, item);
			array.push(texto);
		}

		if (cfgs.select2.multiple) {
			return array;
		} else {
			return texto;
		}
	}

	private static getTexto(select, item) {
		if ($(select).data("cfgs").select2.templateSelection) {
			return $(select).data("cfgs").select2.templateSelection(item)

		} else {
			return item.text;
		}
	}

	static getTOsSelecionadosSelect(id: string) {
		try {
			const select = document.getElementById(id);

			if (select == null) return null;

			const $select = $(select);

			if (!$select.is(".select2")) return null;

			const cfgs = $select.data("cfgs");
			const item = $select.select2("data");

			if (item == null) return null;

			let lista = [];
			let to = null;

			if (Array.isArray(item)) {
				to = item[0];
				lista = item.concat([]);
			} else {
				to = item;
				lista.push(to);
			}

			if (cfgs.select2.multiple) {
				return lista;
			} else {
				return to;
			}
		} catch (e) {
			console.error(e);
			return null;
		}
	}

	static isSelectComOpcaoDesabilitadaSelecionada(id) {
		const to = UtilSelect.getTOsSelecionadosSelect(id);
		return to?.disabled === true;
	}

	static inicializar() {

		$.fn.select2.amd.define('select2/data/fpDataAdapter', [
			'select2/data/array',
			'select2/utils'
		], function (ArrayAdapter, Utils) {

			function FPDataAdapter($element, options) {
				(<any> FPDataAdapter).__super__.constructor.call(this, $element, options);
			}

			Utils.Extend(FPDataAdapter, ArrayAdapter);

			FPDataAdapter.prototype.query = function (params, callback) {
				const cfgs: CfgsAddSelect = this.$element.data("cfgs");
				const options = this.options.options;
				const delay = options.delay || 300;
				let min = 3;

				if (options.minimumInputLength != null) {
					min = options.minimumInputLength
				}

				this.idsTimeouts = this.idsTimeouts || [];

				for (const id of this.idsTimeouts) {
					clearTimeout(id);
				}

				let fraseBusca = "";

				if (params?.term) {
					fraseBusca = params.term.trim();
				}

				if (fraseBusca.length >= min) {
					this.idsTimeouts.push(setTimeout(async () => {
						let data;

						if (cfgs.loadOpcoesBack?.endpoint) {
							const payload = cfgs.loadOpcoesBack.payload ?? {};

							for (const key of Object.keys(payload)) {
								if (typeof payload[key] === "function") {
									const funcao = payload[key];
									payload[key] = await funcao();
								}
							}

							payload.buscaTextual = fraseBusca;

							data = await amaisVH.call(cfgs.loadOpcoesBack.endpoint, payload);
						}

						callback({ results: UtilSelect.addSelectTransformarCollectionParaData(cfgs, data) });
					}, delay));
				} else {
					callback({
						results: [{ text: UtilMsg.getMsg("FP_FRONT_AmaisVH_047", min) }]
					});
				}

			};

			return FPDataAdapter;
		});

		UtilSelect.FP_DATA_ADAPTER = $.fn.select2.amd.require('select2/data/fpDataAdapter');

		$.fn.select2.amd.define('select2/fpSelectAllAdapter', [
			'select2/utils',
			'select2/dropdown',
			'select2/dropdown/attachBody'
		], function (Utils, Dropdown, AttachBody) {
			function SelectAll() { }

			SelectAll.prototype.render = function (decorated) {
				const $rendered = decorated.call(this);
				const $selectNone = $("<a class='btn btn-link pull-right'>Nenhum</button>");
				const $selectAll = $("<a class='btn btn-link pull-right'>Todos</button>");
				const $dropdown = $rendered.find('.select2-dropdown');

				$dropdown.append($selectNone);
				$dropdown.append($selectAll);

				$selectAll.on('click', (event) => {
					const $results = $rendered.find('.select2-results__option[aria-selected=false]');
					$results.each((i, result) => {
						const data = Utils.GetData(result, 'data');
						this.trigger('select', {
							data: data
						});
					});
					this.trigger('close');
				});

				$selectNone.on('click', (event) => {
					this.$element.val("").trigger("change");
					this.trigger('close');
				});

				return $rendered;
			};

			return Utils.Decorate(Utils.Decorate(Dropdown, AttachBody), SelectAll);
		});

		UtilSelect.FP_SELECT_ALL_ADAPTER = $.fn.select2.amd.require('select2/fpSelectAllAdapter');
	}
}

type CfgsAddSelect = {
	id?: string;
	valor?: any;
	label?: string;
	multiplo?: boolean;
	propFilhas?: string;
	propLabelDescricao?: string;
	propLabelCod?: string;
	propId?: string;
	propLabel?: string;
	idComponente?: string;
	collection?: any[];
	ignorarTagForm?: boolean;
	classe?: string;
	visivel?: boolean;
	css?: string;
	ajuda?: string;
	onNovo?: Function;
	onChange?: Function;
	textoOpcaoVazia?: string;
	dica?: string;
	dicaComoValorInicial?: string;
	habilitado?: boolean;
	obrigatorio?: boolean;
	classeCSS?: string;
	html?: string;
	select2?: any;
	retornarHtml?: boolean;
	loadOpcoesBack?: CfgsAddSelectLoadOpcoesBack;
}
type CfgsAddSelectLoadOpcoesBack = {
	endpoint: string;
	payload?: any;
	numMinimoCaracteres?: number;
}
type CfgsUpdateSelect = {
	id: string;
	collection: any[];
}
class UtilMS {

	static FP_MS_NODE_URL: string;
	static FP_MS_XLSX_URL: string;
	static FP_MS_JDBC_URL: string;
	static FP_MS_JPA_URL: string;
	static FP_MS_PDF_URL: string;
	static FP_MS_IMG_URL: string;

	static setFPMsNodeUrl(fpMsNodeUrl: string) {
		UtilMS.FP_MS_NODE_URL = fpMsNodeUrl;
	}

	static setFPMsXlsxUrl(fpMsXlsxUrl: string) {
		UtilMS.FP_MS_XLSX_URL = fpMsXlsxUrl;
	}

	static setFPMsJdbcUrl(fpMsJdbcUrl: string) {
		UtilMS.FP_MS_JDBC_URL = fpMsJdbcUrl;
	}

	static setFPMsJpaUrl(fpMsJpaUrl: string) {
		UtilMS.FP_MS_JPA_URL = fpMsJpaUrl;
	}

	static setFPMsPdfUrl(fpMsPdfUrl: string) {
		UtilMS.FP_MS_PDF_URL = fpMsPdfUrl;
	}

	static setFPMsImgUrl(fpMsImgUrl: string) {
		UtilMS.FP_MS_IMG_URL = fpMsImgUrl;
	}
	
	static getFPMsNodeUrl() {
		return UtilMS.FP_MS_NODE_URL;
	}

	static getFunctionUrlLambda(path: string) {
		if (path.includes("xlsx")) {
			return UtilMS.FP_MS_XLSX_URL;
		} else if (path.includes("jdbc")) {
			return UtilMS.FP_MS_JDBC_URL;
		} else if (path.includes("jpa")) {
			return UtilMS.FP_MS_JPA_URL;
		} else if (path.includes("pdf")) {
			return UtilMS.FP_MS_PDF_URL;
		} else if(path.includes("img")) {
			return UtilMS.FP_MS_IMG_URL;
		}
		return "";
	}


	static async enviar(path: string, requisicaoMSTO: RequisicaoMSTO): Promise<any> {
		const url = this.getFunctionUrlLambda(path);
		
		if (!url) throw new Error("URL não encontrada");

		requisicaoMSTO.jwt = UtilAuth.getJwtToken();

		const fetchOptions = {
			method: "POST",
			headers: {
				"Content-type": "application/json"
			},
			body: JSON.stringify(requisicaoMSTO)
		};

		let response: Response = null;
		let fetchError = null;

		try {
			response = await fetch(url, fetchOptions);
			
		} catch (e) {
			console.error("fetch para fp-ms lançou error", e);
			fetchError = e;
		}

		let responseBody = null;

		try {
			responseBody = await response.json();
		} catch (ignored) {}

		if (response?.ok) {
			// HTTP STATUS 200
			return responseBody;
		}

		// TRATAMENTO DE ERRO

		const msgErro = responseBody?.msgErro || "Ocorreu um erro neste processamento.";
		const e = new FpMsRequestError(msgErro);

		e.request = {
			url,
			fetchOptions
		};

		e.response = {
			httpStatus: response.status,
			body: responseBody
		}

		e.msgErro = responseBody?.msgErro;
		e.idErro = responseBody?.idErro;
		e.fetchError = fetchError;

		throw e;
	}
}

class RequisicaoMSTO {

	jwt?: string;

    servico: string;
    nomeServico?: string;
    nomeArquivoASerGerado?: string;

    codsEmpresas?: number[];
    codsProvas?: number[];
    codsTurmas?: number[];
    codsUsuarios?: number[];
    codsAvaliacoes?: number[];
    codsAplicacoesOnline?: number[];
	codsCategoriasClassificacoes?: number[];
    dataInicio?: string;
    dataFim?: string;
    periodo?: string[];
    codsProvaFeita?: number[];
    
    requisicao?: any;
}
class FpMsRequestError extends Error {

	request: any = null;
	response: any = null;
	idErro: string = null;
	msgErro: string = null;
	fetchError: Error = null;

	constructor(message) {
		super(message);
		this.name = FpMsRequestError.name;
	}
}
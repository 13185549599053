class UtilSP {

	static IDS = new Map();

	static criarToastAguardar(cfgs: CfgsToastAguardarSP) {

		const idToast = Date.now();
		const idBtnAguardando = "btnToastFinalizada" + Date.now();
		let text = cfgs.toastAguardando.texto + `<i class="fa fa-spinner fa-spin"></i>`;

		if (cfgs.toastAguardando.onClick) {
			text = `
				<a id="${idBtnAguardando}">
					${text}
				</a>
			`;
		}

		const toast = $.toast({
			heading: cfgs.toastAguardando.titulo,
			text,
			position: 'bottom-left',
			hideAfter: false,
			icon: 'info',
			showHideTransition: 'fade',
			allowToastClose: true
		});

		if (cfgs.toastAguardando.onClick) {
			$("#" + idBtnAguardando).on("click", () => UtilSP.handleToastClick(idToast, cfgs.toastAguardando.onClick));
		}

		UtilSP.IDS.set(idToast, toast);

		const aguardarGeracao = () => {

			setTimeout(async () => {

				const encerrou = await amaisVH.call({
					endpoint: "CadastroAOFCD/aguardarProcessamento", // mudar para outro FCD mais a ver com isso
					params: [cfgs.idSP],
					desativarMsgCarregando: true
				});
				
				if (encerrou === true) {
					if (cfgs.toastFinalizada) {

						let text;
						const idBtnFinalizada = "btnToastFinalizada" + Date.now();

						if (cfgs.toastFinalizada.onClick) {
							text = `
								<a id="${idBtnFinalizada}">
									${cfgs.toastFinalizada.texto || cfgs.toastAguardando.texto}
								</a>
							`;
						} else {
							text = cfgs.toastFinalizada.texto || cfgs.toastAguardando.texto;
						}
						
						toast.update({
							heading: cfgs.toastFinalizada.titulo || cfgs.toastAguardando.titulo,
							text,
							position: 'bottom-left',
							icon: 'success',
							allowToastClose: true
						});

						if (cfgs.toastFinalizada.onClick) {
							$("#" + idBtnFinalizada).on("click", () => UtilSP.handleToastClick(idToast, cfgs.toastFinalizada.onClick));
						}
					} else {
						toast.reset();
					}
				} else {
					aguardarGeracao();
				}

			}, 3000);
		}
		aguardarGeracao();
	}

	static handleToastClick(idToast: number, onClick: Function) {
		try {
			onClick();
		} catch (ignored) {}
		try {
			UtilSP.IDS.get(idToast).reset();
			UtilSP.IDS.delete(idToast);
		} catch (ignored) {}
	}
}

type CfgsToastAguardarSP = {
	idSP: number;
	toastAguardando: CfgsToast;
	toastFinalizada: CfgsToast;
}

type CfgsToast = {
	titulo: string;
	texto: string;
	onClick: Function;
}

class AmaisVH {

	static isUsuarioAutenticado: boolean;
	static cfgsDasListagens = [];
	static vhEstaMontandoTela = false;
	static isCacheHtmlHabilitado = true;
	static tabelasTablesorter = [];
	static acentos = { 'á': 'A', 'Á': 'A', 'ã': 'A', 'Ã': 'A', 'é': 'E', 'ê': 'E', 'É': 'E', 'Ê': 'E', 'í': 'I', 'Í': 'I', 'ó': 'O', 'ô': 'O', 'õ': 'O', 'Ó': 'O', 'Ô': 'O', 'Õ': 'O', 'ú': 'U', 'Ú': 'U', 'a': 'A', 'b': 'B', 'c': 'C', 'd': 'D', 'e': 'E', 'f': 'F', 'g': 'G', 'h': 'H', 'i': 'I', 'j': 'J', 'k': 'K', 'l': 'L', 'm': 'M', 'n': 'N', 'o': 'O', 'p': 'P', 'q': 'Q', 'r': 'R', 's': 'S', 't': 'T', 'u': 'U', 'v': 'V', 'w': 'W', 'y': 'Y', 'x': 'X', 'z': 'Z' };
	static acentos_re = /[abcdefghijklmnopqrstuvwyxzáÁãÃéêÉÊíÍóôõÓÔÕúÚ]/g;
	static reHoraSegundos = /^\d\d:\d\d$/;
	static reEmail = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
	static secoesMenu = [];
	static idNegativo = -1;
	static zonas = new Map<string, CfgsAddZonaUpload>();
	static argumentsUltimoCall = null;
	static mapMetodoVHPorCallback = {};
	static LISTAGEM_HIERARQUIA_ID_NOVO_ITEM = "novo";
	static idLinhaListagemHierarquica = 0;
	static mapaCacheHtml = {};
	static cacheJs = [];
	static idCursorAba = null;
	static TECLA_ENTER = 13;
	static TECLA_BACKSPACE = 8;
	static TECLA_TAB = 9;
	static TECLA_SHIFT = 16;
	static TECLA_CTRL = 17;
	static TECLA_ALT = 18;
	static TECLA_PAUSE_BREAK = 19;
	static TECLA_CAPS_LOCK = 20;
	static TECLA_ESCAPE = 27;
	static TECLA_ESPACO = 32;
	static TECLA_PAGE_UP = 33;
	static TECLA_PAGE_DOWN = 34;
	static TECLA_END = 35;
	static TECLA_HOME = 36;
	static TECLA_LEFT_ARROW = 37;
	static TECLA_UP_ARROW = 38;
	static TECLA_RIGHT_ARROW = 39;
	static TECLA_DOWN_ARROW = 40;
	static TECLA_INSERT = 45;
	static TECLA_DELETE = [46, 127];
	static TECLA_ZERO = 48;
	static TECLA_NOVE = 57;
	static TECLA_a = 65;
	static TECLA_d = 68;
	static TECLA_s = 83;
	static TECLA_z = 90;
	static TECLA_PONTO_E_VIRGULA = 186;
	static TECLA_VIRGULA = 188;
	static CHAR_QUEBRA_PAGINA = 12
	static TAG_TEXTAREA = "textarea";
	static zIndexCorrente = 1060;
	static alfanumericos = ['a', 'b', 'c', 'd', 'e', 'f', 'g', 'h', 'i', 'j', 'k', 'l', 'm', 'n', 'o', 'p', 'q', 'r', 's', 't', 'u', 'v', 'x', 'w', 'y', 'z', 'A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O', 'P', 'Q', 'R', 'S', 'T', 'U', 'V', 'X', 'W', 'Y', 'Z']
	static idMetodoIntermediario = 0;
	static idParametroIntermediario = 0;
	static localeDoUsuarioLogado: any;
	static loginUsuarioLogado: any;
	static isAmbienteImportacaoBancoQuestoes: any;
	static codEmpresaUsuarioLogado: number;
	static codEmpresaMatrizUsuarioLogado: number;
	static collectionTipoQuestoes: any;
	static categoriasUtilizadas: any;
	static mapaTiposFiltros: any;
	static collectionLocales: any;
	static arrayUFs: any;
	static codUsuarioLogado: number;
	static codUsuarioSessaoWebLogado: number;
	static nomeUsuarioLogado: any;
	static emailUsuarioLogado: any;
	static telefoneUsuarioLogado: any;
	static isLoginAuth0: boolean;
	static idPerfilRootUsuarioLogado: number;
	static isRoot: any;
	static idPerfilUsuarioLogado: any;
	static isBancoQuestoesHabilitado: any;
	static isAlunoComPlanoVigente: any;
	static isAmbienteSegmentado: boolean;
	static idTarget: string;
	static canvas: {};
	static nomeEmpresaUsuarioLogado: string;
	static identificadoresDeTelaComAjuda: any;
	static tipoAcessibilidadeUsuario: any;
	static dataCadastroUsuarioLogado?: Date;
	static linhasNumeradasTextarea = Array.from(Array(1000).keys()).map(item => String(item + 1)).join("<br />");

	nomeVH: string;
	logger: any;
	jaAdicionouForm: boolean;
	metodoSubmit: any;
	popups: CfgsAddPopup[];
	
	constructor(nomeVH: string) {
		this.nomeVH = nomeVH;
		this.logger = UtilLog.getLogger(this.nomeVH);
	}

	gerarId() {
		return UtilString.gerarId();
	}

	getCollectionUFs() {
		return AmaisVH.arrayUFs;
	}

	getCollectionLocales() {
		return AmaisVH.collectionLocales;
	}

	getNomeEmpresaUsuarioLogado() {
		return AmaisVH.nomeEmpresaUsuarioLogado;
	}

	getMapaTiposFiltros() {
		return AmaisVH.mapaTiposFiltros;
	}

	getCategoriasUtilizadas() {
		return AmaisVH.categoriasUtilizadas;
	}

	getCollectionTipoQuestoes() {
		return AmaisVH.collectionTipoQuestoes;
	}

	getCodEmpresaUsuarioLogado() {
		return AmaisVH.codEmpresaUsuarioLogado;
	}

	getCodEmpresaMatrizUsuarioLogado() {
		return AmaisVH.codEmpresaMatrizUsuarioLogado;
	}

	getIsAmbienteImportacaoBancoQuestoes() {
		return AmaisVH.isAmbienteImportacaoBancoQuestoes;
	}

	isUsuarioAutenticado() {
		return AmaisVH.isUsuarioAutenticado;
	}

	getLoginUsuarioLogado() {
		return AmaisVH.loginUsuarioLogado;
	}

	getIsAcessoLinkExterno() {
		return localStorage.getItem('isAcessoLinkExterno') === "true";
	}

	getEncerrarAposConcluirProva() {
		return localStorage.getItem('encerrarAposConcluirProva') === "true";
	}

	getCodUsuarioLogado() {
		return AmaisVH.codUsuarioLogado;
	}

	getCodUsuarioSessaoWebLogado() {
		return AmaisVH.codUsuarioSessaoWebLogado;
	}

	getNomeUsuarioLogado() {
		return AmaisVH.nomeUsuarioLogado;
	}

	getPrimeiroNomeUsuarioLogado() {
		if (!AmaisVH.nomeUsuarioLogado) return "";
		return AmaisVH.nomeUsuarioLogado.split(" ")[0];
	}

	getEmailUsuarioLogado() {
		return AmaisVH.emailUsuarioLogado;
	}

	getTelefoneUsuarioLogado() {
		return AmaisVH.telefoneUsuarioLogado;
	}

	getIsLoginAuth0() {
		return AmaisVH.isLoginAuth0 === true;
	}

	getIsAmbienteSegmentado() {
		return AmaisVH.isAmbienteSegmentado === true;
	}

	getIsAlunoComPlanoVigente() {
		return AmaisVH.isAlunoComPlanoVigente;
	}

	getIsBancoQuestoesHabilitado() {
		return AmaisVH.isBancoQuestoesHabilitado;
	}

	getIdPerfilRootUsuarioLogado() {
		return AmaisVH.idPerfilRootUsuarioLogado;
	}

	getIdPerfilUsuarioLogado() {
		return AmaisVH.idPerfilUsuarioLogado;
	}

	isRoot() {
		return AmaisVH.isRoot;
	}

	isAdministradorSistema() {
		return AmaisVH.idPerfilRootUsuarioLogado == 3;
	}

	isAdministrador() {
		return (AmaisVH.idPerfilRootUsuarioLogado == 1 || this.isAdministradorSistema())
	}

	isAnalista() {
		return AmaisVH.idPerfilRootUsuarioLogado == 7 || this.isAdministrador();
	}

	isSecretaria(considerarHierarquia: boolean = true) {
		if (considerarHierarquia === false) {
			return AmaisVH.idPerfilRootUsuarioLogado == 10;
		} else {
			return AmaisVH.idPerfilRootUsuarioLogado == 10 || this.isAnalista();
		}

	}

	isFiscal(considerarHierarquia: boolean = true) {
		if (considerarHierarquia === false) {
			return AmaisVH.idPerfilRootUsuarioLogado == 5;
		} else {
			return AmaisVH.idPerfilRootUsuarioLogado == 5 || this.isAnalista();
		}
	}

	isConteudista(considerarHierarquia: boolean = true, idPerfil?: number) {
		if (considerarHierarquia === false) {
			if (idPerfil) {
				return idPerfil == 8;
			} else {
				return AmaisVH.idPerfilRootUsuarioLogado == 8;
			}
		} else {
			return AmaisVH.idPerfilRootUsuarioLogado == 8 || this.isAnalista();
		}
	}

	isRevisor() {
		return AmaisVH.idPerfilRootUsuarioLogado == 9 || this.isConteudista();
	}

	isClassificador() {
		return AmaisVH.idPerfilRootUsuarioLogado == 4 || this.isRevisor();
	}

	isAluno() {
		return AmaisVH.idPerfilRootUsuarioLogado == 2;
	}

	getSecoesMenu() {
		return AmaisVH.secoesMenu
	}

	getTipoAcessibilidadeUsuario() {
		return AmaisVH.tipoAcessibilidadeUsuario;
	}

	exibirMenu() {

		const secoesMenu = this.getSecoesMenu();

		if (this.isEmpty(secoesMenu)) {
			return false;
		}

		const html = [];

		for (const secao of secoesMenu) {

			html.push(`
				<li class='dropdown'>
					<a href='#' class='dropdown-toggle' data-toggle='dropdown'>
						${secao.nome}
						<i class='pull-right fa fa-chevron-down'></i>
					</a>
					<ul class='dropdown-menu'>
			`);

			const itens = secao.itens;

			for (const item of itens) {
				html.push(`
						<li>
							<a class="acaoMenu" acao="${item.link}">
								${item.label}
							</a>
						</li>
				`);
			}

			html.push(`
					</ul>
				</li>
			`);
		}

		$("#header #itensDeMenu > ul").prepend(html.join(""));

		$(".navbar a.acaoMenu").on("click", async (event) => {
			try {
				const chamada = $(event.target).attr("acao");
				const retorno = eval(chamada);

				if (retorno?.then) {
					retorno.catch((e) => this.logger.error(e));
				}
			} catch (e) {
				this.logger.error(e)
			}

			$(event.target).closest(".collapse").removeClass("in");
		});
		
		$(".navbar .dropdown li a").click(function () {
			$(this).closest(".dropdown").parent().find(".dropdown").removeClass("active");
			$(this).closest(".dropdown").addClass("active");
		});

		return true;
	}

	formatarOffsetFuso(minutosOffset) {
		let horas = Math.round((minutosOffset - (minutosOffset % 60)) / 60);
		let minutos = minutosOffset % 60;

		let str = (horas < 0 ? "-" : "");

		if (Math.abs(horas) < 10) str += "0";

		str += Math.abs(horas) + ":";

		if (Math.abs(minutos) < 10) str += "0";

		str += Math.abs(minutos);

		return str;
	}

	abrirNovaJanela(url) {
		window.open(url, "_blank");
	}

	abrirMidia(nomeArquivoMidia) {
		window.open(nomeArquivoMidia,
			"_blank",
			"toolbar=no, directories=no, location=no, status=yes, menubar=no, resizable=yes, scrollbars=yes, width=800, height=600")
	}

	limpar(manterTituloEAbas: boolean = false, bgOff: boolean = false) {
		if (AmaisVH.vhEstaMontandoTela) {
			return;
		}

		$(document).trigger("container-pre-alteracao");

		AmaisVH.vhEstaMontandoTela = true;

		$("div[contenteditable='true']").each(async (i, div) => {
			try {
				const CKEDITOR = UtilBoot.getCKEditor();
				CKEDITOR.instances[div.id].destroy();
			} catch (e) { }
		});

		UtilSelect.desativar();

		AmaisVH.cfgsDasListagens = [];

		if ($("#container").length > 0) {

			if (manterTituloEAbas === true) {

				this.setTexto("corpo", "");
				this.setTexto("botoesEsquerda", "");
				this.setTexto("botoesDireita", "");
				this.setIdTarget("corpo");

			} else {
				let classe = bgOff ? "bg-off" : "";
				$("#container").html(`
					<div class='row fixAffix'>
						<div identificacao-conteudo id='identificacaoConteudo' class='row'>
							<div id='barraTitulo'>
								<div id='tituloSuperiorEsquerdo' style='padding-left: 0px;'>
									<h1><span id='titulo'></span> <small id='subtitulo'></small></h1>
								</div>
								<div id='tituloSuperiorDireito'></div>
							</div>
						</div>
					</div>
					<div corpo id='corpo' class='row ${classe}'></div>
					<div class='row'>
						<div id='botoesEsquerda' class='col-md-6'></div>
						<div id='botoesDireita' class='col-md-6'></div>
					</div>
				`);

				$("#subtitulo").hide();
				$("#container").hide();
			}

		} else {
			AmaisVH.idTarget = "container";
			$("#container").html("");
		}

		this.metodoSubmit = null;
		AmaisVH.idCursorAba = null;
		AmaisVH.zonas.clear();
		AmaisVH.canvas = {};

		setTimeout(() => AmaisVH.dispararAjusteAcessibilidade(), 300);
	}

	setMetodoSubmit(metodoSubmit) {
		this.metodoSubmit = metodoSubmit
	}

	exibir(cfgs?: CfgsExibir) {

		cfgs = cfgs || {};

		if (this.isEmpty(cfgs.isDispararContainerAlterado)) {
			cfgs.isDispararContainerAlterado = true;
		}

		if (this.jaAdicionouForm === true) {
			this.fecharFormulario();
		}

		$('div.tooltip').remove();

		AmaisVH.vhEstaMontandoTela = false;

		this.exibirHtmlDaCache(null, false);

		$("#container").show();

		this.executarJsDaCache();
		$("#divBody .form-group:not([class*='col-'])").addClass("col-xs-12 col-sm-6 col-md-4 col-lg-3");

		this.setIdTarget(null);

		this.verificarCarregamentoAbaAtiva();

		if (cfgs.isDeveFazerScrollParaTitulo && $("#container").length > 0) {
			setTimeout(() => UtilWindow.scrollTo("#container"), 100);
		}

		$("form").on("submit", (event) => {
			if ($(event.target).attr("action") == null) {
				event.preventDefault();
				event.stopPropagation();
			}
		});

		$(".img-thumbnail-resizable").on("click", ({ target }) => {

			const $divImg = $(target).closest(".imgThumb");

			if ($divImg.data("animate-em-andamento") === true) return;

			$divImg.data("animate-em-andamento", true)

			const widthOriginal = $divImg.attr("width-original");
			const widthAtual = $divImg.outerWidth();
			let cfgs = { width: "100%" };

			if (widthOriginal == null) {
				$divImg.attr("width-original", widthAtual);

			} else if (widthOriginal < widthAtual) {
				cfgs = { width: widthOriginal };
			}

			$divImg.animate(cfgs, 400, () => {
				$divImg.data("animate-em-andamento", false)
			});
		});

		$(".botao-selecao ul li a").on("click", ({ target }) => {
			const $botao = $(target).closest("a");
			const id = $botao.attr("botao-selecao-valor");
			const nome = $botao.attr("botao-selecao-nome");
			$botao.closest(".botao-selecao").attr("botao-selecao-valor", id).find(".dropdown-toggle").html(nome + " <span class='caret'></span>");
		})

		this.ativarMidiasEmbutidas();

		$("table thead th").on("keydown", (event) => {
			if (this.isTeclaPressionada(AmaisVH.TECLA_DELETE, event)) {
				this.removerColunaDeTabela($(event.target).closest("table"), $(this).index());
			}
		});

		$("tr[identificador][selecionado]").each((i, tr) => {
			UtilWindow.scrollTo(tr);
		});

		let $abaEmExibicao = $("[subabas] [fp-em-click-para-exibicao='true']");

		if ($abaEmExibicao.length > 0) {
			$abaEmExibicao.removeAttr("fp-em-click-para-exibicao");
			$("[subabas] [subabas-conteudo]").hide();
			$abaEmExibicao.attr("fp-is-renderizada", "true").show();
		} else {
			$("[subabas] ul li:first-child a").click();
		}

		if (cfgs.isAffixIdentificacaoConteudo == true) {
			$("#fixAffix").css('min-heigth', '60px');
			$("[identificacao-conteudo]").css('top', $('body').css('padding-top'));
			$("[identificacao-conteudo]").affix({ offset: { top: $("[identificacao-conteudo]").get(0).offsetTop } });
		}

		$("[identificacao-conteudo]").off("affix.bs.affix").on("affix.bs.affix", () => {
			$(this).css("width", "100%").css("margin", "0px").css("padding", "5px 2%").css("z-index", "1000");
		});

		if ($("math").length) {
			UtilImg.transformarSvgs();
		}

		this.ativarLinksAguardandoGeracao();

		this.ativarZonasUpload();

		if (cfgs.isDispararContainerAlterado) {
			try {
				$(document).trigger("container-alterado");
			} catch (e) {
				this.logger.error("Erro ao disparar container-alterado", e);
				console.error("Erro ao disparar container-alterado", e);
			}
		}

		setTimeout(() => AmaisVH.dispararAjusteAcessibilidade(), 300);

		this.adicionarEventoRegistrarClickBotao();
	}

	registrarClickBotao() {
		const texto = $(this).text().trim() 
			|| $(this).attr("title")?.trim() 
			|| $(this).attr("data-original-title")?.trim()
			|| "";
		amaisVH.logger.info(`Clicou no botão "${texto}"`);
	}

	ativarMidiasEmbutidas() {
		$("oembed").each((i, oembed) => {
			let $o = $(oembed);

			if ($o.data("ativo")) return;

			if ($o.closest("[tipo='EDITOR_HTML']").length == 0) {
				$o.oembed($o.text());
				$o.hide();
				$o.data("ativo", true);
			}
		});
	}

	async ativarEditorHtml(div, toolbar?: string) {
		$("*").css("-moz-user-select", "").css("-khtml-user-select", "").css("-webkit-user-select", "").css("user-select", "");
		div = this.getElementoHtml(div);

		const CKEDITOR = UtilBoot.getCKEditor();
		let editor = CKEDITOR.instances[div.id];

		if (editor != null) {
			if (editor.isCarregadoPelaFP) {
				return;
			} else {
				editor.destroy();
			}
		}

		toolbar = toolbar || $(div).attr("toolbar") || "normal";

		if (toolbar == "basicaSemColarCopiar") {
			const key = UtilBoot.getKeymaster();
			key("command+v,ctrl+v", () => {
				return false;
			});
			$(div).on("contextmenu", (evt) => {
				evt.preventDefault();
				return false;
			});
		}

		editor = CKEDITOR.inline(div.id, CKEDITOR.tiposDeToolbar[toolbar]);

		editor.isCarregadoPelaFP = true;
		editor.on("fileUploadRequest", async (evt) => {

			const file = evt.data.fileLoader.file;
			const xhr = evt.data.fileLoader.xhr;
			const fileName = evt.data.fileLoader.fileName;

			evt.stop();

			try {

				const uploadURLTO = await this.call({
					endpoint: "UploadFCD/gerarUrlUpload", 
					params: [{ 
						nomeArquivo: fileName, 
						privado: false 
					}],
					msgCarregando: this.getMsg("FP_FRONT_AmaisVH_036")
				});
				
				file.uploadTO = uploadURLTO.uploadTO;

				const onLoadAntigo = xhr.onload;

				xhr.onload = async (e) => {
					await this.call({
						endpoint: "UploadFCD/finalizar", 
						params: [file.uploadTO],
						msgCarregando: this.getMsg("FP_FRONT_AmaisVH_036")
					});
					onLoadAntigo.call(xhr, e);
				}
				xhr.setRequestHeader('Content-Type', file.type);
				xhr.open("PUT", uploadURLTO.urlUploadViaPUT, true);
				xhr.send(file);

			} catch (e) {
				AmaisVH.mostrarMsgAjax("Erro no upload", 5);
				this.logger.error("Erro no upload", e);
			}

		});

		editor.on("fileUploadResponse", (evt) => {
			evt.stop();

			const data = evt.data;
			const file = evt.data.fileLoader.file;
			const xhr = evt.data.fileLoader.xhr;

			if (xhr.status == 200) {
				data.url = file.uploadTO.url;
				data.message = { uploaded: 1, fileName: file.uploadTO.nomeArquivo, url: file.uploadTO.url };
			} else {
				data.message = "Erro ao fazer o upload da imagem."
				evt.cancel();
			}
		});

		return editor;
	}

	atualizarThumbnailDropzone(element: any, url: string) {
		$(element).find('.dz-preview').find('.dz-image').find('img').attr("src", url).css("height", "100%");
	}

	atualizarNomeArquivoDropzone(element: any, nomeArquivo: string) {
		$(element).find('.dz-preview').find('.dz-details').find('.dz-filename').find('[data-dz-name]').html(nomeArquivo);
	}

	ativarZonasUpload() {

		if ($("div.dropzone").length === 0) return;

		$("div.dropzone").each((i, elemento) => {

			const Dropzone = UtilBoot.getDropZone();
			const $elemento = $(elemento);
			const cfgsZona: CfgsAddZonaUpload = AmaisVH.zonas.get(elemento.id);

			if ($elemento.data("fp_dropzone") != null) {
				return;
			}

			const dz = new Dropzone(elemento, {
				url: (files) => {
					return files[0].urlUploadViaPUT;
				},
				method: "PUT",
				acceptedFiles: cfgsZona.arquivosAceitos,
				maxFiles: 10, //cfgsZona.maxArquivos,
				uploadMultiple: false, //cfgsZona.multiplosArquivos || false,
				maxFilesize: cfgsZona.maxFilesize || 256,
				thumbnailWidth: cfgsZona.width,
				parallelUploads: 10,
				autoProcessQueue: false,
				timeout: 0,
				dictMaxFilesExceeded: this.getMsg("FP_FRONT_AmaisVH_001"),
				dictDefaultMessage: this.getMsg("FP_FRONT_AmaisVH_002"),
				dictInvalidFileType: this.getMsg("FP_FRONT_AmaisVH_003"),
				dictFileTooBig: this.getMsg("FP_FRONT_AmaisVH_004"),
				dictResponseError: this.getMsg("FP_FRONT_AmaisVH_005"),
				dictCancelUpload: this.getMsg("FP_FRONT_AmaisVH_006"),
				dictCancelUploadConfirmation: this.getMsg("FP_FRONT_AmaisVH_007"),
				dictRemoveFile: this.getMsg("FP_FRONT_AmaisVH_008"),
				accept: async (file, done) => {
					
					try {
						const response = await cfgsZona.onAntesAceitacao(file, dz);

						if (response === false) return;

						done();

						dz.filaDeArquivos = dz.filaDeArquivos || [];
						dz.filaDeArquivos.push(file);

						if (!dz.estaEmUpload) {
							setTimeout(() => dz.processFile(dz.filaDeArquivos.shift()));
						}

						dz.estaEmUpload = true;

					} catch (e) {
						const msg = "Erro no dropzone accept";
						console.error(msg, e);
						done(msg + " : " + e);
						cfgsZona.onErro(file);
						this.logger.error(msg, e);
					}
				},
				transformFile: async (file, done) => {
		
					let fileParaUpload = file;

					if (cfgsZona.converterPdfParaPng && file.type == "application/pdf") {
						try {
							fileParaUpload = await UtilPDF.toPng(file);
							file.fileParaUpload = fileParaUpload;
							this.logger.info("Página 1 do PDF convertida para PNG");
						} catch (err) {
							this.logger.error("Erro na conversão de PDF do documento de identidade", err);
						}
					}

					try {
						const uploadURLTO = await UtilArmazenamento.gerarUrlParaUpload(fileParaUpload, cfgsZona.privado);
						file.urlUploadViaPUT = uploadURLTO.urlUploadViaPUT;
						file.uploadTO = uploadURLTO.uploadTO;
						done(file);
						
					} catch (e) {
						const msg = "Erro no dropzone transformFile";
						console.error(msg, e);
						done(msg + ": " + e);
						cfgsZona.onErro(file);
						this.logger.error(msg, e);
					}
				},
			}).on("sending", (file, xhr, formData) => {

				const fileParaUpload = file.fileParaUpload ?? file;
				const sendOriginal = xhr.send;

				// para mandar o file RAW, sem as infos de multipart
				xhr.send = async () => {
					try {
						const fileRedimensionado = await UtilImg.tentarRedimensionar(fileParaUpload, cfgsZona.maxWidthOuHeight);
						sendOriginal.call(xhr, fileRedimensionado);
					} catch (e) {
						sendOriginal.call(xhr, fileParaUpload);
					}
				}

				xhr.setRequestHeader('Content-Type', fileParaUpload.type);

				cfgsZona.onAntesEnvio(fileParaUpload);

			}).on("uploadprogress", (file, progress, bytesSent) => {
				const fileParaUpload = file.fileParaUpload ?? file;
				cfgsZona.onProgressoEnvio(fileParaUpload, progress, bytesSent);

			}).on("error", (file, errorMessage, xhr) => {
				const fileParaUpload = file.fileParaUpload ?? file;
				cfgsZona.onErro(fileParaUpload);

			}).on("success", async (file, novosArquivosString) => {

				const proximoFile = dz.filaDeArquivos.shift();

				if (proximoFile) {
					setTimeout(() => dz.processFile(proximoFile));
				} else {
					dz.estaEmUpload = false;
				}

				file.uploadTO.metadata = file.uploadTO.metadata || {};
				const fileParaUpload = file.fileParaUpload ?? file;

				if (fileParaUpload.width && fileParaUpload.height) {
					file.uploadTO.metadata["fp-img-size"] = file.width + "x" + file.height;
				}

				try {
					await UtilArmazenamento.finalizar(file.uploadTO);

					const seguirFluxo = async (uploadTO: UploadTO) => {

						if (cfgsZona.isBase64) {
							uploadTO.base64 = await UtilImg.blobToBase64(fileParaUpload);
						}

						$(elemento).data("uploadTO", uploadTO);
						cfgsZona.onDepoisEnvio(fileParaUpload, uploadTO);
					}

					if (fileParaUpload.type == "image/heic" || fileParaUpload.type == "image/heif") {

						const divConvertendo = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_AmaisVH_010"));

						try {
							const uploadTOConvertido = await UtilImg.converterParaJpeg(file.uploadTO);
							const uploadTO = <UploadTO> (JSON.parse(JSON.stringify(uploadTOConvertido)));
							amaisVH.atualizarThumbnailDropzone(elemento, uploadTO.url);
							amaisVH.atualizarNomeArquivoDropzone(elemento, uploadTO.nomeArquivo);
							seguirFluxo(uploadTOConvertido);
							divConvertendo.remove();
						} catch (e) {
							divConvertendo.remove();
							this.logger.error(this.getMsg("FP_FRONT_AmaisVH_053"), e);
							cfgsZona.onErro(fileParaUpload);
							$(elemento).data("uploadTO", null);
							dz.removeAllFiles(true);
							amaisVH.mostrarMsgAjax(this.getMsg("FP_FRONT_AmaisVH_053"));
						}
						
					} else {
						seguirFluxo(file.uploadTO);
					}
				} catch (e) {
					this.logger.error("Erro ao finalizar upload", e);
					cfgsZona.onErro(fileParaUpload);
				}
			}).on("complete", () => {
				if (dz.files?.length && dz.files.some(file => file.status != 'error')) {
					this.removerArquivosComErro(dz);
				}
			})

			$elemento.data("fp_dropzone", dz).on("click", ({ target }) => {
				$(target).data("ultimo-clique", Date.now());
				cfgsZona.onSelecaoArquivosAberta(target);
			});
		});

		$(window).off("focus", AmaisVH.onWindowFocus).on("focus", AmaisVH.onWindowFocus);
	}

	removerArquivosComErro(dz) {
		const el = $('.dz-preview.dz-file-preview.dz-error');
		if(el?.length) {
			el.remove();
			dz.files = dz.files.filter((file) => file.status != 'error');
		}
	}

	isJanelaUploadAberta() {
		try {
			return $("div.dropzone")
				.get()
				.filter(div => $(div).data("ultimo-clique") != null)
				.length > 0;
		} catch (e) {
			console.error("erro ao obter isJanelaUploadAberta", e);
			this.logger.error("erro ao obter isJanelaUploadAberta", e);
			return false;
		}
	}

	ativarTablesorters() {

		if (AmaisVH.tabelasTablesorter.length == 0) return;

		for (const tablesorterTO of AmaisVH.tabelasTablesorter) {
			try {
				const $ts = $("#" + tablesorterTO.id).tablesorter(tablesorterTO.params);

				if (tablesorterTO.onOrdenacaoColuna) {
					$ts.on('sortEnd', (event) => {

						const sortList = event?.target?.config?.sortList;

						if (!Array.isArray(sortList)) return;

						const primeiraOrdenacao = sortList[0];

						if (!Array.isArray(primeiraOrdenacao)) return;

						const offset = tablesorterTO.possuiColunaSelecao ? 1 : 0
						const coluna = tablesorterTO.colunas.filter(c => c.isVisivel)[primeiraOrdenacao[0] - offset];
						const isDesc = primeiraOrdenacao[1] === 1;

						tablesorterTO.onOrdenacaoColuna(coluna, isDesc);
					});
				}

			} catch (e) {
				this.logger.error(e);
				console.error(e)
			}
		}

		AmaisVH.tabelasTablesorter = [];
	}

	setTitulo(textoTitulo: string, textoSubtitulo: string = null, ariaLabel: string = null) {
		this.setTexto("titulo", textoTitulo);
		$("#titulo").show();
		if (this.hasValue(ariaLabel)) {
			ariaLabel = UtilHtml.removeTagsHtml(ariaLabel);
			$("[identificacao-conteudo]").attr("aria-label", ariaLabel).attr("tabindex", 0);
			$("#titulo").attr("aria-label", ariaLabel).attr("tabindex", 0);
		}
		$("[identificacao-conteudo] h1").removeAttr("identificador-ajuda").removeAttr("com-link-ajuda");
		if (textoSubtitulo) {
			this.setSubtitulo(textoSubtitulo);
		}
	}

	setSubtitulo(textoSubtitulo) {
		if (this.isEmpty(textoSubtitulo)) return;
		this.setTexto("subtitulo", '<i class="fa fa-chevron-right" aria-hidden="true"></i> ' + textoSubtitulo);
		$("#subtitulo").show();
	}

	addGrupoBotoes(cfgs: CfgsAddBotoes) {

		const h = [`
			<div class="col-md-12">
				<div class='btn-group ${cfgs.classe || ""}'>
		`];

		for (const b of cfgs.botoes) {
			b.retornarHtml = true;
			h.push(this.addBotao(b));
		}

		h.push(`
				</div>
			</div>
		`);

		if (cfgs.retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	addBotao(cfgs: CfgsAddBotao) {

		cfgs.id = cfgs.id || "_amaisvh_botao_" + this.gerarId();

		const h = [];

		h.push("<a ");

		let hash = cfgs.hash || UtilHash.getHash(cfgs.onClick, cfgs.onClickParam1, cfgs.onClickParam2, cfgs.onClickParam3);
		const classeId = this.gerarId();

		if (hash != null) {
			h.push(" href='" + hash + "' ");
		} else if (cfgs.href != null) {
			h.push(" href='" + cfgs.href + "' ");
		} else {
			this.appendJs(() => this.listenToClick("." + classeId, cfgs.onClick));
		}

		if (cfgs.abrirNovaPagina) {
			h.push(" target='_blank' ");
		}

		h.push(" id='" + cfgs.id + "' ");

		if (cfgs.tooltip) {
			h.push(" title='" + cfgs.tooltip + "' ");
		}

		else if (cfgs.dica) {
			h.push(" title='" + cfgs.dica + (cfgs.obrigatorio ? " * " : "") + "' ");
		}

		h.push(" style='")

		if (cfgs.visivel != null && cfgs.visivel === false) {
			h.push(" display: none; ")
		}

		if (cfgs.css != null) {
			h.push(cfgs.css)
		}

		h.push("' class='btn btn-default " + classeId + " ");

		if ("Salvar" == cfgs.label || "Salvar" == cfgs.texto) h.push("btn-primary ");
		if ("Excluir" == cfgs.label || "Excluir" == cfgs.texto) h.push("btn-danger ");

		if (this.hasValue(cfgs.classe)) {
			h.push(cfgs.classe);
		}

		if (cfgs.ativo === false || cfgs.habilitado === false) {
			h.push(" disabled");
		}

		h.push("' ");
		h.push(cfgs.html || "");

		if (cfgs.ariaLabel && this.hasValue(cfgs.ariaLabel)) {
			h.push(` aria-label="${UtilHtml.removeTagsHtml(cfgs.ariaLabel)}" `);
		} else {
			if (this.hasValue(cfgs.label)) {
				h.push(` aria-label="${UtilHtml.removeTagsHtml(cfgs.label)}" `);
			} else if (this.hasValue(cfgs.texto)) {
				h.push(` aria-label="${UtilHtml.removeTagsHtml(cfgs.texto)}" `);
			}
		}
		const tabindex = cfgs.tabIndex || '0';
		h.push(` tabindex='${tabindex}'>`);

		if (this.hasValue(cfgs.label)) {
			h.push(cfgs.label);
		} else if (this.hasValue(cfgs.texto)) {
			h.push(cfgs.texto);
		}

		h.push("</a>");

		if (cfgs.amarrarCampos != null) {
			$.each(cfgs.amarrarCampos, (indice, idInput) => {
				this.appendJs(() => {
					$("#" + idInput).keypress((event) => { 
						if (event.which == 13) { 
							event.preventDefault(); 
							$("#" + cfgs.id).click();
						}
					});
				});
			});
		}

		if (cfgs.retornarHtml === true) {
			return h.join("")
		} else if (cfgs.idAlvo != null) {
			this.append(cfgs.idAlvo, h.join(""))
		} else {
			this.append(h.join(""))
		}
	}

	addBotaoVoltar() {
		this.addBotao({
			label: this.getMsg("FP_FRONT_AmaisVH_050"),
			css: "float: left",
			onClick: () => UtilHash.voltar()
		});
	}

	addBotaoDireito(label, onClick, idBotao?: any) {
		this.setIdTarget("botoesDireita")
		this.addBotao({
			label: label,
			onClick: onClick,
			id: idBotao || null
		})
		this.setIdTarget(null)
	}

	addBotaoEsquerdo(label, onClick) {
		this.setIdTarget("botoesEsquerda");
		this.addBotao({
			label: label,
			onClick: onClick,
		});
		this.setIdTarget(null);
	}

	addBotaoDropdown(cfgs) {
		const h = ["<div "];

		if (cfgs.id != null) {
			h.push(" id='" + cfgs.id + "' ");
		}

		h.push(" class='btn-group divBotaoDropdown");

		if (cfgs.dropup) {
			h.push(" dropup");
		}

		h.push("' ");

		h.push(" >");
		h.push("<a class='btn btn-default ");

		if (cfgs.classe != null) {
			h.push(cfgs.classe);
		}

		h.push(" dropdown-toggle' data-toggle='dropdown' href='#'");

		if (cfgs.css != null) {
			h.push(" style='" + cfgs.css + "' ");
		}

		h.push(">");
		h.push(cfgs.label);
		h.push(" <span class='caret'></span>");
		h.push("</a>");
		h.push("<ul class='dropdown-menu");

		if (cfgs.esq) {
			h.push(" pull-right");
		}

		h.push("'>");

		for (const opcao of cfgs.opcoes) {

			if (opcao.divisor) {
				h.push("<li class='divider'></li>");

			} else {
				const id = this.gerarId();
				h.push(`
					<li>
						<a id="${id}">${opcao.label}</a>
					</li>
				`);
				this.appendJs(() => this.listenToClick("#" + id, opcao.onClick));
			}
		}

		h.push("</ul>");
		h.push("</div>");

		if (cfgs.retornarHtml === true) {
			return h.join("")
		} else {
			this.append(h.join(""))
		}
	}

	addTextArea(cfgs: CfgsAddTextArea) {

		cfgs.retornarHtml = cfgs.retornarHtml || false;
		cfgs.id = cfgs.id || this.gerarId();

		let html = []

		if (this.jaAdicionouForm !== true) {
			html.push(this.addFormulario({ retornarHtml: cfgs.retornarHtml }));
		}

		html.push("<div class='form-group");

		if (cfgs.classe) html.push(" " + cfgs.classe)

		html.push("' ")

		if (cfgs.css) {
			html.push(" style='" + cfgs.css + "' ")
		}

		html.push(cfgs.html || "");

		html.push(">");

		html.push(`
			<label class="control-label" style="${cfgs.cssDoLabel || ''}">
				<span>${cfgs.label || ''} ${cfgs.label && cfgs.obrigatorio ? ' * ' : ''}</span>
		`);

		if (cfgs.botoes?.length > 0) {

			const botoes = cfgs.botoes.filter(b => b.habilitado !== false).map(b => {

				const id = b.id || this.gerarId();
				const display = b.visivel === false ? ` display: none; ` : ``;
				const style = `style="${display} ${b.css || ''}"`;
				const title = b.dica ? ` title="${b.dica}" ` : ``;
				const hash = UtilHash.getHash(b.onClick);
				let href = "";

				if (hash) {
					href = ` href="${hash}" `;
				} else {
					this.appendJs(() => this.listenToClick("#" + id, b.onClick));
				}
				
				return `
					<a id="${id}" ${href} ${style} ${title} class="btn btn-default ${b.classe || ''}">
						${b.label}
					</a>
				`;
			});

			html.push(`
				<div class="btn-group">
					${botoes.join("")}
				</div>
			`);
		}

		html.push(`
			</label>
		`);

		html.push(`
			<div class="textarea-wrapper" style="${cfgs.cssTextareaWrapper || ''}">
		`);

		if (cfgs.isEnumerado) {
			html.push(`
				<div class="textarea-line-numbers" style="height: 0px">
					${AmaisVH.linhasNumeradasTextarea}
				</div>`
			);
		}

		html.push("<textarea ");
		html.push(cfgs.isCopyPasteCutDesabilitado ? " onpaste='return false;' oncopy='return false;' oncut='return false;' " : "");
		html.push(cfgs.obrigatorio ? " obrigatorio " : "");
		html.push(cfgs.bloquearCorretorOrtografico === true ? " spellcheck='false' " : "");
		html.push(" class='form-control font-monospaced' ")

		if (cfgs.habilitado === false) {
			html.push(" disabled='disabled' ")
		}

		if (cfgs.tipo) {
			html.push(" tipo='" + cfgs.tipo + "' ");
		}

		if (cfgs.cssTextarea != null) {
			html.push(" style='" + cfgs.cssTextarea + "' ");
		}

		if (cfgs.dica) {
			html.push(` placeholder="${cfgs.dica}${cfgs.obrigatorio ? ' * ' : ''}" `);
		}

		if (cfgs.linhas != null) {
			html.push(` rows="${cfgs.linhas}" `);
		}

		if (cfgs.ariaLabel != null) {
			html.push(` tabindex='0' aria-label='${UtilHtml.removeTagsHtml(cfgs.ariaLabel)}'`);
		}

		html.push(` id="${cfgs.id}">`);

		if (cfgs.valor != null) {
			html.push(cfgs.valor)
		}

		html.push("</textarea></div></div>");

		
		this.appendJs(async () => {

			const textarea = document.getElementById(cfgs.id);
			const $textarea = $(textarea);

			if (cfgs.maxLinhas) {
					
				const lineHeight = parseInt($textarea.css("line-height"));
				const height = (lineHeight * cfgs.maxLinhas) + 10;
				$textarea.css("resize", "none");
				$textarea.css("height", height);
				$textarea.parent().find("> .textarea-line-numbers").css("height", height);

				$textarea.on("keyup input", () => {
					if (textarea.offsetHeight < textarea.scrollHeight) {
						const $formGroup = $textarea.closest(".form-group");
						if ($formGroup.find(".alerta-max-linhas-excedido").length == 0) {
							$formGroup.append(`
								<div class="alert alert-danger alerta-max-linhas-excedido">
									${exibicaoQuestaoVH.getMsg("FP_FRONT_ExibicaoQuestaoVH_001")}
								</div>
							`);
							setTimeout(() => {
								$formGroup.find(".alerta-max-linhas-excedido").slideUp("slow", function() { $(this).remove() });
							}, 3000);
						}
					}
					while (textarea.clientHeight < textarea.scrollHeight) {
						$(textarea).val((index, value) => {
							return value.substr(0, value.length - 1);
						});
					}
				});

			} else if (cfgs.autoExpandir !== false) {
				
				let inicio = Date.now();

				while (!$textarea.is(":visible") && (Date.now() - inicio) < 3000) {
					// tratamento para popups, que levam um tempo para ficarem visíveis
					await new Promise((r) => setTimeout(() => r(null), 100));
				}

				this.expandirTextareaParaCaberTexto(textarea);

				$textarea.on("keyup input", ({ target }) => {
					this.expandirTextareaParaCaberTexto(target);
				});
			}
		});

		if (cfgs.retornarHtml === true) {
			return html.join("")
		} else {
			this.append(html.join(""))
		}
	}
	
	expandirTextareaParaCaberTexto = (textarea) => {
		textarea.style.height = "5px";
		textarea.style.height = (textarea.scrollHeight) + "px";
		$(textarea).parent().find("> .textarea-line-numbers").css("height", textarea.style.height);
	}

	doSubmit(event, metodo) {
		if (this.isTeclaPressionada(AmaisVH.TECLA_ENTER, event) && metodo != null) {
			metodo.call(this)
		}
	}

	addCampoTexto(cfgs: CfgsAddCampoTexto) {

		cfgs.id = cfgs.id || "campo_texto_" + this.gerarId();
		const idCheckboxAtivacao = cfgs.checkboxAtivacao === true ? this.gerarId() : null;

		const html = [];

		if (cfgs.idComponente == null) {
			cfgs.idComponente = "componente_" + cfgs.id;
		}

		if (this.jaAdicionouForm != true && cfgs.ignorarFormulario != true) {
			html.push(this.addFormulario({ retornarHtml: cfgs.retornarHtml }));
		}

		if (cfgs.removerDiv !== true) {

			html.push("<div class='form-group");

			if (cfgs.classe) html.push(" " + cfgs.classe);
			if (cfgs.tipo === "SENHA") html.push(" form-group-senha");

			html.push(`' id='${cfgs.idComponente}'`);

			html.push(" style='")

			if (cfgs.isVisivel === false || cfgs.visivel === false) {
				html.push(" display:none; ")
			}

			if (cfgs.css != null) {
				html.push(cfgs.css)
			}

			html.push("' ");

			if (idCheckboxAtivacao) {
				html.push(" is-campo-texto-com-checkbox-ativacao='true' ");
			}

			html.push(">");
		}

		let isInputAtivo = true;

		if (this.hasValue(cfgs.label)) {
			html.push("<label class='control-label' for='");
			html.push(idCheckboxAtivacao || cfgs.id);
			html.push("'");

			if (cfgs.dica != null) {
				html.push(" title='");
				html.push(cfgs.dica);
				html.push(cfgs.obrigatorio ? " * " : "");
				html.push("'");

			} else if (cfgs.dicaComoValorInicial != null) {
				html.push(" title='");
				html.push(cfgs.dicaComoValorInicial);
				html.push(cfgs.obrigatorio ? " * " : "");
				html.push("'");
			}

			html.push(">");

			if (cfgs.checkboxAtivacao == true) {
				isInputAtivo = this.hasValue(cfgs.valor);
				const checked = this.hasValue(cfgs.valor) ? " checked=checked " : "";
				html.push(`<input id=${idCheckboxAtivacao} ${checked} type='checkbox' onchange='amaisVH.handleCheckboxAtivacaoText(this)'> `)
			}

			html.push(cfgs.label);
			html.push(cfgs.obrigatorio ? " * " : "");

			if (cfgs.ajuda != null) html.push("<small class='pull-right'>" + cfgs.ajuda + "</small>");

			html.push("</label>")
		}

		let isInputGroup = cfgs.sufixo || cfgs.prefixo || cfgs.tipo == "DATAHORA";

		if (isInputGroup) {
			html.push("<div class='input-group'");
			if (cfgs.cssInput) {
				html.push(" style='" + cfgs.cssInput + "'");
			}
			html.push(">");
		}

		if (cfgs.prefixo) html.push("<span class='input-group-addon'>" + cfgs.prefixo + "</span>");

		const classeId = this.gerarId();

		html.push("<input ");
		html.push(cfgs.obrigatorio ? " obrigatorio " : "");
		html.push(" class='form-control " + classeId + "'");

		if (cfgs.ariaLabel && this.hasValue(cfgs.ariaLabel)) {
			html.push(` aria-label="${UtilHtml.removeTagsHtml(cfgs.ariaLabel)}" `);
		}

		if (cfgs.attrs) {
			html.push(" " + cfgs.attrs);
		}

		if (!isInputGroup && cfgs.cssInput) {
			html.push(" style='" + cfgs.cssInput + "'");
		}

		html.push(" vh='" + this.nomeVH + "' ");

		if (cfgs.tipo == "DATAHORA") html.push(" style='width: 60%' ")

		if (this.hasValue(cfgs.tipo)) {
			html.push(" tipo='" + cfgs.tipo + "' ");

		} else if (cfgs.mascara) {
			html.push(" tipo='MASCARA' mask='" + cfgs.mascara + "' ");
		}

		if (cfgs.tipo == "SENHA") {
			html.push(" type='password' ");

		} else if (cfgs.tipo == "FILE") {
			html.push(" type='file' ");

		} else {
			html.push(" type='text' ");
		}

		html.push(" id='" + cfgs.id + "' name='" + cfgs.id + "' ");

		if (cfgs.tipo == "NUMERO") {
			this.appendJs(() => document.getElementById(cfgs.id).addEventListener("keypress", (event) => {
				if (!"0123456789,.".includes(event.key)) {
					event.preventDefault();
				}
			}));
		}

		if (cfgs.dicaComoValorInicial != null) {
			html.push(" placeholder='" + cfgs.dicaComoValorInicial + (cfgs.obrigatorio ? " * " : "") + "' ");

		} else if (cfgs.dica != null && cfgs.label == null) {
			html.push(" placeholder='" + cfgs.dica + (cfgs.obrigatorio ? " * " : "") + "' ");
		}

		if (cfgs.onChange) {
			this.appendJs(() => this.listenToChange("." + classeId, async (event) => {
				try {
					if (cfgs.tipo == "DATAHORA") {
						$(event.target).next().focus();
					}
					await cfgs.onChange(event);
				} catch (e) {
					this.logger.error(e);
				}
			}));

		} else if (cfgs.tipo == "DATAHORA") {
			html.push(" onchange='$(this).next().focus();' ");
		}

		if (cfgs.dica != null) {
			html.push(" title='")
			html.push(cfgs.dica)
			html.push(cfgs.obrigatorio ? " * " : "");
			html.push("'")
		}

		if (cfgs.habilitado == false || !isInputAtivo) {
			html.push(" disabled=disabled ")
		}

		if (this.hasValue(cfgs.maxLength)) {
			html.push(" maxlength='" + cfgs.maxLength + "' ")
		}

		if (cfgs.valor != null) {
			html.push("value='");

			if (cfgs.isFloat === true) {
				html.push(UtilNumero.floatToString(cfgs.valor));

			} else if (cfgs.tipo == "NUMERO") {
				if (Math.round(cfgs.valor) == cfgs.valor) {
					html.push(cfgs.valor);
				} else if (cfgs.casasDecimais) {
					html.push(UtilNumero.floatToString(cfgs.valor, cfgs.casasDecimais));
				} else {
					html.push(UtilNumero.floatToString(cfgs.valor));
				}

			} else if (cfgs.tipo == "MOEDA") {
				let str = UtilNumero.floatToString(cfgs.valor);
				if (str) {
					if (!str.includes(",")) str = str + ",00";
					while (str.indexOf(",") > str.length - 2) str = str + "0";
					html.push(str);
				}				

			} else if (cfgs.tipo == "DATA" || cfgs.tipo == "DATAHORA") {

				if (cfgs.valor.substring) {
					if (UtilData.isISOString(cfgs.valor) || UtilData.isISOStringSemTime(cfgs.valor)) {
						html.push(UtilData.toDDMMYYYY(cfgs.valor));
					} else {
						html.push(cfgs.valor);
					}
				} else {
					html.push(UtilData.toDDMMYYYY(cfgs.valor));
				}

			} else if (cfgs.tipo == "TEMPO") {
				html.push(UtilData.segundosToHHMM(cfgs.valor));

			} else {
				html.push(cfgs.valor);
			}

			html.push("' ");
		}


		if (cfgs.html) {
			html.push(cfgs.html);
		}

		html.push(" />");

		if (cfgs.tipo === "SENHA") {
			html.push(this.addBotao({
				label: "<i class='fa fa-eye'></i>",
				dica: this.getMsg("FP_FRONT_AmaisVH_057"),
				classe: "btn-mostrar-ocultar-senha",
				retornarHtml: true,
				onClick: async ({target}) => {
					const $a = $(target).closest("a");
					const input = $a.prev().get(0);
					if (input.type == "password") {
						input.type = "text";
					} else {
						input.type = "password";
					}
				}
			}));
		}

		if (cfgs.tipo == "DATAHORA") {

			const idHora = cfgs.id + "_hora";

			html.push("<input id='" + idHora + "' ");
			html.push(cfgs.obrigatorio ? " obrigatorio " : "");
			html.push(" class='clockpicker form-control' style='width: 40%' type='text' tipo='HORA'");

			if (cfgs.habilitado === false) {
				html.push(" disabled=disabled ")
			}

			if (cfgs.valor != null) {
				html.push(" value='" + UtilData.toHHMM(cfgs.valor) + "'");
			}

			if (cfgs.onChange != null) {
				this.appendJs(() => this.listenToChange("#" + idHora, cfgs.onChange));
			}

			html.push(" />");
		}

		if (cfgs.sufixo) html.push("<span class='input-group-addon'>" + cfgs.sufixo + "</span>");

		if (isInputGroup) html.push("</div>");

		if (cfgs.textoAposCampo) {
			html.push("<div style='float: left; margin: 5px 0 0 1px; font-style: italic;'>")
			html.push(cfgs.textoAposCampo)
			html.push("</div>")
		}

		if (cfgs.tipo === "SENHA" && cfgs.validacaoSenha === true) {

			html.push(UtilPasswordValidator.getHtmlBarraForcaSenha());

			this.appendJs(() => {

				let timeoutKeyId: any = null;

				$("#" + cfgs.id).keydown((event) => {

					const strengthBarContainer = $("#" + cfgs.idComponente).children('.progress');

					if (!strengthBarContainer?.length) return; // fp-custom: para permitir desabilitar 

					clearTimeout(timeoutKeyId);

					timeoutKeyId = setTimeout(() => {
						const password = event.target.value;
						let strengthBar = strengthBarContainer.children('.progress-bar');
						if (strengthBar.length > 0) {
							strengthBar = strengthBar[0];
							UtilPasswordValidator.setForcaSenha(event.target, password, strengthBar, strengthBarContainer[0]);
						}
					}, 100);

				}).blur(async (event) => {
					
					const strengthBarContainer = $("#" + cfgs.idComponente).children('.progress');
					
					if (!strengthBarContainer?.length) return; // fp-custom: para permitir desabilitar

					if (this.isEmpty(this.getValor(cfgs.id)) || UtilPasswordValidator.isSenhaForte(cfgs.id)) {
						this.removerMarcacaoDeErroDeInput(event.target);
					} else {
						await this.exibirAlerta({ msg: this.getMsg('FP_FRONT_CadastroUsuarioVH_040') });
						this.marcarInputComErro(event.target);
					}
				});
			});
		}

		if (cfgs.removerDiv != true) {
			html.push("</div>")
		}

		this.appendJs(() => this.listenToEnter(
			"#" + cfgs.id,
			cfgs.onEnter || this.metodoSubmit
		));

		if (cfgs.retornarHtml === true) {
			return html.join("")
		} else {
			this.append(html.join(""))
		}

		$('input[tipo="HORA"]').each((i, input) => {
			$(input).clockpicker({
				autoclose: 'true'
			});
		})

		$('.clockpicker').each((i, elemento) => {
			$(elemento).clockpicker({
				autoclose: 'true'
			});
		})

		$('input[tipo="DATA"]').each((i, input) => {
			$(input).datetimepicker({
				locale: AmaisVH.localeDoUsuarioLogado,
				format: 'DD/MM/YYYY',
				widgetPositioning: {
					horizontal: 'auto',
					vertical: 'bottom'
				},
				icons: {
					time: 'fa fa-time',
					date: 'fa fa-calendar',
					up: 'fa fa-chevron-up',
					down: 'fa fa-chevron-down',
					previous: 'fa fa-chevron-left',
					next: 'fa fa-chevron-right',
					today: 'fa fa-screenshot',
					clear: 'fa fa-trash',
					close: 'fa fa-remove'
				}
			});
		});
	}

	handleCheckboxAtivacaoText(checkbox) {
		const checked = this.getValor(checkbox);
		const inputText = $(checkbox).closest(".form-group").find("input[type='text']").get(0);
		if (checked) {
			this.enable(inputText);
			this.focar(inputText);
		} else {
			this.setValor(inputText, "");
			this.disable(inputText);
			try {
				inputText.onchange();
			} catch (ignored) { }
		}
	}

	addCamposPeriodo(cfgs) {

		const h = [];

		if (cfgs.valorInicio == null) {
			cfgs.valorInicio = "";
		} else {
			cfgs.valorInicio = UtilData.toDDMMYYYY(cfgs.valorInicio);
		}

		if (cfgs.valorFim == null) {
			cfgs.valorFim = ""

		} else {
			cfgs.valorFim = UtilData.toDDMMYYYY(cfgs.valorFim)
		}

		const disabled = cfgs.habilitado === false ? "disabled" : "";

		cfgs.classe = cfgs.classe || "";

		h.push(`
			<div class="periodo form-group ${cfgs.classe || ''}" style="${cfgs.css || ''}; display: flex; flex-wrap: wrap;">
		`);

		if (cfgs.label) {
			h.push(`
				<label class="control-label" for="${cfgs.idInicio}">
					${cfgs.label} ${cfgs.obrigatorio ? ' * ' : ''}
				</label>
			`);
		}

		if (cfgs.labelInicio != null) {
			h.push(`
				<span style="flex-grow: 0; flex-shrink: 0; white-space: nowrap; line-height: 34px">
					${cfgs.labelInicio}&nbsp;&nbsp;
				</span>
			`);
		}

		h.push(`
				<input id="${cfgs.idInicio}" name="${cfgs.idInicio}" value="${cfgs.valorInicio}" ${disabled}
					${cfgs.obrigatorio ? ' obrigatorio ' : ''} class="form-control" fp-periodo-inicio="true" 
					tipo="DATA" type="text" style="flex-grow: 1; flex-basis: 0;" 
					title="${cfgs.dica || ''}" placeholder="${cfgs.dica || ''}" />

				<span style="flex-grow: 0; flex-shrink: 0; white-space: nowrap; line-height: 34px">
					&nbsp;&nbsp;${cfgs.labelFim || this.getMsg("FP_FRONT_AmaisVH_048")}&nbsp;&nbsp;
				</span>
				
				<input id="${cfgs.idFim}" name="${cfgs.idFim}" value="${cfgs.valorFim}" ${disabled}
					${cfgs.obrigatorio ? ' obrigatorio ' : ''} class="form-control" fp-periodo-fim="true" 
					tipo="DATA" type="text" style="flex-grow: 1; flex-basis: 0;" />
			</div>
		`);



		this.appendJs(() => {
			const onchange = async (event) => {
				try {
					if (this.validarCampoPeriodo(event.target) && cfgs.onChange) {
						await cfgs.onChange(event);
					}
				} catch (e) {
					this.logger.error("Erro no onChange do addCamposPeriodo", e);
				}
			}

			$(`#${cfgs.idInicio}`).datetimepicker({
				format: 'DD/MM/YYYY',
				locale: AmaisVH.localeDoUsuarioLogado
			});
			$(`#${cfgs.idFim}`).datetimepicker({
				format: 'DD/MM/YYYY',
				locale: AmaisVH.localeDoUsuarioLogado
			});
			$(`#${cfgs.idInicio}`).off("change").on("change", onchange).mask('99/99/9999');
			$(`#${cfgs.idFim}`).off("change").on("change", onchange).mask('99/99/9999');
			$('#ui-datepicker-div').css('display', 'none');
		});

		$('input[tipo="DATA"]').each((i, input) => {
			$(input).datetimepicker({
				locale: AmaisVH.localeDoUsuarioLogado,
				format: 'DD/MM/YYYY',
				widgetPositioning: {
					horizontal: 'auto',
					vertical: 'bottom'
				},
				icons: {
					time: 'fa fa-time',
					date: 'fa fa-calendar',
					up: 'fa fa-chevron-up',
					down: 'fa fa-chevron-down',
					previous: 'fa fa-chevron-left',
					next: 'fa fa-chevron-right',
					today: 'fa fa-screenshot',
					clear: 'fa fa-trash',
					close: 'fa fa-remove'
				}
			});
		});

		if (cfgs.retornarHtml === true) {
			return h.join("")
		} else {
			this.append(h.join(""))
		}
	}

	validarCampoPeriodo(input) {

		$(input).closest(".periodo").removeClass("has-error");

		const dataInicio = $(input).closest(".periodo").find("input[tipo='DATA']:first").val();
		const dataFim = $(input).closest(".periodo").find("input[tipo='DATA']:last").val();

		if (this.hasValue(dataInicio) && this.hasValue(dataFim)) {

			const inicioDate = UtilData.usuarioDataToDate(dataInicio + " 00:00:00");
			const fimDate = UtilData.usuarioDataToDate(dataFim + " 23:59:59");

			if (inicioDate.getTime() > fimDate.getTime()) {
				$(input).closest(".periodo").addClass("has-error");
				return false;
			}
		}

		return true;
	}

	addEditorHTML(cfgs: CfgsAddEditorHtml) {

		const html = [];

		if (this.jaAdicionouForm !== true && cfgs.ignorarForm !== true) {
			html.push(this.addFormulario({ retornarHtml: cfgs.retornarHtml }));
		}

		cfgs.tipo = "EDITOR_HTML";

		cfgs.css = cfgs.css ?? "";
		cfgs.id = cfgs.id || this.gerarId();

		if (cfgs.width) cfgs.css += "; width: " + cfgs.width + "; ";
		if (cfgs.height) cfgs.css += "; height: " + cfgs.height + "; ";

		html.push("<div class='form-group ");

		if (cfgs.classe != null) html.push(" " + cfgs.classe);

		html.push("' style='")

		if (cfgs.css != null) {
			html.push(cfgs.css)
		}

		if (cfgs.visivel === false) {
			html.push(";display:none;")
		}

		html.push("'>");

		if (cfgs.label != null) {
			html.push("<label class='control-label' ");

			if (cfgs.cssDoLabel != null) {
				html.push(" style='" + cfgs.cssDoLabel + "'");
			}

			html.push(">")
			html.push(cfgs.label);
			html.push(cfgs.obrigatorio ? " * " : "");

			if (cfgs.ajuda) html.push("<small class='pull-right'>" + cfgs.ajuda + "</small>");

			html.push("</label>")
		}

		cfgs.cssEditorHtml = cfgs.cssEditorHtml ? cfgs.cssEditorHtml: '';
		const cssEditorHtml = `min-height: 70px; height: auto; ${cfgs.cssEditorHtml}`

		html.push(`
			<div id="${cfgs.id}" class="form-control" style="${cssEditorHtml}" vh="${this.nomeVH}"
		`);

		if (cfgs.habilitado === false) {
			html.push(" disabled='disabled' ");
		} else {
			html.push(" contenteditable='true' ");
			this.appendJs(() => {
				$(`#${cfgs.id}`).on("click", async (event) => {
					try {
						const div = $(event.target).closest("[contenteditable='true']");
						await this.ativarEditorHtml(div);
					} catch (e) {
						this.logger.error(e);
					}
				})
			});
		}

		if (cfgs.onChange) {
			this.appendJs(() => {
				$(`#${cfgs.id}`).on("keyup blur", async (event) => {
					try {
						await cfgs.onChange(event);
					} catch (e) {
						this.logger.error(e);
					}
				})
			});
		}

		if (cfgs.html) {
			html.push(cfgs.html);
		}

		if (cfgs.dicaComoValorInicial != null) {
			html.push(" placeholder='" + cfgs.dicaComoValorInicial + (cfgs.obrigatorio ? " * " : "") + "' ");
		}

		if (cfgs.tipo != null) {
			html.push(" tipo='" + cfgs.tipo + "' ");
		}

		if (cfgs.toolbar != null) {
			html.push(" toolbar='" + cfgs.toolbar + "' ");
		}

		if (cfgs.obrigatorio) {
			html.push(" obrigatorio ");
		}

		html.push(">");

		if (cfgs.valor != null) {
			html.push(cfgs.valor);

		} else if (cfgs.dicaComoValorInicial != null) {
			html.push(cfgs.dicaComoValorInicial + (cfgs.obrigatorio ? " * " : ""));
		}

		html.push("</div></div>")

		if (cfgs.retornarHtml === true) {
			return html.join("")
		} else {
			this.append(html.join(""))
		}
	}

	setValorEditorHtml(idTextarea, valor) {
		$("#" + idTextarea).val(valor);
	}

	async addSelect(cfgs: CfgsAddSelect) {

		const h = [];
		
		if (this.jaAdicionouForm !== true && cfgs.ignorarTagForm !== true) {
			h.push(this.addFormulario({ retornarHtml: cfgs.retornarHtml }));
		}

		h.push(await UtilSelect.add(this.nomeVH, cfgs));
		
		if (cfgs.onChange) {
			this.appendJs(() => this.listenToChange("#" + cfgs.id, cfgs.onChange));
		}

		if (cfgs.retornarHtml === true) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	getTOSelectSelecionado(id: string) {
		return UtilSelect.getTOSelectSelecionado(id);
	}

	getTOListagem(elemento) {
		try {
			const idListagem = $(elemento).closest("table").attr("id");
			const identificador = $(elemento).closest("tr").attr("identificador");
			return AmaisVH.cfgsDasListagens[idListagem].toLinhaPorCodigo[identificador];
		} catch (e) {
			console.error(e);
			this.logger.error(e);
			return null;
		}
	}

	getCollectionTOListagem(idElemento: any){
		try{
			const idListagem = $(`#${idElemento}`).closest("table").attr("id");
			return AmaisVH.cfgsDasListagens[idListagem].collection;
		} catch (e) {
			console.log(e);
			this.logger.error(e);
			return null;
		}
	}

	addInputHidden(id, valor) {
		this.append("<input type='hidden' id='" + id + "' name='" + id + "' value='" + (valor == null ? "" : valor) + "'/>");
	}

	addCampoExibicao(cfgs: CfgsAddCampoExibicao) {

		const html = [];
		// let cfgs = null;

		// if (args.length > 1) {
		// 	cfgs = {};

		// 	const campo = {
		// 		label: args[0],
		// 		valor: args[1],
		// 		valorDefault: args[2],
		// 		width: args[3],
		// 		textoNoFluxo: args[4],
		// 	};

		// 	cfgs.collection = [campo];

		// } else if (args[0].valor || args[0].label) { // é só um campo
		// 	cfgs = {};

		// 	cfgs.collection = [args[0]];
		// 	cfgs.css = args[0].css;
		// 	cfgs.classe = args[0].classe;
		// 	cfgs.retornarHtml = args[0].retornarHtml;
		// 	cfgs.isExibirLinkCopiaValor = args[0].isExibirLinkCopiaValor;

		// } else {
		// 	cfgs = args[0];
		// }

		cfgs.id = cfgs.id ?? this.gerarId();
		cfgs.classe = cfgs.classe ?? "";
		cfgs.css = cfgs.css ?? "";

		if (this.jaAdicionouForm !== true) {
			html.push(this.addFormulario({ retornarHtml: cfgs.retornarHtml }));
		}

		html.push(`
			<div class="form-group ${cfgs.classe}" id="${cfgs.id}" style="${cfgs.css}">
		`);

		const idConteudoParaCopia = this.gerarId();
		const idLabel = this.gerarId();

		html.push("<label class='control-label' for='" + idLabel + "'>");
		html.push(cfgs.label);
		html.push("</label><div id-conteudo-para-copia='" + idConteudoParaCopia + "'");
		html.push(" id='" + idLabel + "' vh='" + this.nomeVH + "' ");
		if (cfgs.html) html.push(cfgs.html);
		html.push(" tipo='EXIBICAO' style='min-height: 31px'>");

		let valor = cfgs.valor;

		if (valor == null) {
			valor = "";

		} else if (Array.isArray(valor)) {
			valor = valor.join("<br>");

		} else if (cfgs.formato?.toLowerCase() === "numero") {
			valor = UtilNumero.floatToString(valor);

		} else if (cfgs.formato?.toLowerCase() == "moeda") {
			valor = UtilNumero.floatToString(valor);

		} else if (cfgs.formato?.toLowerCase() == "porcentagem") {
			valor = UtilNumero.floatToString(Math.round(valor));

			if (valor.endsWith(",00")) {
				valor = valor.substring(0, valor.length - 3);
			}

			valor += "%";

		} else if (valor.getTime || (cfgs.formato?.startsWith && cfgs.formato?.startsWith("DD/"))) {
			valor = UtilData.toDDMMYYYYHHMMSS(valor, cfgs.formato || "DD/MM/YYYY");
		}

		html.push(valor);

		html.push(cfgs.isExibirLinkCopiaValor ? `
			<a class="link-copia-area-transferencia text-success" title="Copiar para área de transferência" onclick="amaisVH.copiarParaAreaDeTransferencia(this, '${idConteudoParaCopia}')">
				<i class="fa fa-link"></i>
			</a>
		` : "");
		html.push("</div>")

		html.push("</div>");

		if (cfgs.idAlvo != null) {
			this.append(cfgs.idAlvo, html.join(""))

		} else if (cfgs.retornarHtml === true) {
			return html.join("")

		} else {
			this.append(html.join(""))
		}
	}

	addCampoExibicaoComUploadImg(cfgs: CfgsExibicaoCampoComUpload) {

		const valor = [];

		if (this.hasValue(cfgs.urlImg)) {
			valor.push(this.addImagem({
				src: cfgs.urlImg,
				retornarHtml: true,
				classe: "col-md-12",
				css: "width: 200px"
			}));
			valor.push(this.addLink({
				label: `<i class="fa fa-times"></i>`,
				dica: this.getMsg("FP_FRONT_MeusDadosVH_016"),
				classe: "btn-lg",
				retornarHtml: true,
				onClick: cfgs.onClickRemocao
			}));

		} else {
			valor.push(this.addBotao({
				label: `<i class="fa fa-upload"></i> `+ this.getMsg("FP_FRONT_MeusDadosVH_015"),
				classe: "btn-sm",
				retornarHtml: true,
				onClick: cfgs.onClickUpload
			}));
		}

		this.addCampoExibicao({
			label: cfgs.label, 
			valor: valor.join(""),
			css: "padding-bottom: 10px",
			classe: cfgs.classe || "col-md-12",
		});
	}

	/**
	 * Pode receber: 1. um TO com as configurações ou 2. os parâmetros: texto, id, isVisivel
	 */
	addTexto(cfgs: string | CfgsAddTexto) {

		if (typeof cfgs === "string") {
			cfgs = <CfgsAddTexto> {
				texto: cfgs
			}
		}

		cfgs.classe = cfgs.classe || "col-md-12";
		cfgs.id = cfgs.id || this.gerarId();

		let style = cfgs.css || "";
		
		if (cfgs.isVisivel === false) {
			style = "display: none; " + style;
		}

		const html = `
			<p id="${cfgs.id}" class="${cfgs.classe}" style="${style}">
				${cfgs.texto}
			</p>
		`;

		if (cfgs.idAlvo) {
			this.append(cfgs.idAlvo, html);

		} else if (cfgs.retornarHtml) {
			return html;

		} else {
			this.append(html);
		}
	}

	addLink(cfgs: CfgsAddLink) { // texto, metodo, param1

		const id = cfgs.id || this.gerarId();
		const classe = "btn btn-link " + (cfgs.classe || "") + (cfgs.habilitado === false ? " disabled" : "");
		const style = `${cfgs.visivel === false ? ' display: none; ' : ''}${cfgs.css || ''}`;
		const title = (cfgs.dica != null ? " title='" + cfgs.dica + (cfgs.obrigatorio ? " * " : "") + "'" : "");
		const texto = cfgs.label ?? "";
		let href = "";
		let ariaLabel = "";
		let icone = "";

		if (cfgs.hash != null) {
			href = ` href="${cfgs.hash}" `;

		} else if (cfgs.href != null) {
			href = ` href="${cfgs.href}" target="_blank"`;

		} else if (cfgs.onClick != null) {
			cfgs._hash = UtilHash.getHash(cfgs.onClick);

			if (cfgs._hash != null) {
				href = ` href="${cfgs._hash}" `;

			} else {
				this.appendJs(() => this.listenToClick("#" + id, cfgs.onClick));
			}
		}

		if (cfgs.ariaLabel && this.hasValue(cfgs.ariaLabel)) {
			ariaLabel = ` aria-label="${UtilHtml.removeTagsHtml(cfgs.ariaLabel)}" `;
		}

		if (cfgs.href?.endsWith(".pdf") || cfgs.href?.includes(".pdf")) {
			icone = `<i class='fa fa-file-pdf-o'></i>`;
		} else if ((cfgs.href?.endsWith(".doc") || cfgs.href?.includes(".doc")) || (cfgs.href?.endsWith(".docx") || cfgs.href?.includes(".docx"))) {
			icone = `<i class='fa fa-file-word-o'></i>`;
		}

		const html = `
			<a id="${id}" ${href} class="${classe}" style="${style}" ${title} ${ariaLabel} ${cfgs.html || ''} tabindex="0">
				${icone} ${texto}
			</a>
		`;

		if (cfgs.retornarHtml === true) {
			return html;
		} else if (cfgs.idAlvo != null) {
			this.append(cfgs.idAlvo, html);
		} else {
			this.append(html);
		}
	}

	addMsgSucesso(cfgs) {

		if (cfgs.substring) cfgs = { texto: cfgs }; // só passou o texto como parametro

		const html = [];

		html.push("<div ");

		if (cfgs.id) html.push(" id='" + cfgs.id + "' ");

		let classe = "alert alert-success alert-dismissable";
		let style = "";

		if (cfgs.afixar === true) {
			classe += " affix affix-top"
			html.push(" data-spy='affix' ");
		} else {
			style += "display: inline-block;";
		}

		if (cfgs.css) style += cfgs.css;

		html.push(" style='" + style + "' ");

		html.push(" class='" + classe + "' ");

		html.push("> <button type='button' class='close' data-dismiss='alert' aria-hidden='true'>&times;</button>");
		html.push(cfgs.texto ?? "");
		html.push("</div>");

		if (cfgs.afixar == true) {
			return $("#divBody").prepend(html.join(""));

		} else if (cfgs.retornarHtml) {
			return html.join("");

		} else {
			this.append(html.join(""));
		}
	}

	addTextoErro(cfgs) {
		
		const idElement = cfgs.id || this.gerarId();
		let style = cfgs.visivel === false ? ` style="display: none" ` : "";
		let ariaLabel = this.hasValue(cfgs.ariaLabel) ? cfgs.ariaLabel : cfgs.texto;
		
		cfgs.texto = cfgs.texto || "";
		cfgs.classe = cfgs.classe || "";

		let html = `
			<div id="${idElement}" class="alert alert-danger alert-dismissable ${cfgs.classe}" aria-label="${UtilHtml.removeTagsHtml(ariaLabel)}" ${style}>
				<button type="button" class="close" data-dismiss="alert" aria-hidden="true">
					&times;
				</button>
				${cfgs.texto}
			</div>
		`;

		if (cfgs.retornarHtml) {
			return html;
		} else {
			this.append(html);
			this.focar(idElement);
		}
	}

	addTextoAlerta(cfgs: CfgsAddTextoAlerta) {

		cfgs.id = cfgs.id ?? this.gerarId();
		cfgs.texto = cfgs.texto || "";

		const h = [];
		let classes = "alert alert-warning alert-dismissable " + (cfgs.classes || "");
		let ariaLabel = "";
		const style = [];

		if (cfgs.css) {
			style.push(cfgs.css);
		}

		if (cfgs.visivel === false) {
			style.push(" display: none; ");
		}

		if (cfgs.ariaLabel && this.hasValue(cfgs.ariaLabel)) {
			ariaLabel = UtilHtml.removeTagsHtml(cfgs.ariaLabel);
		} else if (cfgs.texto && this.hasValue(cfgs.texto)) {
			ariaLabel = UtilHtml.removeTagsHtml(cfgs.texto);
		}

		h.push(`
			<div id="${cfgs.id}" class="${classes}" aria-label="${ariaLabel}" style="float: left; ${style.join(";")}">
				<button type="button" class="close" data-dismiss="alert" aria-hidden="true">&times;</button>
				${cfgs.texto}
			</div>
		`);

		if (cfgs.retornarHtml === true) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	addTextoObs(cfgs) {
		this.append("<div class='textoObs col-md-12' style='")

		if (cfgs.css != null) {
			this.append(cfgs.css)
		}

		this.append("'>")

		if (cfgs.texto != null) {
			this.append(cfgs.texto)
		}

		this.append("</div>")
	}

	addCheckbox(cfgs: CfgsAddCheckbox) {

		const h = [];

		cfgs.id = cfgs.id || this.gerarId();
		cfgs.idComponente = cfgs.idComponente || "componente_" + cfgs.id;
		cfgs.classe = cfgs.classe ?? "";

		if (typeof cfgs.valor === "string") {
			cfgs.valor = (cfgs.valor === "true");
		}

		const title = cfgs.ajuda ? ` title="${cfgs.ajuda}" ` : ``;
		const obrigatorio = cfgs.obrigatorio ? " obrigatorio " : "";
		const disabled = cfgs.habilitado === false ? " disabled " : "";
		const checked = cfgs.valor === true ? " checked " : "";
		const style = [];

		if (cfgs.visivel === false) {
			style.push("display: none");
		}

		if (cfgs.css) {
			style.push(cfgs.css);
		}

		if (cfgs.onChange) {
			this.appendJs(() => this.listenToChange("#" + cfgs.id, async (event) => {
				const resultado = await cfgs.onChange(event);

				if (resultado === false) {
					setTimeout(function() {
						event.target.checked = !event.target.checked; // Inverte o estado do checkbox
					}, 0);
				}
			}));
		}

		if (this.jaAdicionouForm !== true && cfgs.ignorarFormulario !== true) {
			h.push(this.addFormulario({ retornarHtml: true }));
		}

		h.push(`
			<div id="${cfgs.idComponente}" class="form-group checkbox ${cfgs.classe}" style="${style.join(";")}" ${title}>
				<label>
					<input id="${cfgs.id}" name="${cfgs.id}" type='checkbox' vh="${this.nomeVH}" ${obrigatorio} ${disabled} ${checked}>
					${cfgs.label} ${cfgs.obrigatorio ? " * " : ""}
				</label>
			</div>
		`);

		if (cfgs.retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	addCheckboxGroup(cfgs) {

		const html = [];

		html.push("<div tipo='GRUPO_CHECKBOX' class='form-group " + (cfgs.classe || "") + "' ");

		if (cfgs.id) html.push(" id='" + cfgs.id + "' ");

		html.push(cfgs.ajuda ? " title='" + cfgs.ajuda + "' " : "")
		html.push(">");
		html.push("<label class='control-label'>" + cfgs.label + (cfgs.obrigatorio ? " * " : "") + "</label>");

		for (const checkbox of cfgs.checkboxes) {
			html.push("<label class='checkbox-inline'");
			html.push(checkbox.css ? ` style='${checkbox.css}' `: "");
			html.push(">");
			html.push(`<input vh='${this.nomeVH}' `);
			html.push(" type='checkbox' id='" + checkbox.id + "' ")
			html.push(" name='" + checkbox.id + "' ")
			html.push(checkbox.checked ? " checked='checked' " : "")
			html.push(checkbox.valor == true || cfgs.valor == "true" ? " checked='checked' " : "")
			html.push(">")
			html.push(checkbox.label);
			html.push("</label>");

			if (cfgs.onChange) {
				this.appendJs(() => this.listenToChange("#" + checkbox.id, cfgs.onChange));
			}
		}

		html.push("</div>")

		if (cfgs.retornarHtml == true) {
			return html.join("");
		} else {
			this.append(html.join(""));
		}
	}

	async addTabela(cfgs: CfgsAddTabela<any>) {

		cfgs.id = cfgs.id || ("componente_listagem_" + this.gerarId());

		const containerId = `container_${cfgs.id}`;
		const container = $(`#${containerId}`);

		if (container?.length) {
			container.html("");
		} else {
			this.append(`<div id="container_${cfgs.id}" class="col-md-12"></div>`)
		}

		let collection = cfgs.collection;

		cfgs.colunas = cfgs.colunas.filter(c => c != null);

		const colunasExibir = cfgs.colunas.filter(c => {
			if (this.isEmpty(c.isVisivel)) {
				if (c.regraExibicao) {
					c.isVisivel = c.regraExibicao(collection);
				} else {
					c.isVisivel = true;
				}
			}
			return c.isVisivel;
		});

		cfgs.propId = cfgs.propId || "id";
		cfgs.htmlAcoes = cfgs.htmlAcoes || null;
		cfgs.htmlAcoesRodape = cfgs.htmlAcoesRodape || "";
		cfgs.id = cfgs.id || ("componente_listagem_" + this.gerarId());

		if (cfgs.onCarregarPagina) {
			cfgs.paginacao = cfgs.paginacao || {
				paginacaoTO: {
					numItensPorPagina: cfgs.itensPorPagina,
					numTotalItens: cfgs.numTotalItensPaginacao,
					numPaginaAtual: cfgs.paginaAtiva,
				},
				alterarItensPorPagina: cfgs.alterarItensPorPagina,
				onCarregarPagina: cfgs.onCarregarPagina,
			}
		}

		let htmlBotaoNovo = null;
		let onNovo = null;

		if (cfgs.onNovo || (cfgs.onEdicao && cfgs.labelNovo)) {

			const idOnNovo = this.gerarId();
			let href = "";

			if (cfgs.onNovo) {
				onNovo = cfgs.onNovo;
				this.appendJs(() => this.listenToClick("#" + idOnNovo, async () => await cfgs.onNovo()));
			} else if (cfgs.onEdicao) {
				const hash = UtilHash.getHash(cfgs.onEdicao);

				if (hash) {
					onNovo = " href='" + hash + "' ";
					href = " href='" + hash + "' ";
				} else {
					onNovo = cfgs.onEdicao;
					this.appendJs(() => this.listenToClick("#" + idOnNovo, async () => await cfgs.onEdicao()));
				}
			}

			htmlBotaoNovo = `
				<a id="${idOnNovo}" class='btn btn-sm pull-left ${cfgs.classeOnNovo || "btn-default"}' ${href} title='${this.getMsg("FP_FRONT_AmaisVH_011")}'>
					<i class='fa fa-plus-circle'></i> ${cfgs.labelNovo || ""}
				</a>`;
		}

		if (this.jaAdicionouForm === true) {
			this.fecharFormulario();
		}

		cfgs.numItensExibidos = 0

		let html = collection ? await this.addTabela_addLinha(collection, cfgs, colunasExibir) : null;
		let numLinhas = cfgs.numItensExibidos;
		let htmlTotais = "";
		let idPaginador: string = null;
		const handleDownloadTabela = async () => {
				
			const cfgsDownload: CfgsDownloadTabela = {... (cfgs.download ?? {})};

			cfgsDownload.idPaginador = idPaginador;
			cfgsDownload.paginacao = cfgs.paginacao;
			cfgsDownload.idTabela = cfgs.id;

			await UtilXlsx.exportarPlanilhaTabela(cfgsDownload);
		};

		if (htmlBotaoNovo || cfgs.titulo || cfgs.paginacao?.paginacaoTO || numLinhas > 5) {

			const idDivTitulo = cfgs.id + "_titulo";
			const style = !htmlBotaoNovo && !cfgs.titulo ? " style='border-bottom: none' " : "";

			this.append(containerId,`
				<div class="table-titulo col-md-12" id="${idDivTitulo}" ${style}>
			`);

			if (cfgs.titulo) {
				this.append(containerId,"<h3 vh='" + this.nomeVH + "' id='" + cfgs.id + "_subtitulo'>" + cfgs.titulo);
				if (!cfgs.paginacao?.onCarregarPagina && cfgs.exibirTotalDeItens == null) {

					if (numLinhas > 5) {
						this.append(`container_${cfgs.id}`, " <span class='badge'>" + numLinhas + "</span>");
					}
				}
				this.append(containerId,"</h3>");

			} else if (collection && collection.length > 5) {
				if (!cfgs.paginacao?.paginacaoTO && (cfgs.exibirTotalDeItens == null || cfgs.exibirTotalDeItens)) {
					if (numLinhas > 5) {
						this.append(containerId," <span class='badge pull-right' style='margin-left: 10px'>" + numLinhas + " " + this.getMsg("MSG_VH_A_12") + "</span>");
					}
				}
			}

			if (htmlBotaoNovo || cfgs.htmlAcoes) {
				const spanId = cfgs.id + "_actions_buttons";
				this.append(containerId,`
					<div id="${spanId}" class="tabela-acoes btn-group pull-right">
						${cfgs.htmlAcoes || ""}
						${htmlBotaoNovo || ""}
					</div>
				`);
			}

			if (cfgs.paginacao?.paginacaoTO) {

				idPaginador = "paginador_antes_tabela_" + cfgs.id;

				cfgs.paginacao.onPaginaCarregada = async (collection) => {
					if (collection == null) return;
					cfgs.collection = collection;
					await this.addTabela(cfgs);
					this.exibir();
				}
				cfgs.paginacao.botoesAdicionais = cfgs.paginacao.botoesAdicionais ?? [];

				const labelBtnDownload = `<i class="fa fa-sm fa-download"></i>`;

				if (!cfgs.paginacao.botoesAdicionais.some(b => b.label === labelBtnDownload)) {
					cfgs.paginacao.botoesAdicionais = [{
						label: labelBtnDownload,
						onClick: handleDownloadTabela,
						dica: this.getMsg("MSG_VH_A_11")
					}, ...cfgs.paginacao.botoesAdicionais];
				}

				this.append(containerId, UtilPaginacao.criarPaginador(cfgs.paginacao, idPaginador));
			}

			this.append(containerId, `
				</div>
			`);
		}

		if (this.isEmpty(collection)) {
			if (cfgs.labelNovo && onNovo && !cfgs.msgListaVazia) {
				const idOnNovo = this.gerarId();
				let href = "";

				if (typeof onNovo === "string") {
					href = onNovo;
				} else {
					this.appendJs(() => this.listenToClick("#" + idOnNovo, cfgs.onNovo));
				}
				this.append(containerId, `
					<table id="${cfgs.id}" style="width: 100%; height: 20px; margin-top: 20px; margin-bottom: 30px;">
						<tr>
							<td style="text-align: left">
								<a id="${idOnNovo}" class="btn btn-link" ${href}>
									<i class="fa fa-plus-circle"></i>
									${cfgs.labelNovo}
								</a>
							</td>
						</tr>
					</table>
				`);

			} else {
				this.append(containerId, "<table id='" + cfgs.id + "' style='width: 100%; height: 20px; margin-top: 20px; margin-bottom: 30px;'><tr><td style='text-align: left'>" + (cfgs.msgListaVazia || this.getMsg("MSG_VH_A_13")) + "</td></tr></table>");
			}

			return;
		}

		if (cfgs.totaisDasColunas && cfgs.totalizar && collection.length > 1) {
			let coll: any = [cfgs.totaisDasColunas];
			coll.isTotalizacao = true;
			let primeiraProp = cfgs.colunas[0].prop;

			if (!cfgs.totaisDasColunas[primeiraProp]) {
				cfgs.totaisDasColunas[primeiraProp] = cfgs.tituloTotalizacao || "Totais"
			}
		
			for (const prop of cfgs.colunas.map(c => c.prop)) {

				let valor = cfgs.totaisDasColunas[prop];

				if (this.isEmpty(valor)) continue;

				if (typeof valor === "number") {
					valor = UtilNumero.floatToString(valor);
				}

				if (cfgs.cssTotalizacao) {
					cfgs.totaisDasColunas[prop] = "<span style='" + cfgs.cssTotalizacao + "'>" + valor + "<span>";
				} else {
					cfgs.totaisDasColunas[prop] = valor;
				}
			}

			htmlTotais = await this.addTabela_addLinha(coll, cfgs, colunasExibir);
		}

		cfgs.classe = cfgs.classe || "";

		if(cfgs.bordered && cfgs.bordered === true){
			cfgs.classe = cfgs.classe + " table-bordered";
		}

		this.append(containerId, `
			<div class='row'>
				<div class='col-md-12'>
					<div class='table-responsive' id='tabela_c${cfgs.id}' style='${(cfgs.cssDiv || "")}'>
						<table id='${cfgs.id}' class='tablesorter table table-hover ${cfgs.classe}' border='0' cellpadding='0' cellspacing='1' style='
		`);

		this.append(containerId, "'><thead>");

		if (cfgs.colunasPrincipais && this.hasValue(cfgs.colunasPrincipais)) {
			this.append(containerId, "<tr>");

			for (const colunaPrincipal of cfgs.colunasPrincipais) {
				const label = colunaPrincipal[0] || "";
				const atributes = colunaPrincipal[1] || "";

				this.append(containerId, `
					<th class="group-word" ${atributes} tabindex="0" aria-label="${UtilHtml.removeTagsHtml(label)}">
						${label}
					</th>`
				);
			}

			this.append(containerId, "</tr>");
		}

		this.append(containerId, "<tr>");

		if (cfgs.selecao) {
			this.append(containerId, `
				<th tipo="web">
					<input type="checkbox" value="" title="${this.getMsg("FP_FRONT_AmaisVH_055")}">
				</th>
			`);
		}

		if (cfgs.exibirNumeracao != null && cfgs.exibirNumeracao === true) {

			let labelNumeracao = "";
			if (cfgs.labelNumeracao != null) {
				labelNumeracao = cfgs.labelNumeracao;
			}
			this.append(containerId, "<th>" + labelNumeracao + "</th>");
		}

		for (let i = 0; i < colunasExibir.length; i++) {
			const coluna = colunasExibir[i];
			const label = coluna.titulo || "";
			const nomeProp = coluna.prop;
			const css = coluna.classe || "";
			const listagemNomeProp = coluna.nomeProp || "";
			let attrNomeProp = "";

			try {
				if (typeof nomeProp === "string") {
					attrNomeProp = nomeProp;
				} else if (this.hasValue(listagemNomeProp)) {
					attrNomeProp = listagemNomeProp;
				} else {
					attrNomeProp = i.toString();
				}
				attrNomeProp = this.hasValue(attrNomeProp)
					? ` listagem-nome-prop="${attrNomeProp.replaceAll("\"", "'")}"`
					: "";
			} catch (ignored) { }

			this.append(containerId, `<th class="${css} group-word" ${attrNomeProp} tabindex="0" aria-label="${UtilHtml.removeTagsHtml(label)}">${label}</th>`);
		}

		if (cfgs.onExclusao) {
			this.append(containerId, "<th tipo='web'>&nbsp;</th>")
		}

		this.append(containerId, "</tr>");
		this.append(containerId, "</thead>");

		this.append(containerId, "<tbody id='tbody_" + cfgs.id + "' ");

		this.append(containerId, ">");
		this.append(containerId, html);
		this.append(containerId, "</tbody>");

		this.append(containerId, "<tfoot>");

		if (cfgs.totalizar) {
			this.append(containerId, htmlTotais);
		}

		if (cfgs.desabilitarDownload !== true) {

			this.append(containerId, "<tr>");

			if (htmlBotaoNovo && numLinhas > 15) {
				this.append(containerId, "<td>" + htmlBotaoNovo + "</td>");
			}

			const htmlBtnDownload = this.addLink({
				label: `<i class="fa fa-download"></i> ${this.getMsg("MSG_VH_AP_48")}`,
				dica: this.getMsg("MSG_VH_A_11"),
				onClick: handleDownloadTabela,
				retornarHtml: true
			});

			this.append(containerId, `
				<td colspan='${cfgs.colunas.length + 1}' style='text-align: right'>
					${htmlBtnDownload}
					${cfgs.htmlAcoesRodape}
				</td>
			`);

			this.append(containerId, "</tr>");			
		}

		this.append(containerId, "</tfoot>");

		this.append(containerId, "</table></div></div></div>");

		if (cfgs.ocultarOrdernacao !== true && this.hasValue(collection)) {
			this.ativarTablesorterTabela(cfgs);
		}

		if (cfgs.paginacao?.exibirPaginacaoRodape) {
			idPaginador = "paginador_depois_tabela_" + cfgs.id;
			this.append(containerId, UtilPaginacao.criarPaginador(cfgs.paginacao, idPaginador));
		}

		AmaisVH.cfgsDasListagens[cfgs.id] = cfgs;

		if (!cfgs.paginacao && cfgs.infiniteScroll?.habilitado) {

			this.executarJsDaCache();
			this.exibir();

			const numTotalItens = cfgs.infiniteScroll.endpointParams
				.find(objeto => objeto.paginacaoTO)?.paginacaoTO?.numTotalItens || 0;

			if (cfgs.exibirTotalDeItens == null || cfgs.exibirTotalDeItens) {

				$('.badge.pull-right').text(numTotalItens + " " +
					this.getMsg("MSG_VH_A_12"));
			}

			const divListagem = $("#" + cfgs.id);
			let allowLogging = true;
			let pageCount = 0;

			let lastScrollPosition = 0;

			$(window).on('scroll', async () => {

				if (!allowLogging) {
					return;
				}

				let scrollY = window.scrollY;
				const windowHeight = window.innerHeight;
				const divListagemTop = divListagem.offset()?.top || 0;
				const divListagemHeight = divListagem.height();

				const [primeiroItemNaTelaId, ultimoItemNaTelaId] = this.getQntLinhasExibidas(cfgs);

				const primeiroItemNaTelaObjIndex = collection
					.indexOf(collection.find(item => item[cfgs.propId] == primeiroItemNaTelaId));
				const ultimoItemNaTelaObjIndex = collection
					.indexOf(collection.find(item => item[cfgs.propId] == ultimoItemNaTelaId));

				const tamanhoCollectionForaDeTela = Math.floor((ultimoItemNaTelaObjIndex -
					primeiroItemNaTelaObjIndex));

				const primeiroItemForaDeTelaObjIndex = collection.indexOf(collection.find(item =>
					item[cfgs.propId] == $("table tbody").children("tr").first().attr("identificador")));
				let ultimoItemForaDeTelaObjIndex = collection.indexOf(collection.find(item =>
					item[cfgs.propId] == $("table tbody").children("tr").last().attr("identificador")));

				const tbody = $("#tbody_" + cfgs.id);

				if (cfgs.infiniteScroll.virtualList) {

					//ajustar altura de cada item da tabela
					// const heightItemListagem = 30;
					//
					// $(".table-responsive").height(heightItemListagem * numTotalItens);
					// $("tbody tr").height(heightItemListagem);

					allowLogging = false;
					const timeoutVirtualList = setTimeout(async () => {

						if (ultimoItemForaDeTelaObjIndex <= collection.length - 1 &&
							scrollY + windowHeight >= divListagemTop + divListagemHeight * 0.7) {
							scrollY = 0;


							if (collection.length < numTotalItens) {
								pageCount++;

								for (const objeto of cfgs.infiniteScroll.endpointParams) {
									if (objeto.paginacaoTO) {
										objeto.paginacaoTO.numPaginaAtual = pageCount;
										objeto.paginacaoTO.numItensPorPagina = 100;
										break;
									}
								}

								let listagemTO = await this.call({
									endpoint: cfgs.infiniteScroll.endpoint,
									params: cfgs.infiniteScroll.endpointParams
								});

								if (cfgs.infiniteScroll.atributeList) {
									listagemTO = listagemTO[cfgs.infiniteScroll.atributeList];

								}

								collection = collection.concat(listagemTO);
							}

							const novasLinhasHTML =
								await this.addTabela_addLinha(collection.slice(ultimoItemForaDeTelaObjIndex + 1,
										Math.min(collection.length - 1,
											ultimoItemForaDeTelaObjIndex + tamanhoCollectionForaDeTela)),
									cfgs, colunasExibir);

							tbody.append(novasLinhasHTML);

							collection.forEach((item, index) => {
								const listagemItem = $("#listagem_linha_" + item[cfgs.propId]);

								if (listagemItem.length > 0 && index < primeiroItemNaTelaObjIndex - tamanhoCollectionForaDeTela){
									listagemItem.remove();
								}
							});

						} else if (scrollY < lastScrollPosition && scrollY < divListagemTop + divListagemHeight * 0.3){

							const novasLinhasHTML =
								await this.addTabela_addLinha(collection.slice(Math.max(0,
											primeiroItemForaDeTelaObjIndex - tamanhoCollectionForaDeTela),
										primeiroItemForaDeTelaObjIndex),
									cfgs, colunasExibir);

							tbody.prepend(novasLinhasHTML);

							let count = 0;

							collection.forEach((item, index) => {
								const listagemItem = $("#listagem_linha_" + item[cfgs.propId]);

								if (listagemItem.length > 0 && index > ultimoItemNaTelaObjIndex + tamanhoCollectionForaDeTela){
									listagemItem.remove();
									count++;
								}
							});
							console.log("Removendo ", count, "itens do final da listagem");

						}

						allowLogging = true;
						lastScrollPosition = scrollY;
					}, 100);

				} else {

					if (scrollY + windowHeight >= divListagemTop + divListagemHeight * 0.7) {

						pageCount++;
						console.log("User reached the end of the div!", pageCount, cfgs.id);
						allowLogging = false;
						const timeoutId = setTimeout(async () => {

							let numPaginas = 0;

							//atualizando o numPaginaAtual com o contador de quantas vezes o usuário alcançou o final da pag
							for (const objeto of cfgs.infiniteScroll.endpointParams) {
								if (objeto.paginacaoTO) {
									objeto.paginacaoTO.numPaginaAtual = pageCount;
									numPaginas = objeto.paginacaoTO.numPaginas;
									break;
								}
							}

							//para de enviar requisições se não estiver na mesma página
							if ($("#" + cfgs.id).length <= 0 || pageCount >= numPaginas) {
								console.log("Cannot show more pages");
								clearTimeout(timeoutId);
								return;
							}

							let listagemTO = await this.call({
								endpoint: cfgs.infiniteScroll.endpoint,
								params: cfgs.infiniteScroll.endpointParams
							});

							if (cfgs.infiniteScroll.atributeList) {
								listagemTO = listagemTO[cfgs.infiniteScroll.atributeList];
							}

							collection = collection.concat(listagemTO);

							const novasLinhasHTML =
								await this.addTabela_addLinha(listagemTO, cfgs, colunasExibir);

							divListagem.append(novasLinhasHTML);
							allowLogging = true;
						}, 100);
					}
				}
			});
		}

		if (cfgs.onSelecionarCheckbox) {
			this.appendJs(() => {
				this.listenToChange(`#${cfgs.id} tbody td input[type="checkbox"]`, (e) => {
					cfgs.onSelecionarCheckbox($(e.target), e.target.checked)
				});
			})
		}
	}

    getQntLinhasExibidas(cfgs: CfgsAddTabela<any>) {
		// Get the table and its visible area
		const $table = $("#" + cfgs.id);
		const $visibleArea = $table.find("tbody");

		// Get the window height and scroll position
		const windowHeight = $(window).height();
		const scrollTop = $(window).scrollTop();

		// Get the range of vertical pixels that are currently visible on the screen
		const visibleTop = scrollTop;
		const visibleBottom = scrollTop + windowHeight;

		// Filter the visible rows based on their position
		const $visibleRows = $visibleArea.find("tr").filter(function() {
			const rowTop = $(this).offset().top;
			const rowBottom = rowTop + $(this).outerHeight();
			return (rowTop >= visibleTop && rowTop <= visibleBottom) || (rowBottom >= visibleTop && rowBottom <= visibleBottom);
		});

        return [$($visibleRows[0]).attr("identificador"), $($visibleRows[$visibleRows.length - 1]).attr("identificador")];

	}

	getNumColunaListagem(nomeProp: string) {
		const th = $(`th[listagem-nome-prop="${nomeProp}"]`).get(0);
		return th?.column;
	}

	ativarTablesorterTabela(cfgs: CfgsAddTabela<any>) {

		const tablesorterParams = {
			widgets: ["uitheme", "group", "saveSort"],
			serverSideSorting: cfgs.onOrdenacaoColuna != null,
			widgetOptions: {
				saveSort: cfgs.salvarOrdenacao === true,
				group_collapsible: true,  // make the group header clickable and collapse the rows below it.
				group_collapsed: false, // start with all groups collapsed
				group_saveGroups: true,  // remember collapsed groups
				group_saveReset: '.group_reset', // element to clear saved collapsed groups
				group_count: " ({num})", // if not false, the "{num}" replaced with the number of rows in the group
				group_forceColumn: [],   // only the first value is used; set as an array for future expansion
				group_enforceSort: true, // only apply group_forceColumn when a sort is applied to the table
				group_checkbox: ['checked', 'unchecked'],
				group_months: [
					this.getMsg("FP_FRONT_AmaisVH_012"),
					this.getMsg("FP_FRONT_AmaisVH_013"),
					this.getMsg("FP_FRONT_AmaisVH_014"),
					this.getMsg("FP_FRONT_AmaisVH_015"),
					this.getMsg("FP_FRONT_AmaisVH_016"),
					this.getMsg("FP_FRONT_AmaisVH_017"),
					this.getMsg("FP_FRONT_AmaisVH_018"),
					this.getMsg("FP_FRONT_AmaisVH_019"),
					this.getMsg("FP_FRONT_AmaisVH_020"),
					this.getMsg("FP_FRONT_AmaisVH_021"),
					this.getMsg("FP_FRONT_AmaisVH_022"),
					this.getMsg("FP_FRONT_AmaisVH_023")
				],
				group_week: [
					this.getMsg("FP_FRONT_AmaisVH_024"),
					this.getMsg("FP_FRONT_AmaisVH_025"),
					this.getMsg("FP_FRONT_AmaisVH_026"),
					this.getMsg("FP_FRONT_AmaisVH_027"),
					this.getMsg("FP_FRONT_AmaisVH_028"),
					this.getMsg("FP_FRONT_AmaisVH_029"),
					this.getMsg("FP_FRONT_AmaisVH_030")
				],
				group_time: ["AM", "PM"],
				group_time24Hour: false,
				group_dateInvalid: this.getMsg("FP_FRONT_AmaisVH_031"),
				group_dateString: function (date) {
					return date.toLocaleString();
				},
				group_formatter: function (txt, col, table, c, wo, data) {
					if (col === 7 && txt.indexOf("GMT") > 0) {
						txt = txt.substring(0, txt.indexOf("GMT"));
					}
					return txt === "" ? "Empty" : txt;
				},
				group_callback: function ($cell, $rows, column, table) {
					if (column === 2) {
						let subtotal = 0;
						$rows.each(function () {
							subtotal += parseFloat($(this).find("td").eq(column).text());
						});
						$cell.find(".group-count").append("; subtotal: " + subtotal);
					}
				},
				group_complete: "groupingComplete"
			},
			theme: "bootstrap",
			headerTemplate: '{content} {icon}',
			headers: {},
			sortList: []
		};

		let primeiraColuna = 0;

		if (cfgs.selecao == true) {
			primeiraColuna = 1;
			tablesorterParams.headers["0"] = { sorter: false }
		}

		const colunasVisiveis: ColunaAddTabela[] = (cfgs.colunas ?? []).filter(c => c.isVisivel);
		const mapNomeColuna = new Map();

		if (this.hasValue(colunasVisiveis)) {
			for (let i = 0; i < colunasVisiveis.length; i++) {
				mapNomeColuna.set(colunasVisiveis[i].prop, primeiraColuna + i);
			}
		}

		if (Array.isArray(cfgs.ordenacao)) {
			for (const ord of cfgs.ordenacao) {

				const nomeProp = ord[0]
				const crescOuDescresc = ord[1]
				const indiceProp = mapNomeColuna.get(nomeProp);

				if (indiceProp === undefined) {
					// alert("A propriedade '" + nomeProp + "' não foi informada.");
					continue
				}

				tablesorterParams.sortList.push([
					indiceProp,
					(crescOuDescresc == null || crescOuDescresc == false ? 0 : 1)
				]);
			}

		} else if (cfgs.ordenar !== false) {
			tablesorterParams.sortList = [[primeiraColuna, 0]];
		}

		this.adicionarParsersAmaisNoTableSorter();

		for (let i = 0; i < colunasVisiveis.length; i++) {

			const nomeProp = colunasVisiveis[i].prop;
			let valorSorter = colunasVisiveis[i].classe;

			if (i == 0 && this.isEmpty(valorSorter)) {
				valorSorter = "textoAcentuado";
			} else if (this.isEmpty(valorSorter)) {
				continue;
			}

			tablesorterParams.headers[String(i + primeiraColuna)] = { sorter: valorSorter };
		}

		AmaisVH.tabelasTablesorter.push({
			id: cfgs.id,
			params: tablesorterParams,
			colunas: cfgs.colunas,
			onOrdenacaoColuna: cfgs.onOrdenacaoColuna,
			possuiColunaSelecao: cfgs.selecao
		});
	}

	limparOrdenacaoSalva() {
		try {
			localStorage.removeItem("tablesorter-savesort");
		} catch (e) { }
	}

	static parsersAmaisJaForamAdicionadosNoTableSorter = null;

	adicionarParsersAmaisNoTableSorter() {
		if (AmaisVH.parsersAmaisJaForamAdicionadosNoTableSorter == null) {
			$.tablesorter.addParser({
				id: "DD/MM/YY HH:mm",
				is: s => false,
				format: s => UtilData.usuarioDataToMillis(s, "DD/MM/YY HH:mm"),
				type: "numeric"
			});
			$.tablesorter.addParser({
				id: "DD/MM/YYYY HH:mm",
				is: s => false,
				format: s => UtilData.usuarioDataToMillis(s, "DD/MM/YYYY HH:mm"),
				type: "numeric"
			});
			$.tablesorter.addParser({
				id: "DD/MM/YY",
				is: s => false,
				format: s => UtilData.usuarioDataToMillis(s, "DD/MM/YY"),
				type: "numeric"
			});
			$.tablesorter.addParser({
				id: "numero",
				is: s => false,
				format: s => s.trim().length == 0 ? null : new Number(s.replace('.', '').replace(',', '.')),
				type: "numeric"
			});
			$.tablesorter.addParser({
				id: "porcentagem",
				is: s => false,
				format: s => s.trim().length == 0 ? null : new Number(s.replace('.', '').replace(',', '.').replace('%', '')),
				type: "numeric"
			});
			$.tablesorter.addParser({
				id: "textoAcentuado",
				is: s => false,
				format: s => s.replace(AmaisVH.acentos_re, (match) => AmaisVH.acentos[match].trim()),
				type: "text"
			});

			AmaisVH.parsersAmaisJaForamAdicionadosNoTableSorter = true
		}
	}

	addTabela_getIdLinha(toLinha, cfgs, numColuna) {
		if (cfgs.propId == null) {
			return null;
		}

		if (cfgs.propId.push) {
			return toLinha[cfgs.propId[numColuna]];
		} else {
			return toLinha[cfgs.propId];
		}
	}

	async addTabela_addLinha(collection, cfgs: CfgsAddTabela<any>, colunas: ColunaAddTabela[]) {

		const h = [];

		for (let i = 0; i < collection.length; i++) {
			const toLinha = collection[i]
			const id = this.addTabela_getIdLinha(toLinha, cfgs, 0);

			if (id != null) {
				if (cfgs.toLinhaPorCodigo == null) {
					cfgs.toLinhaPorCodigo = new Object();
				}
				cfgs.toLinhaPorCodigo[id] = toLinha;
			}

			h.push("<tr");
			if (id != null) {
				h.push(" id='listagem_linha_" + id + "' identificador='" + id + "' ");
				if (id == cfgs.idSelecionado) h.push(" selecionado='true' ");
			}
			h.push(">");

			cfgs.numItensExibidos++;

			if (cfgs.selecao) {

				h.push("<td tipo='web'>");

				let deveExibirCheckbox = false;

				if (typeof cfgs.selecao == 'function') {
					try {
						deveExibirCheckbox = cfgs.selecao.call(this, toLinha);
					} catch (e) { }
				} else {
					deveExibirCheckbox = (cfgs.selecao == true);
				}

				if (deveExibirCheckbox) {
					let configsTag = '';
					if (cfgs.atibutoParaDesabilitarCheckbox) {
						configsTag = toLinha[cfgs.atibutoParaDesabilitarCheckbox] ? 'disabled' :  ''
						toLinha.isSelecionado = false;
					}
					h.push(`<input type='checkbox' ${configsTag}`);
					if (id != null) {
						h.push(" value='" + id + "' ");
					}
					if (cfgs.propId.push) {
						for (let j = 0; j < cfgs.propId.length; j++) {
							h.push(cfgs.propId[j] + "='" + this.addTabela_getIdLinha(toLinha, cfgs, j) + "' ");
						}
					} else {
						h.push(cfgs.propId + "='" + this.addTabela_getIdLinha(toLinha, cfgs, 0) + "' ");
					}

					if (cfgs.selecionarCheckbox) {
						toLinha.isSelecionado = cfgs.selecionarCheckbox(toLinha);
					}
					if (toLinha.isSelecionado == true || (cfgs.valoresSelecionados && id && cfgs.valoresSelecionados.includes(id))) {
						h.push(" checked='checked' ");
					}
					h.push(">");
				}

				h.push("</td>");
			}

			if (cfgs.exibirNumeracao === true) {
				if (toLinha.isTotais) {
					h.push("<td></td>");
				} else {
					h.push("<td>");
					h.push((i + 1) + (cfgs.sufixoNumeracao || ""));
					h.push("</td>");
				}
			}

			for (let j = 0; j < colunas.length; j++) {
				const label = colunas[j].titulo;
				const prop = colunas[j].prop;
				const formato = colunas[j].formato;
				const nomeProp = colunas[j].nomeProp;
				let valor;

				if (toLinha.isTotais) {
					valor = toLinha[prop];

					if (this.isEmpty(valor)) {
						valor = "";
					}
				} else {
					valor = await this.addTabela_getValorProp(toLinha, prop, formato, i, collection);
				}

				if (cfgs.totalizar?.includes(prop) && !toLinha.isTotais) {

					if (!cfgs.totaisDasColunas) {
						cfgs.totaisDasColunas = [];
						cfgs.totaisDasColunas.isTotais = true;
					}

					let v = toLinha[prop];

					if (this.isEmpty(v) && this.hasValue(valor)) {
						v = String(valor).replace(".", "").replace(",", ".");
						if (isNaN(v)) {
							v = null;
						} else {
							v = Number(v);
						}
					}

					if ((cfgs.totalizar === true || cfgs.totalizar.includes(prop)) && this.hasValue(v) && !isNaN(v)) {
						cfgs.totaisDasColunas[prop] = (cfgs.totaisDasColunas[prop] ?? 0) + v;
					}
				}

				if (!label) {
					// se não informou o label somente desta coluna, então ela
					// não será impressa, somente totalizada
					//					continue
				}

				let propListagem = prop;
				if (typeof prop == 'function') {
					if (nomeProp) {
						propListagem = nomeProp;
					} else {
						propListagem = j;
					}
				}

				h.push(`<td prop-listagem="${propListagem}" ${this.hasValue(colunas[j].classe) ? 'class="' + colunas[j].classe + '"' : ''}`)

				if (formato === "numero") {
					h.push(" tipo='n' ");
				}

				if (this.hasValue(colunas[j].css)) {
					h.push(" style='" + colunas[j].css + "' ");
				}

				h.push(` tabindex="0" aria-label="${UtilHtml.removeTagsHtml(valor)}" >`);

				if ((this.hasValue(cfgs.onEdicao) || this.hasValue(cfgs.hashEdicao)) && collection.isTotalizacao != true && toLinha.isEditavel != false) {

					const idLinha = this.addTabela_getIdLinha(toLinha, cfgs, j);

					if (idLinha != null) {

						if (this.hasValue(cfgs.hashEdicao)) {

							if (j === 0) {
								valor = `<a href="${cfgs.hashEdicao}/${idLinha}">${valor}</a>`;
							}

						} else {

							let onEdicao = cfgs.onEdicao;

							if (onEdicao.push != null) {
								onEdicao = onEdicao[j];
							} else if (j != 0) {
								onEdicao = null;
							}

							if (onEdicao) {
								let param1 = idLinha;
								let param2 = null;

								if (cfgs.onEdicaoParam1 != null) {
									param1 = typeof cfgs.onEdicaoParam1 === "function" ? cfgs.onEdicaoParam1(toLinha) : cfgs.onEdicaoParam1;
									param2 = idLinha;
								}

								const idLink = this.gerarId();
								let href = "";

								if (UtilHash.isMetodoVHComHash(onEdicao)) {
									href = " href='" + UtilHash.getHash(onEdicao, param1, param2) + "' "
								} else {
									this.appendJs(() => {
										this.listenToClick("#" + idLink, async () => await onEdicao(param1, param2 || toLinha));
									});
								}

								valor = `<a id="${idLink}" ${href}>${valor}</a>`;
							}
						}
					}
				}

				h.push(valor + "</td>")
			}

			if (cfgs.onExclusao) {

				let isExcluivel = true;

				if (cfgs.isExcluivel != null) {
					if (typeof cfgs.isExcluivel == "function") {
						try {
							isExcluivel = cfgs.isExcluivel(toLinha);
						} catch (e) {
							console.error("Erro ao resolver function propIsExcluivel. ", e);
							this.logger.error("Erro ao resolver function propIsExcluivel. ", e);
						}
					} else {
						isExcluivel = toLinha[cfgs.isExcluivel];
					}

				} else if (cfgs.propIsExcluivel != null) {
					isExcluivel = toLinha[cfgs.propIsExcluivel];
				}

				if (isExcluivel == null) {
					isExcluivel = true
				}

				if (isExcluivel && id) {
					const idLink = this.gerarId();
					h.push(`
						<td tipo='web'>
							<a id="${idLink}" class='btn btn-default btn-sm hover-tr pull-right' title='${this.getMsg("FP_FRONT_AmaisVH_032")}'>
								<i class='fa fa-trash-o'></i>
							</a>
						</td>
					`);
					this.appendJs(() => {
						this.listenToClick("#" + idLink, async (event) => {
							const botao = $(event.target).closest("a").get(0); // às vezes o click é no <i>
							await cfgs.onExclusao(botao, id);
						});
					});
				} else {
					h.push("<td></td>");
				}
			}

			h.push("</tr>");
		}

		return h.join("");
	}

	async addTabela_hideLinha(idLinha) {
		await this.hide("listagem_linha_" + idLinha)
	}

	addRadioGroup(cfgs) {
		let h = [];

		if (this.jaAdicionouForm !== true) {
			h.push(this.addFormulario({ retornarHtml: cfgs.retornarHtml }));
		}

		cfgs.propId = cfgs.propId || "id";
		cfgs.css = cfgs.css || "";
		cfgs.classe = cfgs.classe || "";
		cfgs.html = cfgs.html || "";
		cfgs.classeRadio = cfgs.classeRadio || "";
		cfgs.propDescricao = cfgs.propDescricao || "descricao";
		cfgs.idComponente = cfgs.idComponente || "componente_" + cfgs.id;

		h.push(`
			<div class='form-group ${cfgs.classe}' id='${cfgs.idComponente}' ${cfgs.html || ""} style='${cfgs.css} ${cfgs.visivel == false ? ";display: none;" : ""}'>
		`);

		if (cfgs.label) {
			h.push("<label class='control-label'>")
			h.push(cfgs.label);
			h.push(cfgs.obrigatorio ? " * " : "");
			if (cfgs.ajuda != null) h.push("<small class='pull-right'>" + cfgs.ajuda + "</small>");
			h.push("</label>");
		}

		let disabled = cfgs.habilitado === false ? " disabled " : "";

		for (const to of cfgs.collection) {

			if (to.isDesabilitado) continue;

			const isEnum = to instanceof EnumFP;
			const id = isEnum ? to.id : to[cfgs.propId];
			const label = isEnum ? this.getMsg(to.idMsg) : to[cfgs.propDescricao];
			const checked = (to === cfgs.valor || id == cfgs.valor ? "checked" : "");
			const styleDiv = to.visivel === false ? "display: none" : "";

			h.push(`
				<div class='radio ${cfgs.classeRadio}' id="radio_${id}" style="${styleDiv}">
					<label
			`);

			if (to.dica) {
				h.push(` title='${to.dica}' `);
			} else if (to.dica !== false && !cfgs.desabilitarDica) {
				h.push(` title='${this.getMsg("FP_FRONT_AmaisVH_034")}' `);
			}

			h.push(`>`);

			h.push("<input");
			h.push(" vh='" + this.nomeVH + "' ");
			h.push(cfgs.obrigatorio ? " obrigatorio " : "");
			h.push(" type='radio' id='");
			h.push(cfgs.id);
			h.push("' name='");
			h.push(cfgs.id);
			h.push("' idComponente='");
			h.push(cfgs.idComponente);
			h.push("' value='");
			h.push(id);
			h.push("' ");
			h.push(disabled);
			h.push(" ");
			h.push(checked);
			h.push(" ondblclick='if (this.checked) {this.checked = false; this.onchange();}'>")
			h.push(label || to.nome || to.label || to.text);

			h.push("</label></div>");
		}

		if (cfgs.onChange) {
			this.appendJs(() => this.listenToChange(`input[name='${cfgs.id}']`, cfgs.onChange));
		}

		h.push("</div>");

		if (cfgs.retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	desabilitarOpcaoRadio(id, valor) {
		$("#" + id).closest(".form-group").find("input[id='" + id + "'][value='" + valor + "']").attr("disabled", "disabled").closest("label").addClass("disabled");
	}

	habilitarOpcaoRadio(id, valor) {
		$("#" + id).closest(".form-group").find("input[id='" + id + "'][value='" + valor + "']").removeAttr("disabled").closest("label").removeClass("disabled");
	}

	async addTabela_getValorProp(to: any, prop: any, formato, indiceTO: number, collection: any[]) {

		let valor = null;

		if (typeof prop === "function") {
			valor = await prop.call(this, to, indiceTO, collection);

			if (valor == null && to.isTotais) {
				valor = to[prop];
			}

			if (valor == null) {
				valor = ""
			}

			return valor;
		}

		const indiceDelimitador = prop.indexOf('/');

		if (prop.indexOf("${") != -1) {

			if (to[prop] != null) {
				return to[prop];
			}

			let indiceInicioPropriedadeJstl = prop.indexOf("${")

			while (indiceInicioPropriedadeJstl != -1) { // não tem nenhuma
				// propriedade 'jstl'
				// para substituir
				const indiceFinalPropriedade = prop.indexOf("}", indiceInicioPropriedadeJstl + 1)
				const propriedadeJstl = prop.substring(indiceInicioPropriedadeJstl + 2, indiceFinalPropriedade)

				valor = await this.addTabela_getValorProp(to, propriedadeJstl, formato, indiceTO, collection)
				prop = prop.substring(0, indiceInicioPropriedadeJstl) + valor + prop.substring(indiceFinalPropriedade + 1, prop.length)

				indiceInicioPropriedadeJstl = prop.indexOf("${")
			}

			return prop

		} else if (indiceDelimitador != -1) {

			const props = prop.split("/")
			let valores = "";
			let encontrouAlgum = false;

			for (var i = 0; i < props.length; i++) {
				if (i > 0) {
					valores += " / "
				}
				const prop2 = props[i];

				if (to[prop2] != null) {
					encontrouAlgum = true;
				}

				valores += to[prop2]
			}

			if (encontrouAlgum)
				return valores
			else
				return prop;

		} else {
			valor = to[prop];
		}

		if (valor == null) {
			valor = "";

		} else if (valor instanceof EnumFP) {
			valor = this.getMsg(valor.idMsg);

		} else if (Array.isArray(valor)) {
			valor = valor.join("<br>");

		} else if (formato && formato.toLowerCase() == "numero") {
			valor = UtilNumero.floatToString(valor);

		} else if (formato && formato.toLowerCase() == "moeda") {
			valor = UtilNumero.floatToString(valor);

		} else if (formato && formato.toLowerCase() == "porcentagem") {
			valor = UtilNumero.floatToString(Math.round(valor));

			if (valor.endsWith(",00")) {
				valor = valor.substring(0, valor.length - 3);
			}

			valor += "%";

		} else if (valor.getTime || (formato?.startsWith && formato.startsWith("DD/"))) {
			valor = UtilData.toDDMMYYYYHHMMSS(valor, formato || "DD/MM/YYYY");

		} else if (formato == "%") {
			valor = Number(valor);
			valor = valor * 100;
			valor = UtilNumero.floatToString(valor);

			if (valor.endsWith(",00")) {
				valor = valor.substring(0, valor.length - 3)
			}

			valor += "%"
		}

		if (valor.toString() == "true" || valor.toString() == "false") {
			if (valor) {
				valor = "<strong><i class='fa fa-check text-success' title='Sim'></i></strong>"
			} else {
				valor = "-"
			}
		}

		return valor
	}

	addTitulo(cfgs: CfgsAddTitulo) {

		cfgs.id = cfgs.id || this.gerarId();
		
		const subtitulo = cfgs.subtitulo ? ` <small>${cfgs.subtitulo}</small> ` : "";
		const textoADireita = cfgs.textoADireita ? ` <span class="pull-right">${cfgs.textoADireita}</span> ` : "";

		this.append(`
			<div class="page-header" id="${cfgs.id}">
				<h2>
					${cfgs.texto}
					${subtitulo}
					${textoADireita}
				</h2>
			</div>
		`);
	}

	addSubtitulo(cfgs: string | CfgsAddSubtitulo) {

		if (typeof cfgs === "string") {
			cfgs = <CfgsAddSubtitulo> {
				texto: cfgs
			}
		}

		const h = [];
		const classe = cfgs.classeContainer ?? '';
		const css = cfgs.cssContainer ?? '';
		
		h.push(`<div class='subtitulo col-md-12 ${classe}' style="${css}"><h3 `);

		if (cfgs.id != null) {
			h.push(" id='" + cfgs.id + "' ");
		}

		h.push(" vh='" + this.nomeVH + "' ");

		h.push(" style='")
		h.push(cfgs.visivel === false ? "display: none; " : "");

		if (cfgs.css != null) {
			h.push(cfgs.css)
		}
		h.push("'>")
		h.push(cfgs.texto)

		if (cfgs.textoSecundario) h.push(" <small>" + cfgs.textoSecundario + "</small>");

		h.push("</h3>");

		if (cfgs.htmlDireita) h.push(" <span class='pull-right'>" + cfgs.htmlDireita + "</span>");

		h.push("</div>");

		if (cfgs.retornarHtml == true) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	addSubsubtitulo(cfgs) {
		let h = [];

		if (typeof cfgs != 'object') {
			cfgs = {
				texto: cfgs
			}
		}

		h.push(`<h4 class="col-md-12" style="padding-top: 10px; clear: both; ${cfgs.css || ""}">${cfgs.texto}</h4>`);

		if (cfgs.retornarHtml == true) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	idAccordionGrupoAtual = null;

	abrirAbaAccordion(...params) {

		let cfgs = null;

		if (typeof params[0] != 'object') {
			cfgs = {
				titulo: params[0]
			}
		} else {
			cfgs = params[0];
		}

		cfgs.id ??= this.gerarId();
		const idAba = "_vh_acc_a_" + this.gerarId();
		const html = cfgs.html || "";

		if (this.idAccordionGrupoAtual == null) {
			this.idAccordionGrupoAtual = "_av_acc_" + this.gerarId();
			this.append('<div class="row"><div class="panel-group col-md-12" id="' + this.idAccordionGrupoAtual + '"  role="tablist" aria-multiselectable="true">');
		}

		this.append(`
			<div class="panel panel-default" id="${cfgs.id}" ${html}>
				<div class="panel-heading" role="tab" onclick="$(this).parent().find('.collapse').collapse('toggle')" style="cursor: pointer">
					<h3 class="panel-title">
						<a data-toggle="collapse" data-parent="#${this.idAccordionGrupoAtual}" href="#${idAba}" aria-expanded="true" aria-controls="collapseOne" onclick="$(this).find(\'i\').toggle();">
							${cfgs.titulo}
							<i class='fa fa-angle-down'></i>
							<i class='fa fa-angle-up' style='display: none'></i>
						</a>
						${cfgs.texto || ""}
					</h3>
				</div>
				<div id="${idAba}" class="panel-collapse collapse ${cfgs.aberta ? " in" : ""}" role="tabpanel" aria-labelledby="headingOne">
					<div class="panel-body">
						<div class="col-md-12">
		`);
	}

	fecharAbaAccordion() {
		this.append(`
						</div>
					</div>
				</div>
			</div>
		`);
	}

	fecharGrupoAccordion() {
		this.append('</div></div>');
		this.idAccordionGrupoAtual = null;
	}

	collapseAccordion(id) {
		$(`#${id}`).parent().find('.collapse').collapse('toggle');
	}

	addZonaUpload(cfgs: CfgsAddZonaUpload) {

		const vazio = () => true;

		cfgs.id = cfgs.id || this.gerarId();
		cfgs.onAntesAceitacao = cfgs.onAntesAceitacao || vazio;
		cfgs.onAntesEnvio = cfgs.onAntesEnvio || vazio;
		cfgs.onProgressoEnvio = cfgs.onProgressoEnvio || vazio;
		cfgs.onDepoisEnvio = cfgs.onDepoisEnvio || vazio;
		cfgs.onErro = cfgs.onErro || vazio;
		cfgs.onSelecaoArquivosAberta = cfgs.onSelecaoArquivosAberta || vazio;

		const classe = cfgs.classe || "";
		const css = cfgs.css || "";
		const label = cfgs.label || "" + (cfgs.obrigatorio ? " * " : "");
		const h = [];

		h.push(`
			<div id="${cfgs.id}" class="form-group dropzone ${classe}" style="${css}">
				<label class="control-label">${label}</label>
				<span class="dz-message">
					<small>${this.getMsg("FP_FRONT_AmaisVH_035")}</small>
				</span>
			</div>
		`);

		AmaisVH.zonas.set(cfgs.id, cfgs);

		if (cfgs.retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	static DEAD_PROMISE = new Promise((resolve, reject) => { });

	async call(endpointOuBackendCallTO: string | BackendRequest, ...callParams): Promise<any> {

		AmaisVH.argumentsUltimoCall = callParams;

		let backendRequest: BackendRequest;

		if (typeof endpointOuBackendCallTO === "string") {

			backendRequest = {
				endpoint: endpointOuBackendCallTO,
				params: callParams
			}

		} else {
			backendRequest = endpointOuBackendCallTO;
		}

		if (this.isEmpty(backendRequest.endpoint)) {
			const msgErro = this.getMsg("MSG_VH_A_04");
			this.logger.error(msgErro, endpointOuBackendCallTO, callParams);
			this.exibirAlerta({ msg: msgErro });
			throw msgErro;
		}

		const paramsRequisicao = {};

		if (this.hasValue(backendRequest.params)) {
			for (let i = 0; i < backendRequest.params.length; i++) {
				paramsRequisicao[i] = backendRequest.params[i];
			}
		}

		const url = UtilBoot.getURLBaseBackend() + (backendRequest.endpoint.startsWith("c/") ? backendRequest.endpoint : `c/vh/call/${backendRequest.endpoint}`);

		let divMsgCarregando = null;
		let removerMsgCarregando = null;

		if (backendRequest.desativarMsgCarregando !== true) {
			if (backendRequest.blockUiOnCarregando) {
				this.exibirLoading({
					msg: backendRequest.msgCarregando
				})

				removerMsgCarregando = () => {
					this.removerLoading();
				}
			} else {
				divMsgCarregando = AmaisVH.criarDivMsgAjax(
					backendRequest.msgCarregando || this.getMsg("FP_FRONT_AmaisVH_036"),
					true
				);

				removerMsgCarregando = () => {
					divMsgCarregando?.fadeOut(() => {
						divMsgCarregando?.remove();
						divMsgCarregando = null;
					});
				}
			}

		}

		const cfgsFetch = {
			method: "POST",
			headers: {
				"Authorization": UtilAuth.getJwtToken(),
				"Content-type": "application/json"
			},
			body: UtilJson.toString(paramsRequisicao)
		}

		try {
			const response = await filaAcessoVH.fetchRespeitandoFila(url, cfgsFetch, divMsgCarregando);

			try {
				UtilData.setUltimoResponseHeaderDate(response.headers.get("Date"));
			} catch (ignored) { }

			const body = await response.json();

			if (!response.ok) {
				throw BackendRequestError.ofResponse(url, cfgsFetch, body, response);
			}

			if (body) {
				try {
					if (Object.keys(body).includes("_v")) {
						UtilBoot.verificarNovaVersaoFront(body._v);
					}
				} catch (ignored) { }

				try {
					UtilJson.resolverEnuns(body);
				} catch (ignored) { }

				if (Object.keys(body).includes("respostaFCD")) {
					return body.respostaFCD;
				} else {
					return body;
				}

			} else {
				return null;
			}

		} catch (e) { // "fetch" lança uma exceção apenas para erros de rede e erros de segurança, mas não para respostas HTTP com status de erro

			this.logger.error(e);
			if (removerMsgCarregando) removerMsgCarregando();

			const navigatorOnLine = navigator.onLine;
			let backendRequestError: BackendRequestError = null;

			if (e instanceof BackendRequestError) {
				backendRequestError = e;
			} else {
				backendRequestError = BackendRequestError.ofFecthError(url, cfgsFetch, e);
			}

			backendRequestError.navigatorOnLine = navigatorOnLine;

			let isErroTratado = false;

			if (backendRequest.onRequestError) {
				try {
					isErroTratado = await backendRequest.onRequestError(backendRequestError);
				} catch (ignored) { }
			}

			if (isErroTratado) {
				this.logError(backendRequestError);
				throw backendRequestError; // todos os callers que tratam erro estão com catch, então não vai para o sentry
			}

			if (backendRequestError.msgErro) {

				if (backendRequestError.isAcessoRestritoSafeBrowser) {
					this.logError(backendRequestError);
					await safeBrowserVH.exibirInstrucoesInstalacao();
					await AmaisVH.DEAD_PROMISE; // não precisa ir para sentry
				}

				if (backendRequestError.isSemAutenticacao) {
					AmaisVH.isUsuarioAutenticado = false;
					UtilAuth.limparJwtToken();
					this.logError(backendRequestError);
					await this.exibirAlerta({ msg: backendRequestError.msgErro });
					loginVH.onLoginExpirado();
					await AmaisVH.DEAD_PROMISE; // não precisa ir para sentry
				}

				if (backendRequestError.isSessaoWebFechada) {
					AmaisVH.isUsuarioAutenticado = false;
					this.logError(backendRequestError);
					await this.exibirAlerta({ msg: backendRequestError.msgErro });
					await loginVH.onLogout()
					await AmaisVH.DEAD_PROMISE; // não precisa ir para sentry
				}

			} else {

				if (!backendRequestError.navigatorOnLine) {
					this.exibirAlerta({ msg: this.getMsg("FP_FRONT_InicioPFVH_012") });
					this.logError(backendRequestError);
					throw backendRequestError; // se caller não tratar vai para o sentry, que é o registro que queremos ter
				}

				backendRequestError.possuiConexaoInternet = await this.possuiConexao("https://d34ovfwilfr41j.cloudfront.net/imagens/teste-internet.png?ts=" + Date.now());

				if (!backendRequestError.possuiConexaoInternet) {
					this.exibirAlerta({ msg: this.getMsg("FP_FRONT_InicioPFVH_012") });
					this.logError(backendRequestError);
					throw backendRequestError; // se caller não tratar vai para o sentry, que é o registro que queremos ter
				}

				backendRequestError.possuiConexaoBackend = await this.possuiConexao(UtilBoot.getURLBaseBackend() + "c/mon-front?ts=" + Date.now());

				if (!backendRequestError.possuiConexaoBackend) {
					this.exibirAlerta({ msg: this.getMsg("MSG_VH_A_05") });
					this.logError(backendRequestError);
					throw backendRequestError; // se caller não tratar vai para o sentry, que é o registro que queremos ter
				}
			}

			if (backendRequestError.msgErro) {
				this.exibirAlerta({ msg: backendRequestError.msgErro });
				this.logError(backendRequestError);
				try {
					$(document).trigger("container-alterado");
				} catch (e) {
					console.error("Erro ao disparar container-alterado", e);
					this.logger.error("Erro ao disparar container-alterado", e)
				}
				await AmaisVH.DEAD_PROMISE; // se tem msgErro do back, então já tem auditoria lá, melhor não mandar para o sentry
			}

			const email = this.getCfg("EMAIL_SUPORTE");
			const configAlerta = {
				titulo: `<i class="fas fa-exclamation-triangle"></i> ${this.getMsg('MSG_VH_A_15')}`,
				msg: `${this.getMsg("MSG_VH_A_07")} <a href='mailto:${email}'>${email}</a>`,
				botoes: []
			}

			if (this.isCfgHabilitada("FUNCIONALIDADE_CHAT_SUPORTE") && this.isAluno()) {
				configAlerta.msg = `${this.getMsg("MSG_VH_A_17")} <br/> ${this.getMsg("MSG_VH_A_14")}`;;
				this.exibirAlertaEnviarMsgChat(configAlerta);
			} else {
				configAlerta.botoes = [{label: 'Fechar'}];
				this.exibirAlerta(configAlerta);
			}

			this.logError(backendRequestError);
			throw backendRequestError; // se caller não tratar vai para o sentry, que é o registro que queremos ter

		} finally {
			if (removerMsgCarregando) removerMsgCarregando();
		}
	}

	exibirAlertaEnviarMsgChat(configAlerta) {
		if (configAlerta && !configAlerta.botoes) {
			configAlerta.botoes = [];
		}
		configAlerta.botoes.push({
			label: this.getMsg("MSG_VH_A_16"),
			classe: "btn-primary",
			onClick: () => {
				const el = $('.purechat-collapsed-image')
				if (el?.length) {
					el.trigger("click");
				}
			}
		});

		this.exibirAlerta(configAlerta);
	}

	logError(backendRequestError: BackendRequestError) {
		try {
			if (backendRequestError.msgErro) {
				this.logger.warn(backendRequestError, JSON.stringify(backendRequestError, null, 4));
			} else {
				this.logger.error(backendRequestError, JSON.stringify(backendRequestError, null, 4));
			}
		} catch (ignored) { }
	}

	async possuiConexao(url: string) {
		try {
			const response = await fetch(url, {
				method: "HEAD"
			});
			return response.ok;
		} catch (e) {
			this.logger.error(e);
			return false;
		}
	}

	addPopup(cfgs: CfgsAddPopup) {
		cfgs.id = cfgs.id || "popup_" + this.gerarId();

		this.popups = this.popups ?? [];

		this.popups.push(cfgs)
		this.setIdTarget(cfgs.id)
	}

	exibirPopups() {

		$("div.modal").each((i, modal) => {
			if ($(modal).css("display") == "none") {
				this.fecharPopup(modal.id);
			}
		});

		if (this.isEmpty(this.popups)) return;

		const confCacheAnterior = AmaisVH.isCacheHtmlHabilitado;
		AmaisVH.isCacheHtmlHabilitado = false;

		for (const cfgs of this.popups) {
			if (cfgs != null) {
				this.criarPopup(cfgs);
			}
		}

		this.popups.length = 0;
		this.setIdTarget(null);
		AmaisVH.isCacheHtmlHabilitado = confCacheAnterior;
		this.exibirHtmlDaCache();
		this.ativarZonasUpload();
		this.executarJsDaCache();
		this.ativarMidiasEmbutidas();
		this.ativarLinksAguardandoGeracao();

		try {
			$(document).trigger("container-alterado");
		} catch (e) {
			console.error("Erro ao disparar container-alterado", e);
			this.logger.error("Erro ao disparar container-alterado", e);
		}

		$(".modal .form-group:not([class*='col-'])").addClass("col-sm-12 col-md-6 col-lg-6");

		setTimeout(() => AmaisVH.dispararAjusteAcessibilidade(), 300);
	}

	ativarLinksAguardandoGeracao() {
		$(".fp-link-aguardar-geracao-arquivo").each((i, link) => {
			let $link = $(link);
			if ($link.data("is-ativo")) return;
			$link.data("is-ativo", true);
			let href = $link.attr("href");
			let label = $link.html();
			$link.removeAttr("href").prop('onclick', null).off('click');
			$link.html(`<i class='fa fa-spinner fa-pulse'></i> ${this.getMsg("FP_FRONT_AmaisVH_037")}`);
			UtilArmazenamento.aguardarArquivoDisponivel(link, href, label).then(() => { }, () => { });
		});
	}

	exibirPopup(idPopup) {

		if (this.isEmpty(this.popups)) return;

		for (const popupConfig of this.popups) {
			if (popupConfig.id == idPopup) {
				this.criarPopup(popupConfig)
			}
		}
	}

	criarPopup(cfgs: CfgsAddPopup) {
		
		cfgs.botoes = cfgs.botoes || [];
		cfgs.id = cfgs.id || this.gerarId();
		cfgs.idPopup = cfgs.idPopup || ("popup_" + cfgs.id);
		cfgs.css = cfgs.css || "";

		let atributos = [];

		if (cfgs.data) {
			Object.keys(cfgs.data).forEach(it => {
				atributos.push(`${it}="${cfgs.data[it]}"`)
			});
		}

		const h = [`
			<div id="${cfgs.idPopup}" modal="${this.gerarId()}" ${atributos.join(" ")} class="modal fade" aria-labelledby="popup_label_${cfgs.id}" tabindex="-1" role="dialog" style="padding:0px" aria-hidden="true">
				<div class="modal-dialog" style="${cfgs.css}">
					<div class="modal-content">
						<div class="modal-header" id="popup_cabecalho${cfgs.id}">
							<button type='button' class='close' data-dismiss='modal'>
								<span aria-hidden='true'>&times;</span>
								<span class='sr-only'>Close</span>
							</button>
		`];


		if (this.hasValue(cfgs.titulo)) {
			let titulo = cfgs.titulo + (cfgs.subtitulo ? " <br><small> " + cfgs.subtitulo.toUpperCase() + "</small>" : "")
			h.push(`
							<h2 tabindex="0" aria-label="${UtilHtml.removeTagsHtml(titulo)}" class="modal-title" id="popup_label_${cfgs.id}">
								${titulo}
							</h2>
			`);
		}

		h.push(`
						</div>
						<div class="modal-body container" id="${cfgs.id}">
						</div>
		`);

		if (cfgs.botoes.length > 0) {
			h.push(`
						<div class="modal-footer" id="popup_actions_${cfgs.id}">
							<div class="btn-group">
			`);
		}

		cfgs.botoes.filter(botao => botao.habilitado !== false).forEach((botao: CfgsAddPopupBotao) => {

			botao._idBotao = botao.id || "botao_popup_" + this.gerarId();
			botao._hash = UtilHash.getHash(botao.onClick);
			botao.classe = botao.classe || "";

			let href = "";
			let style = botao.visivel === false ? " display: none; " : "";

			style = style + (botao.css || "");

			if (botao._hash != null) {
				href = ` href="${botao._hash}" `;
			}

			h.push(`
								<a id="${botao._idBotao}" class="btn btn-default ${botao.classe}" ${href} style="${style}">
									${botao.label}
								</a>
			`);
		});

		if (cfgs.botoes.length > 0) {
			h.push(`
							</div>
						</div>
			`);
		}

		h.push(`
					</div>
				</div>
			</div>
		`);

		$("body").append(h.join(""));

		$("#" + cfgs.idPopup).on('hide.bs.modal', async (e) => {

			this.removerEditoresHtmlDaPopup(cfgs.idPopup);

			try {
				if (cfgs.onHide) await cfgs.onHide();
			} catch (e) {
				this.logger.error("Erro no onHide da popup", e);
			}

		}).on("hidden.bs.modal", async (e) => {

			try {
				const modal = $(`#${cfgs.idPopup}`);
				if (modal?.length) {
					const modalBackdrop = $('.modal-backdrop');
					if (modalBackdrop.length > 1) {
						const backdrop = modal.data('bs.modal').$backdrop;
						backdrop.remove();
						modal.remove();
						e.preventDefault()
						return;
					}
					modal.remove();
				}
			} catch (e) {
				this.logger.error("Erro na lógica de hidden de popup", e);
			}

			try {
				if (cfgs.onHidden) await cfgs.onHidden();
			} catch (e) {
				this.logger.error("Erro no onHidden da popup", e);
			}
		});

		const jePopup = $("#" + cfgs.idPopup).modal("show");

		$("#" + cfgs.idPopup).on('shown.bs.modal', () => {
			if (cfgs.fullWidth === true) {
				jePopup.css("padding", "0px");
				jePopup.find(".modal-dialog, .modal-content").css("margin", "0px");
			}
			if (cfgs.onShown) cfgs.onShown();
		});

		if (cfgs.width) jePopup.find(".modal-dialog").css("width", cfgs.width).css("min-width", cfgs.minWidth);

		if (cfgs.fullWidth === true) {
			jePopup.css("padding", "0px");
			jePopup.find(".modal-dialog, .modal-content").css("margin", "0px").css("width", "100%").css("min-height", window.innerHeight + "px");
		}
		
		$("#popup_cabecalho" + cfgs.id).css("cursor", "move");
		$("#popup_actions_" + cfgs.id).css("text-align", "right").css("margin-bottom", "0px");

		for (const botao of cfgs.botoes) {
			$("#" + botao._idBotao)
				.data("botao", botao)
				.data("idPopup", cfgs.id)
				.off("click")
				.on("click", async (event) => {
					try {
						if (AmaisVH.prevenirDuploClique(event) === true) {
							event.preventDefault();
							event.stopPropagation();
							return;
						}
					} catch (e) {
						console.error("Erro no tratamento de duplo clique nas popups", e);
						this.logger.error("Erro no tratamento de duplo clique nas popups", e)
					}

					const element = $(event.target);
					const botao: CfgsAddPopupBotao = element.data("botao");
					
					if (!botao._hash && botao.onClick) {
						this.disable(botao);
						try {
							const sucesso = await botao.onClick(event);
							if (this.isEmpty(sucesso) || sucesso === true) {
								const idPopup = element.data("idPopup");
								this.fecharPopup(idPopup);
							}
						} catch (e) {
							this.logger.error("Erro no onClick do botão de popup", e);
						}
						this.enable(botao);
					}
				});
		}
	}

	async fecharTodasPopups() {
		$("div.modal").modal("hide");
		await this.sleep(2000); // TODO precisa esperar os onHide e onHidden terminarem de um jeito melhor
	}

	async fecharPopup(idPopup: string) {
		await $("#popup_" + idPopup).modal("hide");
		await $("#" + idPopup).modal("hide");
	}

	removerEditoresHtmlDaPopup(idPopup) {
		$("#" + idPopup).find("div[contenteditable='true']").each(async (i, div) => {
			try {
				const CKEDITOR = UtilBoot.getCKEditor();
				CKEDITOR.instances[div.id].destroy();
			} catch (ignored) { }
		})
	}

	addEspacamentoHorizontal(height: string = "1px", retornarHtml: boolean = false) {

		const html = "<div class='espacamentoHorizontal' style='height: " + height + "'></div>";

		if (retornarHtml) {
			return html;
		} else {
			this.append(html);
		}
	}

	addListagemHierarquica(collection, cfgs) {

		if (cfgs == null) {
			cfgs = collection;
			collection = cfgs.collection;
		}

		const h = [];
		cfgs.propId = cfgs.propId || "id";
		cfgs.propLabel = cfgs.propLabel || "descricao";
		cfgs.propFilhos = cfgs.propFilhos || "filhos";

		h.push("<div style='width: auto'")

		if (cfgs.id != null) {
			h.push(" id='" + cfgs.id + "' ");
		}

		h.push(">");

		if (cfgs.linksCfgs != null) {
			for (const link of cfgs.linksCfgs) {
				const css = "; float: right; margin-bottom: -10px";

				if (link.css != null) {
					link.css += css;
				} else {
					link.css = css;
				}

				this.addLink(link);
			}
		}

		h.push("<label class='control-label'>")
		h.push(cfgs.label)
		h.push("</label><table listagem-hierarquica>")
		this.addListagemHierarquicaLinha(h, collection, cfgs);
		h.push("</table></div>");

		if (cfgs.retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}

	}

	addListagemHierarquicaLinha(h, collection, cfgs, idItemPai?, nivelHierarquia?) {

		if (cfgs.onNovo != null && collection.jaAdicionouUltimoItem == null) {
			let nomeNovoItem = "Novo"

			if (cfgs.nomeNovoItem != null) {
				nomeNovoItem = cfgs.nomeNovoItem
			}

			const toNovo: any = {};

			toNovo[cfgs.propId] = AmaisVH.LISTAGEM_HIERARQUIA_ID_NOVO_ITEM
			toNovo[cfgs.propLabel] = nomeNovoItem

			collection.push(toNovo)
			collection.jaAdicionouUltimoItem = true
		}

		if (nivelHierarquia == null) {
			nivelHierarquia = 0
		}

		if (cfgs.idsPaisDoIdSelecionado == null && cfgs.idSelecionado != null) {
			cfgs.idsPaisDoIdSelecionado = this.addListagemHierarquicaLinhaGetIdsPaisDoIdSelecionado(collection, cfgs)
		}

		for (const to of collection) {
			const id = to[cfgs.propId];
			const isNovo = (id == AmaisVH.LISTAGEM_HIERARQUIA_ID_NOVO_ITEM);
			let numFilhos = to.numFilhos || to.numFilhas;

			to.nivelHierarquia = nivelHierarquia;

			h.push("<tr");

			if (cfgs.idSelecionado != null && cfgs.idSelecionado == id) {
				h.push(" selecionado='true' ");
			}

			if (id != null) {
				h.push(" identificador='" + id + "' ");
			}

			if (isNovo) {
				h.push(` class="listagem-hierarquica-novo" `)
			}

			h.push(">");

			const filhosCollection = to[cfgs.propFilhos];
			let deveMostrarNavegadorDeFilhos = true;

			if (cfgs.metodoParaChecarSeDeveHabilitarFilhos != null) {
				deveMostrarNavegadorDeFilhos = cfgs.metodoParaChecarSeDeveHabilitarFilhos.call(this, to);
			}

			if (deveMostrarNavegadorDeFilhos && ((numFilhos != null && numFilhos > 0 && cfgs.metodoMostrarFilhos != null) || (filhosCollection != null && filhosCollection.length > 0))) {

				numFilhos = numFilhos || filhosCollection.length;

				const idLink = this.gerarId();

				h.push(`
					<td id="${idLink}" style="cursor: pointer">
						<span>
							<a class="badge">
								${numFilhos}
								<i class='fa fa-angle-down'></i>
							</a>
						</span>
					</td>
				`);

				this.appendJs(() => {
					this.listenToClick("#" + idLink, async (event) => {
						const link = $(event.target).closest("a").get(0);
						await this.addListagemHierarquicaOnAbrirFecharItem(link, to, cfgs);
						try {
							$(document).trigger("container-alterado");
						} catch (e) {
							console.error("Erro ao disparar container-alterado", e);
							this.logger.error("Erro ao disparar container-alterado", e);
						}
					});
				});

			} else {
				h.push("<td></td>")
			}

			const idLink = this.gerarId();
			let metodo = null;

			if (isNovo) {
				metodo = "";
				this.appendJs(() => {
					this.listenToClick("#" + idLink, async () => {
						await cfgs.onNovo(idItemPai)
					});
				});

			} else if (cfgs.onSelecao != null) {
				
				metodo = "";

				this.appendJs(() => {
					this.listenToClick("#" + idLink, async (event) => {
						const link = $(event.target).closest("a").get(0);
						await cfgs.onSelecao(to, link);
					});
				});

			} else if (cfgs.hashSelecao != null) {
				metodo = ` href="${cfgs.hashSelecao}/${id}" `;
			}

			let deveCriarLink = true;

			if (cfgs.metodoCondicaoExibicaoLinkSelecao != null && !isNovo) {
				deveCriarLink = cfgs.metodoCondicaoExibicaoLinkSelecao.call(this, to);
			}

			if (to.isEditavel != null) {
				deveCriarLink = (deveCriarLink && to.isEditavel);
			}

			h.push("<td width='100%'");

			let classeLabel = "col-md-12";

			if (cfgs.classeLabel) {
				classeLabel = cfgs.classeLabel;
			}

			if (isNovo) {
				classeLabel += " listagem-hierarquica-link-novo";
			}

			if (metodo != null && deveCriarLink) {
				h.push(` style="cursor: pointer"><a id="${idLink}" class="${classeLabel}" ${metodo}>`);
			} else {
				h.push("><span class='col-md-12'>");
			}

			if (cfgs.metodoMontarLabel == null || isNovo) {
				h.push(to[cfgs.propLabel]);
			} else {
				h.push(cfgs.metodoMontarLabel.call(this, to));
			}

			if (metodo != null && deveCriarLink) {
				h.push("</a>");
			} else {
				h.push("</span>");
			}

			h.push("</td>");

			if (cfgs.propDetalhe) {
				h.push("<td style='text-align: right; white-space: nowrap;'><span>");

				if (typeof cfgs.propDetalhe === 'function') {
					h.push(cfgs.propDetalhe(to));

				} else if (to[cfgs.propDetalhe]) {
					h.push(to[cfgs.propDetalhe]);
				}
				h.push("</span></td>");
			}

			if ((filhosCollection != null && filhosCollection.length > 0) || (numFilhos != null && numFilhos > 0)) {
				if (filhosCollection != null && filhosCollection.length > 0) {
					h.push("<tr listagem-hierarquica-filhos><td></td><td colspan='2' style='padding: 0px; margin: 0px'><table listagem-hierarquica>");
					this.addListagemHierarquicaLinha(h, filhosCollection, cfgs, id, to.nivelHierarquia + 1);
					h.push("</table></td></tr>")
				}
			}

			h.push("</tr>")
		}

		if (cfgs.onNovo != null && collection.jaAdicionouUltimoItem) {
			collection.pop()
			collection.jaAdicionouUltimoItem = null
		}
	}

	async addListagemHierarquicaOnAbrirFecharItem(link, toPai, cfgs) {

		let collectionFilhos = toPai[cfgs.propFilhos];
		const idPai = toPai[cfgs.propId];
		const item = $(link).closest("tr");
		const $trNext = item.next();

		if ($trNext.is("[listagem-hierarquica-filhos]:visible")) {
			$trNext.hide();

		} else if ($trNext.is("[listagem-hierarquica-filhos]:hidden")) {
			$trNext.show();

		} else if (this.hasValue(collectionFilhos) || cfgs.metodoMostrarFilhos != null) {

			if (this.isEmpty(collectionFilhos)) collectionFilhos = await cfgs.metodoMostrarFilhos.call(this, link, toPai, cfgs);

			if (collectionFilhos != null) {
				const h = ["<tr listagem-hierarquica-filhos><td></td><td colspan='2' style='padding: 0px; margin: 0px'><table listagem-hierarquica>"];
				this.addListagemHierarquicaLinha(h, collectionFilhos, cfgs, idPai, toPai.nivelHierarquia + 1)
				h.push("</table></td></tr>")
				$(h.join("")).insertAfter($(item));
				this.executarJsDaCache();
			}

		} else {
			this.exibirAlerta({ msg: "Erro: faltou definir propriedade 'metodoMostrarFilhos'" });
		}
	}

	addListagemHierarquicaLinhaGetIdsPaisDoIdSelecionado(collection, cfgs) {
		for (const to of collection) {
			const id = to[cfgs.propId];

			if (id == cfgs.idSelecionado) {
				return [];
			}

			if (to[cfgs.propFilhos] != null) {
				const arrayFilhos = this.addListagemHierarquicaLinhaGetIdsPaisDoIdSelecionado(to[cfgs.propFilhos], cfgs)

				if (arrayFilhos != null) {
					arrayFilhos.push(id)
					return arrayFilhos
				}
			}
		}

		return null
	}

	append(param1, param2?) {

		let html = param1;
		let id = null;

		if (param2 != null) {
			id = param1;
			html = param2;
		}

		if (id == null) {
			id = this.getIdTarget();
		}

		if (AmaisVH.isCacheHtmlHabilitado) {
			let arrayHtmlDesteId = AmaisVH.mapaCacheHtml[id];

			if (arrayHtmlDesteId == null) {
				arrayHtmlDesteId = [];
				AmaisVH.mapaCacheHtml[id] = arrayHtmlDesteId;
			}
			arrayHtmlDesteId.push(html);

		} else {
			$("#" + id).append(html);
		}
	}

	appendJs(comando) {
		AmaisVH.cacheJs.push(comando)
	}

	getIdTarget() {
		if (AmaisVH.idTarget == null) {
			return "corpo"
		}
		return AmaisVH.idTarget
	}

	setIdTarget(id, limpar = false) {
		AmaisVH.idTarget = id;
		if (limpar) {
			this.setHtml(id, "");
		}
	}

	exibirHtmlDaCache(idAExibir: string = null, executarJs: boolean = false) {

		if (idAExibir != null) {

			const arrayCacheHtml = AmaisVH.mapaCacheHtml[idAExibir];

			if (arrayCacheHtml != null) {
				try {
					if ($("#" + idAExibir).length) {
						$("#" + idAExibir).append(arrayCacheHtml.join(""));
					} else {
						$(idAExibir).append(arrayCacheHtml.join(""));
					}
				} catch (e) {
					$(idAExibir).append(arrayCacheHtml.join(""));
				}
				delete AmaisVH.mapaCacheHtml[idAExibir];
			}

		} else {

			for (let idElemento in AmaisVH.mapaCacheHtml) {
				if (idElemento == "corpo" && $("#corpo").length == 0) {
					this.limpar();
				}
				const arrayCacheHtml = AmaisVH.mapaCacheHtml[idElemento];
				try {
					if ($("#" + idElemento).length) {
						$("#" + idElemento).append(arrayCacheHtml.join(""));
					} else {
						$(idElemento).append(arrayCacheHtml.join(""));
					}
				} catch (e) {
					$(idElemento).append(arrayCacheHtml.join(""));
				}
				delete AmaisVH.mapaCacheHtml[idElemento];
			}
		}

		if (executarJs === true) {
			this.executarJsDaCache();
		}

		this.ativarTablesorters();
		UtilSelect.ativar();

		$("[tipo]").each((i, input) => {
			const tipo = $(input).attr("tipo");
			let mascara = null;
			const $this = $(input);

			if (tipo === "CPF") {
				$this.on("change", ({ target }) => UtilNumero.validarCPF(target)).trigger("change");
				mascara = "999.999.999-99";
				
			} else if (tipo === "CNPJ") {
				$this.on("change", ({ target }) => UtilNumero.validarCNPJ(target)).trigger("change");
				mascara = "99.999.999/9999-99";

			} else if (tipo === "CEP") {
				mascara = "99.999-999";

			} else if (tipo === "TELEFONE") {
				$this.on("focusout focusin", ({ target }) => {
					const phone = target.value.replace(/\D/g, '');
					const $input = $(target);

					$input.unmask();

					if (phone.length > 10) {
						$input.mask("(99) 99999-999?9");
					} else {
						$input.mask("(99) 9999-9999?9");
					}
				});

			} else if (tipo == "HORA") {
				mascara = "99:99";
				$this.clockpicker({ autoclose: 'true' });

			} else if (tipo == "MASCARA") {
				mascara = $this.attr("mask");

			} else if (tipo == "DATA" || tipo == "DATAHORA") {
				mascara = "99/99/9999";
				$this.datetimepicker({ format: 'DD/MM/YYYY', locale: AmaisVH.localeDoUsuarioLogado });

			} else if (tipo == "TEMPO") {
				mascara = "99:99";

			} else if (tipo == "MOEDA") {
				$this.priceFormat({ prefix: "R$ ", centsSeparator: ",", thousandsSeparator: "." }).on("blur", ({ target }) => {
					if ($(target).val() == "R$ 0,00") $(target).val("");
				});
			}

			if (mascara != null) {
				$this.mask(mascara).attr("mask", mascara);
			}
		});

		$(document).off("click", "table th:first-child :checkbox").on("click", "table th:first-child :checkbox", ({ target }) => {
			$(target).closest("table").find("tr td:first-child :checkbox").prop("checked", target.checked).trigger("change");
		});

		setTimeout(() => {
			this.habilitarTooltips();
			$(".fixo-centralizado").each((i: number, e: HTMLElement) => UtilWindow.centralizar(e));
		}, 500);

		setTimeout(() => {
			$(".arrastavel")
				.css("cursor", "move")
				.each((i: number, e: HTMLElement) => UtilWindow.tornarArrastavel(e));
		}, 1200);

	}

	habilitarTooltips() {
		if (!UtilWindow.isTouchDevice()) {
			$('[title]').each((i, elemento) => {
				if ($(elemento).attr("title") != "") {
					$(elemento).attr("data-container", "body");
					$(elemento).tooltip({ html: true });
				}
			});
		}
	}

	executarJsDaCache() {
		if (AmaisVH.cacheJs.length > 0) {
			for (const js of AmaisVH.cacheJs) {
				if (typeof js == "string") {
					eval(js);
				} else if (typeof js == "function") {
					js();
				}
			}
			AmaisVH.cacheJs.length = 0;
		}
	}

	addSlider(idContainer, id, min, max, obrigatorio?) {
		UtilBoot.carregarBootstrapSlider(() => {
			$("#" + idContainer).html("<input type='text' style='width:100%' " + (obrigatorio ? " obrigatorio " : "") + " type='text' maxlength='3' id='" + id + "'>");

			const ticks = [];

			ticks.push(min);

			if (max > 10) {
				ticks.push(Math.round(max / 4));
				ticks.push(Math.round(2 * max / 4));
				ticks.push(Math.round(3 * max / 4));
			}

			ticks.push(max)

			const slider = $("#" + id).slider({
				min: min,
				max: max,
				tooltip: 'always',
				ticks: ticks,
				ticks_labels: ticks,
			});

			$("#" + idContainer + " .slider").css("margin-bottom", "22px");
			$("#" + id).css("margin-top", "22px").css("text-align", "center").css("width", "30%").css("margin-left", "35%").show().on("change", ({ target }) => {
				slider.slider('setValue', target.value)
			});
		});
	}

	addListaComMarcadores(lista, ariaLabel = null) {
		if (this.hasValue(ariaLabel)) {
			this.append(`<ol aria-label="${UtilHtml.removeTagsHtml(ariaLabel)}" tabindex="0">`);
		} else {
			this.append("<ol>");
		}

		for (let i = 0; i < lista.length; i++) {
			const config = lista[i];
			this.append(`<li tabindex="0" aria-label="${this.getMsg("FP_FRONT_AmaisVH_049")} ${i + 1} ${UtilHtml.removeTagsHtml(config.texto)}">${config.texto}</li>`);
		}

		this.append("</ol>");
	}

	addBox(cfgs) {

		this.append("<div class='well ");

		if (cfgs.classe) this.append(cfgs.classe);

		this.append("' ");

		if (cfgs.id != null) {
			this.append(" id='_box_" + cfgs.id + "' ");
		}

		if (cfgs.css != null) {
			this.append(" style='" + cfgs.css + "' ");
		}

		this.append(">");
		this.append("<h3>");
		this.append(cfgs.titulo);
		this.append("</h3>");
		this.append("<div class='row'><div class='col-md-12' id='");
		this.append(cfgs.id);
		this.append("'>");

		if (!cfgs.deixarAberto) {
			this.append("</div></div></div>");
		}
	}

	fecharBox() {
		this.append("</div></div></div>");
	}

	esconderBox(idBox) {
		setTimeout("$('#_box_" + idBox + "').fadeOut(500)", 700);
	}

	exibirAlerta(cfgs: CfgsAlerta): Promise<void> {
		const promise = UtilAlerta.exibirAlerta(cfgs);
		this.ativarLinksAguardandoGeracao();
		return promise;
	}

	exibirLoading(cfgs?) {
		cfgs = cfgs || {};
		const modal = $("body")
			.append(`<div id='${cfgs.id || 'modal-loading'}' class='alerta modal modal-loading ${cfgs.classe || ''}' tabindex='-1' role='dialog' aria-labelledby='myModalLabel' aria-hidden='true'>
						<div class="loading-wrapper">
							<div class="loading-content">
								<i class='fa fa-spinner fa-spin fa-fw'></i> 
								<span>${cfgs.msg || this.getMsg('MSG_VH_A_18')}</span>
							</div>
						</div>
					</div>`)
			.find(`#${cfgs.id || 'modal-loading'}`);

		modal.modal({
			backdrop: 'static',
			show: true
		});
	}

	removerLoading(id?) {

		id = id ?? 'modal-loading';

		const modal = $(`#${id}`);

		if (modal?.length) {
			modal.modal('toggle');
			const backdrop = modal.data('bs.modal')?.$backdrop;
			if (backdrop) {
				backdrop.remove();
			}
			modal.remove();
		}
	}


	chamarMetodoVHEmNovaPagina(metodoVHStr, tituloDaPagina: String = null) {

		let f = <HTMLFormElement>document.getElementById("form_nova_pagina_vh")

		if (f == null) {
			this.append("<form id='form_nova_pagina_vh' target='_blank' action='/' method='post'>")
			this.append("<input id='form_nova_pagina_vh_mvh' type='hidden' name='metodoVHOnLoad' />")
			this.append("<input id='form_nova_pagina_vh_titulo' type='hidden' name='tituloDaPagina' />")
			this.append("</form>")
			this.exibirHtmlDaCache()
			f = <HTMLFormElement>document.getElementById("form_nova_pagina_vh")
		}

		this.setValor("form_nova_pagina_vh_mvh", metodoVHStr)

		if (tituloDaPagina != null) {
			this.setValor("form_nova_pagina_vh_titulo", tituloDaPagina)
		}

		f.submit()
	}

	abrirNovaPaginaPost(url, params) {

		let f = <HTMLFormElement> document.getElementById("form_nova_pagina_url")
		const urlAbsoluta = url;
		let htmlParams = "";

		for (let i = 0; i < params.length; i++) {
			htmlParams += "<input type='hidden' name='" + params[i++] + "' value='" + params[i] + "' />";
		}

		if (f == null) {

			this.append("<form id='form_nova_pagina_url' target='_blank' action='" + urlAbsoluta + "' method='post'>");
			this.append(htmlParams);
			this.append("</form>");

			this.exibirHtmlDaCache();

			f = <HTMLFormElement> document.getElementById("form_nova_pagina_url");

		} else {
			$(f).attr("action", urlAbsoluta).html(htmlParams);
		}

		f.submit();
	}

	numAba = 0;

	getNumAbaDaReferenciaAbaSubAba(refAbaESubAba: string | number): number {
		if (typeof refAbaESubAba === "string") {
			const matches = /(\d+)([A-Za-z])?/.exec(refAbaESubAba);
			if (matches && matches.length == 3) {
				return Number(matches[1]);
			} else {
				return Number(refAbaESubAba);
			}
		} else {
			return Number(refAbaESubAba);
		}
	}

	getLetraSubAbaDaReferenciaAbaSubAba(refAbaESubAba) {
		if (refAbaESubAba.substring) {
			const matches = /(\d+)([A-Za-z])?/.exec(refAbaESubAba);
			if (matches && matches.length == 3) {
				return matches[2];
			} else {
				return null;
			}
		} else {
			return null;
		}
	}

	// addAbas(abas, numAbaAtiva: string | number = 0, id?: string, idAlvo?: string, classes?: string, noHref?: boolean) {
	addAbas(cfgs: CfgsAddAbas) {

		cfgs.numAbaAtiva = cfgs.numAbaAtiva || 0;
		cfgs.id = cfgs.id || this.gerarId();
		cfgs.classes = cfgs.classes || "";
		cfgs.idAlvo = cfgs.idAlvo || ($("#tituloSuperiorDireito").length > 0 ? "tituloSuperiorDireito" : null);

		let hashAtual = UtilHash.getHashAtual();

		if (typeof cfgs.numAbaAtiva === "string") {
			cfgs.numAbaAtiva = this.getNumAbaDaReferenciaAbaSubAba(cfgs.numAbaAtiva);
		}

		if (cfgs.numAbaAtiva > cfgs.abas.length) {
			cfgs.numAbaAtiva = cfgs.abas.length - 1;
		}		

		this.append(cfgs.idAlvo, `
			<nav id='${cfgs.id}'>
				<ul abas id='${this.gerarId()}' class='nav nav-tabs ${cfgs.classes}'>
		`);

		let ativarPrimeiraAbaVisivel = false;

		for (let i = 0; i < cfgs.abas.length; i++) {
			let cfgsAba = cfgs.abas[i];
			if (i === cfgs.numAbaAtiva && cfgsAba.habilitada === false) {
				ativarPrimeiraAbaVisivel = true;
			}
		}

		let jaAtivouUmaAba = false;

		cfgs.abas.forEach((cfgsAba, i) => {

			cfgsAba.id = cfgsAba.id || this.gerarId();

			this.numAba++;
			const hashAba = UtilHash.getHashAba(hashAtual, i);
			let idAba = "_aba_" + this.numAba;
			const style = (cfgsAba.habilitada === false) ? "display: none" : "";
			let classe = "";
			let href = "";

			if ((i == cfgs.numAbaAtiva || ativarPrimeiraAbaVisivel) && cfgsAba.habilitada != false && !jaAtivouUmaAba) {
				classe = "active";
				jaAtivouUmaAba = true;
			}

			if (!cfgs.noHref) {
				href = `#${hashAba.replaceAll("\"", "'")}`;
			} else {
				idAba = cfgsAba.id || this.gerarId() + idAba;
			}

			this.append(cfgs.idAlvo, `
				<li aba="${i}" id="${cfgsAba.id}" class="${classe}" style="${style}">
					<a id="${idAba}" href="${href}" data-toggle='tab'>
						${cfgsAba.label}
					</a>
				</li>
			`);

			this.appendJs(() => {
				$("#" + idAba).data("onClick", cfgsAba.onClick).on("click", async (event) => {
					try {
						if (cfgs.noHref) {
							await cfgsAba.onClick();
						} else if (event.ctrlKey) {
							// deixa abrir em nova aba
						} else {
							event.preventDefault();
							event.stopPropagation();
							if ($.address.value() == hashAba) {
								await cfgsAba.onClick();
							} else {
								$.address.value(hashAba);
							}
						}
					} catch (e) {
						this.logger.error(e);
					}
				});
			});
		});

		this.append(cfgs.idAlvo, "</ul></nav>");
	}

	verificarCarregamentoAbaAtiva() {
		const liAbaAtiva = $(".nav-tabs li.active");

		if (!liAbaAtiva.length) return;
		
		const aAbaAtiva = liAbaAtiva.find("a");

		if (!aAbaAtiva.attr("iniciado")) {
			aAbaAtiva.attr("iniciado", "true");
			const onClick = aAbaAtiva.data("onClick");
			if (onClick) onClick();
		}
	}

	async show(...params) {

		let ids = [];
		let isShow = true;
		let duracaoMilis = 10;

		for (let p of params) {
			if (typeof p === "boolean") {
				isShow = p;

			} else if (typeof p === "number") {
				duracaoMilis = p;

			} else if (Array.isArray(p)) { // jah eh um array
				ids = ids.concat(p);

			} else {
				ids.push(p);
			}
		}

		const elementos = [];

		for (let id of ids) {
			const e = this.getElementoHtml(id);

			if (!e) continue;

			const $e = $(e);
			const tagName = e.tagName.toLowerCase();
			const tipo = $e.attr("tipo");

			if (["select", "textarea", "input"].includes(tagName) || ["EDITOR_HTML", "EXIBICAO"].includes(tipo)) {
				const pai = $e.closest("div.form-group");
				if (pai.length > 0) {
					elementos.push(pai[0]);
				}
			} else {
				elementos.push(e);
			}
		}

		if (elementos.length === 0) return;

		if (isShow) {
			$(elementos).slideDown(duracaoMilis);
		} else {
			$(elementos).slideUp(duracaoMilis);
		}

		await $(elementos).promise();
	}

	async hide(...params): Promise<void> {
		let ids = [];
		let isEsconder = true;
		let duracaoMilis = 10;

		for (let p of params) {
			if (typeof p === "boolean") {
				isEsconder = p;

			} else if (typeof p === "number") {
				duracaoMilis = p;

			} else if (Array.isArray(p)) {
				ids = ids.concat(p);

			} else {
				ids.push(p);
			}
		}

		const elementos = [];

		for (let id of ids) {
			const e = this.getElementoHtml(id);

			if (!e) continue;

			const $e = $(e);
			const tagName = e.tagName.toLowerCase();
			const tipo = $e.attr("tipo");

			if (["select", "textarea", "input"].includes(tagName) || ["EDITOR_HTML", "EXIBICAO"].includes(tipo)) {
				const pai = $e.closest("div.form-group");
				if (pai.length > 0) {
					elementos.push(pai[0]);
				}
			} else {
				elementos.push(e);
			}
		}

		if (elementos.length === 0) return;

		if (isEsconder) {
			$(elementos).slideUp(duracaoMilis);
		} else {
			$(elementos).slideDown(duracaoMilis);
		}

		await $(elementos).promise();
	}

	exibirAudio(cfgs) {

		const path = cfgs.path;
		const html = ["<div class='exibicaoMidia' "];

		if (cfgs.css != null) {
			html.push(" style='" + cfgs.css + "' ");
		}

		html.push(">");
		html.push("<audio controls src='" + path + "'>");
		html.push("<source src='" + path + "' autoplay='false' />");
		html.push(this.getMsg("MSG_VH_A_08") + " </audio>");

		html.push("</div>");

		if (cfgs.retornarHtml) {
			return html.join("");
		} else {
			this.append(html.join(""));
		}
	}

	exibirVideo(cfgs) {

		let path = cfgs.path;
		let html = ["<div class='exibicaoMidia' "];

		if (cfgs.css != null) {
			html.push(" style='" + cfgs.css + "' ");
		}

		html.push(">");
		html.push("<video controls width='500' src='" + path + "'>");
		html.push("<source src='" + path + "' autoplay='false' />");
		html.push(this.getMsg("MSG_VH_A_08") + " </video>");

		html.push("</div>");

		if (cfgs.retornarHtml) {
			return html.join("");
		} else {
			this.append(html.join(""));
		}
	}

	exibirAudioQuestao(cfgs) {
		return this.exibirAudioOuVideoQuestao(cfgs, 'audio');
	}

	exibirVideoQuestao(cfgs) {
		return this.exibirAudioOuVideoQuestao(cfgs, 'video');
	}

	exibirAudioOuVideoQuestao(cfgs, type) {
		let path = cfgs.path;
		let controlsList = "";
		let contextMenu = "";
		let style = "width: 100%;";
		let html = [];
		let midia = "";

		if (cfgs.css != null) {
			style = cfgs.css;
		}

		if (cfgs.isPermitirDownload === false) {
			controlsList += "nodownload";
			contextMenu = "return false;"
		}

		if (type === "audio") {
			midia = `
				<audio controls controlsList="${controlsList}" style='max-height: 19rem; width: 100%;' src='${path}'>
					<source src='${path}' autoplay='false' />
					${this.getMsg("MSG_VH_A_08")}
				</audio>
			`;
		} else if (type === "video") {
			midia = `
				<video controls controlsList="${controlsList}" style='max-height: 19rem; width: 100%;' src='${path}'>
					<source src='${path}' autoplay='false' />
					${this.getMsg("MSG_VH_A_08")}
				</video>
			`;
		}

		html.push(`
			<div style='${style}' oncontextmenu="${contextMenu}">
				${midia}
			</div>
		`);

		if (cfgs.retornarHtml) {
			return html.join("");
		} else {
			this.append(html.join(""));
		}
	}

	stopAudioAndVideos() {
		try {
			$('audio, video').not(
				'#video_preview_proctoring, #video_proctoring, #video_display_proctoring, #video_cam_proctoring'
			).each(function () {
				$(this)[0].pause();
			});
		} catch (e) {
			this.logger.error("Erro no stopAudioAndVideos", e);
		}
	}

	getCfg(idCfg, valorPadrao?) {
		const v = UtilCfg.getCfg(idCfg);

		if (v == null) return valorPadrao;

		return v;
	}

	getMsg(idMsg, ...params): string {
		return UtilMsg.getMsg(idMsg, ...params)
	}

	isCfgHabilitada(idCfg) {
		const v = UtilCfg.getCfg(idCfg);
		return v != null && (v === '1' || v === 'true' || v === true);
	}

	isCfgDesabilitada(idCfg) {
		return !this.isCfgHabilitada(idCfg);
	}

	addFormulario(cfgs: any = {}) {

		const classe = cfgs.classe || "";
		const css = cfgs.css || "";
		const id = cfgs.id || this.gerarId();
		const action = cfgs.acao ? ' action="' + cfgs.acao + '"' : "";
		const method = cfgs.metodo != null ? " method='" + cfgs.metodo + "' " : "";
		const autocomplete = cfgs.autocomplete != null && cfgs.autocomplete === false ? " autocomplete='off'" : "";
		const h = `
			<form role="form" class="col-md-12 ${classe}" style="${css}" id="${id}" ${action} ${method} ${autocomplete}>
				<div class="row">
		`;

		this.jaAdicionouForm = true;

		this.appendJs(() => this.listenToEnter("#" + id, cfgs.onEnter));

		if (cfgs.retornarHtml === true) {
			return h;
		} else {
			this.append(h);
		}
	}

	fecharFormulario(cfgs: any = {}) {
		if (this.jaAdicionouForm === true) {
			if (cfgs.retornarHtml) {
				return "</div></form>";
			} else {
				this.append("</div></form>");
			}
			this.jaAdicionouForm = null;
		}
	}

	mostrarMsgAjax(msgAjax: string, tempoExibicao?: number) {
		AmaisVH.mostrarMsgAjax(msgAjax, tempoExibicao)
	}

	getNextZIndex() {
		return AmaisVH.getNextZIndex()
	}

	getElementoHtml(seletor: any): any {

		if (this.isEmpty(seletor)) return null;

		if (this.hasValue(seletor.nodeType)) {
			const elemento = seletor;
			return elemento;
		}

		try {
			const e = document.getElementById(seletor);
			if (e) return e;
		} catch (ignored) {}

		return $(seletor).get(0);
	}

	getElementosHtml(idOuElementoHtml) {

		const es = [];

		if (idOuElementoHtml.style != null) {
			es.push(idOuElementoHtml);

		} else if (idOuElementoHtml.replace != null) {
			const e = document.getElementById(idOuElementoHtml);
			if (e) es.push(e);
		}

		if (es.length == 0) {
			const $e = $(idOuElementoHtml);
			for (let i = 0; i < $e.length; i++) {
				es.push($e.get(i));
			}
		}

		return es;
	}

	remove(id) {
		const elemento = this.getElementoHtml(id)
		$("#" + elemento.id).remove()
	}

	isShown(objeto) {

		const elementoHtml = this.getElementoHtml(objeto)

		if (elementoHtml != null) {
			return (elementoHtml.style.display != "none")
		} else {
			return false
		}
	}

	async showHide(objeto) {
		if (this.isShown(objeto)) {
			await this.hide(objeto);
		} else {
			await this.show(objeto);
		}
	}

	enable(...seletores) {

		if (!seletores || seletores.length === 0) return;

		seletores = seletores.flatMap(seletor => seletor);

		for (let seletor of seletores) {
			const e = this.getElementoHtml(seletor);
			if (!e) continue;
			$(e).removeAttr("disabled").find("input").removeAttr("disabled");
		}
	}

	disable(...seletores) {

		if (!seletores || seletores.length === 0) return;

		seletores = seletores.flatMap(seletor => seletor);

		for (let seletor of seletores) {
			const e = this.getElementoHtml(seletor);
			if (!e) continue;
			const $e = $(e);
			$e.attr("disabled", "disabled").find("input").attr("disabled", "disabled");

			if ($e.closest(".form-group").is("[is-campo-texto-com-checkbox-ativacao]")) {
				$e.closest(".form-group").find("input[type='checkbox']").removeAttr("checked").get(0).checked = false;
			}
		}
	}

	isEnabled(objeto) {

		const elementoHtml = this.getElementoHtml(objeto)

		if (elementoHtml != null) {
			return !elementoHtml.disabled
		}
	}

	isMarcado(objeto) {

		let elementoHtml = this.getElementoHtml(objeto);

		if (elementoHtml == null) return null;
		if (elementoHtml.tagName.toLowerCase() != "input") return null;

		if (elementoHtml.type.toLowerCase() == "text") {
			elementoHtml = $(elementoHtml).closest(".form-group").find("input[type='checkbox']").get(0);
		}

		return elementoHtml.checked;
	}

	getValor(objeto: any, converterParaNumero?: boolean, ignorarSelecaoTable?: boolean) {

		const elementoHtml = this.getElementoHtml(objeto);

		if (!elementoHtml) {
			return null;
		}

		const $elementoHtml = $(elementoHtml);
		let valor: any = null;

		if (elementoHtml.tagName.toLowerCase() == "table") {
			valor = [];
			if ($elementoHtml.find("td:first-child :checkbox").length > 0 && !ignorarSelecaoTable) {
				$elementoHtml.find("td:first-child :checkbox:checked").each((i, checkbox) => valor.push(checkbox.value));
			} else {
				$elementoHtml.find("tbody tr[identificador]").each((i, tr) => valor.push($(tr).attr("identificador")));
			}

		} else if (elementoHtml.tagName.toLowerCase() == "input" && elementoHtml.type.toLowerCase() == "radio") {
			// valor = this.getValorRadio(elementoHtml.id)
			const radios = document.getElementsByTagName("input")

			for (let i = 0; i < radios.length; i++) {
				const r = radios[i]
				if (r.type == "radio" && r.checked && r.id == elementoHtml.id) {
					valor = r.value;
				}
			}

		} else if (elementoHtml.tagName.toLowerCase() == "input" && elementoHtml.type.toLowerCase() == "checkbox") {
			valor = elementoHtml.checked ? true : false;

		} else if ($elementoHtml.hasClass("dropzone")) {
			valor = $elementoHtml.data("uploadTO");

		} else if ($elementoHtml.hasClass("select2")) {
			valor = $elementoHtml.val();

		} else if ($elementoHtml.hasClass("botao-selecao")) {
			valor = $elementoHtml.attr("botao-selecao-valor");

		} else {
			valor = elementoHtml.value;

			if ($elementoHtml.attr("multiplo") == "true") {
				valor = [valor];
			}
		}

		const tipo = $elementoHtml.attr("tipo");

		if (tipo == "MOEDA") {

			let f = $elementoHtml.unmask();

			if (f != null && f.substring == null) {
				f = f.val();
			}

			if (this.hasValue(f)) {
				return new Number(f.substring(0, f.length - 2) + "." + f.substring(f.length - 2));
			} else {
				return null;
			}

		} else if (tipo == "DATA" || tipo == "DATAHORA") {

			if (this.hasValue(valor)) {

				try {
					$elementoHtml.closest(".form-group").removeClass("has-error");
					$elementoHtml.next().tooltip("destroy");

					if (tipo == "DATAHORA") {
						const hora = $elementoHtml.next().val();

						if (this.isEmpty(hora)) {
							throw "hora";

						} else {
							return UtilData.usuarioDataToDate(valor + " " + hora);
						}

					} else if ($elementoHtml.is("[fp-periodo-inicio]")) {
						return UtilData.usuarioDataToDate(valor + " 00:00:00");

					} else if ($elementoHtml.is("[fp-periodo-fim]")) {
						return UtilData.usuarioDataToDate(valor + " 23:59:59.999");
					}

					return UtilData.usuarioDataToDate(valor);

				} catch (e) {
					$elementoHtml.focus().closest(".form-group").addClass("has-error").tooltip({ title: this.getMsg("FP_FRONT_AmaisVH_039") }).tooltip("show");
					this.logger.error("valores informados inválidos", e);
					throw "valores informados inválidos";
				}

			} else {
				return null;
			}

		} else if (tipo == "TEMPO") {

			this.removerMarcacaoDeErroDeInput(elementoHtml);

			if (this.hasValue(valor)) {

				try {
					const min = Number(valor.substr(0, 2));
					const segundos = Number(valor.substr(3));

					if (segundos >= 60) {
						throw "erro segundos maior que sessenta";
					}

					return min * 60 + segundos;

				} catch (e) {
					$elementoHtml.focus().closest(".form-group").addClass("has-error").tooltip({ title: "Valor inválido" }).tooltip("show");
					this.logger.error("valores informados inválidos", e);
					throw "valores informados inválidos";
				}

			} else {
				return null;
			}


		} else if (tipo == "EDITOR_HTML") {

			try {
				const CKEDITOR = UtilBoot.getCKEditor();

				if (CKEDITOR.instances[elementoHtml.id]) {
					return CKEDITOR.instances[elementoHtml.id].getData();
				} else {
					return $elementoHtml.html();	
				}

			} catch (e) {
				// CKEDITOR não carregado ou o inline não tenha sido ativado
				this.logger.error("CKEDITOR não carregado ou o inline não ativado", e);
				return $elementoHtml.html();
			}


		} else if (tipo == "NUMERO") {

			this.removerMarcacaoDeErroDeInput(elementoHtml);

			if (this.hasValue(valor)) {
				if (valor.indexOf(',') != -1) {
					valor = valor.replace(',', '.');
				}

				if (isNaN(valor)) {
					this.exibirAlerta({ 
						msg: this.getMsg("FP_FRONT_AmaisVH_040"), 
					}).then(() => {
						this.marcarInputComErro(elementoHtml);
						$elementoHtml.focus();
					});
					throw ("campo " + elementoHtml.id + " com valor inválido não numérico");
				}

				return (new Number(valor)).valueOf();

			} else {
				return null;
			}

		} else if (tipo === "MASCARA") {

			let v: any = $elementoHtml.val();

			if (v !== null && !v.substring) {
				v = v.val();
			}

			if (this.hasValue(v)) {
				return v;
			} else {
				return null;
			}

		} else if (tipo === "CPF" || tipo === "CEP" || tipo === "CNPJ") {
			
			if (this.isEmpty(valor)) return null;

			return valor.replaceAll(/[^\d]/g, "");

		} else if (tipo == "GRUPO_CHECKBOX") {
			const v = [];

			$elementoHtml.find(":checkbox:checked").each((i, checkbox) => {
				v.push(checkbox.id);
			});

			return v;

		} else if (valor != null && valor.push != null) {

			if (converterParaNumero == true) {
				for (let i = 0; i < valor.length; i++) {
					if (!isNaN(valor[i])) valor[i] = new Number(valor[i]);
				}
			}

			return valor;

		} else if (this.isEmpty(valor)) {
			return null;

		} else {
			return valor;
		}
	}

	setValor(seletores, valor): void {

		if (!seletores || seletores.length === 0) return;

		if (!Array.isArray(seletores)) seletores = [seletores];

		seletores = seletores.flatMap(seletor => seletor);

		for (const seletor of seletores) {

			const elementoHtml = this.getElementoHtml(seletor);

			if (!elementoHtml) continue;

			valor = valor ?? "";

			const $elementoHtml = $(elementoHtml);
			const tipo = $elementoHtml.attr("tipo");

			if (tipo === "DATAHORA" && valor.getTime) {
				$elementoHtml.val(UtilData.toDDMMYYYY(valor)).next().val(UtilData.toHHMM(valor));

			} else if (tipo === "TEMPO") {
				$elementoHtml.val(UtilData.segundosToHHMM(valor));

			} else if (tipo === "EXIBICAO") {
				$elementoHtml.html(valor);

			} else if (tipo === "GRUPO_CHECKBOX") {
				valor = valor || [];

				$elementoHtml.find("input:checkbox").each((i, checkbox) => {
					const $checkbox = $(checkbox);

					if (valor.includes(checkbox.id)) {
						$checkbox.prop("checked", true);
					} else {
						$checkbox.prop("checked", false);
					}
				});

			} else if (tipo === "EDITOR_HTML") {

				try {
					const CKEDITOR = UtilBoot.getCKEditor();
					CKEDITOR.instances[elementoHtml.id].setData(valor);
				} catch (e) {
					$elementoHtml.html(valor);
				}

			} else if (elementoHtml.type === "checkbox") {
				elementoHtml.checked = valor;

			} else if (elementoHtml.type === "radio") {
				const $formGroup = $elementoHtml.closest(".form-group");
				$formGroup.find("input").removeAttr("checked");
				$formGroup.find("input[value='" + valor + "']").prop("checked", true);

			} else if ($elementoHtml.hasClass("select2")) {
				$elementoHtml.val(valor).trigger("change");

			} else if ($elementoHtml.hasClass("dropzone")) {
				if (this.isEmpty(valor)) {
					$elementoHtml.removeData("uploadTO");
					const Dropzone = UtilBoot.getDropZone();
					Dropzone.forElement("#" + elementoHtml.id).removeAllFiles(true);
				}
			} else {
				elementoHtml.value = valor
			}
		}
	}

	updateSelect(cfgs: CfgsUpdateSelect) {
		UtilSelect.update(cfgs);
	}

	setHtml(idElemento, texto) {
		let elementoHtml = this.getElementoHtml(idElemento);

		if (elementoHtml != null) {
			elementoHtml.innerHTML = texto;
		}
	}

	setTexto(idElemento, html) {
		this.setHtml(idElemento, html);
	}

	listenToEnter(seletor, onEnter) {
		if (seletor && onEnter) {
			$(seletor).on("keypress", async (event) => {
				try {
					if (this.isTeclaPressionada(AmaisVH.TECLA_ENTER, event)) {
						event.preventDefault();
						event.stopPropagation();
						await onEnter(event);
					}
				} catch (e) {
					this.logger.error(e);
				}
			});
		}
	}

	listenToClick(seletor, onClick) {
		if (onClick) {
			
			$(seletor).on("click", async (event) => {

				let prosseguir = true;
	
				try {
					const event: any = new Event("antes-click");
					await document.querySelector(seletor).dispatchEvent(event);
					prosseguir = event.resultado;
				} catch (ignored) {}

				if (prosseguir === false) return;

				try {
					await onClick(event);
				} catch (e) {
					this.logger.error(e);
				}
			});
		}
	}

	listenToChange(seletor, onChange) {
		if (onChange) {
			
			$(seletor).on("change", async (event) => {

				try {
					if (event.target.getAttribute("fp-on-change-desabilitado")) { // fp-custom
						this.logger.warn("on change desabilitado para ", event.target);
						return;
					}
				} catch (ignored) {}

				try {
					await onChange(event);
				} catch (e) {
					this.logger.error(e);
				}
			});
		}
	}

	isTeclaPressionada(codigoTecla, event) {
		if (codigoTecla == null || !event) {
			return false;
		}
		if (codigoTecla.push) {
			return codigoTecla.includes(event.which);
		} else {
			return event.which == codigoTecla;
		}
	}

	hasValue(obj: any) {
		return UtilString.hasValue(obj);
	}

	sleep(ms: number) {
		return new Promise(resolve => setTimeout(resolve, ms));
	}

	isEmpty(obj: any) {
		return UtilString.isEmpty(obj);
	}
	
	isEmptyObject(obj) {
		for (let prop in obj) {
			if (Object.prototype.hasOwnProperty.call(obj, prop)) {
				return false;
			}
		}
		return JSON.stringify(obj) === JSON.stringify({});
	}

	hasValor(objOuIdObj) {
		const v = this.getValor(objOuIdObj);
		return this.hasValue(v);
	}

	getTextoSelect(id: string): string | string[] {
		return UtilSelect.getTextoSelect(id);
	}

	getTOsSelecionadosSelect(id: string) {
		return UtilSelect.getTOsSelecionadosSelect(id);
	}

	validarCamposObrigatorios(...args) {

		let primeiroCampo = null;
		let idsCampos = args;

		if (args != null && args[0].push != null) {
			// foi passado um array como parâmetro
			idsCampos = args[0];
		}

		let campos = [];

		for (var i = 0; i < idsCampos.length; i++) {
			campos = campos.concat(this.getElementosHtml(idsCampos[i]));
		}

		for (var i = 0; i < campos.length; i++) {

			const campo = campos[i];
			this.removerMarcacaoDeErroDeInput(campo);

			if (!this.hasValor(campo) && !UtilSelect.isSelectComOpcaoDesabilitadaSelecionada(campo)) {

				this.marcarInputComErro(campo);

				if (primeiroCampo == null) {
					primeiroCampo = campo;
				}
			}
		}

		if (primeiroCampo != null) {
			this.marcarInputComErro(primeiroCampo);
			this.exibirAlerta({ 
				msg: this.getMsg("MSG_VH_A_09")
			}).then(() => {
				primeiroCampo.focus();
			});
			return false;
		}

		return true;
	}

	marcarInputComErro(i) {
		$(i).closest(".form-group").addClass("has-error");
	}

	removerMarcacaoDeErroDeInput(i) {
		$(i).closest(".form-group").removeClass("has-error");
	}

	focar(idOuElemento) {
		let selecao = $(idOuElemento);

		if (selecao.length == 0) {
			selecao = $("#" + idOuElemento);
		}

		if (selecao.length == 0) {
			console.log("Erro: elemento '" + idOuElemento + "' não encontrado para dar foco.");
			return;
		}

		selecao.each(function () {
			if ($(this).is(":visible")) {
				$(this).attr("tabindex", -1).focus();
			} else {
				const elemento = this;
				setTimeout(function () {
					$(elemento).attr("tabindex", -1).focus();
				}, 500);
			}
		})
	}

	getHostname() {
		return document.location.hostname;
	}

	submit(idForm) {
		const form = this.getElementoHtml(idForm)

		if (form != null) {
			form.submit()
		}
	}

	ativarScriptsJQuery() {
		$("input[valorInicial]").blur((i, input) => {
			const $input = $(input);

			if (this.isEmpty($input.val())) {
				$input.val($input.attr("valorInicial"))
			}

		}).on("focus", ({ target }) => {
			const $input = $(target);

			if ($input.val() == $input.attr("valorInicial")) {
				$input.val("")
			}
		}).each((i, input) => {
			const $input = $(input)

			if (this.isEmpty($input.val())) {
				$input.val($input.attr("valorInicial"));
			}
		})
	}

	tocarSom(pathArquivoAudio) {
		const audioElement = document.createElement('audio');
		audioElement.setAttribute('src', pathArquivoAudio);
		audioElement.setAttribute('autoplay', 'autoplay');
		audioElement.play()
	}

	

	addImagem(cfgs) {

		cfgs.classe = cfgs.classe ?? "col-xs-12 col-sm-12 col-md-4 col-lg-3"
		cfgs.css = cfgs.css ?? ""

		const h = [`
			<div class="imgThumb ${cfgs.classe}" style="padding: 0px; ${cfgs.css}">
		`];

		if (cfgs.titulo) h.push(`
				<h3>${cfgs.titulo}</h3>
		`);

		h.push(`
				<img class="img-thumbnail img-responsive img-thumbnail-resizable" src="${cfgs.src}" alt="">
			</div>
		`);

		if (cfgs.retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	tornarObrigatorio(id: string, isObrigatorio: boolean = true) {
		const elemento = this.getElementoHtml(id);
		const label = $(elemento).closest(".form-group").find("label").first();
		let textoLabel = label.text().trim();

		if (isObrigatorio) {
			$(elemento).attr("obrigatorio", "true");
			if (!textoLabel.endsWith("*")) {
				label.text(textoLabel + " *");
			}
		} else {
			$(elemento).removeAttr("obrigatorio");
			while (textoLabel.endsWith("*")) {
				textoLabel = textoLabel.substring(0, textoLabel.length - 1).trim();
				label.text(textoLabel);
			}
		}
	}

	verificarObrigatorios(elementoEvento?: any) {

		$("[data-fp-msg-erro-campo-obrigatorio]").remove();

		let primeiroCampo = null;
		let $seletor = null;

		if (elementoEvento) {
			$seletor = $(elementoEvento).closest(".modal-dialog");
		}

		if (!$seletor || !$seletor.length) {
			$seletor = $(".modal-dialog");
			if ($seletor.length == 0 || $seletor.is(":hidden")) {
				$seletor = $("#corpo");
			}
		}

		$seletor.find("input[obrigatorio], select[obrigatorio], textarea[obrigatorio], div[obrigatorio]").each((i, elemento) => {

			if ($(elemento).closest(".form-group").is(":hidden")) {
				return;
			}

			this.removerMarcacaoDeErroDeInput(elemento);

			if (!this.hasValor(elemento) && !UtilSelect.isSelectComOpcaoDesabilitadaSelecionada(elemento)) {
				this.marcarInputComErro(elemento);

				if (primeiroCampo == null) {
					primeiroCampo = elemento;
				}
			}
		});

		if (primeiroCampo != null) {
			this.exibirAlerta({ 
				msg: this.getMsg("MSG_VH_A_09")
			}).then(() => {
				primeiroCampo.focus();
			});
			throw new Error("Campos obrigatórios não informados");
		}
	}

	async addCanvas(cfgs) {

		const { LC } = await UtilBoot.carregarLiterallyCanvas();

		const extend = function (child, parent) {
			for (const key in parent) {
				if (parent[key]) child[key] = parent[key];
			}

			function ctor() {
				this.constructor = child;
			}

			ctor.prototype = parent.prototype;
			child.prototype = new ctor();
			child.__super__ = parent.prototype;
			return child;
		}


		const MarcaTexto = function (lc) {
			const self = this;

			return {
				name: UtilMsg.getMsg("FP_FRONT_AmaisVH_042"),
				strokeWidth: lc.opts.defaultStrokeWidth,
				optionsStyle: 'stroke-width',
				classeFA: 'minus marcatexto',

				didBecomeActive: function (lc) {
					const onPointerDown = function (pt) {
						self.currentShape = LC.createShape('Line', {
							x1: pt.x, y1: pt.y, x2: pt.x, y2: pt.y,
							strokeWidth: 32, color: "rgba(255, 255, 0, 0.45)"
						});
						lc.setShapesInProgress([self.currentShape]);
						lc.repaintLayer('main');
					};

					const onPointerDrag = function (pt) {
						self.currentShape.x2 = pt.x;
						self.currentShape.y2 = pt.y;
						lc.setShapesInProgress([self.currentShape]);
						lc.repaintLayer('main');
					};

					const onPointerUp = function (pt) {
						self.currentShape.x2 = pt.x;
						self.currentShape.y2 = pt.y;
						lc.setShapesInProgress([]);
						lc.saveShape(self.currentShape);
					};

					const onPointerMove = function (pt) {
					};

					self.unsubscribeFuncs = [
						lc.on('lc-pointerdown', onPointerDown),
						lc.on('lc-pointerdrag', onPointerDrag),
						lc.on('lc-pointerup', onPointerUp),
						lc.on('lc-pointermove', onPointerMove)
					];
					$(".lc-options.horz-toolbar").hide();
				},

				willBecomeInactive: function (lc) {
					self.unsubscribeFuncs.map(function (f) { f() });
					$(".lc-options.horz-toolbar").show();
				}
			}
		};

		const Seta = function (lc) {
			const self = this;

			return {
				usesSimpleAPI: false,
				name: UtilMsg.getMsg("FP_FRONT_AmaisVH_043"),
				strokeWidth: lc.opts.defaultStrokeWidth,
				optionsStyle: 'stroke-width',
				classeFA: 'long-arrow-right',
				didBecomeActive: function (lc) {
					const onPointerDown = function (pt) {
						self.currentShape = LC.createShape('Line', {
							x1: pt.x, y1: pt.y, x2: pt.x, y2: pt.y, strokeWidth: 4,
							endCapShapes: [null, 'arrow']
						});
						lc.setShapesInProgress([self.currentShape]);
						lc.repaintLayer('main');
					};

					const onPointerDrag = function (pt) {
						self.currentShape.x2 = pt.x;
						self.currentShape.y2 = pt.y;
						lc.setShapesInProgress([self.currentShape]);
						lc.repaintLayer('main');
					};

					const onPointerUp = function (pt) {
						self.currentShape.x2 = pt.x;
						self.currentShape.y2 = pt.y;
						lc.setShapesInProgress([]);
						lc.saveShape(self.currentShape);
					};

					const onPointerMove = function (pt) {
					};

					// lc.on() returns a function that unsubscribes us. capture
					// it.
					self.unsubscribeFuncs = [
						lc.on('lc-pointerdown', onPointerDown),
						lc.on('lc-pointerdrag', onPointerDrag),
						lc.on('lc-pointerup', onPointerUp),
						lc.on('lc-pointermove', onPointerMove)
					];

					$(".lc-options.horz-toolbar").hide();
				},
				willBecomeInactive: function (lc) {
					// call all the unsubscribe functions
					self.unsubscribeFuncs.map(function (f) { f() });
					$(".lc-options.horz-toolbar").show();
				}
			}
		};

		let RotacionarBG = null;
		let DescartarImagem = null;

		if (cfgs.onRotacionar) {
			RotacionarBG = (lc) => {
				return {
					usesSimpleAPI: false,
					name: UtilMsg.getMsg("FP_FRONT_AmaisVH_044"),
					classeFA: 'repeat',
					didBecomeActive: function (lc) {
						cfgs.onRotacionar(lc)
					},
					willBecomeInactive: function (lc) {
					}
				}
			};
		}

		if (cfgs.onDescartarImagem) {
			DescartarImagem = (lc) => {
				return {
					usesSimpleAPI: false,
					name: UtilMsg.getMsg("FP_FRONT_AmaisVH_045"),
					classeFA: 'trash',
					didBecomeActive: function (lc) {
						cfgs.onDescartarImagem(lc);
					},
					willBecomeInactive: function (lc) {
					}
				}
			};
		}

		const img = new Image();
		let lc = null;

		img.onerror = () => {
			if (cfgs.onError) cfgs.onError.call();
		}

		img.onload = () => {

			const wTotal = 1200;
			const wImg = wTotal - 300;
			const escala = (wImg / img.width);
			let heightDivExterna = (100 + Math.round(img.height * escala));

			if (heightDivExterna < 703) {
				heightDivExterna = 703;
			}

			$("#" + cfgs.id).css("height", heightDivExterna + "px");
			$("#" + cfgs.id).css("width", wTotal + "px");

			const tools = [LC.tools.Pencil, LC.tools.Line, Seta, MarcaTexto, LC.tools.Rectangle, LC.tools.Ellipse, LC.tools.Text, LC.tools.Eraser, LC.tools.SelectShape];

			if (RotacionarBG) tools.push(RotacionarBG);
			if (DescartarImagem) tools.push(DescartarImagem);

			lc = LC.init(document.getElementById(cfgs.id), {
				imageURLPrefix: 'widgets/literallycanvas-0.4.14/img',
				imageSize: { width: wTotal - 60 },
				watermarkImage: img,
				watermarkScale: escala,
				secondaryColor: "transparent",
				tools: tools
			});

			lc.idComponente = cfgs.id;

			if (cfgs.snapshot != null) {
				lc.loadSnapshotJSON(cfgs.snapshot);
			}

			if (cfgs.onLoad) cfgs.onLoad.call(this, lc, cfgs);
		}

		img.src = cfgs.urlImagem;
	}

	addPills(cfgs: any) {

		let hashAtual = UtilHash.getHashAtual();

		if ($("#identificacaoConteudo > #fp-pills").length == 0) {
			$("#identificacaoConteudo").append(`<ul id='fp-pills' class='nav nav-pills pull-right col-md-12' ${cfgs.css ? `style="${cfgs.css}"` : ''}></ul>`);
		} else {
			$("#identificacaoConteudo > #fp-pills").html("");
		}

		let refPillAtiva = null;

		if (cfgs.subAbaAtiva) {
			refPillAtiva = this.getLetraSubAbaDaReferenciaAbaSubAba(cfgs.subAbaAtiva);
		}

		let primeiraLetra = null;
		let achouPill = false;

		cfgs.pills.forEach((pill, i) => {

			pill._letra = String.fromCharCode(65 + i);
			pill._habilitada = pill.habilitada !== false;

			if (pill._habilitada) {
				achouPill = achouPill || (pill._letra === refPillAtiva);
				primeiraLetra = primeiraLetra || pill._letra;
			}
		});

		refPillAtiva = achouPill ? refPillAtiva : primeiraLetra;

		$("#identificacaoConteudo > #fp-pills").attr("ref-pill-solicitada", refPillAtiva);

		let idAlvo = "fp-pills";

		cfgs.pills.forEach((pill, i) => {

			const classe = (refPillAtiva == pill._letra) ? ` class="active" ` : "";
			const style = (pill.habilitada == false) ? ` style="display: none" ` : "";
			const hashSubAba = UtilHash.getHashSubAba(hashAtual, pill._letra);
			const id = this.gerarId();

			this.append(idAlvo, `
				<li role="presentation" data-num-pill="${i}" ${classe} ${style}>
					<a id="${id}" href="#${hashSubAba}" data-pill-id="${cfgs.id}" data-toggle="tab" role="presentation">
						${pill.label}
					</a>
				</li>
			`);

			this.appendJs(() => {
				$("#" + id).data("onClick", pill.onClick);
				$("#" + id).on("click", (event) => {
					event.preventDefault();
					event.stopPropagation();
					if ($.address.value() == hashSubAba) {
						document.location.reload();
					} else {
						$.address.value(hashSubAba);
					}
				})
			})
		});

		$(document).on("container-alterado", () => {
			$("#fp-pills:not([fp-inicializado]) li.active a").each(async (i, a) => {
				$(a).closest("#fp-pills").attr("fp-inicializado", true);
				const onClick = $(a).data("onClick");
				try {
					await onClick();
				} catch (e) {
					this.logger.error(e);
				}
			})
		})
	}

	removerColunaDeTabela(tabela, indice) {
		$(tabela).find("tr > td:nth-child(" + (indice + 1) + "), tr > th:nth-child(" + (indice + 1) + ")").remove();
	}

	getValoresParams(cfgs, prefixoAtributos) {
		const valores = [];

		for (let i = 0; true; i++) {
			const nomeParam = prefixoAtributos + (i + 1);

			if (cfgs.hasOwnProperty(nomeParam)) {
				const valor = cfgs[nomeParam];

				if (typeof valor == 'function') {
					valores.push(valor.call(amaisVH));
				} else {
					valores.push(valor);
				}
			} else {
				break;
			}
		}

		return valores;
	}

	isElementInView(elemento) {
		const pageTop = $(window).scrollTop();
		const pageBottom = pageTop + $(window).height();
		const elementTop = $(elemento).offset().top;
		const elementBottom = elementTop + $(elemento).height();

		return ((elementTop <= pageBottom) && (elementBottom >= pageTop));
	}

	possuiSegmentos(collectionSegmentosTO) {
		return this.hasValue(collectionSegmentosTO) && (collectionSegmentosTO.length > 1 || (collectionSegmentosTO.length == 1 && collectionSegmentosTO[0].filhos != null));
	}

	addFerramenta(cfgs) {

		let $ferramentasPanel = $("#fp-ferramentas-panel");

		if ($ferramentasPanel.length == 0) {
			$("body").append("<div id='fp-ferramentas-panel' class='ferramentas-panel'></div>");
			$ferramentasPanel = $("#fp-ferramentas-panel");
		}

		let $ferramenta = $ferramentasPanel.find("#" + cfgs.id);

		if ($ferramenta.length == 0) {
			$ferramentasPanel.append("<div id='" + cfgs.id + "' class='ferramentas-item " + (cfgs.classe || "") + "'></div>");
			$ferramenta = $ferramentasPanel.find("#" + cfgs.id);
		}
		$ferramenta.html("");
		$ferramenta.append(cfgs.html);
	}

	isAndroidDesktopMode() {
		const webkitVer = parseInt(/WebKit\/([0-9]+)|$/.exec(navigator.appVersion)[1], 10); // also matches AppleWebKit
		const isGoogle = webkitVer && navigator.vendor.indexOf('Google') === 0;  // Also true for Opera Mobile and maybe others
		const isAndroid = isGoogle && navigator.userAgent.indexOf('Android') > 0;  // Careful - Firefox and Windows Mobile also have Android in user agent
		const androidDesktopMode = !isAndroid && isGoogle && (navigator.platform.indexOf('Linux a') === 0) && 'ontouchstart' in document.documentElement;
		return androidDesktopMode === true;
	}

	addBotaoScrollParaTopo() {
		// const heightEscondido = $("#divBody").height() - window.innerHeight;
		this.append("<div class='btn-scroll-para-topo'>");
		this.addBotao({
			label: " <i class='fa fa-chevron-up'></i> ",
			onClick: () => document.querySelector('body').scrollIntoView({ behavior: 'smooth' }),
		});
		this.append("</div>");
	}

	addOperacaoParaHash(idOperacao, metodoVH, ignorarHistorico = false) {
		UtilHash.addOperacao(this, idOperacao, metodoVH, ignorarHistorico);
	}

	findGetParameter(parameterName) {
		let result = null, tmp = [];
		location.search
			.substr(1)
			.split("&")
			.forEach(function (item) {
				tmp = item.split("=");
				if (tmp[0] === parameterName) result = decodeURIComponent(tmp[1]);
			});
		return result;
	}

	activeBtnGroupAction(targetId) {
		$(targetId).click(function () {
			$(this).find('.btn').toggleClass('active');
			if ($(this).find('.btn-primary').length > 0) {
				$(this).find('.btn').toggleClass('btn-primary');
			}
			if ($(this).find('.btn-danger').length > 0) {
				$(this).find('.btn').toggleClass('btn-danger');
			}
			if ($(this).find('.btn-success').length > 0) {
				$(this).find('.btn').toggleClass('btn-success');
			}
			if ($(this).find('.btn-info').length > 0) {
				$(this).find('.btn').toggleClass('btn-info');
			}
			if ($(this).find('.btn-primary-table').length > 0) {
				$(this).find('.btn').toggleClass('btn-primary-table');
			}
		});
	}

	updateOnlineStatus() {
		$.toast().reset('all');
		if (!navigator.onLine) {
			$.toast({
				heading: this.getMsg("FP_FRONT_InicioPFVH_011"),
				text: this.getMsg("FP_FRONT_InicioPFVH_012"),
				icon: 'error',
				position: 'top-center',
				hideAfter: false
			});
		}
		else {
			$.toast({
				heading: this.getMsg("FP_FRONT_InicioPFVH_013"),
				text: this.getMsg("FP_FRONT_InicioPFVH_014"),
				icon: 'success',
				position: 'top-center'
			});
		}
	}

	inicializarOnlineCheck() {
		window.addEventListener('online', () => this.updateOnlineStatus());
		window.addEventListener('offline', () => this.updateOnlineStatus());
	}

	async addSeletorIdioma() {

		if (this.isCfgHabilitada("PERMITIR_TROCA_IDIOMA_USUARIO")) {

			$("#rodape").append(await this.addSelect({
				collection: this.getCollectionLocales(),
				id: "usuario_locale_rodape",
				// css: "width: 200px",
				classe: "col-md-2",
				css: "margin-bottom: 0px; text-align: left;",
				valor: AmaisVH.localeDoUsuarioLogado,
				dica: this.getMsg("FP_FRONT_AmaisVH_054"),
				ignorarTagForm: true,
				retornarHtml: true,
				obrigatorio: true,
				onChange: async () => {
					let dadosUsuario = {
						codUsuario: this.getCodUsuarioLogado(),
						locale: this.getValor("usuario_locale_rodape")
					}
					await this.call("LoginFCD/alterarDadosBasicos", dadosUsuario);

					document.location.reload();
				}
			}));
		}
	}

	copiarParaAreaDeTransferencia(linkCopia, idConteudoParaCopia) {
		const e = document.querySelector(`[id-conteudo-para-copia="${idConteudoParaCopia}"]`);
		if (!e) return;
		let texto = e.textContent;
		if (!texto) return;
		texto = texto.trim();
		if (this.isEmpty(texto)) return;
		navigator.clipboard.writeText(texto.trim());
		$(linkCopia).find("i.fa-link").removeClass("fa-link").addClass("fa-check");
		setTimeout(() => {
			$(linkCopia).find("i.fa-check").removeClass("fa-check").addClass("fa-link");
		}, 3000);
	}

	async fadeOut(element: any, delay: number) {
		
		if (!element) return;

		return new Promise(resolve => {
			$(element).fadeOut(delay, resolve);
		});
	}

	async fadeIn(element: any, delay: number) {
		
		if (!element) return;

		return new Promise(resolve => {
			$(element).fadeIn(delay, resolve);
		});
	}

	static mostrarMsgAjax(msgAjax: string, tempoExibicao: number = 3) {

		const divMsgAjax = AmaisVH.criarDivMsgAjax(msgAjax);

		setTimeout(function () {
			divMsgAjax.fadeOut(() => {
				divMsgAjax.remove();
			});
		}, tempoExibicao * 1000)
	}

	static criarDivMsgAjax(msg: string, ativa: boolean = false, id?: string) {
		id = id || "_msg_ajax_" + amaisVH.gerarId();
		let $divMsgAjax = $("#" + id);

		if ($divMsgAjax.length == 0) {
			$("body").append(`
				<div id='${id}' class='progress progress-striped ${ativa ? " active" : ""}'>
					<div class='progress-bar' role='progressbar' style='width: 100%; color: #eee'>
						${msg}
					</div>
				</div>
			`);

			$divMsgAjax = $("#" + id);

			$divMsgAjax.css("position", "fixed")
				.css("box-shadow", "0px 1px 2px #666")
				.css("top", "72px")
				.css("zIndex", AmaisVH.getNextZIndex())
				.css("height", "auto")
				.css("background-color", "var(--color-verde)")
				.css("border-radius", "10px")
			$divMsgAjax.find(".progress-bar")
				.css("padding", "4px 8px 4px 8px")
				.css("height", "auto")
				.css("line-height", "28px")
				.css("font-size", "16px")
				.css("border-radius", "10px");
			$divMsgAjax.css("left", Math.max(0, (($(window).width() - $($divMsgAjax).outerWidth()) / 2) + $(window).scrollLeft()) + "px").show();
		}

		return $divMsgAjax;
	}

	static getNextZIndex() {
		return ++AmaisVH.zIndexCorrente
	}

	static addSecaoMenu(secaoMenu) {
		AmaisVH.secoesMenu.push(secaoMenu)
	}

	static onWindowFocus() {
		$("div.dropzone").removeData("ultimo-clique");
	}

	static prevenirDuploClique(event): boolean {

		if (!event?.target) return;

		if (event._isTratado || (event.originalEvent && event.originalEvent._isTratado)) {
			if (event._isBloqueado || (event.originalEvent && event.originalEvent._isBloqueado)) {
				if (event.originalEvent) {
					event.originalEvent._isBloqueado = true;
				}
				console.warn("Evitando duplicação de operação em duplo clique (evento já bloqueado)");
				return true;
			} else {
				return false;
			}
		}

		event._isTratado = true;
		if (event.originalEvent) event.originalEvent._isTratado = true;

		const target = event.target;

		if (target.tagName === "IMG" || target.classList.contains("skip-duploclick")) return false;
		if (target.closest('.bootstrap-datetimepicker-widget')) return false;
		
		const agora = Date.now();

		try {
			if (!target._millisUltimoClick) return false;
		
			let tempo = (agora - target._millisUltimoClick);
	
			if (tempo < 700) {
				event._isBloqueado = true;
				if (event.originalEvent) event.originalEvent._isBloqueado = true;
	
				console.warn("Evitando duplicação de operação em duplo clique feito em " + tempo + "ms");
				return true;
	
			} else {
				return false;
			}

		} finally {
			target._millisUltimoClick = agora;
		}
	}

	async handleLaunch() {

		try {
			if (!fpLauncherVH.isLaunched()) return;
		} catch (ignored) { }

		this.inicializarOnlineCheck();
		UtilAuth.inicializarVerificacaoLogoutOutraAba();
		await this.addSeletorIdioma();
		chatBotVH.inicializar();

		if (window.location.href === window.location.origin + "/") {
			if (this.getIsAcessoLinkExterno() && this.getEncerrarAposConcluirProva()) {
				history.back();
			}
		}

		// PREVENÇÃO DE DUPLO CLIQUE
		document.addEventListener("click", (event) => {
			try {
				if (AmaisVH.prevenirDuploClique(event) === true) {
					event.preventDefault();
					event.stopPropagation();
				}
			} catch (e) {
				console.error("Erro no tratamento de duplo clique", e);
				this.logger.error("Erro no tratamento de duplo clique", e);
			}
		}, true); // true faz com que o evento comece de cima para baixo no DOM (nos pais do target até o target)

		UtilBoot.inicializarSentry(this.getCodUsuarioLogado(), this.getCodEmpresaUsuarioLogado());

		if ($.tablesorter) {
			$.tablesorter.themes.bootstrap = {
				// these classes are added to the table. To see other table
				// classes available,
				// look here: http://getbootstrap.com/css/#tables
				table: '',
				caption: '',
				// header class names
				header: '', // give the header a gradient background
				// (theme.bootstrap_2.css)
				sortNone: '',
				sortAsc: '',
				sortDesc: '',
				active: '', // applied when column is sorted
				hover: '', // custom css required - a defined bootstrap
				// style may not override other classes
				// icon class names
				icons: 'fa', // add "icon-white" to make them white;
				// this icon class is added to the <i>
				// in the header
				iconSortNone: 'fa-sort', // class name added to icon when
				// column is not sorted
				iconSortAsc: 'fa-sort-asc', // class name added to icon when
				// column has ascending sort
				iconSortDesc: 'fa-sort-desc', // class name added to icon when
				// column has descending sort
				filterRow: '', // filter row class; use
				// widgetOptions.filter_cssFilter for the
				// input/select element
				footerRow: '',
				footerCells: '',
				even: '', // even row zebra striping
				odd: ''  // odd row zebra striping
			};
		}

		this.exibirMenu();

		if (this.isAluno()) {
			$("body,div,p,a,span,label,li").css("-moz-user-select", "none").css("-khtml-user-select", "none").css("-webkit-user-select", "none").css("user-select", "none");
		}

		$("body").on("click", "button", (event) => {
			event.preventDefault();
		});

		$.fn.modal.Constructor.prototype.enforceFocus = function () {
			var $modalElement = this.$element;
			$(document).on('focusin.modal', (event) => {
				var $parent = $(event.target.parentNode);
				if ($($parent).data("ja-tratou") == true) return;
				$($parent).data("ja-tratou", true);
				if ($modalElement[0] !== event.target && !$modalElement.has(event.target).length && !$parent.hasClass('cke_dialog_ui_input_select') && !$parent.hasClass('cke_dialog_ui_input_text')) {
					$modalElement.focus()
				}
			})
		};

		UtilSelect.inicializar();

		setTimeout(async () => {
			if (this.getTipoAcessibilidadeUsuario() === 'VISUAL') {
				await UtilBoot.carregarAccToolbar();
			}

			this.adicionarEventoRegistrarClickBotao();
		}, 150);

		setTimeout(async () => {
			if (window.location.href.indexOf("login") > -1) return;

			if(!UtilBoot.isProd()) return;

			if (this.isUsuarioAutenticado() && this.isAdministrador()) {
				let dateCreateAt = new Date().getTime();

				if (AmaisVH.dataCadastroUsuarioLogado) {
					dateCreateAt = new Date(AmaisVH.dataCadastroUsuarioLogado).getTime();
				}

				UtilBoot.carregarWootric(
					this.getEmailUsuarioLogado(),
					this.getNomeEmpresaUsuarioLogado(),
					dateCreateAt,
					UtilCfg.getCfg("NOME_SISTEMA_NPS")
				);
			}
		}, 1000);
	}

	adicionarEventoRegistrarClickBotao() {
		if (this.isAluno()) {
			$('a, button').off('click', this.registrarClickBotao).on('click', this.registrarClickBotao);
		}
	}

	static dispararAjusteAcessibilidade() {
		if(AmaisVH.tipoAcessibilidadeUsuario === 'VISUAL') {
			if (window['micAccessTool']) {
				window['micAccessTool'].initFontsChange()
			}
		}
	}

	addTemporizador(cfgs) {
		cfgs.id = cfgs.id || `timer_${this.gerarId()}`;
		const html = `
			<div id="${cfgs.id}">
				<div class="timer-progress">
					<div class="progress">
						<span class="progress-left">
							<span class="progress-bar"></span>
						</span>
						<span class="progress-right">
							<span class="progress-bar"></span>
						</span>
						<div class="progress-value">
							${cfgs.milisegundosRestantes ? Math.round(cfgs.milisegundosRestantes/1000) : ''}
						</div>
					</div>
				</div>
			</div>
		`;
		if (cfgs.retornarHtml) {
			return html;
		}

		this.append(html);

		setTimeout(() => {
			if (cfgs.milisegundosRestantes) {
				const crono: CronoTemporizadorVH = new CronoTemporizadorVH();
				cfgs.onFinalizar = cfgs.onFinalizar || (() => {});
				crono.iniciarCronometro(cfgs.milisegundosRestantes, $(`#${cfgs.id}`), cfgs.onFinalizar);
			}
		}, 300);
	}

	addDropdownSelecao(cfgs: CfgsAddDropdownSelecao) {
		let h = [`
			<div ${cfgs.html} class="btn-group botao-selecao" role="group" data-placement="right" data-container="body" title="${cfgs.dica || ''}">
				<a class="btn btn-default dropdown-toggle" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
					<span class="caret"></span>
				</a>
				<ul class="dropdown-menu">
		`];

		for (const o of cfgs.collection) {
			const valor = o[cfgs.propValor] || "";
			const nome = o[cfgs.propNome] || "";
			const descricao = o[cfgs.propDescricao] || "";
			h.push(`
					<li>
						<a botao-selecao-nome="${nome}" botao-selecao-valor="${valor}">
							${nome}
							<br>
							<small>${descricao}</small>
						</a>
					</li>
			`);
		}

		h.push(`
				</ul>
			</div>
		`);

		if (cfgs.retornarHtml) {
			return h.join("");
		} else {
			this.append(h.join(""));
		}
	}

	addBotaoBusca(idFiltro, cfgs?: {css?: string, classe?: string, retornarHtml?: boolean}) {
		return this.addBotao({
			css: cfgs?.css,
			label: `<i class='fa fa-search'></i> ${this.getMsg("FP_FRONT_AmaisVH_056")}`,
			retornarHtml: cfgs?.retornarHtml,
			onClick: async () => this.alternarExibicaoFiltros(idFiltro),
			classe: `${cfgs?.classe || ''}`,
		});
	}

	alternarExibicaoFiltros(id) {
		const $filtros = $(`#${id}`)
		if (!$filtros.is(":visible")) {
			$filtros.slideDown(300);
			return;
		}
		$filtros.slideUp(300);
	}
	
	async confirmar(msg: string): Promise<boolean> {
		return new Promise<boolean>(async (resolve) => {
			await UtilAlerta.exibirAlerta({
				msg,
				botoes: [{
					label: "Fechar",
					onClick: () => {
						resolve(false);
					}
				}, {
					label: "Confirmar",
					classe: "btn-primary",
					onClick: () => {
						resolve(true);
					}
				}],
				onHidden: () => {
					resolve(false);
				} 
			})
		});
	}
}

const amaisVH = new AmaisVH("AmaisVH");

$(document).ready(async () => await amaisVH.handleLaunch());
$(document).on("fp-launched", async () => await amaisVH.handleLaunch());

class CfgsFluxoTela {
	titulo: string;
	subtitulo: string;
	labelBotaoFinalizacao: string;
	classeBotaoFinalizacao: string;
	onClickBotaoFinalizacao: (resultadoTela: any) => void;
	isFluxoRecursos: boolean = false;
	registrarHistorico: boolean = false;
	botoesAposSalvar: Botao[]
}
class Botao {
	label: string;
	classe: string;
	onClick: Function;
}
class PaginacaoTO {
	numPaginaAtual: number = 0;
	numTotalItens: number = null;
	numItensPorPagina: number = null;
	numPaginas?: number = null;
}
interface BackendRequest {
	endpoint: string;
	params?: any[];
	msgCarregando?: string;
	desativarMsgCarregando?: boolean;
	blockUiOnCarregando?: boolean;
	onRequestError?(backendRequestError: BackendRequestError): Promise<boolean>;
}
class BackendRequestError extends Error {

	static ERRO_TRATADO = true;
	static ERRO_NAO_TRATADO = false;

	url: string = null;
	cfgsFetch: any = null;

	navigatorOnLine: boolean = null;
	possuiConexaoInternet: boolean = null;
	possuiConexaoBackend: boolean = null;
	response: Response = null;
	httpStatus: number;

	responseBody: any = null;
	idErro: string = null;
	msgErro: string = null;
	isSemAutenticacao: boolean = null;
	isSessaoWebFechada: boolean = null;
	isAcessoRestritoSafeBrowser: boolean = null;
	erroFetch: Error = null;

	constructor(message) {
		super(message);
		this.name = BackendRequestError.name;
	}

	isSessaoExpirada() {
		return this.idErro === "SessaoExpiradaNegocioException";
	}

	isProvaAnulada() {
		return this.idErro === "MSG_FCD_APR_04";
	}

	isAplicacaoEncerrada() {
		return this.idErro === "MSG_FCD_APR_03";
	}

	static ofResponse(url: string, cfgsFetch: any, responseBody: any, response: Response): BackendRequestError {
		const e = new BackendRequestError(responseBody?.msgErro || "Falha na chamada do backend");

		e.url = url;
		e.cfgsFetch = cfgsFetch;

		e.response = response;
		e.httpStatus = response.status;

		e.responseBody = responseBody;
		e.msgErro = responseBody?.msgErro;
		e.idErro = responseBody?.idErro;
		e.isSemAutenticacao = responseBody?.isSemAutenticacao;
		e.isSessaoWebFechada = responseBody?.isSessaoWebFechada;
		e.isAcessoRestritoSafeBrowser = responseBody?.isAcessoRestritoSafeBrowser;

		return e;
	}

	static ofFecthError(url: string, cfgsFetch: any, erroFetch: Error) {

		const e = new BackendRequestError(erroFetch.message || "Falha na chamada do backend");

		e.url = url;
		e.cfgsFetch = cfgsFetch;

		e.erroFetch = erroFetch;

		return e;
	}
}

type CfgsExibir = {
	isDeveFazerScrollParaTitulo?: boolean;
	isAffixIdentificacaoConteudo?: boolean;
	isDispararContainerAlterado?: boolean;
}

type CfgsAddTabela<T> = {
	collection: T[];
	id?: string;
	propId?: any;
	titulo?: string;
	selecao?: boolean | ((p: any) => boolean);
	colunas: ColunaAddTabela[];
	ordenacao?: any;
	salvarOrdenacao?: boolean;
	htmlAcoes?: any;
	htmlAcoesRodape?: string;
	numItensExibidos?: number;
	exibirTotalDeItens?: boolean;
	onCarregarPagina?: (paginacaoTO: PaginacaoTO) => Promise<any>;
	itensPorPagina?: number;
	paginaAtiva?: number;
	numTotalItensPaginacao?: number;
	alterarItensPorPagina?: boolean;
	classe?: string;
	bordered?: boolean;
	cssDiv?: string;
	propIsExcluivel?: any;
	colunasPrincipais?: any[][]
	exibirNumeracao?: boolean;
	labelNumeracao?: string;
	sufixoNumeracao?: string;
	onExclusao?: Function;
	desabilitarDownload?: boolean;
	ocultarOrdernacao?: boolean;
	infiniteScroll?: any;
	ordenar?: boolean;
	onEdicao?: any;
	onEdicaoParam1?: any;
	hashEdicao?: any;
	onNovo?: Function;
	labelNovo?: string;
	classeOnNovo?: string;
	msgListaVazia?: string;
	tituloTotalizacao?: string;
	totaisDasColunas?: any;
	totalizar?: any;
	cssTotalizacao?: string;
	download?: CfgsDownloadTabela,
	idSelecionado?: any;
	valoresSelecionados?: any[];
	paginacao?: CfgsPaginador;
	onOrdenacaoColuna?: (coluna: ColunaAddTabela, isOrdenacaoDecrescente: boolean) => Promise<void>;
	atibutoParaDesabilitarCheckbox?: string;
	selecionarCheckbox?: (item?: any) => boolean;
	onSelecionarCheckbox?: (item?: any, selecionado?: boolean) => void;
	isExcluivel?: (to) => boolean;

	// INTERNOS
	toLinhaPorCodigo?: any;
}
type CfgsPaginador = {
	classe?: string;
	css?: string;
	displayOpcao?: number;
	displayOpcaoInicioFim?: number;
	msgListaVazia?: string;
	paginacaoTO: PaginacaoTO;
	alterarItensPorPagina?: boolean;
	exibirPaginacaoRodape?: boolean;
	botoesAdicionais?: CfgsPaginadorBotao[];
	onCarregarPagina?: (paginacaoTO: PaginacaoTO) => Promise<any>;
	onPaginaCarregada?: (collection: any[]) => Promise<any>;
	// interna
	_idGrupo?: string;
}
type CfgsPaginadorBotao = {
	label: string;
	onClick: Function;
	dica?: string;
}
type CfgsDownloadTabela = {
	numItensPorPagina?: number;
	ordenarPelaPrimeiraColuna?: boolean;
	idTabela?: string;
	paginacao?: CfgsPaginador;
	idPaginador?: string;
}
type CfgsExibicaoCampoComUpload = {
	label: string;
	urlImg: string;
	classe?: string;
	onClickUpload: Function;
	onClickRemocao: Function;
}
type ColunaAddTabela = {
	titulo: string;
	prop: any;
	formato?: string;
	classe?: string;
	nomeProp?: string;
	isVisivel?: boolean;
	css?: string;
	regraExibicao?: (collection: any[]) => boolean
}
type ListaTO = {
	id: string | number;
	text: string;
	descricao?: string;
}
type CfgsAddDropdownSelecao = {
	collection: any[];
	propValor: string;
	propNome: string;
	propDescricao: string;
	dica: string;
	html: string;
	retornarHtml: boolean;
}
type CfgsAddSubtitulo = {
	texto: string;
	cssContainer?: string;
	id?: string;
	classeContainer?: string;
	visivel?: boolean;
	css?: string;
	textoSecundario?: string;
	htmlDireita?: string;
	retornarHtml?: boolean;
}
type CfgsAddTitulo = {
	texto: string;
	id?: string;
	textoADireita?: string;
	subtitulo?: string;
}
type CfgsAddAbas = {
	abas: CfgsAba[];
	numAbaAtiva?: string | number;
	id?: string;
	idAlvo?: string;
	classes?: string;
	noHref?: boolean;
}
type CfgsAba = {
	label: string;
	id?: string;
	onClick?: Function;
	habilitada?: boolean;	
}
type CfgsAddTexto = {
	id?: string;
	texto: string;
	idAlvo?: string;
	css?: string;
	classe?: string;
	isVisivel?: boolean;
	retornarHtml?: boolean;	
}
type CfgsAddCheckbox = {
	label: string;
	idComponente?: string;
	id?: string;
	classe?: string;
	css?: string;
	onChange?: Function;
	visivel?: boolean;
	valor?: boolean;
	habilitado?: boolean;
	obrigatorio?: boolean;
	ajuda?: string;
	ignorarFormulario?: boolean;
	retornarHtml?: boolean;	
}
type CfgsAddZonaUpload = {
	id?: string;
	label?: string;
	css?: string;
	classe?: string;
	obrigatorio?: boolean;
	privado?: boolean;
	width?: number;
	arquivosAceitos?: string;
	maxFilesize?: number;
	maxWidthOuHeight?: number;
	isBase64?: boolean;
	onSelecaoArquivosAberta?: Function;
	onDepoisEnvio?: Function;
	onErro?: Function;
	onProgressoEnvio?: Function;
	onAntesEnvio?: Function;
	onAntesAceitacao?: Function;
	converterPdfParaPng?: boolean;
	retornarHtml?: boolean;
}
type CfgsAddTextoAlerta = {
	id?: string;
	texto: string;
	classes?: string;
	css?: string;
	visivel?: boolean;
	ariaLabel?: string;
	retornarHtml?: boolean;	
}
type CfgsAddCampoTexto = {
	id?: string;
	checkboxAtivacao?: boolean;
	idComponente?: string;
	ignorarFormulario?: boolean;
	validacaoSenha?: boolean;
	textoAposCampo?: string;
	html?: string;
	isFloat?: boolean;
	casasDecimais?: number;
	maxLength?: string | number;
	habilitado?: boolean;
	onEnter?: Function;
	onChange?: Function;
	mascara?: string;
	attrs?: string;
	ariaLabel?: string;
	cssInput?: string;
	prefixo?: string;
	sufixo?: string;
	ajuda?: string;
	valor?: any;
	dicaComoValorInicial?: string;
	obrigatorio?: boolean;
	dica?: string;
	label?: any;
	css?: string;
	isVisivel?: boolean;
	visivel?: boolean;
	tipo?: string;
	classe?: string;
	removerDiv?: boolean;
	retornarHtml?: boolean;	
}
type CfgsAddBotao = {
	id?: string;
	href?: string;
	hash?: string;
	onClick?: Function;
	onClickParam1?: any;
	onClickParam2?: any;
	onClickParam3?: any;
	abrirNovaPagina?: boolean;
	tooltip?: string;
	dica?: string;
	obrigatorio?: boolean;
	visivel?: boolean;
	css?: string;
	classe?: string;
	habilitado?: boolean;
	ativo?: boolean;
	html?: string;
	ariaLabel?: string;
	tabIndex?: string;
	label?: string;
	texto?: string;
	amarrarCampos?: string[];
	idAlvo?: string;
	retornarHtml?: boolean;
}
type CfgsAddLink = {
	id?: any;
	label?: string;
	hash?: string;
	css?: string;
	visivel?: boolean;
	dica?: string;
	obrigatorio?: boolean;
	classe?: string;
	habilitado?: boolean;
	onClick?: Function;
	href?: string;
	html?: string;
	ariaLabel?: string;
	idAlvo?: string;
	retornarHtml?: boolean;
	_hash?: string; // interno	
}
type CfgsAddEditorHtml = {
	id?: string;
	ignorarForm?: boolean;
	width?: string;
	height?: string;
	classe?: string;
	css?: string;
	visivel?: boolean;
	label?: string;
	cssDoLabel?: string;
	ajuda?: string;
	cssEditorHtml?: string;
	habilitado?: boolean;
	onChange?: Function;
	html?: string;
	tipo?: string;
	toolbar?: string;
	valor?: any;
	obrigatorio?: boolean;
	dicaComoValorInicial?: string;
	retornarHtml?: boolean;
}
type CfgsAddTextArea = {
	cssTextareaWrapper?: string;
	autoExpandir?: boolean;
	maxLinhas?: number;
	valor?: string;
	ariaLabel?: string;
	linhas?: number;
	dica?: string;
	cssTextarea?: string;
	tipo?: string;
	habilitado?: boolean;
	bloquearCorretorOrtografico?: boolean;
	isCopyPasteCutDesabilitado?: boolean;
	isEnumerado?: boolean;
	botoes?: CfgsAddTextAreaBotao[];
	label?: string;
	obrigatorio?: boolean;
	cssDoLabel?: string;
	html?: string;
	css?: string;
	classe?: string;
	id?: string;
	retornarHtml?: boolean;	
}
type CfgsAddTextAreaBotao = {
	label: string;
	classe: string;
	onClick: Function;
	css?: string;
	dica: string;
	visivel: boolean;
	id?: string;
	habilitado?: boolean;
}
type CfgsAddPopup = {
	id?: string;
	titulo?: string;
	subtitulo?: string;
	css?: string;
	data?: { [key: string]: string };
	botoes?: CfgsAddPopupBotao[];
	onHide?: () => void;
	onHidden?: () => void;
	onShown?: () => void;
	fullWidth?: boolean;
	width?: string;
	minWidth?: string;
	idPopup?: string;
}
type CfgsAddPopupBotao = {
	id?: string; 
	label: string; 
	classe?: string; 
	css?: string; 
	visivel?: boolean; 
	habilitado?: boolean; 
	onClick?: (event: any) => Promise<boolean | void>; 
	/**
	 * interna
	 */
	_hash?: any;
	/**
	 * interna
	 */
	_idBotao?: string; 
}
type ItemListaTO = {
	id: any;
	descricao: string;
	ordenacao: number;
	numFilhos: number;
	idPai: number;
	filhos: ItemListaTO[];
}
type OpcaoListaTO = {
	id: any;
	nome: string;
	descricao: string;
	isSelecionado: boolean;
	isEditavel: boolean;
	disabled: boolean;
	filhos: OpcaoListaTO[];
}
type CfgsAddCampoExibicao = {
	id?: string;
	classe?: string;
	css?: string;
	retornarHtml?: boolean;
	isExibirLinkCopiaValor?: boolean;
	idAlvo?: string;
	label?: string;
	valor: any;
	html?: string;
	formato?: string;
}
type CfgsAddBotoes = {
	botoes: CfgsAddBotao[];
	classe?: string;
	retornarHtml?: boolean;
}
class UtilXlsx {

	static async getArrayComDadosDaPlanilha(file: Blob): Promise<any[]> {

		const { XLSX }:any = await UtilBoot.carregarXLSX();

		return new Promise((resolve) => {

			let reader = new FileReader();

			reader.onload = function(e) {
				let data = e.target.result;

				/* if binary string, read with type 'binary' */

				let workbook = XLSX.read(data, { type: 'binary' });
				let linhas = [];

				for (const name of workbook.SheetNames) {
					let worksheet = workbook.Sheets[name];

					if (worksheet["!ref"] == null) {
						continue;
					}

					let range = XLSX.utils.decode_range(worksheet["!ref"]);

					for (let row = range.s.r; row <= range.e.r; row++) {
						let linha = [];
						for (let col = range.s.c; col <= range.e.c; col++) {
							let refCelula = XLSX.utils.encode_cell({ c: col, r: row });
							let valorCelula = null;
							if (worksheet[refCelula]) {
								valorCelula = worksheet[refCelula].h || worksheet[refCelula].v;
							}

							linha.push(valorCelula);
						}
						linhas.push(linha)
					}
				}

				resolve(linhas);
			}

			reader.readAsBinaryString(file);
		});
	}

	static async getConteudoFile(file) {
		await UtilBoot.carregarXLSX();

		return new Promise((resolve) => {
			let reader = new FileReader();

			reader.onload = (e) => {
				resolve(e.target.result);
			};

			reader.readAsBinaryString(file);
		});
	}

	// ver https://www.npmjs.com/package/sheetjs-style para possibilidades
	// não é a lib que estamos usando mas está bem documentado ali
	static styleHeader = {
		font: {
			name: "Calibri",
			bold: true,
			sz: "10"
		},
		alignment: {
			wrapText: false,
			vertical: "center",
			horizontal: "center"
		}
	}
	static styleCelula = {
		font: {
			name: "Calibri",
			sz: "10"
		},
		alignment: {
			wrapText: false,
			vertical: "center",
			horizontal: "left"
		}
	}
	static styleCelulaWrapText = {
		font: {
			name: "Calibri",
			sz: "10"
		},
		alignment: {
			wrapText: true,
			vertical: "center",
			horizontal: "left"
		}
	}
	static styleCelulaNumero = {
		font: {
			name: "Calibri",
			sz: "10"
		},
		alignment: {
			wrapText: false,
			vertical: "center",
			horizontal: "center"
		}
	}

	static async criarXlsx(colunas, items: any, nomeArquivo: string) {

		const matriz = [];
		const linhas = [];
		matriz.push(linhas);

		for(let coluna of colunas){
			linhas.push({
				t: 's', s: UtilXlsx.styleHeader, v: String(coluna.nome)
			});
		}

		for (let item of items) {
			let linha = [];

			for(let coluna of colunas){
				let tipo = coluna.tipo && coluna.tipo === "numero" ? 'n': 's';
				let valor = item[coluna.identificador];
				let style = UtilXlsx.getEstiloCelula(null, tipo, valor);
				if (valor && tipo === 'n') {
					valor = UtilNumero.stringToFloat(valor);
				}
				linha.push(UtilXlsx.criarCelula(valor, tipo, style));
			}

			matriz.push(linha);
		}

		await UtilXlsx.exportFile(matriz, nomeArquivo);
	}

	private static formatNumber(valor) {

		if (valor != null) {
			valor = valor.replace('.', '').replace(',', '.').replace('%', '');
		}

		if (valor == null || valor.length == 0 || isNaN(valor)) {
			valor = null;

		} else {
			valor = Number(valor);
		}

		return valor;
	}

	static async fazerDownloadTabela(cfgs: CfgsDownloadTabela) {

		if (cfgs?.paginacao) {
			await this.fazerDownloadTodasPaginas(cfgs);
			return;
		}

		let $trs = $("#" + cfgs.idTabela).find('thead tr, tbody tr');

		if (cfgs.ordenarPelaPrimeiraColuna) {
			$trs = $trs.sort(function(tr1, tr2) {
				let c1 = $(tr1).find("td:first").text();
				let c2 = $(tr2).find("td:first").text();

				return c1.localeCompare(c2);
			});
		}

		const matriz = [];
		const $divTemp = $(`<div></div>`);

		$trs.each((i: number, tr: HTMLTableRowElement) => {

			const $tr = $(tr);

			if ($tr.attr("tipo") == "web") return;

			const linha = [];

			matriz.push(linha);

			$tr.find('th, td').each((j: number, td: HTMLTableCellElement) => {

				const $td = $(td);
				const tipo = $td.attr("tipo") || "s";

				if (tipo == "web") return;

				$divTemp.html($td.html());
				$divTemp.find("br").before(document.createTextNode("\n"));
				$divTemp.find("i[data-original-title]").each((k: number, i: HTMLElement) => {
					const $i = $(i);
					$i.before(document.createTextNode("(" + $i.attr("data-original-title") + ")"));
				});

				let valor: any = UtilString.trimLinhas($divTemp.text());

				if (tipo == "n") {

					valor = UtilXlsx.formatNumber(valor);
				}

				const estilo = UtilXlsx.getEstiloCelula($td, tipo, valor);

				linha.push(UtilXlsx.criarCelula(valor, tipo, estilo));

				const colspan = $td.attr("colspan");

				if (colspan != null) {
					for (let indice = 2; indice <= Number(colspan); indice++) {
						linha.push(UtilXlsx.criarCelula("", "s", estilo));
					}
				}
			});
		});

		UtilXlsx.removerColunasVazias(matriz);

		await UtilXlsx.exportFile(matriz, UtilXlsx.getTituloTela(cfgs));
	}

	private static async fazerDownloadTodasPaginas(cfgs: CfgsDownloadTabela) {

		cfgs.numItensPorPagina = cfgs.numItensPorPagina ?? 100;

		await amaisVH.exibirAlerta({
			titulo: amaisVH.getMsg('MSG_VH_R_14'),
			msg: '',
			classe: 'modal-overlay',
			blockUICallback: async ($modalMsg) => {

				const paginacaoAtual = {...cfgs.paginacao.paginacaoTO};

				cfgs.paginacao.paginacaoTO.numItensPorPagina = cfgs.numItensPorPagina;
				cfgs.paginacao.paginacaoTO.numPaginaAtual = 0;

				const numPaginas = Math.ceil(cfgs.paginacao.paginacaoTO.numTotalItens / cfgs.paginacao.paginacaoTO.numItensPorPagina);
				const matriz = [];

				await this.setarDadosTabelaPorPagina(cfgs, matriz, numPaginas, $modalMsg);
				await this.exportarMatrizEResetarPagina(cfgs, matriz, paginacaoAtual);

				setTimeout(() => {
					if ($('[modal]').length > 0) {
						$('body').addClass('modal-open');
					}
				}, 500);
			}
		})
	}
	
	private static async setarDadosTabelaPorPagina(cfgs: CfgsDownloadTabela, matriz, numPaginas, $modalMsg) {

		this.setTituloTabela(cfgs, matriz);

		for (let pagina = 0; pagina < numPaginas; pagina++) {

			cfgs.paginacao.paginacaoTO.numPaginaAtual = pagina;

			if ($modalMsg) {
				$modalMsg.text(amaisVH.getMsg('MSG_VH_L_102', pagina + 1, numPaginas))
			}

			let el = $(`#${cfgs.idPaginador}`).find(`a[num-pagina="${pagina}"]`);

			if (!el.length) {
				el = $(`#${cfgs.idPaginador}`).find('a');
			}

			this.setarCfgsPaginado(el, cfgs.numItensPorPagina, pagina);

			await UtilPaginacao.handleClickBotaoPagina(el);

			await new Promise(r => setTimeout(r, 1000));

			this.setDadosTabela(cfgs, matriz);
		}
	}

	private static setarCfgsPaginado(el, numItensPorPagina: number, numPaginaAtual: number) {

		let $elemento = $(el);
		let idPaginador;
		
		if (!$elemento.length) {
			$elemento = el.prevObject;
		}
		
		idPaginador = $elemento.closest(".paginador").attr("id");
		
		const cfgs = UtilPaginacao.getCfgs(idPaginador);
		
		if (cfgs?.paginacaoTO) {
			cfgs.paginacaoTO.numItensPorPagina = numItensPorPagina;
			cfgs.paginacaoTO.numPaginaAtual = numPaginaAtual;
			UtilPaginacao.CFGS_DOS_PAGINADORES[idPaginador] = cfgs;
		}
	}

	private static async exportarMatrizEResetarPagina(cfgs: CfgsDownloadTabela, matriz, paginacaoAtual) {

		UtilXlsx.removerColunasVazias(matriz);

		await UtilXlsx.exportFile(matriz, UtilXlsx.getTituloTela(cfgs));

		cfgs.paginacao.paginacaoTO.numItensPorPagina = paginacaoAtual.numItensPorPagina;
		cfgs.paginacao.paginacaoTO.numPaginaAtual = paginacaoAtual.numPaginaAtual;

		const el = $(`#${cfgs.idPaginador}`).find(`a[num-pagina="${paginacaoAtual.numPaginaAtual}"]`);

		this.setarCfgsPaginado(el, paginacaoAtual.numItensPorPagina, 0);

		await new Promise(resolve => setTimeout(resolve, 1000));

		if (el.length) {
			await UtilPaginacao.handleClickBotaoPagina(el);

		} else if (cfgs.paginacao.onCarregarPagina){
			await cfgs.paginacao.onCarregarPagina(paginacaoAtual);
		}
	}

	private static setDadosTabela(cfgs: CfgsDownloadTabela, matriz) {
		const filtro = ($td) => !$td.is("th");
		this.processarLinhasTabela(cfgs, matriz, filtro);
	}

	private static setTituloTabela(cfgs: CfgsDownloadTabela, matriz) {
		const filtro = ($td) => $td.is("th");
		this.processarLinhasTabela(cfgs, matriz, filtro);
	}

	private static processarLinhasTabela(cfgs: CfgsDownloadTabela, matriz, filtro) {

		let $trs = $("#" + cfgs.idTabela).find('thead tr, tbody tr');
		
		if (cfgs.ordenarPelaPrimeiraColuna) {
			$trs = $trs.sort(function(tr1, tr2) {
				let c1 = $(tr1).find("td:first").text();
				let c2 = $(tr2).find("td:first").text();
				return c1.localeCompare(c2);
			});
		}

		const $divTemp = $("<div></div>");

		$trs.each((i, tr) => {

			const $tr = $(tr);

			if ($tr.attr("tipo") == "web") return;

			const linha = [];

			$tr.find('th, td').each((j, td) => {

				const $td = $(td);

				if (filtro($td)) {
					this.setarDadosLinha($td, $divTemp, linha);
				}
			});

			if (linha.length > 0) {
				matriz.push(linha);
			}
		});
	}

	private static setarDadosLinha($td, $divTemp, linha) {

		const tipo = $td.attr("tipo") || "s";

		if (tipo == "web") return;

		$divTemp.html($td.html());
		$divTemp.find("br").before(document.createTextNode("\n"));
		$divTemp.find("i[data-original-title]").each((k: number, i: HTMLElement) => {
			const $i = $(i);
			$i.before(document.createTextNode("(" + $i.attr("data-original-title") + ")"));
		});

		let valor: any = UtilString.trimLinhas($divTemp.text());

		if (tipo == "n") {
			valor = UtilXlsx.formatNumber(valor);
		}

		const estilo = UtilXlsx.getEstiloCelula($td, tipo, valor);

		linha.push(UtilXlsx.criarCelula(valor, tipo, estilo));

		const colspan = $td.attr("colspan");

		if (colspan != null) {
			for (let indice = 2; indice <= Number(colspan); indice++) {
				linha.push(UtilXlsx.criarCelula("", "s", estilo));
			}
		}
	}

	private static async exportFile(matriz, nomeArquivo){

		const { XLSX, saveAs }: any = await UtilBoot.carregarXLSX();

		const planilha = {};

		matriz.forEach((linha, numLinha) => {
			linha.forEach((coluna, numColuna) => {
				const coords = { c: numColuna, r: numLinha };
				const ref = XLSX.utils.encode_cell(coords);
				planilha[ref] = coluna;
			})
		})

		planilha['!ref'] = XLSX.utils.encode_range({
			s: {
				c: 0,
				r: 0
			},
			e: {
				c: matriz[0].length - 1,
				r: matriz.length - 1
			}
		});

		const tituloPlanilha = nomeArquivo.length > 30 ? nomeArquivo.substring(0, 30) : nomeArquivo;

		let workbook = {
			SheetNames: [],
			Sheets: {}
		}

		workbook.SheetNames.push(tituloPlanilha);
		workbook.Sheets[tituloPlanilha] = planilha;

		const wopts = { bookType:'xlsx', bookSST:false, type:'binary' };
		const wbout = XLSX.write(workbook, wopts);

		function s2ab(s) {
			let buf = new ArrayBuffer(s.length);
			let view = new Uint8Array(buf);
			for (let i=0; i!=s.length; ++i) view[i] = s.charCodeAt(i) & 0xFF;
			return buf;
		}

		/* FileSaver.js */
		saveAs(new Blob([ s2ab(wbout) ], { type: "" }), nomeArquivo + ".xlsx");
	}

	private static getEstiloCelula($td: any, tipo: string, v: any) {

		if ($td?.is("th")) return UtilXlsx.styleHeader;
		if (tipo == "n") return UtilXlsx.styleCelulaNumero;

		let estilo = UtilXlsx.styleCelula;

		if (v?.substring && v.includes("\n")) {
			estilo = UtilXlsx.styleCelulaWrapText;
		}

		const textAlign = $td ? $td.css("text-align"): null;

		if (!textAlign || textAlign == "") return estilo;

		let estiloDerivado = estilo["_cache_" + textAlign];

		if (!estiloDerivado) {
			estiloDerivado = JSON.parse(JSON.stringify(estilo));
			estiloDerivado.alignment.horizontal = textAlign;
			estilo["_cache_" + textAlign] = estiloDerivado;
		}

		return estiloDerivado;
	}

	private static removerColunasVazias(matriz: any[][]) {

		const vazias = [];

		if (matriz?.length) {
			for (let col = 0; col < matriz[0].length; col++) {

				let colunaPossuiValor = false;

				for (let linha = 0; linha < matriz.length; linha++) {
					const celula = matriz[linha][col];
					if (celula == null || celula.v == null || celula.v == "") continue;
					colunaPossuiValor = true;
					break;
				}

				if (!colunaPossuiValor) {
					vazias.push(col);
				}
			}
		}


		for (let i = vazias.length - 1; i >= 0; i--) {
			const numColunaParaRemocao = vazias[i];
			matriz.forEach(linha => linha.splice(numColunaParaRemocao, 1));
		}
	}

	private static criarCelula(v, t, s): object {

		if (typeof v === "string") v = v.trim();

		if (v === null || v === undefined || v === "") {
			return { t };

		} else if (t == "s") {
			return { t, s, v: String(v)};

		} else {
			return { t, s, v };
		}
	}

	private static getTituloTela(cfgs: CfgsDownloadTabela) {

		const montarTitulo = (...textos) => {
			let titulo = null;
			for (const t of textos) {

				if (t == null || t.trim() == "") continue;

				if (titulo == null) {
					titulo = t;

				} else {
					titulo = titulo + " - " + t;
				}
			}
			return titulo;
		}

		let titulo = $("#" + cfgs.idTabela).closest(".modal").find(".modal-title").text();
		titulo = titulo || montarTitulo($("#titulo").text(), $("#subtitulo").text());
		titulo = titulo || "download tabela";
		titulo = titulo.replaceAll("*", "").replaceAll("/", "_").replaceAll(":", "_").replaceAll("\\", "_").replace(/&/g, "e");
		return titulo;
	}

	// Método chamado pelo addTabela
	static async exportarPlanilhaTabela(cfgs?: CfgsDownloadTabela) {

		if (cfgs?.paginacao) {
			await this.fazerDownloadTodasPaginasTabela(cfgs);

		} else {
			await this.fazerDownloadPaginaUnicaTabela(cfgs);
		}
	}

	private static async fazerDownloadPaginaUnicaTabela(cfgs?: CfgsDownloadTabela) {
		let $trs = $("#" + cfgs.idTabela).find('thead tr, tbody tr');

		if (cfgs.ordenarPelaPrimeiraColuna) {
			$trs = $trs.sort(function(tr1, tr2) {
				let c1 = $(tr1).find("td:first").text();
				let c2 = $(tr2).find("td:first").text();

				return c1.localeCompare(c2);
			});
		}

		const matriz = [];
		const $divTemp = $(`<div></div>`);

		$trs.each((i: number, tr: HTMLTableRowElement) => {

			const $tr = $(tr);

			if ($tr.attr("tipo") == "web") return;

			const linha = [];

			matriz.push(linha);

			$tr.find('th, td').each((j: number, td: HTMLTableCellElement) => {

				const $td = $(td);
				const tipo = $td.attr("tipo") || "s";

				if (tipo == "web") return;

				$divTemp.html($td.html());
				$divTemp.find("br").before(document.createTextNode("\n"));
				$divTemp.find("i[data-original-title]").each((k: number, i: HTMLElement) => {
					const $i = $(i);
					$i.before(document.createTextNode("(" + $i.attr("data-original-title") + ")"));
				});

				let valor: any = UtilString.trimLinhas($divTemp.text());

				if (tipo == "n") {

					valor = UtilXlsx.formatNumber(valor);
				}

				const estilo = UtilXlsx.getEstiloCelula($td, tipo, valor);

				linha.push(UtilXlsx.criarCelula(valor, tipo, estilo));

				const colspan = $td.attr("colspan");

				if (colspan != null) {
					for (let indice = 2; indice <= Number(colspan); indice++) {
						linha.push(UtilXlsx.criarCelula("", "s", estilo));
					}
				}
			});
		});

		UtilXlsx.removerColunasVazias(matriz);

		await UtilXlsx.exportFile(matriz, UtilXlsx.getTituloTela(cfgs));
	}

	private static async fazerDownloadTodasPaginasTabela(cfgs: CfgsDownloadTabela) {

		cfgs.numItensPorPagina = cfgs.numItensPorPagina || 100;

		await amaisVH.exibirAlerta({
			titulo: amaisVH.getMsg('MSG_VH_R_14'),
			msg: '',
			classe: 'modal-overlay',
			blockUICallback: async ($modalMsg) => {

				const paginacaoAtual = { ...cfgs.paginacao.paginacaoTO };

				cfgs.paginacao.paginacaoTO.numItensPorPagina = cfgs.numItensPorPagina;
				cfgs.paginacao.paginacaoTO.numPaginaAtual = 0;

				const numPaginas = Math.ceil(cfgs.paginacao.paginacaoTO.numTotalItens / cfgs.paginacao.paginacaoTO.numItensPorPagina)
				const colunas: any[] = [];
				const tos: any[] = [];

				await this.setarDadosPlanilhaPorPagina(cfgs, numPaginas, $modalMsg, colunas, tos);
				await this.exportarPlanilhaEResetarPagina(cfgs, paginacaoAtual, colunas, tos);

				setTimeout(() => {
					if ($('[modal]').length > 0) {
						$('body').addClass('modal-open');
					}
				}, 500);
			}
		})
	}

	private static async setarDadosPlanilhaPorPagina(cfgs: CfgsDownloadTabela, numPaginas, $modalMsg, colunas, tos) {

		for (let pagina = 0; pagina < numPaginas; pagina++) {

			cfgs.paginacao.paginacaoTO.numPaginaAtual = pagina;

			if ($modalMsg) {
				$modalMsg.text(amaisVH.getMsg('MSG_VH_L_102', pagina + 1, numPaginas))
			}

			let el = $(`#${cfgs.idPaginador}`).find(`a[num-pagina="${pagina}"]`);
			
			if (!el.length) {
				el = $(`#${cfgs.idPaginador}`).find('a');
			}

			this.setarCfgsPaginado(el, cfgs.numItensPorPagina, pagina);

			await UtilPaginacao.handleClickBotaoPagina(el);

			await new Promise(r => setTimeout(r, 1000));

			this.setDadosPlanilha(cfgs, colunas, tos);
		}
	}

	private static async exportarPlanilhaEResetarPagina(cfgs: CfgsDownloadTabela, paginacaoAtual, colunas, tos) {
		await this.exportarPlanilha(cfgs, colunas, tos);
		await this.resetarPagina(cfgs, paginacaoAtual);
	}

	private static async exportarPlanilha(cfgs: CfgsDownloadTabela, colunas, tos) {

		const matriz: any[][] = [];

		this.setarCabecalhoTabelaMatriz(colunas, matriz);
		this.setarCorpoTabelaMatriz(colunas, tos, matriz);

		UtilXlsx.removerColunasVazias(matriz);

		await UtilXlsx.exportFile(matriz, UtilXlsx.getTituloTela(cfgs));
	}

	private static async resetarPagina(cfgs: CfgsDownloadTabela, paginacaoAtual) {

		cfgs.paginacao.paginacaoTO.numItensPorPagina = paginacaoAtual.numItensPorPagina;
		cfgs.paginacao.paginacaoTO.numPaginaAtual = paginacaoAtual.numPaginaAtual;

		let el = $(`#${cfgs.idPaginador}`).find(`a[num-pagina="${paginacaoAtual.numPaginaAtual}"]`);

		this.setarCfgsPaginado(el, paginacaoAtual.numItensPorPagina, 0);

		await new Promise(resolve => setTimeout(resolve, 1000));

		if (el.length) {
			await UtilPaginacao.handleClickBotaoPagina(el);

		} else if (cfgs.paginacao.onCarregarPagina){
			await cfgs.paginacao.onCarregarPagina(paginacaoAtual);
		}
	}

	private static setarCabecalhoTabelaMatriz(colunas, matriz) {

		const linhaColuna = [];

		colunas.forEach(coluna => {

			const $divTemp = $("<div></div>");
			const valorTo = coluna.titulo;

			$divTemp.html(valorTo);

			$divTemp.find("i[data-original-title]").each((k: number, i: HTMLElement) => {
				const $i = $(i);
				$i.before(document.createTextNode("(" + $i.attr("data-original-title") + ")"));
			});

			let valor: any = UtilString.trimLinhas($divTemp.text());

			linhaColuna.push(UtilXlsx.criarCelula(valor, 's', UtilXlsx.styleHeader))
		});

		matriz.push(linhaColuna);
	}

	private static setarCorpoTabelaMatriz(colunas, tos, matriz) {

		tos.forEach(to => {

			const linha = [];
			const $divTemp = $("<div></div>");
			let colspan = to.colspan;

			if (colspan && colspan > colunas.length) {
				colspan = colunas.length;
			}

			colunas.forEach((coluna, i) => {
				const valorTo = to[coluna.prop] !== undefined ? to[coluna.prop] : to[Object.keys(to).filter(it => it != 'colspan')[0]] || "";

				if (colspan && i > 0 && i < colspan) {
					linha.push(UtilXlsx.criarCelula("", "s", UtilXlsx.styleCelula));
					return;
				}

				$divTemp.html(valorTo);
				$divTemp.find("i[data-original-title]").each((k: number, i: HTMLElement) => {
					const $i = $(i);
					$i.before(document.createTextNode("(" + $i.attr("data-original-title") + ")"));
				});

				let valor: any = UtilString.trimLinhas($divTemp.text());
				let tipo = 's';

				if (!isNaN(valorTo)) {
					tipo = 'n';
					valor = UtilXlsx.formatNumber(valor);
				}

				linha.push(UtilXlsx.criarCelula(valor, tipo, UtilXlsx.styleCelula));
			});

			matriz.push(linha);
		})
	}

	private static setarDadosTO(cfgs: CfgsDownloadTabela, tos: any[]) {

		let $trs = $("#" + cfgs.idTabela).find('tbody tr');

		if (cfgs.ordenarPelaPrimeiraColuna) {
			$trs = $trs.sort(function(tr1, tr2) {
				let c1 = $(tr1).find("td:first").text();
				let c2 = $(tr2).find("td:first").text();
				return c1.localeCompare(c2);
			});
		}

		$trs.each((i, tr) => {

			const $tr = $(tr);

			if ($tr.attr("tipo") == "web") return;

			const to = {};

			$tr.find('td').each((j, td) => {

				const $td = $(td);
				const prop = $td.attr('prop-listagem');

				if (prop) {
					to[prop] = $td.html();
				}

				const colspan = $td.attr("colspan");

				if (colspan) {
					to["colspan"] = colspan;
				}
			});

			tos.push(to);
		});
	}

	private static setarDadosColunas(cfgs: CfgsDownloadTabela, colunas: any[]) {

		let $trs = $("#" + cfgs.idTabela).find('thead tr');

		if (cfgs.ordenarPelaPrimeiraColuna) {
			$trs = $trs.sort(function(tr1, tr2) {
				let c1 = $(tr1).find("td:first").text();
				let c2 = $(tr2).find("td:first").text();
				return c1.localeCompare(c2);
			});
		}

		$trs.each((i, tr) => {
			const $tr = $(tr);
			if ($tr.attr("tipo") == "web") return;
			$tr.find('th').each((j, th) => {
				const $th = $(th);
				if ($th.attr("tipo") == "web") return;
				const prop = $($th).attr('listagem-nome-prop');
				const index = colunas.findIndex(it => it.prop == prop);
				if (index < 0) {
					colunas.push({prop, titulo: $th.html()});
				}
			});
		});
	}

	private static setDadosPlanilha(cfgs: CfgsDownloadTabela, colunas: any[], tos: any[]) {
		this.setarDadosTO(cfgs, tos);
		this.setarDadosColunas(cfgs, colunas);
	}
}
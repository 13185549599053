class UtilPaginacao {

	static ID = 1;

	static CFGS_DOS_PAGINADORES = {};

	static getCfgs(idPaginador: string): CfgsPaginador {
		return UtilPaginacao.CFGS_DOS_PAGINADORES[idPaginador];
	}

	static setCfgs(idPaginador: string, cfgs: CfgsPaginador): void {
		UtilPaginacao.CFGS_DOS_PAGINADORES[idPaginador] = cfgs;
	}

	static criarPaginador(cfgs: CfgsPaginador, idPaginador?: string): string {

		idPaginador = idPaginador ?? "paginador_" + UtilPaginacao.ID++;
		
		cfgs._idGrupo = cfgs._idGrupo || "grupo_paginadores_" + UtilPaginacao.ID++;
		UtilPaginacao.setCfgs(idPaginador, cfgs);
		
		const html = [];
		const classes = cfgs.classe ?? "";
		const css = cfgs.css ?? '';

		html.push(`
			<div class="row">
				<div id="${idPaginador}" class="col-md-12 paginador ${classes}" id-grupo="${cfgs._idGrupo}" style="${css}">
		`);

		UtilPaginacao.addPaginadorPageSize(cfgs, html);
		UtilPaginacao.addBotoesPaginas(cfgs, html);

		html.push(`
				</div>
			</div>
		`);

		return html.join("");
	}

	static addPaginadorPageSize(cfgs: CfgsPaginador, html: Array<string>) {

		if (cfgs.paginacaoTO.numTotalItens === 0 || cfgs.alterarItensPorPagina !== true) {
			return;
		}

		const pageSizeOptions = [0, 5, 10, 25, 50, 100];

		const idContainerPageSize = "paginador_pagesize_" + UtilPaginacao.ID++;

		const optionsSelect = pageSizeOptions.map(i => {
			let selected = (i === cfgs.paginacaoTO.numItensPorPagina);
			return `<option value="${i}" ${selected ? 'selected': ''}>${i !== 0 ? i : '-'}</option>`;
		}).join("");

		html.push(`
			<div class='paginador-pagesize' id='${idContainerPageSize}'>
				<select class="form-control" id="paginator-linhas-pagina" onchange="UtilPaginacao.handleClickBotaoPagina(this)" title="${UtilMsg.getMsg("MSG_VH_L_98")}">
					${optionsSelect}
				</select>
			</div>
		`);
	}

	static addBotoesPaginas(cfgs: CfgsPaginador, h: Array<string>) {

		if (cfgs.paginacaoTO.numTotalItens === 0) {
			h.push(cfgs.msgListaVazia || "");
			return;
		}

		cfgs.displayOpcao = cfgs.displayOpcao ?? 7;
		cfgs.displayOpcaoInicioFim = cfgs.displayOpcaoInicioFim ?? 3;

		if (cfgs.paginacaoTO.numPaginaAtual == null) {
			cfgs.paginacaoTO.numPaginaAtual = 0;
		}

		const numPaginas = Math.ceil(cfgs.paginacaoTO.numTotalItens / cfgs.paginacaoTO.numItensPorPagina);

		h.push("<ul class='pagination'>");

		if (numPaginas > 1) {

			UtilPaginacao.addPaginadorBotao("«", cfgs.paginacaoTO.numPaginaAtual - 1, numPaginas, cfgs, h);
			const numerosDasPaginasExibidos = UtilPaginacao.addPaginadorEscolherNumeros(cfgs, numPaginas);
			let imprimiuTresPontos = true;

			for (let pagina = 0; pagina < numPaginas; pagina++) {
				if (numerosDasPaginasExibidos[pagina] != null) {
					UtilPaginacao.addPaginadorBotao(pagina + 1, pagina, numPaginas, cfgs, h);
					imprimiuTresPontos = false;

				} else if (imprimiuTresPontos === false) {
					UtilPaginacao.addPaginadorBotao("...", -1, numPaginas, cfgs, h);
					imprimiuTresPontos = true;
				}
			}

			UtilPaginacao.addPaginadorBotao("»", cfgs.paginacaoTO.numPaginaAtual + 1, numPaginas, cfgs, h);
		}

		h.push("<li class='disabled'><a><strong>")
		let fimPagina = (cfgs.paginacaoTO.numPaginaAtual + 1) * cfgs.paginacaoTO.numItensPorPagina;

		if (fimPagina > cfgs.paginacaoTO.numTotalItens) {
			fimPagina = cfgs.paginacaoTO.numTotalItens;
		}

		if (fimPagina < cfgs.paginacaoTO.numTotalItens || numPaginas > 1) {
			const id = UtilString.gerarId();
			h.push(`
				<span>
					${(cfgs.paginacaoTO.numPaginaAtual * cfgs.paginacaoTO.numItensPorPagina + 1)}
				</span>
				 - 
				<span id="${id}" style='padding-left: 5px; padding-right: 5px' contenteditable='true' itens-por-pagina>
					${fimPagina}
				</span>
				 de 
			`);
			amaisVH.appendJs(() => {
				amaisVH.listenToClick("#" + id, (event) => {
					$(event.target).text("");
				});
				amaisVH.listenToEnter("#" + id, (event) => {
					UtilPaginacao.addPaginadorOnKeyPressFimPagina(event, event.target);
				});
			});
		}

		h.push(`
						${cfgs.paginacaoTO.numTotalItens}</strong>
					</a>
				</li>
		`);

		if (cfgs.botoesAdicionais) {
			for (const botao of cfgs.botoesAdicionais) {
				const idBotao = UtilString.gerarId();
				h.push(`
					<li class="disabled">
						<a id="${idBotao}" title="${botao.dica ?? ''}" style="cursor: pointer">
							${botao.label}
						</a>
					</li>
				`);
				amaisVH.appendJs(() => {
					amaisVH.listenToClick("#" + idBotao, botao.onClick);
				});
			}
		}

		h.push(`
			</ul>
		`);
	}

	static addPaginadorOnKeyPressFimPagina(event: Event, span) {
		const key = UtilBoot.getKeymaster();
		if (key.isPressed("enter")) {
			UtilPaginacao.handleClickBotaoPagina(span);
			event.preventDefault();
			return false;
		}
	}

	static addPaginadorEscolherNumeros(cfgs: CfgsPaginador, numPaginas) {

		if (typeof cfgs.paginacaoTO.numPaginaAtual === 'string') {
			cfgs.paginacaoTO.numPaginaAtual = parseInt(cfgs.paginacaoTO.numPaginaAtual, 10);
		}

		let nums = [];

		nums[cfgs.paginacaoTO.numPaginaAtual] = cfgs.paginacaoTO.numPaginaAtual;

		let numRestante = 9;

		for (let i = cfgs.paginacaoTO.numPaginaAtual; i >= cfgs.paginacaoTO.numPaginaAtual - 1; i--) {
			if (i >= 0 && nums[i] == null) {
				nums[i] = i;
				numRestante--;
			}
		}

		for (let i = cfgs.paginacaoTO.numPaginaAtual; i <= cfgs.paginacaoTO.numPaginaAtual + 1; i++) {
			if (i <= numPaginas && nums[i] == null) {
				nums[i] = i;
				numRestante--;
			}
		}

		for (let i = 0; i <= 2; i++) {
			if (i <= numPaginas && nums[i] == null) {
				nums[i] = i;
				numRestante--;
			}
		}

		for (let i = numPaginas; i >= numPaginas - 2; i--) {
			if (i >= 0 && nums[i] == null) {
				nums[i] = i;
				numRestante--;
			}
		}

		let i = cfgs.paginacaoTO.numPaginaAtual - 2

		while (numRestante > 0 && i >= 0) {
			if (nums[i] == null) {
				nums[i] = i;
				numRestante--;
			}
			i--;
		}

		i = cfgs.paginacaoTO.numPaginaAtual + 2;

		while (numRestante > 0 && i <= numPaginas) {
			if (nums[i] == null) {
				nums[i] = i;
				numRestante--;
			}
			i++;
		}

		return nums;
	}

	static async handleClickBotaoPagina(elemento) {

		let $elemento = $(elemento);

		if (!$elemento.length) {
			$elemento = elemento.prevObject;
		}

		const $paginador = $elemento.closest(".paginador");
		const idPaginador = $paginador.attr("id");
		const cfgs = UtilPaginacao.getCfgs(idPaginador);

		if ($elemento.is("a")) {
			let numPagina = $elemento.attr("num-pagina");

			if (UtilString.hasValue(numPagina)) {
				cfgs.paginacaoTO.numPaginaAtual = Number(numPagina);
			} else {
				cfgs.paginacaoTO.numPaginaAtual = 0;
			}

		} else if ($elemento.is("select")) {
			$('div.tooltip').remove();
			cfgs.paginacaoTO.numPaginaAtual = 0;
			let itensPorPaginaVal = $elemento.val();
			itensPorPaginaVal = itensPorPaginaVal === '0' ? '5': itensPorPaginaVal;
			cfgs.paginacaoTO.numItensPorPagina = +itensPorPaginaVal;

		} else {
			cfgs.paginacaoTO.numPaginaAtual = 0;
			const numItensPorPagina = Number($elemento.text());
			if (!isNaN(numItensPorPagina)) {
				cfgs.paginacaoTO.numItensPorPagina = numItensPorPagina
			}
		}

		const collection = await cfgs.onCarregarPagina(cfgs.paginacaoTO);

		const html = [];
		
		this.addPaginadorPageSize(cfgs, html);
		this.addBotoesPaginas(cfgs, html);

		$paginador.html(html.join(""));

		for (const $paginadorGrupo of UtilPaginacao.getOutrosPaginadoresDoGrupo($paginador)) {
			$paginadorGrupo.html(html.join(""));
		}

		if (cfgs.onPaginaCarregada) {
			await cfgs.onPaginaCarregada(collection);
		}
	}

	static getOutrosPaginadoresDoGrupo($paginador): any[] {
		
		const idPaginador = $paginador.attr("id");
		const idGrupo = $paginador.attr("id-grupo");
		const outrosPaginadoresDoGrupo = [];

		if (!idGrupo) return outrosPaginadoresDoGrupo;
		
		$(`[id-grupo="${idGrupo}"]`).each((i: number, e: Element) => {
			if (e.id !== idPaginador) {
				outrosPaginadoresDoGrupo.push($(e));
			}
		});

		return outrosPaginadoresDoGrupo;
	}

	static addPaginadorBotao(label, numPagina, numPaginas, cfgs, html) {

		const isPaginaValida = numPagina >= 0 && numPagina < numPaginas;

		let classe = "";
		let propsA = "";

		if (isPaginaValida) {
			propsA = `onclick="UtilPaginacao.handleClickBotaoPagina(this)" num-pagina="${numPagina}"`;
			if (cfgs.paginacaoTO.numPaginaAtual == numPagina) {
				classe = `class="active"`;
			}
		} else {
			classe = `class="disabled"`;
		}

		html.push(`
			<li ${classe}>
				<a ${propsA}>
					${label}
				</a>
			</li>
		`);
	}
}
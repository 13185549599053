class UtilBoot {

	static URL_BASE_FRONTEND: string;
	static URL_BASE_BACKEND = "";
	static filtrosParaVHsTO: any;
	static codsDisciplinasRestricao: any;
	static dirVersaoFront: string = null;
	static timeoutIdAtualizacaoFront: number = null;
	static arquivosMathJax: any[];

	static getURLBaseBackend()  {
		return UtilBoot.URL_BASE_BACKEND;
	}

	static isProd(){
		return UtilBoot.getURLBaseBackend() === 'https://backend.fabricadeprovas.com.br/';
	}

	static isHomologacao(){
		return UtilBoot.getURLBaseBackend() === 'https://backend.homologacao-fabricadeprovas.com/';
	}

	static getURLBaseFrontend = function() {

		if (UtilBoot.URL_BASE_FRONTEND == null) {

			let porta = ""

			if (document.location.port != null && document.location.port != "80" && document.location.port != "9080") {
				porta = ":" + document.location.port
			}

			UtilBoot.URL_BASE_FRONTEND = document.location.protocol + "//" + document.location.hostname + porta + "/";
		}

		return UtilBoot.URL_BASE_FRONTEND;
	}

	static getDropZone() {
		return window["Dropzone"];
	}

	static getMoment() : any {
		return window["moment"];
	}

	static getCKEditor(): any {
		return window["CKEDITOR"];
	}
	
	static async getCreateAuth0Client(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: "https://cdn.auth0.com/js/auth0-spa-js/1.19/auth0-spa-js.production.js",
					teste: () => window["createAuth0Client"]
				}],
				onDone: () => resolve({ 
					createAuth0Client: window["createAuth0Client"]
				})
			});
		});
	}
	
	static inicializarSentry(codUsuarioLogado: number, codEmpresaUsuarioLogado: number): any {
		if (UtilBoot.isProd() || UtilBoot.isHomologacao()) {
			setTimeout(() => {
				try {
					const Sentry = window["Sentry"];
					if (!Sentry) {
						console.warn("Sentry não foi carregado.");
						return;
					}
					Sentry.init({
						dsn: 'https://f8c8dcefc05d494b99893c143202c913@o88885.ingest.sentry.io/5424988',
						ignoreErrors: ['Non-Error promise rejection captured with value: undefined'],
						beforeSend(event) {
							const errosParaNaoEnviarAoSentry = [
								'Evitando duplicação de operação em duplo clique',
								'CKEditor',
								'NotAllowedError: Permission denied',
								'NotFoundError: Requested device not found',
								'NotReadableError: Could not start video source',
								'Camera check failed [object Object]'
							];

							if (errosParaNaoEnviarAoSentry.some(erro => event.message?.includes(erro))) {
								return null;
							}
							if (event.extra?.arguments?.length > 0) {
								let found = false;
								for (let argument of event.extra.arguments) {
									if (argument.type === "unhandledrejection") {
										found = true;
										break;
									}
								}
								if (found) return null;
							}
							return event;
						},
						tracesSampleRate: 1.0,
						integrations: [Sentry.captureConsoleIntegration({ levels: ["error"] })],
					});
					Sentry.setUser({ "id": codUsuarioLogado });
					Sentry.setTag("codEmpresaUsuarioLogado", codEmpresaUsuarioLogado);
					Sentry.setTag("token", UtilAuth.getJwtToken());
				} catch (e) {
					console.warn("Erro ao inicializar Sentry", e);
				}
			}, 5000);
		}
	}
	
	static getJquery() {
		return window["$"];
	}
	
	static getKeymaster() {
		return window["key"];
	}
	
	static getJsCookie() {
		return window["Cookies"];
	}

	static getFflate() {
		return window["fflate"];
	}

	static setFiltros(filtrosParaVHsTO) {
		UtilBoot.filtrosParaVHsTO = filtrosParaVHsTO;
	}

	static setCodsDisciplinasRestricao(codsDisciplinasRestricao) {
		UtilBoot.codsDisciplinasRestricao = codsDisciplinasRestricao;
	}
	
	static getCodsDisciplinasRestricao() {
		return UtilBoot.codsDisciplinasRestricao;
	}
	
	static getFiltrosTurmas() {
		if (!UtilBoot.filtrosParaVHsTO || !UtilBoot.filtrosParaVHsTO.codsTurmas) return null;
		return UtilBoot.filtrosParaVHsTO.codsTurmas;
	}
	
	static getFiltrosAplicacoes() {
		if (!UtilBoot.filtrosParaVHsTO || !UtilBoot.filtrosParaVHsTO.codsAgendamentos) return null;
		return UtilBoot.filtrosParaVHsTO.codsAgendamentos;
	}
	
	static isCarregado(arquivo: ArquivoParaCarregar) {
		
		if (arquivo.teste) {
			try {
				return UtilBoot.isArquivoNoHead(arquivo) && arquivo.teste();
			} catch (e) {
				return false;
			}
		} else if (arquivo.isJs || arquivo.isCss || UtilBoot.isComExtensao(arquivo.url, [".js", ".css"])) {
			return UtilBoot.isArquivoNoHead(arquivo);
		} else {
			return true;
		}
	}
	
	static isArquivoNoHead(arquivo: ArquivoParaCarregar) {
		return $(`script[src="${arquivo.url}"], link[href="${arquivo.url}"]`).length > 0;
	}

	static isComExtensao(url: string, extensoes: string[]): boolean {
		if (!url) return false;
		if (url.indexOf("?") > -1) url = url.substring(0, url.indexOf("?"));
		return extensoes.some(extensao => url.toLowerCase().endsWith(extensao));
	}

	static carregarArquivos(cfgs: CfgsCarregarArquivos) {
		
		const arquivoNaoCarregado: ArquivoParaCarregar = cfgs.arquivos.find((arquivo) => !UtilBoot.isCarregado(arquivo));
		
		if (!arquivoNaoCarregado) {
			if (cfgs.onDone) cfgs.onDone();
			return;
		}
		
		if (UtilBoot.isArquivoNoHead(arquivoNaoCarregado)) {
			setTimeout(() => {UtilBoot.carregarArquivos(cfgs)}, 500);
			return;
		}
		
		if (arquivoNaoCarregado.isJs || UtilBoot.isComExtensao(arquivoNaoCarregado.url, [".js"])) {
			const script = document.createElement("script");
			
			script.type = "text/javascript";
			script.async = false;
			script.onload = () => {
				UtilBoot.carregarArquivos(cfgs);
			};
			script.src = arquivoNaoCarregado.url;

			document.querySelector("head").appendChild(script);
			
		} else if (arquivoNaoCarregado.isCss || UtilBoot.isComExtensao(arquivoNaoCarregado.url, [".css"])) {
			const link = document.createElement("link");
			
			link.type = "text/css";
			link.rel = "stylesheet";
			link.onload = function() {
				UtilBoot.carregarArquivos(cfgs);
			};
			link.href = arquivoNaoCarregado.url;

			document.querySelector("head").appendChild(link);

		} else {
			console.warn("Carregamento de arquivo imprevisto: " + JSON.stringify(cfgs));
		}
	}

	static carregarMathJax(callback) {
		if (!UtilBoot.arquivosMathJax) {
			window["MathJax"] = {
				startup: {
					pageReady: () => {
						return window["MathJax"].startup.defaultPageReady().then(() => {
							console.log('MathJax initial typesetting complete');
							if (callback) callback();
						});
					}
				}
			}; 
			UtilBoot.arquivosMathJax = [{
				url: "https://cdn.jsdelivr.net/npm/mathjax@3/es5/mml-svg.min.js"
			}];
		}
		UtilBoot.carregarArquivos({
			arquivos: UtilBoot.arquivosMathJax,
			onDone: () => {
				// if (callback) callback();
			}
		});
	}
	
	static carregarBootstrapSlider(callback) {
		UtilBoot.carregarArquivos({
			arquivos: [
				{url: this.getURLBaseFrontend() + "widgets/bootstrap-slider-10.2.3.min.js"}, 
				{url: this.getURLBaseFrontend() + "widgets/bootstrap-slider-10.2.3.min.css"}
			],
			onDone: () => {
				if (callback) callback();
			}
		});
	}
	
	static async carregarXLSX(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: this.getURLBaseFrontend() + "widgets/js-xlsx/FileSaver-2.0.4.min.js"
				},{
					url: this.getURLBaseFrontend() + "widgets/js-xlsx/js-xlsx-0.8.11-xlsx.full.min.js",
					teste: () => window["XLSX"] && window["saveAs"]
				}],
				onDone: () => resolve({ 
					XLSX: window["XLSX"], 
					saveAs: window["saveAs"] 
				})
			});
		});
	}

	static async carregarPDFExtractor(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: this.getURLBaseFrontend() + 'widgets/pdf.min.js'
				}],
				onDone: () => resolve({
					pdfjsLib: window["pdfjsLib"]
				})
			});
		});
	}

	static async carregarGoogleCharts(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: "https://www.gstatic.com/charts/loader.js", 
					teste: () => window["google"].load != null
				}],
				onDone: () => resolve({ 
					google: window["google"] 
				})
			});
		});
	}
	
	static async carregarLiterallyCanvas(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [
					{
						url: this.getURLBaseFrontend() + "widgets/react/0.14.7/react-with-addons.js",
						teste: () => window["React"] != null
					}, 
					{
						url: this.getURLBaseFrontend() + "widgets/react/0.14.7/react-dom.js",
						teste: () => window["ReactDOM"] != null
					}, 
					{
						url: this.getURLBaseFrontend() + "widgets/literallycanvas-0.4.14/css/literallycanvas.css"
					}, 
					{
						url: this.getURLBaseFrontend() + "widgets/literallycanvas-0.4.14/js/literallycanvas-HOTFIX-CSFP-852.js",
						teste: () => window["LC"].init != null
					}, 
				],
				onDone: () => resolve({ 
					LC: window["LC"] 
				})
			});
		});
	}
	
	static carregarECharts(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: "https://cdn.jsdelivr.net/npm/echarts@5/dist/echarts.min.js", 
					teste: () => window["echarts"] != null
				}],
				onDone: () => resolve({ 
					echarts: window["echarts"] 
				})
			});
		});
	}
	
	static carregarAceCodeEditor(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: "https://cdnjs.cloudflare.com/ajax/libs/ace/1.8.1/ace.js", 
					teste: () => window["ace"].edit != null
				}],
				onDone: () => resolve({ 
					ace: window["ace"] 
				})
			});
		});
	}
	
	static async carregarProctoringFP(): Promise<any> {
		const proctoringUrl = "https://proctoring.imaginie.com.br";
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: proctoringUrl + "/proc/aws-sdk.min.js"
				},{
					url: proctoringUrl + "/proc/rxjs.umd.min.js"
				},{
					url: proctoringUrl + "/proc/dexie.min.js"
				},{
					url: proctoringUrl + "/proc/proctoring.min.js",
					teste: () => window["Proctoring"] !== null
				}], 
				onDone: () => resolve({ 
					Proctoring: window["Proctoring"]
				})
			});
		});
	}
	
	static async recarregarProctoringMettl(): Promise<any> {
		return new Promise((resolve) => {

			const url = this.getURLBaseFrontend() + "widgets/proctoring-mettl-v20240328.js";

			UtilBoot.removerDoHead(url);

			UtilBoot.carregarArquivos({
				arquivos: [{
					url, 
					teste: () => window["MP"].launch != null
				}], 
				onDone: () => resolve({ 
					mp: window["MP"] 
				})
			});
		});
	}

	static async carregarAccToolbar(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: this.getURLBaseFrontend() + "widgets/acctoolbar.min.js",
					teste: () => window["MicAccessTool"] != null
				}],
				onDone	: () => {
					const MicAccessTool = window["MicAccessTool"];
					window['micAccessTool'] = new MicAccessTool({
						forceLang: "br",
						//buttonPosition: 'right'
					});
					resolve({
						MicAccessTool
					})
				}
			});
		});
	}

	static removerDoHead(url: string) {
		$(`
			script[src="${url}"], 
			link[href="${url}"]
		`).remove();
	}

	static setDirVersaoFront(dirVersaoFront: string) {
		if (!dirVersaoFront || (typeof dirVersaoFront !== "string") || (dirVersaoFront.trim().length === 0)) return;
		UtilBoot.dirVersaoFront = dirVersaoFront;
	}

	static verificarNovaVersaoFront(dirVersaoFront: string) {

		if (!dirVersaoFront 
			|| !UtilBoot.dirVersaoFront 
			|| UtilBoot.timeoutIdAtualizacaoFront != null
			|| UtilBoot.dirVersaoFront === dirVersaoFront
			|| typeof dirVersaoFront !== "string"
			|| dirVersaoFront.trim().length === 0) return;

		try {
			if (monitoramentoPFVH.isEmAplicacaoDeProva()) return;
		} catch (ignored) {}

		if (UtilBoot.dirVersaoFront !== dirVersaoFront) {

			UtilBoot.timeoutIdAtualizacaoFront = window.setTimeout(() => {
				
				$.toast().reset('all');
				$.toast({
					heading: "Nova versão disponível",
					text: `
						<a onclick="UtilBoot.atualizarFront()">
							Clique aqui para atualizar.
						</a>
					`,
					position: 'top-right',
					hideAfter: false,
					showHideTransition: 'fade',
					allowToastClose: true,
				});

			}, 15 * 60 * 1000);
		}
	}

	static atualizarFront() {
		const url = new URL(document.location.href);
		url.searchParams.set("front-atualizado-em", new Date().toISOString());
		document.location.href = url.toString();
	}

	static async carregarUAParser(): Promise<any> {
		return new Promise((resolve) => {
			UtilBoot.carregarArquivos({
				arquivos: [{
					url: this.getURLBaseFrontend() + "widgets/ua-parser-1.0.35.min.js",
					teste: () => window["UAParser"] != null
				}],
				onDone: () => resolve({ 
					UAParser: window["UAParser"] 
				})
			});
		});
	}
	
	static carregarPureChat(idWebsite:string, nomeUsuario: string, emailUsuario: string, hostName: string, linkHistoricoChat?: string) {
		return new Promise((resolve) => {
				
			window["purechatApi"] = {
				l: [],
				t: [],
				on: function () {
					this.l.push(arguments);
				}
			};

			UtilBoot.carregarArquivos({
				arquivos: [{
					url: "https://app.purechat.com/VisitorWidget/WidgetScript",
					teste: () => window["PCWidget"] != null,
					isJs: true
				}],
				onDone: () => {

					window["purechatApi"].on('chatbox:ready', ({ chatboxId }) => {
						const api = window["purechatApi"];
						if (nomeUsuario) api.set('visitor.name', nomeUsuario);
						if (emailUsuario) api.set('visitor.email', emailUsuario);
						if (hostName) api.set('visitor.company', hostName);
						if (linkHistoricoChat) {
							api.set('visitor.phoneNumber', linkHistoricoChat);
							api.set('visitor.question', "Olá, gostaria de continuar o meu atendimento.");
						}
					});

					const PCWidget = window["PCWidget"];
					new PCWidget({ 
						c: idWebsite || 'd9d19108-db93-459c-a26f-482f0438f18e', // id do website para atendimento geral
						f: true 
					});					
					resolve(null);
				}
			});
		});
	}

	static carregarWootric(emailUsuario: string, nomeEmpresa: string, millisCadastroUsuario: number, nomeSistema: string) {
		if (UtilBoot.isProd()) {
			try {
				window["wootricSettings"] = {
					created_at: Math.round((millisCadastroUsuario || Date.now()) / 1000), // segundos
					email: emailUsuario,
					account_token: 'NPS-fc90528b',
					product_name: nomeSistema || 'a Fábrica de Provas',
					properties: {
						perfil: "FP Administrador",
						instituicao: nomeEmpresa,
						plataforma: 'FP - Administradores'
					}
				};
				setTimeout(() => {
					const wootric = window["wootric"];
					if (wootric) wootric('run');
				}, 1000);
			} catch (e) {
				console.error(e);
			}
		}
	}
}

type ArquivoParaCarregar = {
	url: string;
	isJs?: boolean;
	isCss?: boolean;
	teste?: Function;
}

type CfgsCarregarArquivos = {
	arquivos: ArquivoParaCarregar[];
	onDone?: Function;
}
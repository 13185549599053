class TelaInicialVH extends AmaisVH {

	constructor() {
		super(TelaInicialVH.name);
		this.addOperacaoParaHash("ac", this.verificarAutenticidadeDeCertificado);
	}

	exibirPastasAplicacoes(pastas) {
		this.setIdTarget("lista-pastas-aplicacoes");

		Object.entries(pastas).forEach(([key, pasta]) => {
			const keySplited = key.split(":");
			const idPasta = keySplited[0];
			const nomePasta = keySplited[1];
			const verAplicacoes = this.addBotao({
				id: `btn-ver-aplicacoes-pasta-${idPasta}`,
				label: `<i class='fa fa-arrows-v' style="color: white;"></i> ${this.getMsg("MSG_VH_TI_55")}`,
				classe: "btn-success btn-sm btn-ver-aplicacoes-prova",
				onClick: async () => {
					const pastaElement = $(`[pasta-id="${key}"]`);
					const divisorPastaElement = $(`[divisor-pasta-id="${key}"]`);
					const aplicacoesPastaElement = $(`#lista-aplicacoes-pasta-${idPasta}`);
					const abasPastaElement = $(`#abas-pasta-${idPasta}`);
					let isOpened = pastaElement.attr("opened") === "true";
					let botaoPasta = $(`#btn-ver-aplicacoes-pasta-${idPasta}`);
					if(isOpened){
						divisorPastaElement.css("display", "none");
						abasPastaElement.css("display", "none");
						aplicacoesPastaElement.find(`[tela-aluno-linha]`).fadeOut({durantion: 50}, function() {
							this.remove();
						});
						pastaElement.attr("opened", "false");
						botaoPasta.html(`<i class='fa fa-arrows-v' style="color: white;"></i> ${this.getMsg("MSG_VH_TI_55")}`);
					} else {
						let aplicacoes = pasta['aplicacoes'];
						if(aplicacoes && !this.isEmpty(aplicacoes)){
							aplicacoes = aplicacoes.filter((aplicacao) => {
								return !aplicacao.jaTerminouProva || aplicacao.numTentativasRestantes === -1 || aplicacao.numTentativasRestantes > 0;
							})
						}
						this.updateAplicacoesAba(idPasta, aplicacoes);
						pastaElement.attr("opened", "true");
						botaoPasta.html(`<i class='fa fa-arrows-v' style="color: white;"></i> ${this.getMsg("MSG_VH_TI_56")}`);
						divisorPastaElement.css("display", "block");
						abasPastaElement.css("display", "contents");
					}

					this.exibir();
				},
				retornarHtml: true
			});

			this.append(`
				<div class="col-md-12 pasta-aplicacao" opened="false" pasta-id="${key}">
					<div class="descricao-pasta">
						<div class="col-md-8 nome-pasta">
							<i class='fa fa-folder fa-lg'></i>
							<span class='label-avaliacao'>${nomePasta}</span> 
						</div>
						<div class="col-md-4 quantidade">
							<i class='fa fa-file-o'></i>
							${pasta['aplicacoes'].length}
							${verAplicacoes}
						</div>
					</div>
				</div>
				<div id="abas-pasta-${idPasta}" class="row col-md-12 pasta-aplicacoes-abas" style="display: none;"></div>
				<div class="lista-aplicacoes-pasta row" id="lista-aplicacoes-pasta-${idPasta}"></div>
				<div divisor-pasta-id="${key}" class="divisor-pasta-aplicacoes"></div>
			`);

			this.addAbas({
				abas: [{
					label: this.getMsg("MSG_VH_TI_57"),
					onClick: () => {
						let aplicacoes = pasta['aplicacoes'];
						if(aplicacoes && !this.isEmpty(aplicacoes)){
							aplicacoes = aplicacoes.filter((aplicacao) => {
								return !aplicacao.jaTerminouProva || aplicacao.numTentativasRestantes === -1 || aplicacao.numTentativasRestantes > 0;
							})
						}
						this.updateAplicacoesAba(idPasta, aplicacoes);
					}
				}, {
					label: this.getMsg("MSG_VH_TI_58"),
					onClick: () => {
						let aplicacoes = pasta['aplicacoes'];
						if(aplicacoes && !this.isEmpty(aplicacoes)){
							aplicacoes = aplicacoes.filter((aplicacao) => {
								return aplicacao.jaTerminouProva === true;
							})
						}
						this.updateAplicacoesAba(idPasta, aplicacoes);
					}
				}], 
				numAbaAtiva: 0, 
				id: this.gerarId(), 
				idAlvo: `abas-pasta-${idPasta}`, 
				noHref: true,
				classes: "pull-right"
			});
		});

		this.setIdTarget(null);
	}

	updateAplicacoesAba(idPasta, aplicacoes){
		if(this.isEmpty(aplicacoes)){
			let htmlSemAplicacoes = `<h5 style="text-align: center;padding: 20px 0;">${this.getMsg("MSG_VH_TI_59")}</h5>`;
			this.setHtml(`lista-aplicacoes-pasta-${idPasta}`, htmlSemAplicacoes);
		} else {
			this.setHtml(`lista-aplicacoes-pasta-${idPasta}`, "");
			this.exibirCardsAplicacoes(aplicacoes, `lista-aplicacoes-pasta-${idPasta}`);
		}

		this.exibir();
	}

	exibirCardsAplicacoes(collectionAplicacoesTO, idTarget?) {
		if(idTarget){
			this.setIdTarget(idTarget);
		}
		this.append("<div tela-aluno-linha>");

		collectionAplicacoesTO.sort(function (a, b) {
			let dataA = UtilString.max(a.inicioAgendamento, a.inicioAplicacao, a.dataCorrecao, "1970-01-01T00:00:00Z");
			let dataB = UtilString.max(b.inicioAgendamento, b.inicioAplicacao, b.dataCorrecao, "1970-01-01T00:00:00Z");

			if (!dataA || !dataB || dataA == dataB) {
				return a.codAgendamento - b.codAgendamento;
			} else if (dataA > dataB) {
				return -1
			} else {
				return 1;
			}
		});

		for (let i = 0; i < collectionAplicacoesTO.length; i++) {

			let exibicaoAplicacaoAvaliadoTO = collectionAplicacoesTO[i];
			let isAplicacaoImpressa = exibicaoAplicacaoAvaliadoTO.tipoAplicacao == TipoAplicacao.F;

			let opcao = (exibicaoAplicacaoAvaliadoTO.nomeSecaoOptativaEscolhida ? this.getMsg("MSG_VH_TI_02") + ": <strong>" + exibicaoAplicacaoAvaliadoTO.nomeSecaoOptativaEscolhida + "</strong>" : null);
			let tituloCaixa = this.isEmpty(exibicaoAplicacaoAvaliadoTO.nomeProvaPublico) ? exibicaoAplicacaoAvaliadoTO.nomeProva : exibicaoAplicacaoAvaliadoTO.nomeProvaPublico;
			let ariaLabelDescricao = `${this.getMsg("FP_FRONT_TelaInicialVH_019")} ${tituloCaixa}`;

			if (exibicaoAplicacaoAvaliadoTO.nomeTurma) {
				tituloCaixa = "<span caixa-agendamento-nome-turma>" + exibicaoAplicacaoAvaliadoTO.nomeTurma + " - </span> " + tituloCaixa;
				ariaLabelDescricao += `${this.getMsg("FP_FRONT_TelaInicialVH_020")} ${exibicaoAplicacaoAvaliadoTO.nomeTurma}`;
			}

			const isAprovado = this.hasValue(exibicaoAplicacaoAvaliadoTO.isAtingiuPontuacaoMinima) ? exibicaoAplicacaoAvaliadoTO.isAtingiuPontuacaoMinima : "";

			this.append(`
				<div cod-prova='${exibicaoAplicacaoAvaliadoTO.codProva}' is-aprovado="${isAprovado}">
				<div caixa tabindex='0' aria-label="${ariaLabelDescricao}">
			`);

			this.addSubtitulo({
				texto: (isAplicacaoImpressa ? "<i class='fa fa-print'></i> " : "") + tituloCaixa,
				textoSecundario: opcao
			});

			this.append("<div cod-agendamento-usuario='" + exibicaoAplicacaoAvaliadoTO.codAgendamentoUsuario + "' id='caixa_aplicacao_" + exibicaoAplicacaoAvaliadoTO.codAgendamento + "'>");

			if (isAplicacaoImpressa) {
				const exibicaoAIAvaliadoTO = <ExibicaoAIAvaliadoTO> exibicaoAplicacaoAvaliadoTO;
				this.exibirCaixaAI("caixa_aplicacao_" + exibicaoAplicacaoAvaliadoTO.codAgendamento, exibicaoAIAvaliadoTO);
			} else {
				this.exibirCaixaAO("caixa_aplicacao_" + exibicaoAplicacaoAvaliadoTO.codAgendamento, exibicaoAplicacaoAvaliadoTO);
			}

			if(idTarget){
				this.setIdTarget(idTarget);
			}

			this.append("</div></div></div>");

			if (i % 2 == 1) this.append("</div><div tela-aluno-linha>");
		}

		this.append("</div>");

		if(idTarget){
			this.setIdTarget(null);
		}
	}

	async iniciar(codAgendamentoParaFoco?: number) {

		const telaInicialAlunoTO: TelaInicialAlunoTO = await this.call("TelaInicialFCD/recuperarDadosTelaInicialAluno");
		let informacoesComplementares = "";

		if (telaInicialAlunoTO.informacoesComplementaresUsuario) {
			// o delimitador do campo precisa ser aspas simples pois o conteúdo pode vir com json com aspas duplas:
			informacoesComplementares = " fp-informacoes-complementares='" + telaInicialAlunoTO.informacoesComplementaresUsuario.replaceAll("'", '"') + "' ";
		}
			
		this.limpar(false, true);

		this.append(`
			<div id="div_tela_inicial" class="row" ${informacoesComplementares}>
				<div class="col-md-12">
					<div class="row" id="tela_inicial_alertas">
						${this.mostrarAlertaTimezone()}
						${this.mostrarAlertaUploadDocumento(telaInicialAlunoTO.alertarUploadDocumento)}
						${this.mostrarAlertaInstalacaoSafeBrowser(telaInicialAlunoTO.isExibirAlertaInstalacaoSafeBrowser)}
					</div>
				</div>
		`);

		this.setTitulo(this.getMsg("MSG_VH_TI_01"), null, this.getMsg("FP_FRONT_TelaInicialVH_022"));

		if (this.hasValue(telaInicialAlunoTO.msgSaudacao)) {
			this.append(`
				<div msg-saudacao class='col-md-12' style='margin-bottom: 20px;' tabindex="-1" aria-label="${UtilHtml.removeTagsHtml(telaInicialAlunoTO.msgSaudacao)}">
					<p>${telaInicialAlunoTO.msgSaudacao}</p>
				</div>
			`);
		}

		this.append(`
				<div id='lista-pastas-aplicacoes'></div>
				<div id='provas-sem-pasta'></div>
				<div class='col-md-12'>
					<div class='row'>
						<div id='lista-aplicacoes' class='row'>
		`);

		let collectionExibicaoAOAvaliadoTO = telaInicialAlunoTO.aplicacoesDeProvaDoAlunoTO.collectionExibicaoAOAvaliadoTO;
		let collectionExibicaoAIAvaliadoTO = telaInicialAlunoTO.aplicacoesDeProvaDoAlunoTO.collectionExibicaoAIAvaliadoTO;
		let collectionExibicaoAplicacaoAvaliadoTO = collectionExibicaoAOAvaliadoTO.concat(collectionExibicaoAIAvaliadoTO);
		let pastasAplicacoes = {};
		let aplicacoesSemPasta = [];

		if(this.isCfgHabilitada("FUNCIONALIDADE_AGRUPAMENTO_PROVAS_PASTAS_ALUNO") === true){
			for(let aplicacaoAvaliado of collectionExibicaoAplicacaoAvaliadoTO){
				if(this.isEmpty(aplicacaoAvaliado.listaAvaliacoes)){
					aplicacoesSemPasta.push(aplicacaoAvaliado);
				} else{
					for(let pasta of aplicacaoAvaliado.listaAvaliacoes){
						let pastaAplicacao = pastasAplicacoes[`${pasta.id}:${pasta.text}`];
						if(!pastaAplicacao){
							pastasAplicacoes[`${pasta.id}:${pasta.text}`] = {};
							pastasAplicacoes[`${pasta.id}:${pasta.text}`]['aplicacoes'] = [];
							pastaAplicacao = pastasAplicacoes[`${pasta.id}:${pasta.text}`];
						}
						pastaAplicacao['aplicacoes'].push(aplicacaoAvaliado);
					}
				}
			}
			
			if(!this.isEmpty(aplicacoesSemPasta)){
				this.setIdTarget("provas-sem-pasta");
				this.addSubtitulo(this.getMsg("MSG_VH_TI_60"));
				this.setIdTarget(null);
			}
		} else {
			this.exibirCardsAplicacoes(collectionExibicaoAplicacaoAvaliadoTO);
		}

		this.exibirPastasAplicacoes(pastasAplicacoes);
		this.exibirCardsAplicacoes(aplicacoesSemPasta);

		this.append("</div>");
		this.append("</div>");
		this.append("</div>");

		this.append("<div class='col-md-12'>");
		this.append("<div class='row tela-inicial-aluno'>");

		if (telaInicialAlunoTO.possuiPlanoMontagemProvasVigente) {
			this.append("<div class='col-md-6'>");
			this.addSubtitulo(this.getMsg("MSG_VH_TI_03"));
			montagemProvaVH.exibirCampoBuscaTextual();
			this.append("</div>");
		}

		const collectionExibicaoAplicacaoFuturaTO = telaInicialAlunoTO.aplicacoesDeProvaDoAlunoTO.collectionExibicaoAplicacaoFuturaTO;

		if (collectionExibicaoAplicacaoFuturaTO.length > 0) {
			this.append("<div class='col-md-6'>");
			this.addSubtitulo(this.getMsg("MSG_VH_TI_04"));
			this.append("<div class='row'>");
			for (const exibicaoAplicacaoFuturaTO of collectionExibicaoAplicacaoFuturaTO) {
				this.append("<div class='col-md-6'>");
				this.addSubsubtitulo(exibicaoAplicacaoFuturaTO.nomeProva);
				this.append(`<p>${this.getMsg("FP_FRONT_TelaInicialVH_002", UtilData.toDDMMYYYYHHMM(exibicaoAplicacaoFuturaTO.fimInscricoes))}<br>`);
				this.append(this.getMsg("MSG_VH_TI_05") + " " + UtilData.toDDMMYYYYHHMM(exibicaoAplicacaoFuturaTO.inicioAplicacao) + "<br></p>");
				if (exibicaoAplicacaoFuturaTO.isInscrito) {
					this.append(`<p><strong>${this.getMsg("FP_FRONT_TelaInicialVH_001")}</strong></p>`);
				} else {
					this.addBotao({
						label: this.getMsg("FP_FRONT_TelaInicialVH_003"), classe: "btn-primary", onClick: () => {
							aplicacaoExternaVH.verificarHabilitacaoUsuarioNaAplicacao(exibicaoAplicacaoFuturaTO.tokenDeIdentificacaoDaAplicacao);
						}
					});
				}
				this.append("</p>");
				this.append("</div>");
			}
			this.append("</div>");
			this.append("</div>");
		}

		if (telaInicialAlunoTO.listUltimasProvasFeitasTO.length > 0) {
			this.append("<div class='col-md-6'>");
			this.addSubtitulo(this.getMsg("MSG_VH_TI_06"));

			if (telaInicialAlunoTO.listUltimasProvasFeitasTO.length == 1) {
				let msg = this.getMsg("MSG_VH_TI_07") + ": ";
				const codProvaFeita = telaInicialAlunoTO.listUltimasProvasFeitasTO[0].codProvaFeita;
				const tituloProva = telaInicialAlunoTO.listUltimasProvasFeitasTO[0].titulo;

				if (codProvaFeita != null) {
					msg = msg + "<a onclick='aplicacaoProvaVH.exibirDetalhesProvaRealizada(" + codProvaFeita + ", null)'>" + tituloProva + "</a>"
				} else {
					msg = msg + tituloProva;
				}

				this.addTexto(msg)

			} else if (telaInicialAlunoTO.listUltimasProvasFeitasTO.length > 1) {

				telaInicialAlunoTO.listUltimasProvasFeitasTO.forEach((ultimasProvasFeitasTO, i) => {

					if (i == 3 && telaInicialAlunoTO.possuiPlanoMontagemProvasVigente) {
						// soh mostra as tres ultimas provas para quem tem plano de estudo
						// para quem nao tem, nao mostra senao daria chance para o usuario refazer as provas
						this.addLink({
							label: this.getMsg("MSG_VH_TI_08") + " (" + telaInicialAlunoTO.listUltimasProvasFeitasTO.length + ")",
							onClick: () => aplicacaoProvaVH.listarRealizadas()
						});
					}

					if (i >= 3 && telaInicialAlunoTO.possuiPlanoMontagemProvasVigente) {
						return;
					}

					const codProvaFeita = ultimasProvasFeitasTO.codProvaFeita
					let titulo = ultimasProvasFeitasTO.titulo
					const idade = UtilData.getTempoUserFriendly(ultimasProvasFeitasTO.dataFim);

					if (this.hasValue(idade)) titulo = titulo + " <small>(" + this.getMsg("MSG_VH_TI_09") + " " + idade + ")</small>";

					if (codProvaFeita == null) {
						this.addLink({ label: titulo, css: "pointer-events: none;" });

					} else {
						this.addLink({ label: titulo, onClick: () => aplicacaoProvaVH.exibirDetalhesProvaRealizada(codProvaFeita) });
					}
				});
			}

			this.append("</div>");
		}

		if (telaInicialAlunoTO.collectionListagemProvasFeitasAConcluirTO.length > 0) {
			if (telaInicialAlunoTO.possuiPlanoMontagemProvasVigente) {
				this.append("<div id='secao_provas_a_concluir' class='col-md-6'>");
				this.addSubtitulo(this.getMsg("MSG_VH_TI_10"));

				const colunas: ColunaAddTabela[] = [];
				colunas.push({ titulo: this.getMsg("MSG_VH_TI_11"), prop: "titulo" });
				colunas.push({ titulo: this.getMsg("MSG_VH_TI_12"), prop: this.montarTempoDaProva });
				colunas.push({ titulo: this.getMsg("MSG_VH_TI_13"), prop: "numQuestoes" });
				colunas.push({ titulo: this.getMsg("MSG_VH_TI_14"), prop: this.montarBarra });

				await this.addTabela({
					collection: telaInicialAlunoTO.collectionListagemProvasFeitasAConcluirTO,
					propId: "codProvaFeita",
					colunas: colunas,
					onEdicao: async (codProvaFeita) => await inicioPFVH.verificarPrecondicoesParaInicioDePF(codProvaFeita),
					// css: "float: left; width:100%",
					msgListaVazia: this.getMsg("MSG_VH_TI_15"),
				});

				this.append("</div>");
			}
		}

		if (telaInicialAlunoTO.collectionListagemAplicacoesPublicasTO) {
			this.append("<div class='col-md-6'>");
			this.addSubtitulo(this.getMsg("MSG_VH_TI_16"));

			const colunas: ColunaAddTabela[] = [];
			colunas.push({ titulo: "", prop: "nomeProva", classe: "descricao" });
			colunas.push({ titulo: this.getMsg("MSG_VH_TI_17"), prop: "numQuestoes" });

			await this.addTabela({
				collection: telaInicialAlunoTO.collectionListagemAplicacoesPublicasTO,
				propId: "tokenIdentificacaoAplicacao",
				colunas: colunas,
				onEdicao: async (tokenIdentificacaoAplicacao) => await aplicacaoExternaVH.verificarHabilitacaoUsuarioNaAplicacao(tokenIdentificacaoAplicacao),
			});
			this.append("</div>");
		}

		if (telaInicialAlunoTO.collectionExibicaoFeedbacksUsuarioTO != null && telaInicialAlunoTO.collectionExibicaoFeedbacksUsuarioTO.length > 0) {
			this.append("<div id='secao_feedbacks' class='col-md-6'>");
			this.addSubtitulo(this.getMsg("MSG_VH_TI_19"));

			for (const exibicaoFeedbacksUsuarioTO of telaInicialAlunoTO.collectionExibicaoFeedbacksUsuarioTO) {
				const idadeConclusao = UtilData.getTempoUserFriendly(exibicaoFeedbacksUsuarioTO.dataConclusao)

				this.addTexto({
					texto: "<span style='font-weight:bold'> " + this.getMsg("MSG_VH_TI_20") + ": </span>" + exibicaoFeedbacksUsuarioTO.textoDaQuestao,
					css: "width : 100%; min-height: 1px; margin: 0 0 5px;"
				})

				this.addTexto({
					texto: "<span style='font-weight:bold'> " + this.getMsg("MSG_VH_TI_21") + ": </span>" + exibicaoFeedbacksUsuarioTO.obs,
					css: "width : 100%; min-height: 1px; margin: 0 0 3px;"
				})

				this.addTexto("<a onclick='telaInicialVH.detalharResposta(" + exibicaoFeedbacksUsuarioTO.codFeedback + ")'>" + this.getMsg("MSG_VH_TI_22") + " " + idadeConclusao + "</a>")
			}
			this.append("</div>");
		}

		this.append(`
						</div>
					</div>
		`);

		if (telaInicialAlunoTO.collectionExibicaoMaterialPublicadoTOUltimosPosts && this.hasValue(telaInicialAlunoTO.collectionExibicaoMaterialPublicadoTOUltimosPosts)) {
			publicacaoMaterialVH.exibirUltimosMateriais(telaInicialAlunoTO.collectionExibicaoMaterialPublicadoTOUltimosPosts, telaInicialAlunoTO.collectionListagemPastaMaterialTO);
		}

		this.append(`
			</div>
		`);
		this.exibir();

		if (codAgendamentoParaFoco) {
			const id = "#caixa_aplicacao_" + codAgendamentoParaFoco;
			$(id).closest("[caixa]").addClass("borderBlink");
			UtilWindow.scrollTo($(id).closest(".row"));
		}

		this.focar('titulo');
	}

	montarTempoDaProva(collection) {
		return "<span style='font-size:85%'>(" + this.getMsg("MSG_VH_TI_26") + " " + UtilData.getTempoUserFriendly(collection.dataInicio) + ")</span>";
	};

	montarBarra(collection) {
		return UtilProgressBar.basic({ 
			evolucao: collection.numQuestoesRespondidas, 
			total: collection.numQuestoes 
		});
	};

	async detalharResposta(codFeedback) {

		const exibicaoFeedbackRespostaTO = await this.call("TelaInicialFCD/recuperarFeedbackResposta", codFeedback);

		const idadeCadastro = UtilData.getTempoUserFriendly(exibicaoFeedbackRespostaTO.dataCadastro)
		const idadeConclusao = UtilData.getTempoUserFriendly(exibicaoFeedbackRespostaTO.dataConclusao)

		this.addPopup({
			width: "850px",
			// height: "550px",
			id: "detalhar_resposta",
			titulo: this.getMsg("MSG_VH_TI_27")
		});

		this.addCampoExibicao({
			label: this.getMsg("MSG_VH_TI_28"),
			valor: idadeCadastro,
			css: "width: 45%"
		});

		this.addCampoExibicao({
			label: this.getMsg("MSG_VH_TI_29"),
			valor: this.getMsg("MSG_VH_TI_22") + " " + idadeConclusao,
			css: "width: 50%; margin: 15px 0;"
		})

		this.addCampoExibicao({
			label: this.getMsg("MSG_VH_TI_30"),
			valor: exibicaoFeedbackRespostaTO.observacao,
			css: "width: 97%;"
		})

		if (this.hasValue(exibicaoFeedbackRespostaTO.classificacao)) {
			this.addCampoExibicao({
				label: this.getMsg("MSG_VH_TI_31"),
				valor: exibicaoFeedbackRespostaTO.classificacao
			})
		}

		this.addCampoExibicao({
			label: this.getMsg("MSG_VH_TI_32"),
			valor: exibicaoFeedbackRespostaTO.resposta,
			css: "width: 97%"
		})

		this.append("<hr style= 'width: 100%; float: left'>");

		exibicaoQuestaoVH.limparControleTextos()
		exibicaoQuestaoVH.exibirQuestao(exibicaoFeedbackRespostaTO.exibicaoQuestaoTO)

		this.exibirPopups();
	}

	limparDivInicio() {
		let divInicio = this.getElementoHtml("divInicio")
		divInicio.innerHTML = "";
	}

	verificarAutenticidadeDeCertificado() {

		UtilHash.registrarHistorico(this.verificarAutenticidadeDeCertificado);

		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_TI_42"));
		this.addCampoTexto({ id: "numCertificado", label: this.getMsg("MSG_VH_TI_43") });
		this.addEspacamentoHorizontal("1px")
		this.addBotao({
			label: this.getMsg("MSG_VH_TI_44"), 
			onClick: async () => {

				const numCertificado = this.getValor("numCertificado");

				if (this.isEmpty(numCertificado)) {
					await this.exibirAlerta({ msg: this.getMsg("MSG_VH_TI_45") });
					return;
				}

				const dadosVerificacaoCertificadoTO = await this.call("EmissaoCertificadoFCD/verificarAutenticidade", numCertificado);
			
				if (!dadosVerificacaoCertificadoTO.numCertificado) {
					await this.exibirAlerta({ msg: this.getMsg("MSG_VH_TI_46") });
					return;
				}
				if ($("#boxVerificacao").length == 0) {
					this.addEspacamentoHorizontal("10px");
					this.addBox({
						titulo: this.getMsg("MSG_VH_TI_47"),
						id: "boxVerificacao",
						classe: "col-md-12"
					});
				} else {
					this.setHtml("boxVerificacao", "");
				}

				this.setIdTarget("boxVerificacao");

				this.addCampoExibicao({ label: this.getMsg("MSG_VH_TI_48"), valor: dadosVerificacaoCertificadoTO.nomeProva});
				this.addCampoExibicao({ label: this.getMsg("MSG_VH_TI_49"), valor: dadosVerificacaoCertificadoTO.nomePessoaCertificada});
				this.addCampoExibicao({ label: this.getMsg("MSG_VH_TI_50"), valor: dadosVerificacaoCertificadoTO.dataProva});
				this.addCampoExibicao({ label: this.getMsg("MSG_VH_TI_51"), valor: dadosVerificacaoCertificadoTO.aproveitamento + "%"});
				this.addCampoExibicao({ label: this.getMsg("MSG_VH_TI_52"), valor: dadosVerificacaoCertificadoTO.numCertificado});

				this.exibir();
			}
		})
		this.exibir();
	}

	exibirCaixaAO(idBox, exibicaoAOAvaliadoTO) {

		this.setIdTarget(idBox);
		const nomeProva = exibicaoAOAvaliadoTO.nomeProvaPublico ||  exibicaoAOAvaliadoTO.nomeProva;

		if (exibicaoAOAvaliadoTO.isAnulada) {

			if (!exibicaoAOAvaliadoTO.isProvaReaberta) {
				this.append(`
					<p aria-label="${nomeProva} ${this.getMsg("FP_FRONT_TelaInicialVH_023")}" tabindex="0">
						<span class='label label-danger'>${this.getMsg("MSG_VH_AP_75")}</span>
					</p>
				`);
			}

			if (exibicaoAOAvaliadoTO.numTentativasRestantes != null && exibicaoAOAvaliadoTO.numTentativasRestantes != 0) {
				let msg = this.getMsg("MSG_VH_AP_45");
				const usuarioNaoPodeRealizarProva = exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva && !exibicaoAOAvaliadoTO.milisegundosRestantesParaProva;
				if (usuarioNaoPodeRealizarProva) {
					msg = this.getMsg('MSG_VH_AP_89', UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva))
				}

				this.append(`
					<p aria-label="${this.getMsg("MSG_VH_AP_44")} ${msg}" tabindex="0">
						<strong>${this.getMsg("MSG_VH_AP_44")}</strong>:${msg}
					</p>
				`);

				this.setarCronometroInicioProva(exibicaoAOAvaliadoTO);

				this.addEspacamentoHorizontal("10px");

				if (!usuarioNaoPodeRealizarProva) {
					this.append(this.addBotao({
						label: this.getMsg("MSG_VH_AP_46"),
						classe: "btn-primary",
						retornarHtml: true,
						onClick: async (event) => await inicioPFVH.verificarSePodeIniciarProvaAgendada(null, exibicaoAOAvaliadoTO.codAgendamentoUsuario, event.target),
						ariaLabel: `${this.getMsg("FP_FRONT_TelaInicialVH_021")} ${nomeProva}`
					}));
				}
			}

			
			if (exibicaoAOAvaliadoTO.isProvaReaberta) {
				this.append(`
					<p aria-label="${this.getMsg("MSG_VH_AP_91")}" tabindex="0">
						${this.getMsg("MSG_VH_AP_91")}
					</p>
				`);

				this.append(this.addBotao({
					texto: this.getMsg("MSG_VH_AP_56"),
					retornarHtml: true,
					onClick: async (event) => await inicioPFVH.verificarPrecondicoesParaInicioDePF(exibicaoAOAvaliadoTO.codProvaFeita, event.target),
					classe: "btn-primary",
					ariaLabel: `${this.getMsg("MSG_VH_AP_56")} ${nomeProva}`
				}));
			}

			this.setIdTarget(null);
			return;
		}

		let t = [];
		let b = [];
		let labelVerDesempenho = "<i class='fa fa-bar-chart-o'></i> " + this.getMsg("MSG_VH_AP_33");

		if (!exibicaoAOAvaliadoTO.jaTerminouProva && !exibicaoAOAvaliadoTO.isLiberado) {
			if (exibicaoAOAvaliadoTO.possuiConfirmacaoInscricao && (!exibicaoAOAvaliadoTO.statusInscricao || exibicaoAOAvaliadoTO.statusInscricao == StatusInscricao.PENDENTE)) {
				this.getMsg("FP_FRONT_TelaInicialVH_027")
				t.push(`
					<p aria-label='${this.getMsg("FP_FRONT_TelaInicialVH_027")}' tabindex="0">
						${this.getMsg("FP_FRONT_TelaInicialVH_027")}
					</p>
				`);
			} else {
				t.push(`
					<p aria-label="${this.getMsg("MSG_VH_AP_37")}" tabindex="0">
						${this.getMsg("MSG_VH_AP_37")}
					</p>
				`);
			}
		}

		if (!exibicaoAOAvaliadoTO.jaTerminouProva) {
			if (this.hasValue(exibicaoAOAvaliadoTO.endereco)) {
				t.push(`
					<p aria-label="${this.getMsg("MSG_VH_AP_38")} ${this.getMsg("FP_FRONT_TelaInicialVH_025")} ${nomeProva} ${exibicaoAOAvaliadoTO.endereco}" tabindex="0">
						<strong>${this.getMsg("MSG_VH_AP_38")}</strong>
						${exibicaoAOAvaliadoTO.endereco}
					</p>
				`);
			}

			if (this.hasValue(exibicaoAOAvaliadoTO.local)) {
				t.push(`
					<p aria-label="${this.getMsg("MSG_VH_AP_39")} ${this.getMsg("FP_FRONT_TelaInicialVH_025")} ${nomeProva} ${exibicaoAOAvaliadoTO.local}" tabindex="0">
						<strong>${this.getMsg("MSG_VH_AP_39")}</strong>
						${exibicaoAOAvaliadoTO.local}
					</p>
				`);
			}

			if (this.hasValue(exibicaoAOAvaliadoTO.nomeFiscal)) {
				t.push(`
					<p aria-label="${this.getMsg("MSG_VH_AP_40")} ${this.getMsg("FP_FRONT_TelaInicialVH_025")} ${nomeProva} ${exibicaoAOAvaliadoTO.nomeFiscal}" tabindex="0">
						<strong>${this.getMsg("MSG_VH_AP_40")}</strong>
						${exibicaoAOAvaliadoTO.nomeFiscal}
					</p>
				`);
			}

			if (exibicaoAOAvaliadoTO.msgApresentacao) {
				t.push(`
					<p tabindex="0" aria-label="${UtilHtml.removeTagsHtml(exibicaoAOAvaliadoTO.msgApresentacao)}">
						${exibicaoAOAvaliadoTO.msgApresentacao}
					</p>
				`);
			}

			if (exibicaoAOAvaliadoTO.possuiConfirmacaoInscricao && exibicaoAOAvaliadoTO.statusInscricao == StatusInscricao.CONFIRMADO) {
				this.getMsg("FP_FRONT_TelaInicialVH_028")
				t.push(`
					<p aria-label='${this.getMsg("FP_FRONT_TelaInicialVH_028")}' tabindex="0">
						${this.getMsg("FP_FRONT_TelaInicialVH_028")}
					</p>
				`);
			}
		}

		let mensagem = this.getMensagemCaixaAO(exibicaoAOAvaliadoTO);
		if (mensagem != null) {
			t.push(`
				<p tabindex="0">
					${mensagem}
				</p>
			`);
		}

		if (exibicaoAOAvaliadoTO.jaTerminouProva) {

			if (exibicaoAOAvaliadoTO.tipoAplicacao == TipoAplicacao.I) {

				let acao = "<i class='fa fa-bar-chart-o'></i> " + this.getMsg("MSG_VH_AP_41");
				let ariaLabel = this.getMsg("MSG_VH_AP_41");

				if (exibicaoAOAvaliadoTO.possuiCorrecaoPendente) {
					acao = this.getMsg("MSG_VH_AP_42");
					ariaLabel = this.getMsg("MSG_VH_AP_42");
				}

				if (exibicaoAOAvaliadoTO.isVisualizacaoResultadoAutorizada) {
					b.push(this.addBotao({ 
						texto: acao, 
						onClick: async () => await aplicacaoProvaVH.exibirDetalhesProvaRealizada(exibicaoAOAvaliadoTO.codProvaFeita), 
						classe: "btn-primary", 
						retornarHtml: true,
						ariaLabel: `${ariaLabel} ${nomeProva}`
					}));
				}
			} else {

				if (exibicaoAOAvaliadoTO.isVisualizacaoResultadoAutorizada) {
					b.push(this.addBotao({ 
						texto: labelVerDesempenho, 
						onClick: async () => await aplicacaoProvaVH.exibirDetalhesProvaRealizada(exibicaoAOAvaliadoTO.codProvaFeita), 
						classe: "btn-primary",
						retornarHtml: true,
						ariaLabel: `${this.getMsg("MSG_VH_AP_33")} ${nomeProva}`
					}));
				}

				if (exibicaoAOAvaliadoTO.deveColetarFeedbacks) {
					b.push(this.addBotao({ 
						texto: "<i class='fa fa-comment-o'></i> " + this.getMsg("MSG_VH_AP_43"), 
						onClick: () => feedbacksVH.exibirTelaRegistroFeedback(exibicaoAOAvaliadoTO.codProvaFeita), 
						retornarHtml: true,
						ariaLabel: `${this.getMsg("MSG_VH_AP_43")} ${nomeProva}`
					}));
				}

				if (exibicaoAOAvaliadoTO.idAplicacaoQuestPosProva) {
					b.push("<span id='botaoQuestionarioPosProva'></span>");
					this.appendJs(async () => {
						const respondeuAgora = await questVH.exibirBotaoEAplicarPosProva($("#botaoQuestionarioPosProva"), exibicaoAOAvaliadoTO.idAplicacaoQuestPosProva);
						
						if (respondeuAgora) {
							UtilHash.carregarTelaInicial();
						}
					})
				}

				if (exibicaoAOAvaliadoTO.numTentativasRestantes != null && exibicaoAOAvaliadoTO.numTentativasRestantes != 0) {

					let msg = this.getMsg("MSG_VH_AP_45");
					const isAprovadoENaoPodeTentarDeNovo = this.isCfgDesabilitada("PERMITIR_NOVA_TENTATIVA_MESMO_APROVADO") && exibicaoAOAvaliadoTO.isAtingiuPontuacaoMinima;
					let usuarioNaoPodeRealizarProva = exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva && !exibicaoAOAvaliadoTO.milisegundosRestantesParaProva;

					if (isAprovadoENaoPodeTentarDeNovo) {
						usuarioNaoPodeRealizarProva = true;
						msg = "";
					} else if (usuarioNaoPodeRealizarProva) {
						msg = this.getMsg('MSG_VH_AP_89', UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva))
					}

					if (msg) {
						this.append(`
							<p aria-label="${this.getMsg("MSG_VH_AP_44")} ${msg}" tabindex="0">
								<strong>${this.getMsg("MSG_VH_AP_44")}</strong>:${msg}
							</p>
						`);
					}

					if (!usuarioNaoPodeRealizarProva) {
						b.push(this.addBotao({
							label: this.getMsg("MSG_VH_AP_46"),
							classe: "btn-primary",
							retornarHtml: true,
							onClick: async (event) => await inicioPFVH.verificarSePodeIniciarProvaAgendada(null, exibicaoAOAvaliadoTO.codAgendamentoUsuario, event.target),
							ariaLabel: `${this.getMsg("FP_FRONT_TelaInicialVH_021")} ${nomeProva}`
						}));
					}
				}

				if (this.isAnalista() || (this.isAluno() && exibicaoAOAvaliadoTO.permitirExportacaoDOCXParaAluno)) {
					b.push(this.addBotaoDropdown({
						id: "dropdownExportacaoCorrecao",
						label: "<i class='fa fa-file-o'></i> " + this.getMsg("MSG_VH_AP_48"), 
						retornarHtml: true,
						opcoes: [
							{ label: "PDF", onClick: (event) => aplicacaoProvaVH.exportarParaDOCX(event, exibicaoAOAvaliadoTO.codProvaFeita, true) },
							{ label: "Word (DOCX)", onClick: (event) => aplicacaoProvaVH.exportarParaDOCX(event, exibicaoAOAvaliadoTO.codProvaFeita, false) }
						],
						ariaLabel: `${this.getMsg("MSG_VH_AP_48")} ${nomeProva}`
					}));
				}

				if (exibicaoAOAvaliadoTO.isDeveExibirRecursos) {
					const labelRecursos = exibicaoAOAvaliadoTO.numRecursosAbertos > 0 ? " " + this.getMsg("MSG_VH_AP_35") : this.getMsg("MSG_VH_AP_36");
					b.push(this.addBotao({
						label: "<i class='fa fa-legal'></i> " + labelRecursos,
						onClick: async () => await recursoVH.interporRecurso(exibicaoAOAvaliadoTO.codProvaFeita), 
						classe: "btn-warning", 
						retornarHtml: true,
						ariaLabel: `${labelRecursos} ${nomeProva}`
					}));
				}
			}

			if (exibicaoAOAvaliadoTO.isProvaReaberta) {
				t.push(`
					<p aria-label="${this.getMsg("MSG_VH_AP_91")}" tabindex="0">
						${this.getMsg("MSG_VH_AP_91")}
					</p>
				`);

				b.push(this.addBotao({
					texto: this.getMsg("MSG_VH_AP_56"),
					retornarHtml: true,
					onClick: async (event) => await inicioPFVH.verificarPrecondicoesParaInicioDePF(exibicaoAOAvaliadoTO.codProvaFeita, event.target),
					classe: "btn-primary",
					ariaLabel: `${this.getMsg("MSG_VH_AP_56")} ${nomeProva}`
				}));
			}

		} else if (exibicaoAOAvaliadoTO.tipoAplicacao == TipoAplicacao.I && exibicaoAOAvaliadoTO.codProvaFeita != null) {

			if (exibicaoAOAvaliadoTO.tempoEnvioRespostasEncerrado === false) {

				let labelBotao = exibicaoAOAvaliadoTO.isSomenteDiscursiva ? this.getMsg("MSG_VH_AP_49") : this.getMsg("MSG_VH_AP_50");

				b.push(this.addBotao({
					label: " <i class='fa fa-upload'></i> " + labelBotao, 
					classe: "btn-success botao-upload-fr-discursiva", 
					retornarHtml: true,
					html: "agendamento-usuario='" + exibicaoAOAvaliadoTO.codAgendamentoUsuario + "'", 
					onClick: (event) => {
						let botao = event.target;
						aplicacaoProvaVH.exibirUploadFRIndividual(exibicaoAOAvaliadoTO.codAgendamentoUsuario, botao);
					},
					ariaLabel: `${labelBotao} ${nomeProva}`
				}));

				b.push(this.addBotao({ 
					texto: this.getMsg("MSG_VH_AP_51"), 
					retornarHtml: true, 
					onClick: async () => await inicioPFVH.recuperarComposicaoDaProvaFeita(exibicaoAOAvaliadoTO.codProvaFeita), 
					classe: "btn-primary",
					ariaLabel: `${this.getMsg("MSG_VH_AP_51")} ${nomeProva}`
				}));

				if (exibicaoAOAvaliadoTO.isVisualizacaoResultadoAutorizada) {
					b.push(this.addBotao({ 
						texto: this.getMsg("MSG_VH_AP_52"), 
						onClick: async () => await aplicacaoProvaVH.exibirDetalhesProvaRealizada(exibicaoAOAvaliadoTO.codProvaFeita), 
						classe: "btn-primary",
						retornarHtml: true,
						ariaLabel: `${this.getMsg("MSG_VH_AP_52")} ${nomeProva}`
					}));
				}
			}

		} else if (exibicaoAOAvaliadoTO.jaComecouProva) {
			t.push(`
				<p aria-label="${this.getMsg("MSG_VH_AP_53")}" tabindex="0">
					${this.getMsg("MSG_VH_AP_53")}
				</p>	
			`);

			b.push(this.addBotao({
				texto: this.getMsg("MSG_VH_AP_54"),
				onClick: async (event) => await inicioPFVH.verificarPrecondicoesParaInicioDePF(exibicaoAOAvaliadoTO.codProvaFeita, event.target),
				classe: "btn-primary",
				retornarHtml: true,
				ariaLabel: `${this.getMsg("MSG_VH_AP_54")} ${nomeProva}`
			}));

		} else if (exibicaoAOAvaliadoTO.codProvaFeita != null && exibicaoAOAvaliadoTO.dataFuturaEmQueUsuarioPoderaComecarAProva == null && (exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva == null || exibicaoAOAvaliadoTO.isDentroLimiteToleranciaInicio)) {
			t.push(`
				<p aria-label="${this.getMsg("MSG_VH_AP_55")}" tabindex="0">
					${this.getMsg("MSG_VH_AP_55")}
				</p>
			`);

			b.push(this.addBotao({
				texto: this.getMsg("MSG_VH_AP_56"),
				retornarHtml: true,
				onClick: async (event) => await inicioPFVH.verificarPrecondicoesParaInicioDePF(exibicaoAOAvaliadoTO.codProvaFeita, event.target),
				classe: "btn-primary",
				ariaLabel: `${this.getMsg("MSG_VH_AP_56")} ${nomeProva}`
			}));

		} else if (exibicaoAOAvaliadoTO.isLiberado && exibicaoAOAvaliadoTO.isPermiteIniciarProva && exibicaoAOAvaliadoTO.dataFuturaEmQueUsuarioPoderaComecarAProva == null && (exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva == null || exibicaoAOAvaliadoTO.isDentroLimiteToleranciaInicio)) {
			let labelBotao = this.getMsg("MSG_VH_AP_56");

			if (exibicaoAOAvaliadoTO.tipoAplicacao == TipoAplicacao.I) {
				labelBotao = this.getMsg("MSG_VH_AP_64");
				t.push(`
					<p aria-label="${this.getMsg("MSG_VH_AP_65")}" tabindex="0">
						${this.getMsg("MSG_VH_AP_65")}
					</p>
				`);
			} else {
				t.push(`
					<p aria-label="${this.getMsg("MSG_VH_AP_66")}" tabindex="0">
						${this.getMsg("MSG_VH_AP_66")}
					</p>
				`);
			}

			if (exibicaoAOAvaliadoTO.isDentroLimiteToleranciaInicio && exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva) {
				const msgDataAtraso = this.getMsg("FP_FRONT_TelaInicialVH_004", UtilData.getTempoUserFriendly(exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva))
				t.push(`
					<p aria-label="${msgDataAtraso}" tabindex="0">
						<i>${msgDataAtraso}</i>
					</p>
				`);
			}

			if (exibicaoAOAvaliadoTO.tokenDeIdentificacaoAplicacao != null && exibicaoAOAvaliadoTO.codAgendamentoUsuario == null) {
				b.push(this.addBotao({
					label: labelBotao,
					retornarHtml: true,
					classe: "btn-primary",
					onClick: () => aplicacaoExternaVH.verificarHabilitacaoUsuarioNaAplicacao(exibicaoAOAvaliadoTO.tokenDeIdentificacaoAplicacao),
					ariaLabel: `${labelBotao} ${nomeProva}`
				}));

			} else {
				b.push(this.addBotao({
					label: labelBotao,
					retornarHtml: true,
					classe: "btn-primary",
					ariaLabel: `${labelBotao} ${nomeProva}`,
					onClick: async (event) => await inicioPFVH.verificarSePodeIniciarProvaAgendada(exibicaoAOAvaliadoTO.codProva, exibicaoAOAvaliadoTO.codAgendamentoUsuario, event.target)
				}));
			}
		} else {
			if(exibicaoAOAvaliadoTO.isSyscheckProctoring === true && !exibicaoAOAvaliadoTO.isSyscheckProctoringRealizado){
				b.push(this.addBotao({
					label: this.getMsg("MSG_VH_AP_82"),
					classe: "btn-primary",
					retornarHtml: true,
					onClick: async () => await aplicacaoProvaVH.realizarChecagemSistemaProctoring(exibicaoAOAvaliadoTO.codAgendamentoUsuario),
					ariaLabel: this.getMsg("MSG_VH_AP_82")
				}));
			}

			if (exibicaoAOAvaliadoTO.fimAgendamento != null) {
				let mensagemPeriodoProva = "<p><strong>" + this.getMsg("MSG_VH_AP_57") + "</strong>: " + this.getMsg("MSG_VH_AP_58") + " " + UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.inicioAgendamento) + " " + this.getMsg("MSG_VH_AP_59") + " " + UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.fimAgendamento);
				t.push(mensagemPeriodoProva + "</p>");
			} else {
				t.push("<p><strong>" + this.getMsg("MSG_VH_AP_57") + "</strong>: " + UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.inicioAgendamento));
			}

			if (exibicaoAOAvaliadoTO.dataFuturaEmQueUsuarioPoderaComecarAProva != null) {

				const milis = UtilData.getDiferencaMillisUltimaRequisicao(exibicaoAOAvaliadoTO.dataFuturaEmQueUsuarioPoderaComecarAProva);

				if (milis < UtilData.MILLIS_UM_DIA) {
					t.push("<p><i class='fa fa-exclamation-triangle'></i> " + this.getMsg("MSG_VH_AP_67") + " <b>" + UtilData.getDuracao({ millis: milis }) + "</b> " + this.getMsg("MSG_VH_AP_68") + "</p>");
				}

			} else if (exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva != null) {
				let mensagemAtrasoInicio = "<p><i>" + this.getMsg("MSG_VH_AP_69") + " <b>" + UtilData.getTempoUserFriendly(exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva) + "</b>.</i></p>";
				if (exibicaoAOAvaliadoTO.isDentroLimiteToleranciaInicio) {
					mensagemAtrasoInicio = `<p><i>${this.getMsg("FP_FRONT_TelaInicialVH_005", UtilData.getTempoUserFriendly(exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva))}</i></p>`;
				}
				t.push(mensagemAtrasoInicio);
			} else if (exibicaoAOAvaliadoTO.nomeFiscal) {
				t.push("<p><b>" + this.getMsg("MSG_VH_AP_01") + "</b></p>");
			}

			this.adicionarAgendamentoUsuarioParaAtualizacao(exibicaoAOAvaliadoTO.codAgendamento);
		}

		this.append(t.join(""));

		this.setarCronometroInicioProva(exibicaoAOAvaliadoTO);

		if (b.length > 0) {
			this.addEspacamentoHorizontal("10px");

			const classe = UtilWindow.getWidth() < 600 ? "btn-group-vertical col-xs-12" : "btn-group";
			
			this.append(`
				<div class="${classe}" role="group">
					${b.join("")}
				</div>
			`);
		}

		this.setIdTarget(null);
	}

	atualizarBotoesPrazoInicioProvaExpirado(exibicaoAOAvaliadoTO) {
		const divElement = $(`[cod-agendamento-usuario="${exibicaoAOAvaliadoTO.codAgendamentoUsuario}"] .btn`).parent(); // Seleciona a div pelo seu id
		const aElements = divElement.find('a');
		const textoBotao = this.getMsg("MSG_VH_AP_33").toUpperCase();
		if (aElements.length === 1 && aElements.text().trim().toUpperCase() != textoBotao) {
			divElement.remove();
			return;
		}
		if (aElements.length > 1) {
			aElements.filter(function() {
				return $(this).text().trim().toUpperCase() != textoBotao;
			}).remove();
		}
	}

	atualizarParagrafosPrazoInicioProvaExpirado(exibicaoAOAvaliadoTO) {
		const divAgendamentoElement = $(`[cod-agendamento-usuario="${exibicaoAOAvaliadoTO.codAgendamentoUsuario}"]`);
		const pElements = divAgendamentoElement.find('p');
		if (pElements.length === 1) {
			pElements.empty();
			pElements.append(`
					 <i class="fas fa-exclamation-triangle"></i>
					 <span>${this.getMsg('MSG_VH_AP_89')}</span>
				`);
			return;
		}
		const lastPElement = pElements.last();
		lastPElement.empty();
		lastPElement.append(`
				 <i class="fas fa-exclamation-triangle"></i>
				 <span>${this.getMsg('MSG_VH_AP_89')}</span>
			`);
	}
	atualizarCaixaPrazoInicioProvaExpirado(exibicaoAOAvaliadoTO) {
		this.atualizarBotoesPrazoInicioProvaExpirado(exibicaoAOAvaliadoTO);
		this.atualizarParagrafosPrazoInicioProvaExpirado(exibicaoAOAvaliadoTO);
	}
	setarCronometroInicioProva(exibicaoAOAvaliadoTO) {
		if (exibicaoAOAvaliadoTO.numTentativasRestantes === -1 || exibicaoAOAvaliadoTO.numTentativasRestantes > 0) {
			const tolerancia = this.calcularTolerancia(exibicaoAOAvaliadoTO);
			const tempoEmMs = (exibicaoAOAvaliadoTO.milisegundosRestantesParaIniciarProva || 0) + tolerancia;
			const minutos = Math.floor(tempoEmMs / 60000);
			if (tempoEmMs && tempoEmMs > 0 && minutos <= 24 * 60) {
				const podeIniciarProva = exibicaoAOAvaliadoTO.codProvaFeita != null && exibicaoAOAvaliadoTO.dataFuturaEmQueUsuarioPoderaComecarAProva == null && (exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva == null || exibicaoAOAvaliadoTO.isDentroLimiteToleranciaInicio)
					||  exibicaoAOAvaliadoTO.isLiberado && exibicaoAOAvaliadoTO.dataFuturaEmQueUsuarioPoderaComecarAProva == null && (exibicaoAOAvaliadoTO.dataPassadaEmQueUsuarioPoderiaComecarAProva == null || exibicaoAOAvaliadoTO.isDentroLimiteToleranciaInicio)
				if(podeIniciarProva) {
					this.append(`
						<span id="cron" cronometro-tempo-restante-abrir-prova>
							 <i class="fas fa-clock"></i>	
							 <span>${this.getMsg("MSG_VH_AP_83")}</span>					
							 <b>${UtilTempo.getTempoFormatado(tempoEmMs)}</b>
							 <span>${this.getMsg("MSG_VH_AP_84")}</span>
						</span>
					`)
					setTimeout(() => {
						this.iniciarCronometro(exibicaoAOAvaliadoTO);
					}, 100)
				}
			}
		}
	}

	iniciarCronometro(exibicaoAOAvaliadoTO) {
		const $tempoElement = $(`[cod-agendamento-usuario="${exibicaoAOAvaliadoTO.codAgendamentoUsuario}"] #cron b`);
		const tolerancia = this.calcularTolerancia(exibicaoAOAvaliadoTO);
		let tempoEmMs = exibicaoAOAvaliadoTO.milisegundosRestantesParaIniciarProva + tolerancia;
		const crono = new CronoTelaInicialVH();
		crono.iniciarCronometro(tempoEmMs, $tempoElement, async () => {
			this.idsCaixasAUsParaAtualizacao.push(exibicaoAOAvaliadoTO.codAgendamento);
			await this.atualizarDadosCaixaAu();
		})
	}

	calcularTolerancia(exibicaoAOAvaliadoTO) {
		let tolerancia = (exibicaoAOAvaliadoTO.minutosToleranciaInicio || 0) * 60000;
		if (tolerancia) {
			const dataAtual = new Date().getTime();
			const data = new Date(exibicaoAOAvaliadoTO.fimAgendamento).getTime();
			if (dataAtual >= data) {
				tolerancia -= dataAtual - data;
			}
		}
		return tolerancia;
	}

	getMensagemCaixaAO(exibicaoAOAvaliadoTO) {

		let mensagem = [];
		const nomeProva = exibicaoAOAvaliadoTO.nomeProvaPublico ||  exibicaoAOAvaliadoTO.nomeProva;

		if (exibicaoAOAvaliadoTO.numFRsGeradas > 0 && !exibicaoAOAvaliadoTO.isAnulada) {
			if (exibicaoAOAvaliadoTO.numFRsGeradas == exibicaoAOAvaliadoTO.numFRsEnviadas) {
				if (exibicaoAOAvaliadoTO.possuiCorrecaoPendente) {
					if (exibicaoAOAvaliadoTO.numFRsGeradas == 1) {
						mensagem.push("<p tabindex='0'>" + this.getMsg("MSG_VH_AP_77", UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.dataUltimaFREnviada)) + "</p>");
					} else {
						mensagem.push(`<p tabindex='0'>${this.getMsg("FP_FRONT_TelaInicialVH_006", exibicaoAOAvaliadoTO.numFRsGeradas, UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.dataUltimaFREnviada))}</p>`);
					}
				}
			} else if (exibicaoAOAvaliadoTO.dataUltimaFRGerada != null) {
				mensagem.push(`<p tabindex='0'>${this.getMsg("FP_FRONT_TelaInicialVH_007", UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.dataUltimaFRGerada))}</p>`);

				const restantes = exibicaoAOAvaliadoTO.numFRsGeradas - exibicaoAOAvaliadoTO.numFRsEnviadas;
				let msgAguardando = "<p tabindex='0'>" + (restantes == 1 ? this.getMsg("FP_FRONT_TelaInicialVH_011") : this.getMsg("FP_FRONT_TelaInicialVH_012", restantes)) + "</p>";

				if (exibicaoAOAvaliadoTO.numFRsEnviadas > 0) {
					msgAguardando = `${msgAguardando}<p tabindex='0'><strong>${this.getMsg("FP_FRONT_TelaInicialVH_008", exibicaoAOAvaliadoTO.paginasFRNaoEnviadas)}</strong></p>`;
				}

				if (exibicaoAOAvaliadoTO.dataLimiteParaEnvioFRDiscursiva != null) {
					if (exibicaoAOAvaliadoTO.tempoEnvioRespostasEncerrado) {
						mensagem.push(`<p class='text-danger' tabindex='0'>${this.getMsg("FP_FRONT_TelaInicialVH_009", UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.dataLimiteParaEnvioFRDiscursiva))}</p>`);
					} else {
						mensagem.push(msgAguardando);
						mensagem.push(`<p class='text-danger' tabindex='0'>${this.getMsg("FP_FRONT_TelaInicialVH_010", UtilData.toDDMMYYYYHHMM(exibicaoAOAvaliadoTO.dataLimiteParaEnvioFRDiscursiva))}</p>`);
					}
				} else {
					mensagem.push(msgAguardando);
				}
			}
		}

		if ((exibicaoAOAvaliadoTO.numFRsGeradas == null || exibicaoAOAvaliadoTO.numFRsGeradas == exibicaoAOAvaliadoTO.numFRsEnviadas) && !exibicaoAOAvaliadoTO.isAnulada) {
			if (exibicaoAOAvaliadoTO.possuiCorrecaoPendente) {
				mensagem.push(this.substituirVariaveisNaMensagem(exibicaoAOAvaliadoTO, exibicaoAOAvaliadoTO.msgCorrecaoPendente));

			} else if (exibicaoAOAvaliadoTO.codProvaFeita != null && exibicaoAOAvaliadoTO.nota != null) {
				if (exibicaoAOAvaliadoTO.isSomenteDiscursiva) {
					mensagem.push(`
						<p tabindex='0' aria-label="${this.getMsg("MSG_VH_TI_53")}">
							<b>${this.getMsg("MSG_VH_TI_53")}</b>
						</p>
					`);
				} else {
					mensagem.push(`
						<p tabindex='0' aria-label="${nomeProva} ${this.getMsg("FP_FRONT_TelaInicialVH_024")}">
							<b>${this.getMsg("MSG_VH_TI_54")}</b>
						</p>
					`);
				}
				if (exibicaoAOAvaliadoTO.porcentagemAproveitamentoParaAprovacao != null && exibicaoAOAvaliadoTO.isAtingiuPontuacaoMinima != null) {
					if (exibicaoAOAvaliadoTO.isAtingiuPontuacaoMinima) {
						const msgAprovacao = this.substituirVariaveisNaMensagem(exibicaoAOAvaliadoTO, exibicaoAOAvaliadoTO.msgAprovacao);
						mensagem.push(`
							<span tabindex="0" aria-label="${UtilHtml.removeTagsHtml(msgAprovacao)}">${msgAprovacao}</span>
						`);
					} else {
						const msgReprovacao = this.substituirVariaveisNaMensagem(exibicaoAOAvaliadoTO, exibicaoAOAvaliadoTO.msgReprovacao);
						mensagem.push(`
							<span tabindex="0" aria-label="${UtilHtml.removeTagsHtml(msgReprovacao)}">${msgReprovacao}</span>
						`);
					}
				} else {
					const msgProvaEncerrada = this.substituirVariaveisNaMensagem(exibicaoAOAvaliadoTO, exibicaoAOAvaliadoTO.msgProvaEncerrada);
					mensagem.push(`
						<span tabindex="0" aria-label="${UtilHtml.removeTagsHtml(msgProvaEncerrada)}">${msgProvaEncerrada}</span>
					`);
				}
			}
		}

		if (mensagem.length == 0) {
			return null;
		} else {
			return mensagem.join("");
		}
	}

	substituirVariaveisNaMensagem(to, msg) {
		if (this.isEmpty(msg)) {
			return "";
		}

		if (msg.indexOf("${") == -1) {
			return msg;
		}

		if (to.porcentagemAproveitamento != null) {
			msg = msg.replace("${porcentagemAproveitamento}", UtilNumero.floatToString(to.porcentagemAproveitamento) + "%");
		}

		if (to.porcentagemAproveitamentoParaAprovacao != null) {
			msg = msg.replace("${porcentagemAproveitamentoParaAprovacao}", UtilNumero.floatToString(to.porcentagemAproveitamentoParaAprovacao) + "%");
		}

		return msg;
	}

	exibirCaixaAI(idBox: string, exibicaoAIAvaliadoTO: ExibicaoAIAvaliadoTO) {

		this.setIdTarget(idBox);
		const nomeProva = exibicaoAIAvaliadoTO.nomeProvaPublico ||  exibicaoAIAvaliadoTO.nomeProva;

		if (exibicaoAIAvaliadoTO.isAnulada) {
			this.append(`
				<p tabindex="0" aria-label="${nomeProva} ${this.getMsg("MSG_VH_AP_75")}">
					<strong style='color: #d9534f'>${this.getMsg("MSG_VH_AP_75")}</strong>
				</p>
			`);
			this.setIdTarget(null);
			return;
		}

		let t = [];
		let b = [];

		if (exibicaoAIAvaliadoTO.inicioAplicacao != null) {
			t.push(`
				<p tabindex="0" aria-label="${nomeProva} ${this.getMsg("MSG_VH_AP_34")} ${UtilData.toDDMMYYYYHHMM(exibicaoAIAvaliadoTO.inicioAplicacao)}">
					<strong>${this.getMsg("MSG_VH_AP_34")}</strong>: ${UtilData.toDDMMYYYYHHMM(exibicaoAIAvaliadoTO.inicioAplicacao)}
				</p>
			`);
		}

		t.push(this.getMensagemCaixaAI(
			exibicaoAIAvaliadoTO.codProvaFeita,
			exibicaoAIAvaliadoTO.possuiCorrecaoPendente,
			exibicaoAIAvaliadoTO.pontuacaoObtida,
			exibicaoAIAvaliadoTO.isDeveExibirRecursos,
			exibicaoAIAvaliadoTO.numRecursosAbertos
		));

		if (exibicaoAIAvaliadoTO.codProvaFeita && exibicaoAIAvaliadoTO.isVisualizacaoResultadoAutorizada) {
			b.push(this.addBotao({ 
				texto: "<i class='fa fa-bar-chart-o'></i> " + this.getMsg("MSG_VH_AP_33"), 
				onClick: () => aplicacaoProvaVH.exibirDetalhesProvaRealizada(exibicaoAIAvaliadoTO.codProvaFeita), 
				classe: "btn-primary", 
				retornarHtml: true,
				ariaLabel: `${this.getMsg("MSG_VH_AP_33")} ${nomeProva}`
			}));
		}

		if (this.isAnalista() || (this.isAluno() && exibicaoAIAvaliadoTO.permitirDownload)) {
			b.push(this.addBotaoDropdown({
				id: `dropdownExportacaoCorrecao${exibicaoAIAvaliadoTO.codProvaFeita}`,
				label: "<i class='fa fa-file-o'></i> " + this.getMsg("MSG_VH_AP_48"), 
				retornarHtml: true,
				ariaLabel: `${this.getMsg("MSG_VH_AP_48")} ${nomeProva}`,
				opcoes: [
					{ label: "PDF", onClick: (event) => aplicacaoProvaVH.exportarParaDOCX(event, exibicaoAIAvaliadoTO.codProvaFeita, true) },
					{ label: "Word (DOCX)", onClick: (event) => aplicacaoProvaVH.exportarParaDOCX(event, exibicaoAIAvaliadoTO.codProvaFeita, false) }
				]
			}));
		}

		if (exibicaoAIAvaliadoTO.isDeveExibirRecursos) {
			let labelBotaoRecursos = exibicaoAIAvaliadoTO.numRecursosAbertos > 0 ? " " + this.getMsg("MSG_VH_AP_35") : this.getMsg("MSG_VH_AP_36");
			b.push(this.addBotao({
				id: `botaoRecursosAI${exibicaoAIAvaliadoTO.codProvaFeita}`,
				label: `<i class='fa fa-legal'></i> (${labelBotaoRecursos})`,
				onClick: async () => await recursoVH.interporRecurso(exibicaoAIAvaliadoTO.codProvaFeita), 
				classe: "btn-warning", 
				retornarHtml: true,
				ariaLabel: `${labelBotaoRecursos} ${nomeProva}`
			}));
		}

		this.append(t.join(""));

		if (b.length > 0) {
			this.addEspacamentoHorizontal("10px");

			const classe = UtilWindow.getWidth() < 600 ? "btn-group-vertical col-xs-12" : "btn-group";

			this.append(`
				<div class="${classe}" role="group">
					${b.join("")}
				</div>
			`);
		}

		this.setIdTarget(null);
	}

	getMensagemCaixaAI(codProvaFeita, possuiCorrecaoPendente, pontuacaoObtida, isDeveExibirRecursos, numRecursosAbertos) {
		let mensagem;

		if (codProvaFeita == null) {
			mensagem = this.getMsg("MSG_VH_AP_78");

		} else if (possuiCorrecaoPendente) {
			mensagem = this.getCfg("MSG_CORRECAO_DISCURSIVA_PENDENTE");

		} else if (pontuacaoObtida != null) {

			mensagem = this.getMsg("MSG_VH_AP_79");

			if (isDeveExibirRecursos) {
				if (numRecursosAbertos == null || numRecursosAbertos == 0) {
					mensagem = mensagem + this.getMsg("MSG_VH_AP_80");
				} else {
					mensagem = mensagem + this.getMsg("MSG_VH_AP_81", numRecursosAbertos, numRecursosAbertos > 1 ? "s" : "");
				}
			}

		} else {
			mensagem = this.getMsg("FP_FRONT_TelaInicialVH_013");
		}

		return mensagem;
	}

	idsCaixasAUsParaAtualizacao = [];
	idIntervalAtualizacaoCaixasAUs = null;

	adicionarAgendamentoUsuarioParaAtualizacao(codAgendamento) {
		this.idsCaixasAUsParaAtualizacao.push(codAgendamento);

		if (this.idIntervalAtualizacaoCaixasAUs == null) {
			this.idIntervalAtualizacaoCaixasAUs = setInterval(() => this.recuperarAtualizacoesCaixasAUs(), 90 * 1000);
		}
	}

	async recuperarAtualizacoesCaixasAUs() {

		if (!this.isUsuarioAutenticado() || !this.idsCaixasAUsParaAtualizacao || this.idsCaixasAUsParaAtualizacao.length == 0 || $("[id^='caixa_aplicacao_']").length == 0) {
			return;
		}

		await this.atualizarDadosCaixaAu();
	}

	async atualizarDadosCaixaAu() {
		this.idsCaixasAUsParaAtualizacao = this.idsCaixasAUsParaAtualizacao.filter(id => $(`#caixa_aplicacao_${id}`).length);

		if (this.idsCaixasAUsParaAtualizacao?.length) {
			const aplicacoesDeProvaDoAlunoTO = await this.call("TelaInicialFCD/recuperarAplicacoesDeProvaDoAluno", this.idsCaixasAUsParaAtualizacao);

			if (!aplicacoesDeProvaDoAlunoTO || this.isEmpty(aplicacoesDeProvaDoAlunoTO.collectionExibicaoAOAvaliadoTO)) {
				return null;
			}
			for (const exibicaoAOAvaliadoTO of aplicacoesDeProvaDoAlunoTO.collectionExibicaoAOAvaliadoTO) {
				const codAgendamento = exibicaoAOAvaliadoTO.codAgendamento;
				this.idsCaixasAUsParaAtualizacao = this.idsCaixasAUsParaAtualizacao.filter(id => id != codAgendamento);
				const idCaixa = "caixa_aplicacao_" + codAgendamento;

				if ($("#" + idCaixa).length == 0) {
					continue;
				}

				this.setHtml(idCaixa, "");
				this.exibirCaixaAO(idCaixa, exibicaoAOAvaliadoTO);
			}
			this.exibir();
		}
	}

	mostrarAlertaUploadDocumento(isDeveAlertar: boolean): string {
		let h = [];
		if (isDeveAlertar === true) {
			h.push(`
				<div id="div-alerta-upload-doc" class='col-md-12 alert alert-warning text-center' style='margin-bottom: 20px; display: none;'>
					${this.getMsg("FP_FRONT_TelaInicialVH_014")}
			`);
			h.push(this.addLink({
				label: this.getMsg("FP_FRONT_TelaInicialVH_016"),
				retornarHtml: true,
				css: "padding: 0px; vertical-align: unset",
				onClick: async () => {
					const isDocAnexado = await meusDadosVH.uploadDocIdentidade(this.getCodUsuarioLogado());
					if (isDocAnexado === true) {
						document.location.reload();
					}
				}
			}));
			h.push(`
					${this.getMsg("FP_FRONT_TelaInicialVH_015")}
				</div>
			`);
			setInterval(() => {
				this.show("div-alerta-upload-doc", 500);
			}, 1000);
		}
		return h.join("");
	}

	mostrarAlertaInstalacaoSafeBrowser(isDeveAlertar: boolean): string {
		let h = "";
		if (isDeveAlertar === true) {
			h = `
				<div id="div-alerta-instalacao-safe-browser" class='col-md-12 alert alert-warning text-center' style='margin-bottom: 20px; display: none;'>
					Atenção: você tem uma prova agendada e, para acessá-la, é preciso acessar por outro navegador. 
					${this.addLink({ 
						label: "Clique aqui", 
						onClick: () => safeBrowserVH.exibirInstrucoesInstalacao(),
						css: "vertical-align: unset; padding: 0px",
						retornarHtml: true })}
					para saber mais.
				</div>
			`;
			setInterval(() => {
				this.show("div-alerta-instalacao-safe-browser", 500);
			}, 1000);
		}
		return h;
	}
	
	mostrarAlertaTimezone(): string {
		let h = "";
		if (this.isCfgHabilitada("FUNCIONALIDADE_ALERTAR_ALUNO_FUSO_HORARIO")) {
			h = `
                <div id="div-alerta-fuso-horario" class='col-md-12 alert alert-warning text-center' style='margin-bottom: 20px; display: none'>
                    ${this.getMsg("FP_FRONT_TelaInicialVH_017")} 
					<strong>
						${this.getMsg("FP_FRONT_TelaInicialVH_018")} 
						<span class="relogio-brasilia"></span>
					</strong>
                </div>
            `;
			setInterval(() => {
				this.show("div-alerta-fuso-horario", 500);
				let horaAtual = moment().tz('America/Sao_Paulo');
				$(".relogio-brasilia").html(horaAtual.format("LTS"));
			}, 1000);
		}
		return h;
	}
}

const telaInicialVH = new TelaInicialVH();

type ExibicaoAIAvaliadoTO = {
	tipoAplicacao: TipoAplicacao;
	codAgendamento: number;
	codAgendamentoUsuario: number;
	codProva: number;
	nomeProva: string;
	nomeProvaPublico: string;
	codProvaFeita: number;
	pontuacaoObtida: number;
	numRecursosAbertos: number;
	possuiCorrecaoPendente: boolean;
	inicioAplicacao: string;
	nomeTurma: string;
	isAnulada: boolean;
	isDeveExibirRecursos: boolean;
	isVisualizacaoResultadoAutorizada: boolean;
	permitirDownload: boolean;
	nomeSecaoOptativaEscolhida: string;
	dataCorrecao: string;
}

type TelaInicialAlunoTO = {
	msgSaudacao: string;
	informacoesComplementaresUsuario: string;
	possuiPlanoMontagemProvasVigente: boolean;
	possuiOuPossuiuAlgumPlano: boolean;
	alertarUploadDocumento: boolean;
	isExibirAlertaInstalacaoSafeBrowser: boolean;
	listUltimasProvasFeitasTO: any[];
	collectionListagemProvasFeitasAConcluirTO: any[];
	aplicacoesDeProvaDoAlunoTO: any;
	collectionListagemPastaMaterialTO: any[];
	collectionExibicaoMaterialPublicadoTOUltimosPosts: any[];
	collectionExibicaoFeedbacksUsuarioTO: any[];
	collectionListagemAplicacoesPublicasTO: any[];
}
class LoginVH extends AmaisVH {
	
	isEnviouArquivoParaUpload: boolean;
	exibirEmPopup: boolean;
	isLTI: boolean;
	propId: string;
	firefoxReleaseHistory = null;
	chromeReleaseHistory = {};

	constructor() {
		super(LoginVH.name);
		this.addOperacaoParaHash("tcc", this.exibirConfirmacaoConta);
		this.addOperacaoParaHash("trs", this.exibirRedefinicaoSenha);
		this.addOperacaoParaHash("lvas", this.exibirAlteracaoSenhaPreLogin);
		this.addOperacaoParaHash("unsubscribe", this.cancelarInscricaoEmail);
		this.addOperacaoParaHash("logs", this.exibirArquivoDeLogs);
	}

	async exibirSessoesWebDoUsuario(codUsuario, inicioPeriodo?, fimPeriodo?) {
		const exibicaoSessoesWebUsuarioTO = await this.call("LoginFCD/recuperarSessoesWeb", codUsuario, inicioPeriodo, fimPeriodo);
		await this.exibirLogins(exibicaoSessoesWebUsuarioTO, inicioPeriodo, fimPeriodo);
	}

	async exibirLogins(exibicaoSessoesWebUsuarioTO, inicioPeriodo, fimPeriodo) {

		if ($("#listagem-logins").length == 0) {

			this.addPopup({
				titulo: this.getMsg("MSG_VH_L_09") + " " + exibicaoSessoesWebUsuarioTO.nomeUsuario,
				width: "1100px",
				// height: "670px"
			});

			this.abrirAbaAccordion({ titulo: "Filtros", aberta: inicioPeriodo || fimPeriodo });

			this.addCamposPeriodo({
				label: "Data de login",
				idInicio: "inicioPeriodo",
				idFim: "fimPeriodo",
				onChange: () => {
					this.exibirSessoesWebDoUsuario(
						exibicaoSessoesWebUsuarioTO.codUsuario,
						this.getValor("inicioPeriodo"),
						this.getValor("fimPeriodo")
					);
				},
				valorInicio: inicioPeriodo,
				valorFim: fimPeriodo,
				classe: "col-md-12"
			});

			this.fecharAbaAccordion();
			this.fecharGrupoAccordion();

			this.append("<div id='listagem-logins' class='row'></div>");

			this.exibirPopups();
		}

		$("#listagem-logins").html("");
		this.setIdTarget("listagem-logins");

		const temSessaoAtiva = exibicaoSessoesWebUsuarioTO.collectionListagemSessaoWebUsuarioTO.some(uswTO => this.isEmpty(uswTO.dataLogoff));

		this.addBotao({
			label: this.getMsg("MSG_VH_L_103"),
			classe: "btn-sm pull-right",
			onClick: async () => await this.encerrarSessoesSelecionadas(exibicaoSessoesWebUsuarioTO),
			visivel: (this.isAdministrador() && temSessaoAtiva)
		});

		const agentesParaRecuperarVersao = [];

		const colunas: ColunaAddTabela[] = [{
			titulo: this.getMsg("MSG_VH_L_10"), 
			prop: "dataLogin", 
			formato: "DD/MM/YY HH:mm"
		}, {
			titulo: this.getMsg("MSG_VH_L_11"), 
			prop: "dataLogoff", 
			formato: "DD/MM/YY HH:mm"
		}, {
			titulo: this.getMsg("MSG_VH_L_12"), 
			prop: async (listagemSessaoWebUsuarioTO) => {

				const userAgent = await this.montarUserAgent(listagemSessaoWebUsuarioTO.agente);
				const idSpan = this.gerarId();

				agentesParaRecuperarVersao.push({ 
					idParaTooltip: idSpan, 
					agente: listagemSessaoWebUsuarioTO.agente 
				});

				return `${userAgent} <span id="${idSpan}"><i class="fa fa-spinner fa-pulse"></i></span>`;
			}
		}, {
			titulo: this.getMsg("MSG_VH_L_94"), 
			prop: "ip"
		}, {
			titulo: this.getMsg("MSG_VH_L_13"), 
			prop: this.montarTempoLogin
		}, {
			titulo: "",
			isVisivel: this.isAdministrador(),
			prop: (listagemSessaoWebUsuarioTO) => {
				const btns: string[] = [];
				btns.push(
					this.addBotao({
						label: "<i class='fa fa-history' title='Logs do frontend'></i>",
						hash: UtilHash.getHash(this.exibirArquivoDeLogs, listagemSessaoWebUsuarioTO.codUsuarioSessaoWeb),
						retornarHtml: true,
						abrirNovaPagina: true,
					})
				);
				btns.push(
					this.addBotao({
						label: "<i class='fa fa-comments' title='Histórico do Chatbot'></i>",
						hash: UtilHash.getHash(chatBotVH.exibirHistoricoChat, exibicaoSessoesWebUsuarioTO.nomeUsuario, listagemSessaoWebUsuarioTO.codUsuarioSessaoWeb),
						retornarHtml: true,
						abrirNovaPagina: true,
					})
				);

				return btns.join("");
			}
		}];

		await this.addTabela({
			id: "listagemSessaoWebUsuario",
			collection: exibicaoSessoesWebUsuarioTO.collectionListagemSessaoWebUsuarioTO, 
			propId: "codUsuarioSessaoWeb",
			colunas: colunas,
			onEdicao: async (codUsuarioSessaoWeb) => await this.exibirAcessosDaSessaoWebDoUsuario(codUsuarioSessaoWeb),
			msgListaVazia: (!inicioPeriodo && !fimPeriodo ? this.getMsg("MSG_VH_L_14") : "Não há acessos do usuário para este período."),
			selecao: temSessaoAtiva ? (sessaoWeb: any) => {
				return sessaoWeb.dataLogoff == null;
			} : false,
		});

		this.exibir();

		for (const a of agentesParaRecuperarVersao) {

			const idadeVersao = await this.getDataReleaseNavegador(a.agente);

			if (this.hasValue(idadeVersao)) {
				$("#" + a.idParaTooltip).attr("title", idadeVersao).html(`
					<i class="fa fa-rocket" style="opacity: 0.5"></i>
				`);
			} else {
				$("#" + a.idParaTooltip).html("");
			}
		}

		this.exibir();
	}

	private async encerrarSessoesSelecionadas(exibicaoSessoesWebUsuarioTO) {

		const $listagemSessoesWeb = $(this.getElementoHtml("listagemSessaoWebUsuario"));

		let codUsuarioSessoesWeb = [];

		if ($listagemSessoesWeb.find("td:first-child :checkbox").length > 0) {
			$listagemSessoesWeb.find("td:first-child :checkbox:checked").each(function () {
				codUsuarioSessoesWeb.push(+$(this).attr("codusuariosessaoweb"));
			});
		}

		if (codUsuarioSessoesWeb.length == 0){
			if (await this.confirmar(this.getMsg("MSG_VH_L_104"))) {
				return this.exibirSessoesWebDoUsuario(exibicaoSessoesWebUsuarioTO.codUsuario);
			}
		}

		if(await this.confirmar(this.getMsg("MSG_VH_L_105"))) {

			const sessoesWebDeslogadas =
				await this.call("LoginFCD/encerrarListaUsuarioSessoesWeb",
					codUsuarioSessoesWeb);

			return this.exibirSessoesWebDoUsuario(exibicaoSessoesWebUsuarioTO.codUsuario);

		}
	}


	parserAgente = null;

	async montarUserAgent(userAgent: string): Promise<string> {

		if (!userAgent) return "";

		if (!this.parserAgente) {
			const { UAParser } = await UtilBoot.carregarUAParser();
			this.parserAgente = new UAParser();
		}

		this.parserAgente.setUA(userAgent);

		let r = this.parserAgente.getResult();

		let s = "";

		if (r?.browser?.name) s += r.browser.name;
		if (r?.browser?.major) s += " " + r.browser.major;
		if (r?.os?.name) s += " - " + r.os.name;
		if (r?.os?.version) s += " " + r.os.version;
		if (r?.device?.vendor) s += " - " + r.device.vendor;
		if (r?.device?.model) s += " " + r?.device?.model;
		if (r?.device?.type === "mobile") s += " <i class='fa fa-mobile-alt' title='Smartphone'></i>";
		if (r?.device?.type === "tablet") s += " <i class='fa fa-tablet-alt' title='Tablet'></i>";

		return s;
	}

	async converterUserAgent(userAgent: string) {
		if (!userAgent) return null;

		if (!this.parserAgente) {
			const { UAParser } = await UtilBoot.carregarUAParser();
			this.parserAgente = new UAParser();
		}

		this.parserAgente.setUA(userAgent);

		return this.parserAgente.getResult();
	}

	async getDataReleaseNavegador(agente) {
		const ua = await this.converterUserAgent(agente);

		if (!ua) return '';

		const major = ua?.browser?.major;

		if (ua.browser?.name === 'Chrome') {
			return await this.getDataReleaseChrome(major);
		}

		if (ua.browser?.name === 'Firefox') {
			return await this.getDataReleaseFirefox(major);
		}

		return '';
	}

	async getDataReleaseChrome(major: string) {

		if (!this.chromeReleaseHistory[major]) {
				
			try {
				const response = await fetch(`https://versionhistory.googleapis.com/v1/chrome/platforms/all/channels/stable/versions/all/releases?filter=version%3E=${major},version%3C${Number(major) + 1}&order_by=starttime`);
				const json = await response.json();
				this.chromeReleaseHistory[major] = json.releases;
			} catch (e) {
				this.logger.error("Erro ao consultar API do Chrome", e);
				return '';
			}
		}

		try {
			const releases = this.chromeReleaseHistory[major];

			if (releases?.length) {
				const dataInicial = new Date(releases[0].serving.startTime);
				const dataFinal = new Date(releases[releases.length-1].serving.startTime);

				return this.getMsg("FP_FRONT_LoginVH_044", UtilData.toDDMMYYYY(dataInicial), UtilData.toDDMMYYYY(dataFinal))

			} else {
				return '';
			}

		} catch (e) {
			this.logger.error(`Erro ao consultar data de lançamento da versão ${major} do Chrome`, e);
			return '';
		}
	}

	async getDataReleaseFirefox(major: string) {

		if (this.firefoxReleaseHistory === null) {
			try {
				const response = await fetch(`https://product-details.mozilla.org/1.0/firefox_history_major_releases.json`);
				this.firefoxReleaseHistory = await response.json();
			} catch (e) {
				this.logger.error("Erro ao consultar API do Firefox", e);
				return '';
			}
		}

		try {
			let release = this.firefoxReleaseHistory[major];
		
			if (!release) {
				release = this.firefoxReleaseHistory[String(Number(major).toFixed(1))];
			}

			if (!release) {
				const key = Object.keys(this.firefoxReleaseHistory).find(k => k.split(".")[0] === major);
				if (key) {
					release = this.firefoxReleaseHistory[key];
				}
			}

			if (!release) {
				return "";
			}

			const data = new Date(`${release} UTC-3`);

			return this.getMsg("FP_FRONT_LoginVH_045", UtilData.toDDMMYYYY(data));

		} catch (e) {
			this.logger.error(`Erro ao consultar data de lançamento da versão ${major} do Firefox`, e);
			return '';
		}
	}

	montarTempoLogin(listagemSessaoWebUsuarioTO) {
		return UtilData.getTempoUserFriendly(listagemSessaoWebUsuarioTO.dataLogin);
	}

	async exibirAuditoriaEmpresa(codEmpresa) {
		this.exibirEmPopup = true;
		const endpointAuditoria = "LoginFCD/recuperarAuditoriaEmpresa";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codEmpresa, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codEmpresa)
	}

	async exibirAcessosDaSessaoWebDoUsuario(codUsuarioSessaoWeb) {
		this.exibirEmPopup = true;

		const endpointAuditoria = "LoginFCD/recuperarAcessosPorSessaoWeb";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codUsuarioSessaoWeb, null);

		this.addPopup({
			id: 'popup-auditoria-acesso-sessao-web',
			titulo: this.getMsg("MSG_VH_L_15"),
			width: "1100px",
			// height: "670px"
		});

		const idInfoNavegador = this.gerarId();
		this.append(`<div id=${idInfoNavegador} class='col-md-12'></div>`);

		this.append("<div id='container-tabela-auditoria' class='row container-tabela-auditoria'></div>");
		await this.montarListagemAuditoria(acessoUsuarioTO, endpointAuditoria, codUsuarioSessaoWeb, null, null, null, null);

		this.exibirPopups();
		this.activeBtnGroupAction('.btn-toggle');
		
		const to = this.getTOListagem($(`tr[identificador="${codUsuarioSessaoWeb}"]`));

		if (!to?.agente) return;

		const dataRelease = await this.getDataReleaseNavegador(to.agente);

		if (!dataRelease) return;

		$(`#${idInfoNavegador}`).html(
			this.addCampoExibicao({
				label: this.getMsg("FP_FRONT_LoginVH_046"),
				valor: dataRelease,
				css: "padding-bottom: 10px",
				classe: "col-md-12",
				retornarHtml: true
			})
		);
	}

	async exibirAcessosDoUsuario(codUsuario) {
		this.exibirEmPopup = true;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorUsuario";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codUsuario, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codUsuario)
	}

	async exibirAcessosDaTurma(codTurma) {
		this.exibirEmPopup = true;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorTurma";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codTurma, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codTurma)
	}

	async exibirAcessosDoAgendamento(codAgendamento) {
		this.exibirEmPopup = true;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorAgendamento";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codAgendamento, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codAgendamento)
	}

	async exibirAcessosDoAgendamentoSala(idAgendamentoSala) {
		this.exibirEmPopup = true;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorAgendamentoSala";
		const acessoUsuarioTO = await this.call(endpointAuditoria, idAgendamentoSala, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, idAgendamentoSala)
	}

	async downloadAcessosProvaFeitaPDF(codProvaFeita) {
		try{
			const divMsgAjax = AmaisVH.criarDivMsgAjax("Gerando auditoria, aguarde...");
			let idLink = 'path_download_auditoria';
			await this.show(idLink);
			let $link = $("#" + idLink);
			let nomeArquivoASerGerado = `auditoria_prova_${codProvaFeita}_${Date.now()}.pdf`;

			let requisicaoMSTO: RequisicaoMSTO = {
				servico: "AuditoriaProvaFeitaServicoPdf",
				codsProvaFeita: [codProvaFeita],
				nomeArquivoASerGerado: nomeArquivoASerGerado,
				requisicao: {},
			};

			try {
				const { path }: any = await UtilMS.enviar("/pdf", requisicaoMSTO);
				$link.text(UtilArquivo.getNomeArquivo(path)).attr('href', path);
				divMsgAjax.remove();
			} catch (e) {

				const fpMsRequestError: FpMsRequestError = e;

				this.logger.error(`Erro no UtilMS.enviar`, fpMsRequestError);

				this.exibirAlerta({msg: fpMsRequestError.msgErro || this.getMsg("MSG_VH_A_19")});

				if ($link.attr('href') === undefined) {
					$link.html(`<span class="text-danger">${this.getMsg("FP_FRONT_RelatorioProvasVH_090")}</span>`);
					this.logger.error(this.getMsg("FP_FRONT_RelatorioProvasVH_090"));
				}
				
				divMsgAjax.remove();
			}
		} catch (e) {
			this.logger.error(e);
			console.log(e);
		}
	}

	async exibirAcessosProvaFeita(codProvaFeita: number) {
		this.exibirEmPopup = true;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorProvaFeita";
		const extraActions = `
			<button class='btn btn-link' type='button' 
				onclick='loginVH.downloadAcessosProvaFeitaPDF(${codProvaFeita})' 
				title='${this.getMsg("MSG_VH_A_11")}'>
				<i class='fa fa-file-pdf-o'></i>
			</button>
			<a id="path_download_auditoria" target="_blank" style="display: none;" class="btn btn-link">
				<i class='fa fa-spinner fa-pulse'></i> ${this.getMsg("FP_FRONT_RelatorioProvasVH_089")}
			</a>
		`;
		const acessoUsuarioTO = await this.call(endpointAuditoria, codProvaFeita, null);

		this.propId = 'codUsuarioAcesso';
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codProvaFeita, extraActions)
	}

	async exibirAcessosDaProva(codProva, exibirEmPopup) {
		this.exibirEmPopup = exibirEmPopup || false;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorProva";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codProva, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codProva)
	}

	async exibirAcessosDaQuestao(codQuestao: number, exibirEmPopup: boolean = false) {
		this.exibirEmPopup = exibirEmPopup || false;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorQuestao";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codQuestao, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codQuestao)
	}

	async exibirAcessosDaCorrecaoDiscursiva(codCorrecaoDiscursiva) {
		this.exibirEmPopup = true;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorCorrecaoDiscursiva";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codCorrecaoDiscursiva, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codCorrecaoDiscursiva)
	}
	
	async exibirAcessosDaDisciplina(codDisciplina) {
		this.exibirEmPopup = true;
		const endpointAuditoria = "LoginFCD/recuperarAcessosPorDisciplina";
		const acessoUsuarioTO = await this.call(endpointAuditoria, codDisciplina, null);
		await this.exibirAuditoria(acessoUsuarioTO, endpointAuditoria, codDisciplina);
	}

	async exibirAuditoria(acessoUsuarioTO, endpoint, paramId, extraActions = null) {

		if (this.exibirEmPopup === false) {
			this.limpar(true);

		} else {
			this.addPopup({
				id: 'popup-auditoria',
				titulo: this.getMsg("MSG_VH_L_15"),
				width: "1100px",
				// height: "670px"
			});
		}
		this.append("<div id='container-tabela-auditoria' class='row container-tabela-auditoria'></div>");
		await this.montarListagemAuditoria(acessoUsuarioTO, endpoint, paramId, null, null, null, extraActions);

		if (this.exibirEmPopup === false) {
			this.exibir();
		} else {
			this.exibirPopups();
		}
		this.activeBtnGroupAction('.btn-toggle');
	}

	async montarListagemAuditoria(acessoUsuarioTO, endpoint, paramId, order, pesquisa, forceExibicao, extraActions = null, ocultarFiltro = false) {
console.log(acessoUsuarioTO)		
		this.setarDadosUsuarioSemComunicacaoSistema(acessoUsuarioTO);

		order = order || 'ASC';
		this.setIdTarget("container-tabela-auditoria");

		let paginacaoTO = acessoUsuarioTO.paginacaoTO;
		let listagemAcessoUsuarioTO = acessoUsuarioTO.listagemAcessoUsuarioTO;

		this.append(`<div class='col-md-12 container-actions-auditoria'>`);

		this.append(`<div class='col-md-6 action'>`);

		this.addFormulario({
			onEnter: async (event) => {
				paginacaoTO.numPaginaAtual = 0;
				// paginacaoTO.numItensPorPagina = 5;
				pesquisa = this.getValor("busca_auditoria");
				const acessoUsuarioTO = await this.call(endpoint, paramId, paginacaoTO, order, pesquisa);
				$("#container-tabela-auditoria").html("");
				await this.montarListagemAuditoria(acessoUsuarioTO, endpoint, paramId, order, pesquisa, true);
				event.preventDefault();
				return false;
			}
		})

		this.addCampoTexto({
			id: "busca_auditoria",
			classe: "col-md-10",
			label: this.getMsg("MSG_VH_L_99"),
			dica: this.getMsg("MSG_VH_L_100"),
			valor: pesquisa,
			visivel: !ocultarFiltro,
			prefixo: "<i class='fa fa-search'></i> "
		});

		this.fecharFormulario();

		this.append(`
			</div><div class='col-md-6 action action-order'>
			<label style="display: contents;">${this.getMsg("MSG_VH_L_95")}</label>
			<div class='btn-group btn-toggle btn-order-group' style="margin-left: 5px">
		`);

		this.addBotao({
			label: "<i class='fa fa-sort-asc'></i> " + this.getMsg("MSG_VH_L_96"),
			classe: order === "ASC" ? "btn-primary-table active": "btn-default",
			onClick: async () => {
				paginacaoTO.numPaginaAtual = 0;
				order = 'ASC';
				const acessoUsuarioTO = await this.call(endpoint, paramId, paginacaoTO, order);
				$("#container-tabela-auditoria").html("");
				await this.montarListagemAuditoria(acessoUsuarioTO, endpoint, paramId, order, pesquisa, true, extraActions);
			},
		});

		this.addBotao({
			label: "<i class='fa fa-sort-desc'></i> " + this.getMsg("MSG_VH_L_97"),
            classe: order === "ASC" ? "btn-default": "btn-primary-table active",
			onClick: async () => {
				paginacaoTO.numPaginaAtual = 0;
				order = 'DESC';
				const acessoUsuarioTO = await this.call(endpoint, paramId, paginacaoTO, order);
				$("#container-tabela-auditoria").html("");
				acessoUsuarioTO.listagemAcessoUsuarioTO = [...acessoUsuarioTO.listagemAcessoUsuarioTO].reverse();
				await this.montarListagemAuditoria(acessoUsuarioTO, endpoint, paramId, order, pesquisa, true, extraActions);
			},
		});
		this.append("</div></div></div>");

		const colunas: ColunaAddTabela[] = [];
		colunas.push({titulo: this.getMsg("MSG_VH_L_16"), prop: this.montarColunaDataResultadoTempo});
		colunas.push({titulo: this.getMsg("MSG_VH_L_17"), prop: this.montarColunaNomeUsuarioIP});
		colunas.push({titulo: this.getMsg("MSG_VH_L_18"), prop: this.montarColunaAcaoRegistro});
		colunas.push({titulo: this.getMsg("FP_FRONT_LoginVH_005"), prop: this.montarColunaDetalhes});
		await this.addTabela({
			id: "id_tabela_auditoria",
			collection: listagemAcessoUsuarioTO,
			classe: "tabela-auditoria",
			colunas: colunas,
			ordenar: false,
			ocultarOrdernacao: true,
			paginacao: {
				alterarItensPorPagina: true,
				paginacaoTO : paginacaoTO,
				exibirPaginacaoRodape: true,
				onCarregarPagina: async (paginacaoTO) => {
					const res = (await this.call(endpoint, paramId, paginacaoTO, order, pesquisa));
					this.setarDadosUsuarioSemComunicacaoSistema(res);
					this.setarLinksParaProvaFeita();
					return res.listagemAcessoUsuarioTO;
				},
			},
			htmlAcoesRodape: extraActions,
			propId: this.propId,
		});

		this.setarLinksParaProvaFeita();
		if(forceExibicao) this.exibir();
	}

	setarLinksParaProvaFeita() {
		setTimeout(() => {
			$('td span[cod-prova-feita-link]').each(function () {
				const span = $(this);
				const codigo = span.attr('cod-prova-feita-link');
				const novoLink = $('<a>').attr({
					'href':  `#/apdr/${codigo}`,
					'target': '_blank' // Adicionar o atributo target="_blank"
				}).text(span.text());
				span.replaceWith(novoLink);
			});
		}, 150)
	}

	setarDadosUsuarioSemComunicacaoSistema(res) {
		if (res?.registrosInatividade?.length) {
			setTimeout(() => {
				res.registrosInatividade.forEach(it => {
					let msg = this.getMsg('FP_FRONT_LoginVH_027');
					let classIcone = 'fa fa-info-circle';
					let classAlerta = 'alert-info';
					if (it.tempoEmSegundos) {
						msg = this.getMsg('FP_FRONT_LoginVH_026', UtilTempo.getTempoFormatado(it.tempoEmSegundos * 1000));
						classIcone = 'fa fa-exclamation-triangle';
						classAlerta = 'alert-warning';
					}

					const el = $(`#listagem_linha_${it.codUsuarioAcesso}`);
					const novaLinha = $(`
								<tr class="${classAlerta}">
									<td colspan='999' prop-listagem="alerta_linha">
										<i class="${classIcone}"></i> ${msg}
									</td>
								</tr>
							`);
					el.after(novaLinha);
				})
			}, 200);
		}
	}

	montarColunaDetalhes(listagemAcessoUsuarioTO) {

		let detalheAcao = listagemAcessoUsuarioTO.detalhesAcao;

		if (detalheAcao?.includes('<pre>')) {
			detalheAcao = detalheAcao.replaceAll("<br>", "<br\\>");
			
			const $detalhes = $("<div>" + detalheAcao + "</div>");
			const idBotao = this.gerarId();

			$detalhes.find("pre").first().before(`<a id="${idBotao}">Exibir detalhes</a><br>`);
			$detalhes.find("pre").addClass(`${idBotao}`).css("display", "none");
			
			this.appendJs(() => {
				$("#" + idBotao).on("click", () => {
					const pre = $(`.${idBotao}`);
					const a = $(`#${idBotao}`)
					if (pre.is(':visible')) {
						a.text(this.getMsg('FP_FRONT_LoginVH_024'));
						pre.slideUp(200);
					} else {
						a.text(this.getMsg('FP_FRONT_LoginVH_025'));
						pre.slideDown(200);
					}
					return false;
				});
			});

			return `<div style="width: 500px">${$detalhes.html()}</div>`;
		}
		
		return detalheAcao || "";
	}

	montarColunaAcaoRegistro(listagemAcessoUsuarioTO) {
		if (listagemAcessoUsuarioTO.tipoEntidade == 0) {
			let hash = UtilHash.getHash(aplicacaoProvaVH.exibirDetalhesProvaRealizada, listagemAcessoUsuarioTO.idEntidade);
			return listagemAcessoUsuarioTO.acao + "<br><a href='" + hash + "' target='_blank'>" + (listagemAcessoUsuarioTO.objetoAcao || "") + "</a>";
		} else {
			return listagemAcessoUsuarioTO.acao + "<br>" + (listagemAcessoUsuarioTO.objetoAcao || "");
		}
	}

	montarColunaNomeUsuarioIP(listagemAcessoUsuarioTO) {
		let h = [];
		h.push(listagemAcessoUsuarioTO.nomeUsuario);
		if (listagemAcessoUsuarioTO.ip) {
			h.push("IP " + listagemAcessoUsuarioTO.ip);
		}
		return h.join("<br>");
	}

	montarColunaDataResultadoTempo(listagemAcessoUsuarioTO) {
		return UtilData.toDDMMYYYYHHMMSS(listagemAcessoUsuarioTO.dataAcesso)
			+ `<br>
			   <small>${this.getMsg("FP_FRONT_LoginVH_006")} ${UtilNumero.floatToString(listagemAcessoUsuarioTO.duracaoEmMillis / 1000)}s</small> 
			   ${listagemAcessoUsuarioTO.resultado ? "" : " <i class='fa fa-ban text-danger' title='Acesso bloqueado'></i>"}`;
	}

	onPasswordChange() {
		let inputPassword = <HTMLInputElement> document.getElementById("userNameLoginFormPassword")
		inputPassword.value = inputPassword.value.trim()
	}

	montarHtmlTelaDeLogin(cfgs: CfgsLoginTO) {

		if (this.isEmpty(cfgs.nomeLabelLogin)) {
			cfgs.nomeLabelLogin = this.getMsg("MSG_VH_L_30");

		} else if (cfgs.nomeLabelLogin !== this.getMsg("MSG_VH_L_30")) {
			cfgs.nomeLabelLogin = cfgs.nomeLabelLogin + " " + this.getMsg("MSG_VH_L_24");
		}

		this.append(`
			<div class='panel panel-default login'>
				<div id='divLogin' class='panel-body' ariaLabel='${this.getMsg("FP_FRONT_LoginVH_023")}'>`
		);

		this.append("<div id='divLoginMsg' class='col-md-12 col-xs-12 col-lg-12'>");
		this.append("</div>");

		this.addFormulario({
			id: "userNameLogin",
			css: "padding: 20px",
			acao: "login",
			onEnter: async () => {
				cfgs.login = this.getValor("username");
				cfgs.senha = this.getValor("password");

				const div = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_AmaisVH_036"), true);
				await this.handleLogin(cfgs);
				div.remove();
			}
		});

		this.addCampoTexto({
			id: "username",
			dica: cfgs.nomeLabelLogin,
			dicaComoValorInicial: cfgs.nomeLabelLogin,
			mascara: (cfgs.habilitarMascaraCPFNoLogin == true ? "999.999.999-99" : null),
			valor: cfgs.login,
			classe: "col-xs-12",
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_007")
		})

		this.addCampoTexto({
			id: "password",
			dica: (cfgs.habilitarMascaraCPFNaSenha === true ? this.getMsg("MSG_VH_L_25") : this.getMsg("MSG_VH_L_26")),
			dicaComoValorInicial: (cfgs.habilitarMascaraCPFNaSenha === true ? this.getMsg("MSG_VH_L_25") : this.getMsg("MSG_VH_L_26")),
			tipo: (cfgs.habilitarMascaraCPFNaSenha === true ? null : "SENHA"),
			mascara: (cfgs.habilitarMascaraCPFNaSenha === true ? "99999999999" : null),
			classe: "col-xs-12",
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_009")
		})

		this.append("<div class='form-group col-md-12 col-xs-12 col-lg-12'>");

		this.addBotao({
			id: "btn_entrar_login",
			label: this.getMsg("MSG_VH_L_28"),
			onClick: async () => {
				cfgs.login = this.getValor("username");
				cfgs.senha = this.getValor("password");
				await this.handleLogin(cfgs);
			},
			classe: "btn-primary",
			css: "float: right",
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_008")
		});

		this.addLink({
			id: "btn_esqueceusenha_login",
			label: "<small>" + this.getMsg("MSG_VH_L_29") + "</small>",
			onClick: () => this.exibirEsquecimentoSenha(),
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_010")
		})

		if (cfgs.auth0?.domain && cfgs.auth0.client_id) {
			let labelBotao = this.getMsg("MSG_VH_L_101") + " " + cfgs.auth0.name;
			if(cfgs.auth0.labelBotao){
				labelBotao = cfgs.auth0.labelBotao;
			}
			this.addBotao({
				id: "btn-login-auth0",
				label: labelBotao,
				onClick: async () => await this.handleLoginAuth0(cfgs),
				classe: "col-md-12 col-xs-12 col-lg-12",
				css: "margin-top: 10px;background-color: dimgray;border-color: gray;",
				ariaLabel: this.getMsg("FP_FRONT_LoginVH_015")
			});
		}

		this.append("</div>");

		this.fecharFormulario();
		this.append("</div></div>");
	}

	async handleLogin(cfgs: CfgsLoginTO) {

		try {
			if (cfgs.onBeforeLogin) cfgs.onBeforeLogin();
		} catch (e) {
			this.logger.error("Erro no onBeforeLogin", e);
		}

		if (!cfgs.perguntaSecreta) {
			try {
				await this.efetuarLoginJwt(cfgs);
			} catch (e) {
				this.logger.error("Erro no efetuarLoginJwt", e);
			}
			return;
		}

		let user = $("#username").val();
		let pass = $("#password").val();

		const resultadoVerificacaoPS = await this.call("LoginWebFCD/verificarPS", { 
			u: user, 
			s: pass, 
			idPS: cfgs.perguntaSecreta.id 
		});

		if (this.isEmpty(resultadoVerificacaoPS)) {

			// Usuário ou senha inválidos
			
			if (cfgs.identityOauth2) {
				try{
					await this.efetuarLoginJwt(cfgs);
				} catch (e) {
					this.logger.error("Erro no efetuarLoginJwt", e);
				}

			} else {
				$("#divLoginMsg").html(this.addTextoErro({
					id: "erroLoginMsg",
					texto: this.getMsg("MSG_VH_L_34"),
					retornarHtml: true,
					classe: "col-md-12 col-xs-12",
					ariaLabel: this.getMsg("MSG_VH_L_34")
				}));
				this.focar("erroLoginMsg");
			}
			return;
		}

		if (resultadoVerificacaoPS === true) {
			 
			// possui PS

			let htmlInput = loginVH.addCampoTexto({
				id: cfgs.perguntaSecreta.id,
				dica: cfgs.perguntaSecreta.nome,
				classe: "col-xs-12",
				retornarHtml: true
			});

			let $dl = $("#divLogin");
			$dl.children().hide();

			let $dps = $("[div-ps]");

			if ($dps.length == 0) {
				$dl.append(`
					<div div-ps class="col-xs-12">
						<p>${this.getMsg("MSG_VH_L_31")}: </p>
						<p>&nbsp;</p>
						<p>${htmlInput}</p>
						<p>&nbsp;</p>
						<p>
							<button continuar-ps class="btn btn-default btn-primary">
								${this.getMsg("MSG_VH_L_32")}
							</button>
						</p>
					</div>
				`);
			} else {
				$dps.show();
			}

			$("[continuar-ps]").on("click", async () => {

				const resultadoPS = await this.call("LoginWebFCD/ps", { 
					u: user, 
					s: pass, 
					idPS: cfgs.perguntaSecreta.id,
					ps: $("#" + cfgs.perguntaSecreta.id).val()
				});

				if (resultadoPS === true) {
					try {
						await this.efetuarLoginJwt(cfgs);
					} catch (e) {
						this.logger.error("Erro no login após autenticar PS com sucesso", e);
					}
				} else {
					$("#username").val("");
					$("#password").val("");
					$("#" + cfgs.perguntaSecreta.id).val("");
					let $dl = $("#divLogin");
					$dl.children().show();
					$("[div-ps]").hide();
					$("#divLoginMsg").html(
						this.addTextoErro({
							id: "erroLoginMsg",
							texto: this.getMsg("MSG_VH_L_33"),
							retornarHtml: true,
							classe: "col-md-12 col-xs-12",
							ariaLabel: this.getMsg("MSG_VH_L_33")
						})
					);
					this.focar("erroLoginMsg");
				}
			});

		} else if (resultadoVerificacaoPS === false) {

			// não possui PS mas está OK

			try {
				await this.efetuarLoginJwt(cfgs);
			} catch (e) {
				this.logger.error("Erro no login", e);
			}
		}
	}

	async handleLoginAuth0(cfgs) {
		try {
			const jsonAuth0 = {
				domain: cfgs.auth0.domain,
				client_id: cfgs.auth0.client_id,
				redirect_uri: UtilBoot.getURLBaseFrontend() + 'auth0',
				cacheLocation: 'localstorage'
			};

			localStorage.setItem('jsonAuth0', JSON.stringify(jsonAuth0));
			
			const { createAuth0Client } = await UtilBoot.getCreateAuth0Client();
			const auth0 = await createAuth0Client(jsonAuth0);
			await auth0.loginWithRedirect();

		} catch (e){
			this.logger.error(e);
		}
	}

	async handleLogoutAuth0(redirect = true) {
		try {
			const jsonAuth0 = JSON.parse(localStorage.getItem('jsonAuth0'));
			const { createAuth0Client } = await UtilBoot.getCreateAuth0Client();

			const auth0 = await createAuth0Client(jsonAuth0);

			localStorage.removeItem('jsonAuth0');

			if (redirect) {
				auth0.logout({
					returnTo: UtilBoot.getURLBaseBackend() + "login"
				});

			} else {
				auth0.logout();
			}
			
		} catch (e) {
			this.logger.error("Erro no handleLogoutAuth0", e);
		}
	}

	async callbackSSOAuth0(divMsg: any) {

		try {
			divMsg.innerHTML = "<i style='font-size: 120%'>Carregando...<br><br></i>";
		} catch (ignored) {}

		try {
			const jsonAuth0 = JSON.parse(localStorage.getItem('jsonAuth0'));
			const { createAuth0Client } = await UtilBoot.getCreateAuth0Client();
			const auth0 = await createAuth0Client(jsonAuth0);
			await auth0.handleRedirectCallback();
			const user = await auth0.getUser();
			const claims = await auth0.getIdTokenClaims();

			UtilAuth.limparJwtToken();

			const autenticacaoSSOTO = await this.call("LoginWebFCD/loginAuth0", {
				id: user.sub,
				token: claims.__raw,
				host: amaisVH.getHostname(),
				email: user.email,
				nickname: user.nickname,
				name: user.name
			});
		
			if (autenticacaoSSOTO.token) {
				UtilAuth.setJwtToken(autenticacaoSSOTO.token);

				setTimeout(() => {
					document.location = UtilBoot.getURLBaseFrontend();
				}, 200);
			}

		} catch (e) {
			console.warn("Erro login auth0", e);
			this.logger.warn("Erro login auth0", e);

			const msgErro = e.msgErro || "Erro ao realizar o login. Tente novamente mais tarde.";

			if (divMsg) {
				divMsg.innerHTML = `
					<i>${msgErro}</i>
				`;
			} else {
				this.exibirAlerta({ msg: msgErro });
			}
		}
	}

	async exibirTelaDeLogin(cfgs = new CfgsLoginTO()) {

		if (this.getCodUsuarioLogado() != null) {
			UtilHash.carregarTelaInicial();
			return;
		}

		UtilAuth.limparJwtToken();

		this.limpar();

		this.montarHtmlTelaDeLogin(cfgs);

		await this.hide("identificacaoConteudo");

		if (this.hasValue(cfgs.msgErro)) {
			$("#divLoginMsg").prepend(this.addTextoErro({
				id: "erroLoginMsg",
				texto: cfgs.msgErro,
				retornarHtml: true,
				classe: "col-md-12 col-xs-12",
				ariaLabel: cfgs.msgErro
			}));
			this.focar("erroLoginMsg");
		}

		if (this.hasValue(cfgs.msg)) {
			$("#divLoginMsg").prepend(this.addTextoAlerta({
				texto: cfgs.msg,
				retornarHtml: true
			}));
		}

		this.exibir();

		if ($("#username").val() !== "") {
			this.focar("password");
		} else {
			this.focar("username");
		}
	}

	async exibirConfirmacaoConta(tcc) {

		const confirmacaoContaTO = await this.call("LoginFCD/verificarConfirmacaoConta", tcc);

		if (confirmacaoContaTO == null) {
			await loginVH.exibirTelaDeLogin();
			return;
		}

		if (confirmacaoContaTO.deveExibirMsgContaAtivada) {

			const cfgsLoginTO = new CfgsLoginTO();

			cfgsLoginTO.login = confirmacaoContaTO.login;
			cfgsLoginTO.msg = "<p>" + this.getMsg("MSG_VH_L_35") + "</p><p>" + this.getMsg("MSG_VH_L_36") + "</p>";

			await loginVH.exibirTelaDeLogin(cfgsLoginTO);

			return;
		}

		this.limpar();

		this.append("<div class='panel panel-default login'>")
		this.append("<div id='divDefinicaoSenha' class='panel-body'>")

		if (confirmacaoContaTO.deveExibirDefinicaoSenha) {
			this.addTexto(this.getMsg("MSG_VH_L_37") + " <b>" + this.getMsg("MSG_VH_L_38") + "</b> " + this.getMsg("MSG_VH_L_39") + ":");
			await this.exibirFormularioDefinicaoSenha(confirmacaoContaTO.login, tcc);
		}
	}

	async exibirRedefinicaoSenha(trs) {

		const login = await this.call("LoginFCD/verificarTokenRedefinicaoSenha", trs);

		if (login == null) {
			await loginVH.exibirTelaDeLogin();
			return;
		}

		this.limpar();

		this.append("<div class='panel panel-default login'>")
		this.append("<div id='divDefinicaoSenha' class='panel-body'>")

		this.addTexto(this.getMsg("MSG_VH_L_40") + " <b>" + this.getMsg("MSG_VH_L_41") + "</b> " + this.getMsg("MSG_VH_L_42") + ":");

		await this.exibirFormularioDefinicaoSenha(login, trs);
	}

	private async exibirFormularioDefinicaoSenha(login, token) {
		this.addFormulario({id: "userNameLogin", acao: "login", metodo: "post", css: "padding: 20px"});

		const handleDefinirSenha = () => {
			this.definirSenha(login, token);
		}

		this.setMetodoSubmit(handleDefinirSenha);

		this.addCampoTexto({
			id: "login",
			dicaComoValorInicial: this.getMsg("MSG_VH_L_43"),
			valor: login,
			habilitado: false,
			classe: "col-md-12",
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_016")
		});

		this.addCampoTexto({
			id: "password",
			dicaComoValorInicial: this.getMsg("MSG_VH_L_44"),
			tipo: "SENHA",
			classe: "col-md-12",
			validacaoSenha: true,
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_017")
		});

		this.addCampoTexto({
			id: "definicao_senha_confirmacao",
			dicaComoValorInicial: this.getMsg("MSG_VH_L_45"),
			tipo: "SENHA",
			classe: "col-md-12",
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_018")
		});

		this.addBotao({
			label: this.getMsg("MSG_VH_L_46") + " &raquo;",
			css: "float: right",
			onClick: handleDefinirSenha,
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_019")
		});

		this.addEspacamentoHorizontal("1px");

		this.append("</form>")
		this.append("</div>")

		this.exibir();
		await this.hide("identificacaoConteudo");

		this.focar("password");
	}

	async definirSenha(login, tokenConfirmacaoCadastro) {

		if (!this.validarCamposObrigatorios("password", "definicao_senha_confirmacao")) {
			return
		}

		let definicaoSenhaTO = {
			tokenConfirmacaoCadastro: tokenConfirmacaoCadastro,
			senha: String(this.getValor("password"))
		}

		let confirmacaoSenha = String(this.getValor("definicao_senha_confirmacao"))

		if (definicaoSenhaTO.senha.length < 6) {
			await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_47") });
			return;
		}

		if (definicaoSenhaTO.senha.length != confirmacaoSenha.length) {
			await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_48") });
			return;
		}

		for (let i = 0; i < definicaoSenhaTO.senha.length; i++) {
			if (definicaoSenhaTO.senha.charCodeAt(i) != confirmacaoSenha.charCodeAt(i)) {
				await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_49") });
				return;
			}
		}

		await this.call("LoginFCD/definirSenha", definicaoSenhaTO);
		
		this.setIdTarget("divDefinicaoSenha");
		this.addMsgSucesso(this.getMsg("MSG_VH_L_50"));
		this.exibir();
		// localStorage.removeItem("fp_hash_login");
		setTimeout(async () => {
			try {
				const cfgsLoginTO = new CfgsLoginTO();
				cfgsLoginTO.login = login;
				cfgsLoginTO.senha = definicaoSenhaTO.senha;
				cfgsLoginTO.onUsuarioAutenticado = function() {
					setTimeout(() => {
						const url = new URL(document.URL);
						document.location = url.origin + "/";
					}, 200);
				};
				await this.efetuarLoginJwt(cfgsLoginTO);
			} catch (e) {
				this.logger.error(e);
			}
		}, 1000);
	}

	exibirEsquecimentoSenha() {
		this.setHtml("divLogin", "");
		this.setIdTarget("divLogin");

		this.addEspacamentoHorizontal("10px");
		this.addFormulario({ css: "padding: 20px" });

		this.addRadioGroup({
			id: "escolha_esquecimento_senha",
			valor: "login", label: this.getMsg("MSG_VH_L_51") + " <b>" + this.getMsg("MSG_VH_L_52") + "</b> " + this.getMsg("MSG_VH_L_53") + " <b>" + this.getMsg("MSG_VH_L_54") + "</b> " + this.getMsg("MSG_VH_L_55"),
			classe: "col-xs-12 col-sm-12 col-md-12 col-lg-12",
			collection: [{
				id: "login",
				descricao: "<input class='form-control' placeholder='" + this.getMsg("MSG_VH_L_56") + "' class='login' id='login_esquecimento_senha' name='login_esquecimento_senha' type='text' value=''>"
			}, {
				id: "email",
				descricao: "<input class='form-control' placeholder='" + this.getMsg("MSG_VH_L_57") + "' id='email_esquecimento_senha' name='email_esquecimento_senha' type='text' value='' disabled='disabled'>"
			}],
		})

		this.addBotao({ 
			label: this.getMsg("MSG_VH_L_58"), 
			onClick: () => this.enviarEmailEsquecimentoSenha(), 
			css: "float: right",
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_020")
		});

		this.addBotao({
			label: this.getMsg("MSG_VH_L_59"),
			css: "float: left", 
			onClick: () => {
				document.location = "/"
			},
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_021")
		});

		this.append("</div>")

		this.exibir();
		this.focar("login_esquecimento_senha");
		this.setIdTarget(null);

		$("[name='escolha_esquecimento_senha']").on("change", ({ target }) => {
			let radioSelecionado = target;
			$("[name='escolha_esquecimento_senha']").each((i, input) => {
				$(input).parent().find("input:text").attr('disabled', radioSelecionado != input);
			})
		})
	}

	async enviarEmailEsquecimentoSenha() {
		let info = $("input:radio:checked").val()
		let login = this.getValor("login_esquecimento_senha");
		let email = this.getValor("email_esquecimento_senha");

		if (info == "login") {

			if (this.isEmpty(login)) {
				await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_60") });
				return;
			}

		} else {

			if (this.isEmpty(email)) {
				await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_61") });
				return;
			}
		}

		const enviou = await this.call("LoginFCD/enviarEmailDeEsquecimentoDeSenha", login, email);

		$("#divLogin").html("");

		this.setIdTarget("divLogin");

		let msg = this.getMsg("MSG_VH_L_62") + " <br><br>" + this.getMsg("MSG_VH_L_63");

		if (!enviou) {
			msg = this.getMsg("FP_FRONT_LoginVH_047");
		}

		this.addTextoAlerta({ texto: msg });

		this.addBotao({
			label: "OK", 
			css: "float: right", 
			onClick: () => {
				document.location = "/"
			},
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_022")
		})

		this.exibir();
	}

	onLoginExpirado() {
		document.location.reload();
	}

	addMsgErro(msgErro?: string) {
		let errorMessage = msgErro || this.getMsg("MSG_VH_L_34");
		$("#divLoginMsg").html(
			this.addTextoErro({
				id: "erroLoginMsg",
				texto: errorMessage,
				retornarHtml: true,
				classe: "col-md-12 col-xs-12",
				ariaLabel: errorMessage,
				visivel: false
			})
		);
		this.show("erroLoginMsg", 500).then(() => {
			console.log("vai chamar focar agora")
			this.focar("erroLoginMsg");
		});
	}

	async efetuarLoginJwt(cfgsLoginTO: CfgsLoginTO) {

		const msgCarregando = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_AmaisVH_036"), true);

		cfgsLoginTO.login = cfgsLoginTO.login || this.getValor("username");
		cfgsLoginTO.senha = cfgsLoginTO.senha || this.getValor("password");

		if (!cfgsLoginTO.skipOauth2 && cfgsLoginTO.identityOauth2) {
			await this.efetuarLoginOauth2(cfgsLoginTO);
			return;
		}

		let msgErro = null;
		let hashInicio = null;
		let tokenJwt = null;
		let infosComplementares = null;
		
		try {
			const response = await filaAcessoVH.fetchRespeitandoFila(UtilBoot.getURLBaseBackend() + 'login-jwt', {
				method: "POST",
				headers: {
					"Content-type": 'application/json'
				},
				body: JSON.stringify({
					username: cfgsLoginTO.login,
					password: cfgsLoginTO.senha,
					chaveDeAcesso: cfgsLoginTO.chaveDeAcesso			
				})
			});

			if (response.ok) {
				hashInicio = response.headers.get("fp-hash-inicio");
				tokenJwt = response.headers.get("fp-token-jwt");
				try {
					infosComplementares = JSON.parse(response.headers.get("fp-infos-complementares"));
				} catch (ignored) {}
			} else {
				const responseBody = await response.json();
				msgErro = responseBody.msgErro;
			}

		} catch (e) {
			msgErro = e.msgErro || this.getMsg("FP_FRONT_AmaisVH_005");
			this.logger.error(msgErro, e);
		}

		if (msgErro) {
			this.addMsgErro(msgErro);
			if(cfgsLoginTO.onErro){
				cfgsLoginTO.onErro(msgErro);
			}

			msgCarregando.remove();
			return;
		}

		if (hashInicio !== null) {
			await UtilHash.carregarHash(hashInicio);
			msgCarregando.remove();
			return;
		}
		
		if (tokenJwt) {

			AmaisVH.isUsuarioAutenticado = true;
			UtilAuth.setJwtToken(tokenJwt);
			
			try {
				await UtilLog.enviarBuffer();
			} catch (ignored) {}

			if (cfgsLoginTO.onUsuarioAutenticado) {
				cfgsLoginTO.onUsuarioAutenticado(infosComplementares);

			} else {
				setTimeout(() => {
					const url = new URL(document.URL);
					url.pathname = "/";
					document.location = url.href;
				}, 200);
			}

			return;
		} 

		throw new Error("Erro no login. Situação não prevista!");
	}

	async efetuarLoginOauth2(cfgsLoginTO: CfgsLoginTO) {

		const identityOauth2 = cfgsLoginTO.identityOauth2;
		
		const identityData = {
			grant_type: identityOauth2.grant_type,
			username: cfgsLoginTO.login,
			password: cfgsLoginTO.senha,
			scope: identityOauth2.scope,
			client_id: identityOauth2.client_id,
			client_secret: identityOauth2.secret
		}

		const divMsgAjax = AmaisVH.criarDivMsgAjax(this.getMsg("FP_FRONT_LoginVH_028"));
		let isResponseTokenOK = false;
		let responseTokenData = null;
		let msgErro = null;

		try {
			const responseToken = await fetch(identityOauth2.domain + "/token", {
				method: "POST",
				headers: {
					"Content-type": "application/x-www-form-urlencoded"
				},
				body: new URLSearchParams(identityData).toString()
			});
			
			isResponseTokenOK = responseToken.ok;
			responseTokenData = await responseToken.json();
			msgErro = responseTokenData?.error_description;

			if (isResponseTokenOK && responseTokenData?.access_token) {
				
				const loginIdentityOauth2TO = {
					username: identityData.username,
					token: responseTokenData.access_token,
					host: this.getHostname()
				};

				const autenticacaoSSOTO = await this.call("LoginWebFCD/loginIdentityOauth2", loginIdentityOauth2TO);

				if (autenticacaoSSOTO?.msgErro) {
					msgErro = autenticacaoSSOTO.msgErro;

				} else if (autenticacaoSSOTO?.token) {
					UtilAuth.setJwtToken(autenticacaoSSOTO.token);

					setTimeout(() => {
						document.location = UtilBoot.getURLBaseFrontend();
					}, 200);

					return; // único caso de sucesso
				}
			}

		} catch (e) {
			msgErro = e.msgErro || msgErro;

		} finally {
			divMsgAjax.remove();
		}

		this.logger.warn("Erro no login oauth2: " + msgErro);

		if (identityOauth2.skip === true) {
			cfgsLoginTO.skipOauth2 = true;
			await this.efetuarLoginJwt(cfgsLoginTO);
			return;
		}

		this.addMsgErro(msgErro);
	}

	exibirAlteracaoSenhaPreLogin(token) {

		this.limpar();
		this.setTitulo(this.getMsg("MSG_VH_L_82"));
		this.setSubtitulo(this.getMsg("MSG_VH_L_83"));

		this.append(`
			<div class="col-lg-4 col-md-3 col-xs-12"></div>
			<div class="col-lg-4 col-md-6 col-xs-12">
		`);

		this.addFormulario();

		this.addTexto(this.getMsg("MSG_VH_L_84"));

		this.addCampoTexto({
			tipo: "SENHA",
			id: "senha_atual",
			classe: "col-md-12",
			label: this.getMsg("MSG_VH_L_85"),
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_011")
		});

		this.addCampoTexto({
			tipo: "SENHA",
			id: "senha_nova",
			classe: "col-md-12",
			label: this.getMsg("MSG_VH_L_86"),
			html: "autocomplete='new-password'",
			validacaoSenha: true,
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_012")
		});

		this.addCampoTexto({
			tipo: "SENHA",
			id: "senha_nova_confirmacao",
			classe: "col-md-12",
			label: this.getMsg("MSG_VH_L_87"),
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_013")
		});

		this.append(`
				<div class="col-xs-12">
		`);
		
		this.addBotao({
			label: this.getMsg("MSG_VH_L_88"), 
			classe: "btn-primary", 
			css: "color: white",
			ariaLabel: this.getMsg("FP_FRONT_LoginVH_014"),
			onClick: async () => {

				if (!this.validarCamposObrigatorios("senha_atual", "senha_nova", "senha_nova_confirmacao")) return;

				let alteracaoSenhaPreLoginTO = {
					tokenAlteracaoSenha: token,
					senhaAtual: this.getValor("senha_atual"),
					novaSenha: this.getValor("senha_nova"),
					novaSenhaConfirmacao: this.getValor("senha_nova_confirmacao"),
				}

				let novaSenha = String(this.getValor("senha_nova"));
				let novaSenhaConfirmacao = String(this.getValor("senha_nova_confirmacao"));

				if (!this.isEmpty(novaSenha) && !UtilPasswordValidator.isSenhaForte("senha_nova")) {
					await this.exibirAlerta({ msg: UtilMsg.getMsg('FP_FRONT_CadastroUsuarioVH_040') });
					return false;
				}

				if (novaSenha.length < 6) {
					await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_89") });
					return;
				}

				if (novaSenha.length != novaSenhaConfirmacao.length) {
					await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_90") });
					return;
				}

				for (let i = 0; i < novaSenha.length; i++) {
					if (novaSenha.charCodeAt(i) != novaSenhaConfirmacao.charCodeAt(i)) {
						await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_91") });
						return;
					}
				}

				const login = await this.call("LoginFCD/alterarSenhaPreLogin", alteracaoSenhaPreLoginTO);

				if (login == null) {
					await this.exibirAlerta({ msg: this.getMsg("MSG_VH_L_92") });
					return;
				}

				this.addEspacamentoHorizontal("10px");
				this.addMsgSucesso(this.getMsg("MSG_VH_L_93"));
				this.exibir();
				setTimeout(() => {
					this.onLogout();
				}, 1000);
			}
		});

		this.append(`
				</div>
		`);

		this.fecharFormulario();

		this.append(`
			</div>
			<div class="col-lg-4 col-md-3 col-xs-12"></div>
		`);

		this.exibir();
	}

	async onLogout(closeWindow = false) {

		try {
			await $(document).triggerHandler("fp-pre-logoff");
		} catch (e) {
			this.logger.error("Erro ao chamar fp-pre-logoff:", e);
		}

		if (this.getIsLoginAuth0()) {
			await this.handleLogoutAuth0(false);
		}

		let proximaPagina = UtilBoot.getURLBaseFrontend();

		try {
			const preLogoutTO = await this.call({
				endpoint: "LoginWebFCD/preLogout",
				onRequestError: async () => BackendRequestError.ERRO_TRATADO
			});
			proximaPagina = preLogoutTO?.proximaPagina || proximaPagina;
		} catch (ignored) {}

		try {
			this.limparOrdenacaoSalva();
			this.limpar();
			this.addTexto(this.getMsg("FP_FRONT_LoginVH_001"));
			this.exibir();
			UtilAuth.limparJwtToken();
			this.limparConfsLinkExterno();
		} catch (e) { }

		try {
			await UtilLog.enviarBuffer();
		} catch (ignored) {}

		if (closeWindow) {
			document.location.replace("about:blank");
		} else {
			document.location = proximaPagina;
		}
	}

	limparConfsLinkExterno() {
		localStorage.removeItem("isAcessoLinkExterno");
		localStorage.removeItem("encerrarAposConcluirProva");
	}

	setIsAutenticacaoLTI(isLTI: boolean) {
		this.isLTI = isLTI;
	}

	isAutenticacaoLTI() {
		return this.isLTI == true;
	}

	async cancelarInscricaoEmail(jwt) {
		try {
			await this.call("EmailEnvioFCD/cancelarInscricao", jwt);
			
			this.limpar();
			this.setTitulo(this.getMsg("FP_FRONT_LoginVH_002"))
			this.addTextoAlerta({ texto: `<i class='fa fa-check'></i> ${this.getMsg("FP_FRONT_LoginVH_004")}` });
			this.exibir();

		} catch (e) {
			this.limpar();
			this.addTextoErro({
				id: "erroLoginMsg",
				texto: this.getMsg("FP_FRONT_LoginVH_003"),
				ariaLabel: this.getMsg("FP_FRONT_LoginVH_003")
			});
			this.exibir();
			this.focar("erroLoginMsg");
			this.logger.error(e);
		}
	}

	async exibirArquivoDeLogs(codUsuarioSessaoWeb) {

		this.limpar();

		this.setTitulo("Logs do front");
		this.setSubtitulo("Sessão " + codUsuarioSessaoWeb);

		const listaArquivoDetalhadoTO: ArquivoDetalhadoTO[] = await this.call("CadastroUsuarioFCD/recuperarArquivosDeLog", codUsuarioSessaoWeb);
		let listaArquivoDetalhadoTOPorIdNavegador: ArquivoDetalhadoTO[] = null;
		let faltaCarregarPorIdNavegador = true;
		let listaLogs = [];

		for (const arquivoDetalhadoTO of listaArquivoDetalhadoTO) {
			const response = await UtilLog.readGzFileContent(arquivoDetalhadoTO.urlPreAssinada);

			if (faltaCarregarPorIdNavegador) {

				listaArquivoDetalhadoTOPorIdNavegador = await this.call("CadastroUsuarioFCD/recuperarArquivosDeLog", null, response.idNavegador);

				this.addTexto(`<strong>${this.getMsg("FP_FRONT_LoginVH_033")}: </strong> ${await this.montarUserAgent(response.agente)}`);
				this.addTexto(`<strong>${this.getMsg("FP_FRONT_LoginVH_034")}: </strong> ${response.idNavegador}`);
				this.addTexto(`<strong>${this.getMsg("FP_FRONT_LoginVH_035")}: </strong> ${response.codUsuarioSessaoWeb}`);
				this.addTexto(`<strong>${this.getMsg("FP_FRONT_LoginVH_036")}: </strong> ${response.codUsuario}`);
				this.addTexto(`<strong>${this.getMsg("FP_FRONT_LoginVH_037")}: </strong> ${response.codEmpresa}`);

				faltaCarregarPorIdNavegador = false;
			}

			for (const log of response.logs) {
				log.nomeArquivo = arquivoDetalhadoTO.nomeArquivo;
				log.idNavegador = response.idNavegador;
				log.codUsuario = response.codUsuario;
				log.codUsuarioSessaoWeb = response.codUsuarioSessaoWeb;
				listaLogs.push(log);
			}
		}
		
		for (const arquivoDetalhadoTO of listaArquivoDetalhadoTOPorIdNavegador) {
			const response = await UtilLog.readGzFileContent(arquivoDetalhadoTO.urlPreAssinada);

			for (const log of response.logs) {
				log.key = arquivoDetalhadoTO.nomeArquivo;
				log.idNavegador = response.idNavegador;
				log.codUsuario = response.codUsuario;
				log.codUsuarioSessaoWeb = response.codUsuarioSessaoWeb;
				listaLogs.push(log);
			}
		}

		listaLogs.sort((a, b) => a.contador - b.contador);

		const colunas: ColunaAddTabela[] = [{
			titulo: "Arquivo", 
			prop: "nomeArquivo"
		}, {
			titulo: "Id Navegador", 
			prop: "idNavegador"
		}, {
			titulo: "Id Log Navegador", 
			prop: "contador"
		}, {
			titulo: "Usuário", 
			prop: "codUsuario"
		}, {
			titulo: "Sessão", 
			prop: "codUsuarioSessaoWeb"
		}, {
			titulo: "Data do computador do usuário", 
			prop: "data", 
			formato: "DD/MM/YYYY HH:mm:ss.SSS"
		}, {
			titulo: "Logger", 
			prop: "logger" 
		}, {
			titulo: this.getMsg("FP_FRONT_LoginVH_039"), 
			prop: (log) => {
				switch (log.level) {
					case "DEBUG": return `<span class="label" style="color: #555">DEBUG</span>`;
					case "INFO": return `<span class="label" style="color: #555">INFO</span>`;
					case "WARN": return `<span class="label label-warning">WARN</span>`;
					case "ERROR": return `<span class="label label-danger">ERROR</span>`;
					default: return log;
				}
			}
		}, {
			titulo: this.getMsg("FP_FRONT_LoginVH_040"), 
			prop: (log) => {
				let msg = log.msg;
				if (Array.isArray(msg) && msg.length == 1) {
					msg = msg[0];
				}
				if (typeof msg === "string") {
					return msg;
				}
				return `<pre style="text-align: left;">${JSON.stringify(msg, null, 4)}</pre>`
			}
		}];

		await this.addTabela({
			collection: listaLogs,
			id: "listagemLogs",
			ordenar: false,
			colunas: colunas
		});

		this.exibir();

		$(`td[prop-listagem="codUsuarioSessaoWeb"]:empty`).closest("tr").css("opacity", "0.5");

		const trs = $("#listagemLogs tbody tr").get();

		for (let i = 0; i < trs.length; i++) {

			const tr1 = trs[i];
			const tr2 = trs[i + 1];

			if (!tr2) continue;

			const contador1 = $(tr1).find(`td[prop-listagem="contador"]`).text();
			const contador2 = $(tr2).find(`td[prop-listagem="contador"]`).text();

			if (this.isEmpty(contador1) || this.isEmpty(contador2)) continue;

			const diferenca = Number(contador2) - Number(contador1);

			if (diferenca > 1) {
				const colspan = $(tr1).find("td").length;
				$(tr1).after(`
					<tr class="alert-warning">
						<td colspan="${colspan}">
							Buraco de ${diferenca - 1} logs
						</td>
					</tr>
				`);
			}
		}
	}
}

const loginVH = new LoginVH();

class CfgsLoginTO {
	login: string = null;
	senha: string = null;
	nomeLabelLogin: string = null;
	habilitarMascaraCPFNoLogin: boolean = false;
	habilitarMascaraCPFNaSenha: boolean = false;
	msgErro: string = null;
	msg: string = null;
	perguntaSecreta: any = null;
	identityOauth2: any = null;
	auth0: any = null;
	onBeforeLogin: Function = null;
	onUsuarioAutenticado: Function = null;
	onErro: Function = null;
	chaveDeAcesso: string = null;
	skipOauth2: boolean = false;
}
type ArquivoDetalhadoTO = {
	nomeArquivo: string;
	keyArmazenamento: string;
	size: number;
	lastModified: string;
	urlPreAssinada: string;
}
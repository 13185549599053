class UtilHash {

	static logger = UtilLog.getLogger(UtilHash.name);

	static metodoVHPorIdOperacao = new Map();
	static idOperacaoPorMetodoVH = new Map();
	static vhPorMetodoVH = new Map();
	
	static penultimoHashUtilizado: string;
	static ultimoHashUtilizado: string;
	static hashAlteradoParaHistorico: string;
	static vaiCarregarHashNaCargaDaPagina: boolean;
	static ultimoEventoAddressChanged: Event;
	static metodoVHInicialDoPerfil: string;
	static metodoVHInicial: string;
		
	static handleDocumentReady() {

		try {
			if (!fpLauncherVH.isLaunched()) return;
		} catch (ignored) {}

		const hash = $.address.value();

		// if (hash == "" || hash == "/") {

		// 	const hashCookie = localStorage.getItem("fp_hash_login");

		// 	if (hashCookie != null && hashCookie.toString().trim().length > 0) {
		// 		localStorage.removeItem("fp_hash_login");
		// 		$.address.value(hashCookie);
		// 	}
		// } else {
			if (UtilHash.getMetodoVH(hash) != null) {
				UtilHash.vaiCarregarHashNaCargaDaPagina = true;
			}
		// }
	}

	static isHashCarregadoNoCarregamentoDaPagina() {
		return UtilHash.vaiCarregarHashNaCargaDaPagina === true;
	}

	static isEventoDuplicado(event: Event) {
		
		if (event == null) {
			return false;
		}
		
		const e1: any = UtilHash.ultimoEventoAddressChanged;
		const e2: any = event;
		
		UtilHash.ultimoEventoAddressChanged = event;

		if (e1 == null) {
			return false;
		}

		if (e2.timeStamp > e1.timeStamp && e2.timeStamp - e1.timeStamp < 500 && e2.path == e1.path) {
			UtilHash.logger.warn("Evento duplicado: ", e1, e2);
			return true;
		}

		return false;
	}

	static async handleHashChanged(event: Event) {

		try {
			if (!fpLauncherVH.isLaunched()) return;
		} catch (ignored) {}

		if (UtilHash.isEventoDuplicado(event)) return;

		var hash = $.address.value();
		var ignorarHistorico = false;

		if (UtilHash.ultimoHashUtilizado) {
			UtilHash.logger.info(`Usuário navegou do hash ${UtilHash.ultimoHashUtilizado} para o hash ${hash}`);
		} else {
			UtilHash.logger.info(`Usuário navegou para o hash ${hash}`);
		}

		try {
			var metodoVH = UtilHash.getMetodoVH(hash);
			if (metodoVH && metodoVH.ignorarHistorico == true) {
				ignorarHistorico = true;
			}
		} catch (ignored) {}
	
		if (!ignorarHistorico) {
			UtilHash.penultimoHashUtilizado = UtilHash.ultimoHashUtilizado == null ? null : UtilHash.ultimoHashUtilizado;
			UtilHash.ultimoHashUtilizado = hash;
		}
	
		if (hash == UtilHash.hashAlteradoParaHistorico) {
			UtilHash.hashAlteradoParaHistorico = null;
	
		} else {
			try {
				/*let carregouHash = */await UtilHash.carregarHash(hash);
				// if (!carregouHash || AmaisVH.codUsuarioLogado == null && !hash.includes("/fa/?uuid=")) {
				// 	localStorage.setItem("fp_hash_login", hash);
				// } else {
				// 	localStorage.removeItem("fp_hash_login");
				// }
		
				UtilHash.hashAlteradoParaHistorico = null;
			} catch (e) {
				UtilHash.logger.error("Erro ao carregar hash", e);
			}
		}

		setTimeout(() => {AmaisVH.dispararAjusteAcessibilidade()}, 300);
	}
	
	static getMetodoVH(hash: string) {
		try {
			if (typeof hash !== "string") return null;
			let idOperacao = hash;
			if (hash.includes("/")) {
				idOperacao = hash.split("/")[1];
			}
			return UtilHash.metodoVHPorIdOperacao.get(idOperacao);
		} catch (e) {
			return null;
		}
	}

	static addOperacao(vh, idOperacao, metodoVH, ignorarHistorico) {

		if (metodoVH === undefined || metodoVH === null) return;

		if (UtilHash.getMetodoVH(idOperacao) != null) {
			UtilHash.logger.warn("Ignorando hash " + idOperacao + " pois já foi utilizado.", vh, idOperacao, metodoVH, ignorarHistorico);
			return;
		}

		if (ignorarHistorico) {
			metodoVH.ignorarHistorico = ignorarHistorico;
		}
		
		UtilHash.metodoVHPorIdOperacao.set(idOperacao, metodoVH);
		UtilHash.idOperacaoPorMetodoVH.set(metodoVH, idOperacao);
		UtilHash.vhPorMetodoVH.set(metodoVH, vh);
	}

	static getVH(metodoVH) {
		if (metodoVH === undefined || metodoVH === null) return null;
		return UtilHash.vhPorMetodoVH.get(metodoVH);
	}

	static isMetodoVHComHash(metodoVH) {
		const idHash = UtilHash.getIdOperacao(metodoVH);
		return idHash !== undefined && idHash !== null;
	}
	
	static getIdOperacao(metodoVH) {
		if (metodoVH === undefined || metodoVH === null) return null;
		return UtilHash.idOperacaoPorMetodoVH.get(metodoVH);
	}
	
	static registrarHistorico(metodoVH, ...params) {
		if (!metodoVH || metodoVH.naoRegistrarHistorico == true || $.address == null) {
			return;
		}

		var hash = UtilHash.getHash(metodoVH, ...params);

		if (hash == null) return;

		hash = hash.substring(2); // tira o #/

		if (hash != null && hash != $.address.value()) {
			UtilHash.hashAlteradoParaHistorico = "/" + hash;

			$.address.value(hash);
		}
	}

	static setMetodoVHInicialDoPerfil(metodoVHInicialDoPerfil: string) {
		UtilHash.metodoVHInicialDoPerfil = metodoVHInicialDoPerfil;
	}

	static setMetodoVHInicial(metodoVHInicial: string) {
		UtilHash.metodoVHInicial = metodoVHInicial;
	}

	static carregarTelaInicialDoPerfil() {
		eval(UtilHash.metodoVHInicialDoPerfil);
	}

	static carregarTelaInicial() {

		const hashAtual = $.address.value();

		if (hashAtual == "/") {
			document.location.reload();
			
		} else {
			$.address.value("/");
		}
	}
	
	static async carregarHash(hash) {

		if (hash == null || hash == "" || hash == "/") {
			eval(UtilHash.metodoVHInicial);
			return false;

		} else {
			var params = hash.split("/");

			var idOperacao = params[1];
			params = params.slice(2);

			var metodoVH = UtilHash.getMetodoVH(idOperacao);

			if (metodoVH != null) {
				metodoVH.naoRegistrarHistorico = true;
				var vh = UtilHash.getVH(metodoVH);

				for (var i = 0; i < params.length; i++) {

					if (params[i] == null || params[i] == "") {
						params[i] = null;
					} else {
						try {
							params[i] = JSON.parse(decodeURIComponent(params[i]));
						} catch (ignored) {
							try {
								params[i] = JSON.parse(params[i]);
							} catch (ignored2) {}
						}
						try {
							UtilJson.resolverEnuns(params[i]);
						} catch (ignored) {
						}
					}
				}

				try {
					await metodoVH.call(vh, ...params);
				} catch (e) {
					UtilHash.logger.error(e);
				}

				metodoVH.naoRegistrarHistorico = null;
				return true;

			} else {
				var paramsGet = idOperacao.split("&");
				var ps = {};
				metodoVH = null;

				for (var i = 0; i < paramsGet.length; i++) {
					var nomeEValor = paramsGet[i].split("=");
					var nome = nomeEValor[0];
					var valor = nomeEValor[1];

					ps[nome] = valor;

					if (metodoVH == null) {
						metodoVH = UtilHash.getMetodoVH(nome);
					}
				}

				if (metodoVH != null) {
					metodoVH.naoRegistrarHistorico = true;

					try {
						await metodoVH.call(UtilHash.getVH(metodoVH), ps);
					} catch (e) {
						UtilHash.logger.error(e);
					}
					
					metodoVH.naoRegistrarHistorico = null;
					return true;

				} else {
					return false;
				}
			}
		}
	}

	static voltar() {
		if (UtilHash.penultimoHashUtilizado != null) {
			$.address.value(UtilHash.penultimoHashUtilizado);
		} else {
			document.location.reload();
		}
	}

	static getHash(metodoVH, ...params) {

		if (!$.address || !metodoVH) {
			return null;
		}

		let idOperacao = UtilHash.getIdOperacao(metodoVH);

		if (!idOperacao) {
			return null;
		}

		let hash = ["#", idOperacao];

		// try {
		// 	for (const param of metodoVH.arguments) {
		// 		hash.push(param);
		// 	}
		// } catch (e) {
			for (const param of params) {
				hash.push(param);
			}
		// }

		return UtilHash.arrayToString(hash);
	}

	static getHashAtual() {
		const hash = $.address.value().split("/");

		for (var i = 0; i < hash.length; i++) {
			if (hash[i] == null || hash[i] == "") {
				hash[i] = null;
			} else {
				try {
					hash[i] = JSON.parse(decodeURI(hash[i]));
				} catch (ignored) {
					try {
						hash[i] = JSON.parse(hash[i]);
					} catch (ignored2) {}
				}
			}
		}		
		
		return hash;
	}

	static PATTERN_HASH_ABA_SUBABA = /([0-9]*)([a-zA-Z]*)/;

	static getHashAba(hash, i) {
		const h = hash.concat([]);
		h[2] = String(h[2]).replace(UtilHash.PATTERN_HASH_ABA_SUBABA, i + "$2");
		return UtilHash.arrayToString(h);
	}

	static getHashSubAba(hashAtual, letraSubAba) {
		const h = hashAtual.concat([]);
		h[2] = String(h[2]).replace(UtilHash.PATTERN_HASH_ABA_SUBABA, "$1" + letraSubAba);
		return UtilHash.arrayToString(h);
	}

	static arrayToString(array) {

		array = array.map((valor, i) => {
			if (i <= 1) return valor;
			if (valor == null) return null;
			if (typeof valor == "string") return valor;
			valor = JSON.stringify(valor);
			return valor == null ? "" : valor;
		});

		let indiceUltimoItem = array.length - 1;

		while ([null, "null", ""].includes(array[indiceUltimoItem])) {
			array.splice(indiceUltimoItem, 1);
			indiceUltimoItem = array.length - 1;
		}

		return array.join("/");
	}

	static removerQueryParam(nomeParam: string) {
		const url = new URL(window.location.toString());
		url.searchParams.delete(nomeParam);
		window.history.replaceState(
			{}, 
			document.title, 
			url.toString()
		);
	}

	// static salvarHash() {
	// 	let hash = $.address.value();
	// 	if (![null, "", "/", "/fa/?uuid"].includes(hash)) {
	// 		localStorage.setItem("fp_hash_login", hash);
	// 	}
	// }
}
$(document).ready(UtilHash.handleDocumentReady);
$.address.change(UtilHash.handleHashChanged);
$(document).on("fp-launched", UtilHash.handleDocumentReady);
$(document).on("fp-launched", UtilHash.handleHashChanged);
class UtilProgressBar {

	static basic(cfgs: ProgressBarCfgs) {

		if (cfgs.porcentagem == null) {
			if (cfgs.total == null || cfgs.total == 0) {
				return ""
			}
			cfgs.porcentagem = 100 * (cfgs.evolucao / cfgs.total)
		}

		cfgs.label = cfgs.label || "";

		if (cfgs.sufixo == null) {
			cfgs.sufixo = ""
		}

		const title = Math.round(cfgs.porcentagem) + "% (" + cfgs.evolucao + " de " + cfgs.total + ")";

		return `
			<div class="progress" title="${title}" style="${cfgs.css || ""}">
				<div class="progress-bar progress-bar-success" role="progressbar" aria-valuenow="${cfgs.porcentagem}" aria-valuemin="0" aria-valuemax="100" style="width: ${cfgs.porcentagem}%">
					${cfgs.label}
				</div>
			</div>
		`;
	}
	
	static dificuldade(cfgs: DificuldadeProgressBarCfgs) {

		const total = cfgs.limiteTotalSuperior - cfgs.limiteTotalInferior;
		const progresso = cfgs.progresso - cfgs.limiteTotalInferior;
		const width = 100 * progresso / total;
		const colors = ["#00af21", "#80c22c", "#ffd230", "#ed7f31", "#df2e31"];
		const ultimaStack = cfgs.dificuldades.find(d => {
			if (d.limiteSuperior === null || d.limiteSuperior === undefined) return true;
			return cfgs.progresso < d.limiteSuperior;
		});
		const indiceUltimaStack = cfgs.dificuldades.indexOf(ultimaStack);

		return `
			<div class="progress" title="${ultimaStack.nome}">
				<div class="progress-bar" style="width: ${width}%; background-color: ${colors[indiceUltimaStack]}">
					<span class="sr-only">35% Complete (success)</span>
				</div>
			</div>
		`;
	}
}

type ProgressBarCfgs = {
	css?: string;
	sufixo?: string;
	label?: string;
	evolucao: number;
	total: number;
	porcentagem?: number;	
}

type DificuldadeProgressBarCfgs = {
	limiteTotalInferior: number;
	limiteTotalSuperior: number;
	dificuldades: DificuldadeProgressBar[];
	progresso: number;
}

type DificuldadeProgressBar = {
	nome: string;
	limiteSuperior: number;
}
class ChatBotVH extends AmaisVH {

	inicializado: boolean = false;
	dadosChatBot: any;

	constructor() {
		super(ChatBotVH.name);
		this.addOperacaoParaHash("chatbot", this.exibirHistoricoChat);
	}

	setDadosChatBot(dadosChatBot: any) {
		if (!dadosChatBot) return;
		this.dadosChatBot = dadosChatBot;
	}

	inicializar() {

		if (this.inicializado) return;

		this.inicializado = true;

		if (this.isUsuarioAutenticado() && !this.isAluno()) return;

		try {
			setTimeout(() => {

				if (this.dadosChatBot) {
					chatBotVH.adicionarChatBot();

				} else if (this.isCfgHabilitada("FUNCIONALIDADE_CHAT_SUPORTE")) {
					UtilBoot.carregarPureChat(
						this.getCfg("FUNCIONALIDADE_CHAT_SUPORTE_ID"),
						this.getNomeUsuarioLogado(),
						this.getEmailUsuarioLogado(),
						this.getHostname()
					);
				}
			}, 2000);
		} catch (e) {
			this.logger.error(e);
		}
	}

	adicionarChatBot() {

		if (!this.isAluno()) return;

		if (!$('#btn-abrir-chat').length) {
			this.addFerramenta({
				id: "ferramenta-btn-abrir-chat-bot",
				html: this.addBotao({
					id: "btn-abrir-chat",
					classe: "btn btn-success btn-abrir-chat",
					texto: "<i class='fa fa-comment'></i> Precisa de ajuda?",
					onClick: async () => {
						await this.hide("btn-abrir-chat");
						await this.show("chat-container");
					},
					retornarHtml: true
				})
			});
		}

		if ($('#container-chat-bot').length) return;

		this.addFerramenta({
			id: "container-chat-bot",
			html: this.getHtmlBaseChatbot()
		});

		this.appendJs(() => this.listenToEnter("#user-input", async () =>{
			await this.enviarMensagem();
		}));

		this.exibir();
	}

	private adicionarMensagem(msg) {
		const chatBotBody = $('#chat-body')

		chatBotBody.append(msg);
		chatBotBody.scrollTop(chatBotBody[0].scrollHeight);
	}

	private adicionarMensagemUsuario(texto: string) {
		const timeStamp = UtilData.toDDMMYYYYHHMMSS(new Date());
		const html = this.getHtmlMsgUsuario(timeStamp, texto);

		this.adicionarMensagem(html);
	}

	private adicionarMensagemBot(texto?: string) {
		const timeStamp = UtilData.toDDMMYYYYHHMMSS(new Date());
		const html = texto ? this.getHtmlMsgBot(timeStamp, false, texto) : this.getHtmlMsgBot(timeStamp, true);

		this.adicionarMensagem(html);
	}

	async alternarParaSuporteHumano() {
		if (!this.isCfgHabilitada("FUNCIONALIDADE_CHAT_SUPORTE")) return;
		await this.fecharChat();
		await this.carregarPurechat();
	}

	private async carregarPurechat() {

		const msgCarregando = AmaisVH.criarDivMsgAjax("Carregando o chat de suporte...");

		if (!window["purechatApi"]) {
			await UtilBoot.carregarPureChat(
				amaisVH.getCfg("FUNCIONALIDADE_CHAT_SUPORTE_ID"),
				amaisVH.getNomeUsuarioLogado(),
				amaisVH.getEmailUsuarioLogado(),
				amaisVH.getHostname(),
				`${UtilBoot.getURLBaseFrontend()}#/chatbot/${amaisVH.getCodUsuarioSessaoWebLogado()}`,
			);
		}

		let tempoDecorrido = 0;
		const interValoChecagemPurechat = setInterval(() => {
			const pureChat = $('.purechat-collapsed-image');
			if (tempoDecorrido >= 5000 || pureChat?.length) {
				pureChat.click();
				msgCarregando.remove();
				clearInterval(interValoChecagemPurechat);
			}
			tempoDecorrido += 500;
		}, 500);
	}

	async enviarMensagem() {

		if ($('.digitando').length) return;

		const texto = this.getValor("user-input");
		if (this.isEmpty(texto)) return;
		this.setValor("user-input", "");
		this.adicionarMensagemUsuario(texto);

		this.toggleLoading(true);
		const fpMsNodeUrl = UtilMS.getFPMsNodeUrl();
		const response = await fetch(`${fpMsNodeUrl}/cs-zero/chatbot`, {
			method: "POST",
			body: JSON.stringify({
				msgUsuario: texto,
				nomeUsuario: amaisVH.getNomeUsuarioLogado(),
				codUsuarioSessaoWeb: amaisVH.getCodUsuarioSessaoWebLogado(),
				informacoesProva: this.dadosChatBot
			})
		});
		this.toggleLoading(false);

		const json = await response.json();

		if (!response.ok) {
			this.adicionarMensagemBot("Ops! Algo deu errado. Tente novamente mais tarde.");
			return;
		}

		if (json.msgBot.includes("Posso te encaminhar para o atendimento humano?") && this.isCfgHabilitada("FUNCIONALIDADE_CHAT_SUPORTE")) {
			json.msgBot += "<p>Clique <a onclick='chatBotVH.alternarParaSuporteHumano()'>aqui</a> para habilitar o chat de contato com o suporte.</p>";
		}
		
		this.adicionarMensagemBot(json.msgBot);
	}

	async fecharChat() {
		await this.hide("chat-container");
		await this.show("btn-abrir-chat");
	}

	private getHtmlBaseChatbot() {
		return `
			<div class="chat-container" id="chat-container" style="display: none">
				<div class="chat-header">
					<h2>CS0 - ASSISTENTE</h2>
					<button class="close-btn" onclick="chatBotVH.fecharChat()"><i class="fa fa-close"></i></button>
				</div>
				<div class="chat-body" id="chat-body">
				
					<div class="message-container bot-message-container">
						<div class="bot-icon"><i class="fa fa-user"></i></div>
						<div class="message-wrapper">
							<div class="message bot-message">
								Oi ${amaisVH.getPrimeiroNomeUsuarioLogado()}! 👋 <br/>
								Sou um assistente baseado em IA e posso responder suas dúvidas.
							</div>
							<span class="timestamp">${UtilData.toDDMMYYYYHHMMSS(new Date()).split(" ")[1]}</span>
						</div>
					</div>	
						
				</div>
				<div class="chat-footer">
					<input type="text" id="user-input" placeholder="Digite sua pergunta...">
					<button id="btn-enviar-msg" onclick="chatBotVH.enviarMensagem()"><i class="fa fa-paper-plane"></i></button>
				</div>
			</div>
		`;
	}

	getHtmlMsgUsuario(timeStamp, texto) {
		return `
			<div class="message-container">
				<div class="message-wrapper">
					<div class="message user-message">
						${texto}
					</div>
					<span class="timestamp user-timestamp" data-hora="${timeStamp}">${timeStamp.split(" ")[1]}</span>
				</div>
			</div>
		`
	}

	getHtmlMsgBot(timestamp: string, isDigitando = false, texto?: string) {
		return `
			<div class="message-container bot-message-container ${isDigitando ? 'digitando' : ''}">
				<div class="bot-icon"><i class="fa fa-user"></i></div>
				<div class="message-wrapper">
					<div class="message bot-message">
						${isDigitando ? this.getHtmlEfeitoDigitando() : texto}
					</div>
					<span class="timestamp" data-hora="${timestamp}">${timestamp.split(" ")[1]}</span>
				</div>
			</div>
		`
	}

	getHtmlEfeitoDigitando() {
		return `
			<div class="typing">
				<div class="dot"></div>
				<div class="dot"></div>
				<div class="dot"></div>
			</div>
		`
	}

	toggleLoading(isCarregando: boolean) {
		if (isCarregando) {
			this.adicionarMensagemBot();
			this.disable('btn-enviar-msg');
			return;
		}
		$('.digitando').remove();
		this.enable('btn-enviar-msg');
	}

	async exibirHistoricoChat(nomeUsuario, codUsuarioSessaoWeb) {
		this.limpar();

		if (!this.isAdministrador()) {
			UtilHash.carregarTelaInicial();
			return;
		}

		this.setTitulo("Histórico Chatbot");
		this.setSubtitulo(`${nomeUsuario} (Sessão #${codUsuarioSessaoWeb})`);

		const response = await fetch(`${UtilMS.getFPMsNodeUrl()}/cs-zero/chatbot?usw=${codUsuarioSessaoWeb}`, {
			method: "GET",
		});
		let json = null;

		try {
			json = await response.json();
		} catch (_) {}

		if (!response.ok) {
			this.addTextoAlerta({ texto: "Ocorreu um erro ao recuperar as mensagens: " + JSON.stringify(json) });
			this.exibir();
			return;
		}

		const historicoChat = json;

		if (!historicoChat?.historico) {
			this.addTextoAlerta({ texto: "Não há histórico de chat" });
			this.exibir();
			return;
		}

		const html: string[] = [];

		historicoChat.historico.forEach(h => {
			const timeStamp = UtilData.toDDMMYYYYHHMMSS(h.timestamp);
			const textoMsg = h.content;
			if (h.role === 'user') {
				html.push(chatBotVH.getHtmlMsgUsuario(timeStamp, textoMsg))
			} else if (h.role === 'assistant') {
				html.push(chatBotVH.getHtmlMsgBot(timeStamp, false, textoMsg))
			}
		})

		this.append(`
			<div class="chat-container" style="width: 600px">
				<div class="chat-body">
					${html.join("")}
				</div>
			</div>
		`)

		this.exibir();
	}
}

const chatBotVH = new ChatBotVH();

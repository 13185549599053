class UtilNumero {

	static NUMBER_FORMAT = new Intl.NumberFormat('pt-BR');
	static MOEDA_NUMBER_FORMAT = new Intl.NumberFormat('pt-BR', { style: 'currency', currency: 'BRL' });

	static from(valor: any) {
		if (!valor) return null;
		if (typeof valor === "string") return Number(valor);
		if (Array.isArray(valor)) return valor.map((v: any) => Number(v));
		throw new Error(`Formato não esperado: ${typeof valor}`);
	}

	static formatar(n: number) {
		return UtilNumero.NUMBER_FORMAT.format(n);
	}
	
	static floatToString(f: number | string, numCasasDecimais: number = 2) {

		if (f == null) {
			return null;
		}

		if (typeof f === "string") {
			f = Number(f);
		}

		if (Math.round(f) !== f) {
			if (String(Math.round(f)) !== f.toFixed(numCasasDecimais)) {
				f = Number(f.toFixed(numCasasDecimais));
			} else {
				f = Math.round(f);
			}
		}

		return UtilNumero.formatar(f).replace(/(,[1-9]*)(0+)$/, "$1").replace(/,0*$/, "");
	}
	
	static stringToFloat(numero: string | number): number {
		if (numero === undefined || numero === null) return null; 
		if (typeof numero === "number" && !isNaN(numero)) return numero;
		return new Number(String(numero).replaceAll('.', '').replaceAll(',', '.')).valueOf();
	}

	static isCPFValido = (strCPF: any) => {
		if(!strCPF) return;
		if (strCPF == "00000000000") return false;

		let soma = 0;

		for (let i = 1; i <= 9; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (11 - i);

		let resto = (soma * 10) % 11;

		if ((resto == 10) || (resto == 11)) resto = 0;
		if (resto != parseInt(strCPF.substring(9, 10))) return false;

		soma = 0;

		for (let i = 1; i <= 10; i++) soma = soma + parseInt(strCPF.substring(i - 1, i)) * (12 - i);

		resto = (soma * 10) % 11;

		if ((resto == 10) || (resto == 11)) resto = 0;

		return resto == parseInt(strCPF.substring(10, 11));
	}

	static validarCPF(campo: any) {

		$(campo).closest(".form-group").removeClass("has-error").tooltip("destroy");

		const cpf = UtilString.getSomenteNumeros($(campo).val());

		if (!cpf || cpf == "") return;

		if (!UtilNumero.isCPFValido(cpf)) {
			$(campo).closest(".form-group").addClass("has-error").tooltip({
				title: UtilMsg.getMsg("FP_FRONT_AmaisVH_041")
			});
			return false;
		}

		return true;
	}

	static validarCNPJ(campo) {

		$(campo).closest(".form-group").removeClass("has-error").tooltip("destroy");

		const isCNPJValido = (cnpj) => {

			cnpj = cnpj.replace(/[^0-9]/g, '');

			if (cnpj == '') return false;

			if (cnpj.length != 14) return false;

			// Valida DVs
			let tamanho = cnpj.length - 2
			let numeros = cnpj.substring(0,tamanho);
			let digitos = cnpj.substring(tamanho);
			let soma = 0;
			let pos = tamanho - 7;
			for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2) pos = 9;
			}
			
			let resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;

			if (resultado != digitos.charAt(0)) return false;

			tamanho = tamanho + 1;
			numeros = cnpj.substring(0,tamanho);
			soma = 0;
			pos = tamanho - 7;
			for (let i = tamanho; i >= 1; i--) {
				soma += numeros.charAt(tamanho - i) * pos--;
				if (pos < 2) pos = 9;
			}
			
			resultado = soma % 11 < 2 ? 0 : 11 - soma % 11;
			
			if (resultado != digitos.charAt(1)) return false;

			return true;
		}

		const cnpj = UtilString.getSomenteNumeros($(campo).val());

		if (!cnpj || cnpj == "") return;

		if (!isCNPJValido(cnpj)) {
			$(campo).closest(".form-group").addClass("has-error").tooltip({ title: UtilMsg.getMsg("FP_FRONT_UtilNumero_001") });
		}
	}

	static formatarMoeda(valor: number) {
		if (valor === null || valor === undefined) return "";
		if (typeof valor !== "number") valor = Number(valor);
		return this.MOEDA_NUMBER_FORMAT.format(valor);
	}
}


class UtilLog {

	static LOG_BUFFER: ItemLog[] = [];
	static TS_ULTIMO_ENVIO = Date.now();
	static MS_ENVIO_BUFFER = 5 * 60 * 1000;
	static MS_ESPERA_PARA_ENVIO_APOS_ERRO = 60 * 1000;
	static IS_CRONO_TICK_ENVIANDO = false;
	static PROMISE_ENVIAR_BUFFER: Promise<void> = null;
	static LETRAS = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz';

	static CONSOLE_DEBUG_ORIGINAL = null;
	static CONSOLE_LOG_ORIGINAL = null;
	static CONSOLE_INFO_ORIGINAL = null;
	static CONSOLE_WARN_ORIGINAL = null;
	static CONSOLE_ERROR_ORIGINAL = null;

	static inicializar() {

		UtilLog.CONSOLE_DEBUG_ORIGINAL = console.debug;
		UtilLog.CONSOLE_LOG_ORIGINAL = console.log;
		UtilLog.CONSOLE_INFO_ORIGINAL = console.info;
		UtilLog.CONSOLE_WARN_ORIGINAL = console.warn;
		UtilLog.CONSOLE_ERROR_ORIGINAL = console.error;

		console.debug = (...params) => {
			UtilLog.addConsoleToBuffer("DEBUG", params);
			UtilLog.CONSOLE_DEBUG_ORIGINAL(...params);
		}

		console.log = (...params) => {
			UtilLog.addConsoleToBuffer("INFO", params);
			UtilLog.CONSOLE_LOG_ORIGINAL(...params);
		}

		console.info = (...params) => {
			UtilLog.addConsoleToBuffer("INFO", params);
			UtilLog.CONSOLE_INFO_ORIGINAL(...params);
		}

		console.warn = (...params) => {
			UtilLog.addConsoleToBuffer("WARN", params);
			UtilLog.CONSOLE_WARN_ORIGINAL(...params);
		}

		console.error = (...params) => {
			UtilLog.addConsoleToBuffer("ERROR", params);
			UtilLog.CONSOLE_ERROR_ORIGINAL(...params);
		}

		window.addEventListener("unhandledrejection", (e) => console.error(e));
		window.addEventListener("error", (e) => UtilLog.addEventToBuffer("ERROR", e));
		window.addEventListener("offline", (e) => UtilLog.addEventToBuffer("WARN", e));
		window.addEventListener("online", (e) => {
			UtilLog.addEventToBuffer("INFO", e);
			UtilLog.enviarBuffer();
		});
		window.addEventListener("focus", (e) => UtilLog.addEventToBuffer("INFO", e));

		window.addEventListener("blur", async (e) => {
			UtilLog.addEventToBuffer("INFO", e);
			try {
				await UtilLog.enviarBuffer();
			} catch (ignored) {}
		});
		
		window.addEventListener("beforeunload", async () => {
			try {
				UtilLog.addToBuffer((new Date()).toISOString(), "WARN", UtilLog.name, ["Descarregando página"]);
				const grupoLogs = UtilLog.criarGrupoLogs(UtilLog.LOG_BUFFER);
				UtilLog.addPendentes(grupoLogs);
			} catch (ignored) {}
		});

		UtilCrono.adicionarListener(UtilLog.handleCronoTick);

		$(document).on("fp-launched", async () => {
			await UtilLog.enviarPendentes();
		});
	}

	static criarGrupoLogs(logs: ItemLog[]): GrupoLogs {

		const idNavegador = UtilLog.getIdNavegador();
		const registroLog: GrupoLogs = {
			host: window.location.hostname,
			agente: window.navigator.userAgent,
			idNavegador: idNavegador,
			logs: logs,
			dir: "idn/" + idNavegador
		}

		try {
			if (amaisVH.getCodUsuarioLogado()) {
				registroLog.codEmpresa = amaisVH.getCodEmpresaUsuarioLogado();
				registroLog.codUsuario = amaisVH.getCodUsuarioLogado();
				registroLog.codUsuarioSessaoWeb = amaisVH.getCodUsuarioSessaoWebLogado();
				registroLog.dir = "usw/" + registroLog.codUsuarioSessaoWeb;
			}
		} catch (ignored) {}

		return registroLog;
	}

	static async handleCronoTick() {

		const agora = Date.now();

		if ((agora - UtilLog.TS_ULTIMO_ENVIO) > UtilLog.MS_ENVIO_BUFFER) {

			if (UtilLog.IS_CRONO_TICK_ENVIANDO) return;

			UtilLog.IS_CRONO_TICK_ENVIANDO = true;

			try {
				await UtilLog.enviarBuffer();
				UtilLog.TS_ULTIMO_ENVIO = agora;

			} catch (e) {
				// para esperar após erro e não ficar tentando a cada segundo do tick
				UtilLog.TS_ULTIMO_ENVIO = agora - (UtilLog.MS_ENVIO_BUFFER - UtilLog.MS_ESPERA_PARA_ENVIO_APOS_ERRO);
			}

			UtilLog.IS_CRONO_TICK_ENVIANDO = false;
		}
	}

	static getLogger(nomeLogger) {
		return {
			debug: (...params: any[]) => {
				const agora = (new Date()).toISOString();
				UtilLog.addToBuffer(agora, "DEBUG", nomeLogger, params);
				UtilLog.CONSOLE_DEBUG_ORIGINAL(`${agora} DEBUG [${nomeLogger}]`, ...params);
			},
			info: (...params) => {
				const agora = (new Date()).toISOString();
				UtilLog.addToBuffer(agora, "INFO", nomeLogger, params);
				UtilLog.CONSOLE_INFO_ORIGINAL(`${agora} INFO [${nomeLogger}]`, ...params);
			},
			warn: (...params) => {
				const agora = (new Date()).toISOString();
				UtilLog.addToBuffer(agora, "WARN", nomeLogger, params);
				UtilLog.CONSOLE_WARN_ORIGINAL(`${agora} WARN [${nomeLogger}]`, ...params);
			},
			error: (...params) => {
				const agora = (new Date()).toISOString();
				UtilLog.addToBuffer(agora, "ERROR", nomeLogger, params);
				UtilLog.CONSOLE_ERROR_ORIGINAL(`${agora} ERROR [${nomeLogger}]`, ...params);
			}
		}
	}
	
	static addEventToBuffer(level: string, event: any) {

		if (event.type === "offline") {
			UtilLog.addToBuffer((new Date()).toISOString(), level, "EVENT", ["Sem conexão de internet"]);
			return;
		}
		if (event.type === "online") {
			UtilLog.addToBuffer((new Date()).toISOString(), level, "EVENT", ["Conexão de internet voltou"]);
			return;
		}

		if (event.type === "focus") {
			UtilLog.addToBuffer((new Date()).toISOString(), level, "EVENT", ["Voltou para aba"]);
			return;
		}

		if (event.type === "blur") {
			UtilLog.addToBuffer((new Date()).toISOString(), level, "EVENT", ["Saiu da aba"]);
			return;
		}

		const eventoSerializado = {};
		for (let k in event) {
			if (["detail", "layerX", "layerY", "which", "rangeOffset", "SCROLL_PAGE_UP", 
				"SCROLL_PAGE_DOWN", "NONE", "CAPTURING_PHASE", "AT_TARGET", "BUBBLING_PHASE", 
				"ALT_MASK", "CONTROL_MASK", "SHIFT_MASK", "META_MASK"].includes(k)) {
				continue;
			}
			const value = event[k];
			if (["string", "boolean", "number"].includes(typeof value)) {
				eventoSerializado[k] = value;
			}
		}
		UtilLog.addToBuffer((new Date()).toISOString(), level, "EVENT", [eventoSerializado]);
	}

	static addConsoleToBuffer(level: string, params: any[]) {
		UtilLog.addToBuffer((new Date()).toISOString(), level, "CONSOLE", params);
	}

	static async addToBuffer(data: string, level: string, logger: string, params: any[]) {

		try {

			const paramsComErrorSerializavel = params.map(p => {
				if (p instanceof Error) {
					return {
						errorName: p.name,
						message: p.message,
						stack: p.stack
					}
				} else {
					return p;
				}
			});

			let contador = Number(localStorage.getItem("fp-util-hash-contador")) || 0;

			contador++;

			UtilLog.LOG_BUFFER.push({
				contador,
				data, 
				level, 
				logger, 
				msg: JSON.parse(JSON.stringify(paramsComErrorSerializavel))
			});

			localStorage.setItem("fp-util-hash-contador", String(contador));

			if (UtilLog.LOG_BUFFER.length > 100 || level === "ERROR") {
				await UtilLog.enviarBuffer(); // não espera com await para não bloquear o console
			}
		} catch (ignored) {}
	}

	static async enviarBuffer(): Promise<void> {

		if (UtilLog.PROMISE_ENVIAR_BUFFER !== null) {
			return UtilLog.PROMISE_ENVIAR_BUFFER;
		}
		
		let resolve;
		let reject;

		UtilLog.PROMISE_ENVIAR_BUFFER = new Promise((res, rej) => {
			resolve = res;
			reject = rej;
		});

		setTimeout(async () => {
			try {
				await UtilLog.enviarBufferParaS3();
				resolve();
			} catch (e) {
				reject(e);
			} finally {
				UtilLog.PROMISE_ENVIAR_BUFFER = null;
			}
		}, 10);

		return UtilLog.PROMISE_ENVIAR_BUFFER;
	}

	static async enviarBufferParaS3() {

		const fpMsNodeUrl = UtilMS.getFPMsNodeUrl();

		if (!fpMsNodeUrl) {
			UtilLog.CONSOLE_WARN_ORIGINAL("Descartando logs por falta de cfg.");
			return;
		}

		await UtilLog.enviarPendentes();

		const numLogsParaEnvio = UtilLog.LOG_BUFFER.length;

		if (numLogsParaEnvio === 0) return Promise.resolve();

		const logs = UtilLog.LOG_BUFFER.slice(0, numLogsParaEnvio);
		
		const grupoLogs = UtilLog.criarGrupoLogs(logs);
		
		await UtilLog.enviarParaS3(grupoLogs);

		// remove só a qtd enviada ou add como pendente
		UtilLog.LOG_BUFFER.splice(0, numLogsParaEnvio);
	}

	private static async enviarPendentes() {

		const logFrontPendente: string = localStorage.getItem("log-front-pendente");

		if (logFrontPendente) {

			// remove agora pois se enviar e der erro volta para localStorage
			localStorage.removeItem("log-front-pendente");

			const listaGrupoLogs : GrupoLogs[] = JSON.parse(logFrontPendente);

			for (const grupoLogs of listaGrupoLogs) {
				await UtilLog.enviarParaS3(grupoLogs);
			}
		}
	}

	/**
	 * Nunca lança exceção. 
	 * Se der erro, coloca no local storage para envio posterior.
	 */
	private static async enviarParaS3(grupoLogs: GrupoLogs) {

		const fpMsNodeUrl = UtilMS.getFPMsNodeUrl();
		
		if (!fpMsNodeUrl) {
			UtilLog.CONSOLE_WARN_ORIGINAL("Descartando logs por falta de cfg.");
			return;
		}

		try {
			const fflate = UtilBoot.getFflate();
			const enc = new TextEncoder();
			const buf = enc.encode(JSON.stringify(grupoLogs, null, 4));
			const compressed = fflate.compressSync(buf, { level: 9, mem: 4 }); // gzip é o default

			const response = await fetch(`${fpMsNodeUrl}/logs-front/assinar`, {
				method: "POST",
				headers: { "Content-type": "application/json" },
				body: JSON.stringify({ dir: grupoLogs.dir, extensao: ".log.gz" })
			});

			const body = await UtilLog.getBody(response);

			if (!response.ok) {
				throw new Error("Geração de URL para upload retornou status code " + response.status + " com body " + body);
			}

			if (body) {
				if (!body.urlUploadPUT) {
					throw new Error("Geração de URL para upload retornou sem 'urlUploadPUT' com body " + body);
				}

				const responsePut = await fetch(body.urlUploadPUT, {
					method: "PUT",
					headers: {
						"Content-Type": "application/gzip",
					},
					body: compressed
				});
				
				if (!responsePut.ok) {
					const bodyPut = await UtilLog.getBody(response);
					throw new Error("Put no S3 deu erro " + responsePut.status + " com body " + bodyPut);
				}

			} else {
				UtilLog.addPendentes(grupoLogs);
			}

		} catch (e) {
			UtilLog.addToBuffer((new Date()).toISOString(), "ERROR", UtilLog.name, ["Erro ao enviar logs para S3", e]);
			UtilLog.addPendentes(grupoLogs);
		}
	}

	private static addPendentes(grupoLogs: GrupoLogs) {

		try {
			if (!grupoLogs?.logs?.length) return;

			const pendentesString = localStorage.getItem("log-front-pendente");
			const pendentes: GrupoLogs[] = pendentesString ? JSON.parse(pendentesString) : [];

			pendentes.push(grupoLogs);

			localStorage.setItem("log-front-pendente", JSON.stringify(pendentes));

		} catch (e) {
			UtilLog.CONSOLE_WARN_ORIGINAL("Erro inesperado no salvando logs pendentes no localStorage", e);
		}
	}

	static getIdNavegador(): string {
		let idNavegador = localStorage.getItem("fp-id-navegador");
		if (!idNavegador) {
			idNavegador = Date.now() + "";
			for (let i = 0; i < 4; i++) {
				idNavegador += UtilLog.LETRAS.charAt(Math.floor(Math.random() * UtilLog.LETRAS.length));
			}
			localStorage.setItem("fp-id-navegador", idNavegador);
		}
		return idNavegador;
	}

	static async getBody(response: Response) {
		try {
			return await response.json();
		} catch (ignored) {
			try {
				return await response.text();
			} catch (ignored2) {
				return null;
			}
		}
	}

	static async readGzFileContent(fileURL) {
		try {
			const fflate = UtilBoot.getFflate();

			const response = await fetch(fileURL).then(res => res.arrayBuffer());

			const decompressed = fflate.decompressSync(new Uint8Array(response));
			const text = fflate.strFromU8(decompressed);

			return JSON.parse(text);

		} catch (error) {
			console.error('Error reading file:', error);
			throw error;
		}
	}
}

type ItemLog = {
	contador: number;
	data: string;
	level: string;
	logger: string;
	msg: string;
}

type GrupoLogs = {
	host: string;
	agente: string;
	logs: ItemLog[];
	dir: string;
	// sem auth
	idNavegador: string;
	// com auth
	codUsuarioSessaoWeb?: number;
	codEmpresa?: number;
	codUsuario?: number;
}

UtilLog.inicializar();